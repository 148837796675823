// Borrowed from coral-xyz/anchor
//
// https://github.com/coral-xyz/anchor/blob/master/ts/packages/anchor/src/idl.ts
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Buffer } from "buffer";
import { PublicKey } from "@solana/web3.js";
import * as borsh from "@project-serum/borsh";
// Deterministic IDL address as a function of the program id.
export function idlAddress(programId) {
    return __awaiter(this, void 0, void 0, function* () {
        const base = (yield PublicKey.findProgramAddress([], programId))[0];
        return yield PublicKey.createWithSeed(base, seed(), programId);
    });
}
// Seed for generating the idlAddress.
export function seed() {
    return "anchor:idl";
}
const IDL_ACCOUNT_LAYOUT = borsh.struct([
    borsh.publicKey("authority"),
    borsh.vecU8("data"),
]);
export function decodeIdlAccount(data) {
    return IDL_ACCOUNT_LAYOUT.decode(data);
}
export function encodeIdlAccount(acc) {
    const buffer = Buffer.alloc(1000); // TODO: use a tighter buffer.
    const len = IDL_ACCOUNT_LAYOUT.encode(acc, buffer);
    return buffer.slice(0, len);
}
