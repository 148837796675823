var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export const parseSmartContractStateResponse = ({ data, }) => JSON.parse(Buffer.from(data).toString());
export const queryExternalIdInjective = (client, tokenBridgeAddress, externalTokenId) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d, _e;
    try {
        const response = yield client.fetchSmartContractState(tokenBridgeAddress, Buffer.from(JSON.stringify({
            external_id: {
                external_id: Buffer.from(externalTokenId, "hex").toString("base64"),
            },
        })).toString("base64"));
        const parsedResponse = parseSmartContractStateResponse(response);
        const denomOrAddress = ((_a = parsedResponse.token_id.Bank) === null || _a === void 0 ? void 0 : _a.denom) ||
            ((_c = (_b = parsedResponse.token_id.Contract) === null || _b === void 0 ? void 0 : _b.NativeCW20) === null || _c === void 0 ? void 0 : _c.contract_address) ||
            ((_e = (_d = parsedResponse.token_id.Contract) === null || _d === void 0 ? void 0 : _d.ForeignToken) === null || _e === void 0 ? void 0 : _e.foreign_address);
        return denomOrAddress || null;
    }
    catch (_f) {
        return null;
    }
});
