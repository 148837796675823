var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { coalesceChainId } from "../utils/consts";
import { publicrpc } from "@certusone/wormhole-sdk-proto-web";
const { GrpcWebImpl, PublicRPCServiceClientImpl } = publicrpc;
export function getSignedVAA(host, emitterChain, emitterAddress, sequence, extraGrpcOpts = {}) {
    return __awaiter(this, void 0, void 0, function* () {
        const rpc = new GrpcWebImpl(host, extraGrpcOpts);
        const api = new PublicRPCServiceClientImpl(rpc);
        return yield api.GetSignedVAA({
            messageId: {
                emitterChain: coalesceChainId(emitterChain),
                emitterAddress,
                sequence,
            },
        });
    });
}
