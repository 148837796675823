var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BN } from "@project-serum/anchor";
import { Keypair, PublicKey, Transaction, } from "@solana/web3.js";
import { isBytes } from "ethers/lib/utils";
import { NFTBridge__factory, NFTImplementation__factory, } from "../ethers-contracts";
import { createBridgeFeeTransferInstruction } from "../solana";
import { createApproveAuthoritySignerInstruction, createTransferNativeInstruction, createTransferWrappedInstruction, } from "../solana/nftBridge";
import { CHAIN_ID_SOLANA, coalesceChainId, createNonce, } from "../utils";
export function transferFromEth(nftBridgeAddress, signer, tokenAddress, tokenID, recipientChain, recipientAddress, overrides = {}) {
    return __awaiter(this, void 0, void 0, function* () {
        const recipientChainId = coalesceChainId(recipientChain);
        //TODO: should we check if token attestation exists on the target chain
        const token = NFTImplementation__factory.connect(tokenAddress, signer);
        yield (yield token.approve(nftBridgeAddress, tokenID, overrides)).wait();
        const bridge = NFTBridge__factory.connect(nftBridgeAddress, signer);
        const v = yield bridge.transferNFT(tokenAddress, tokenID, recipientChainId, recipientAddress, createNonce(), overrides);
        const receipt = yield v.wait();
        return receipt;
    });
}
export function transferFromSolana(connection, bridgeAddress, nftBridgeAddress, payerAddress, fromAddress, mintAddress, targetAddress, targetChain, originAddress, originChain, originTokenId, commitment) {
    return __awaiter(this, void 0, void 0, function* () {
        const originChainId = originChain
            ? coalesceChainId(originChain)
            : undefined;
        const nonce = createNonce().readUInt32LE(0);
        const transferIx = yield createBridgeFeeTransferInstruction(connection, bridgeAddress, payerAddress);
        const approvalIx = createApproveAuthoritySignerInstruction(nftBridgeAddress, fromAddress, payerAddress);
        let message = Keypair.generate();
        const isSolanaNative = originChain === undefined || originChain === CHAIN_ID_SOLANA;
        if (!isSolanaNative && (!originAddress || !originTokenId)) {
            return Promise.reject("originAddress and originTokenId are required when specifying originChain");
        }
        const nftBridgeTransferIx = isSolanaNative
            ? createTransferNativeInstruction(nftBridgeAddress, bridgeAddress, payerAddress, message.publicKey, fromAddress, mintAddress, nonce, targetAddress, coalesceChainId(targetChain))
            : createTransferWrappedInstruction(nftBridgeAddress, bridgeAddress, payerAddress, message.publicKey, fromAddress, payerAddress, originChainId, originAddress, isBytes(originTokenId)
                ? BigInt(new BN(originTokenId).toString())
                : originTokenId, nonce, targetAddress, coalesceChainId(targetChain));
        const transaction = new Transaction().add(transferIx, approvalIx, nftBridgeTransferIx);
        const { blockhash } = yield connection.getLatestBlockhash(commitment);
        transaction.recentBlockhash = blockhash;
        transaction.feePayer = new PublicKey(payerAddress);
        transaction.partialSign(message);
        return transaction;
    });
}
export function transferFromAptos(nftBridgeAddress, creatorAddress, collectionName, tokenName, propertyVersion, recipientChain, recipient) {
    const recipientChainId = coalesceChainId(recipientChain);
    return {
        function: `${nftBridgeAddress}::transfer_nft::transfer_nft_entry`,
        type_arguments: [],
        arguments: [
            creatorAddress,
            collectionName,
            tokenName,
            propertyVersion,
            recipientChainId,
            recipient,
            createNonce().readUInt32LE(0),
        ],
    };
}
