/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { utils, Contract, ContractFactory } from "ethers";
const _abi = [
    {
        type: "event",
        name: "LogGuardianSetChanged",
        inputs: [
            {
                name: "oldGuardianIndex",
                type: "uint32",
                indexed: false,
                internalType: "uint32",
            },
            {
                name: "newGuardianIndex",
                type: "uint32",
                indexed: false,
                internalType: "uint32",
            },
        ],
        anonymous: false,
    },
    {
        type: "event",
        name: "LogMessagePublished",
        inputs: [
            {
                name: "emitter_address",
                type: "address",
                indexed: false,
                internalType: "address",
            },
            {
                name: "nonce",
                type: "uint32",
                indexed: false,
                internalType: "uint32",
            },
            {
                name: "payload",
                type: "bytes",
                indexed: false,
                internalType: "bytes",
            },
        ],
        anonymous: false,
    },
];
const _bytecode = "0x6080604052348015600f57600080fd5b50603f80601d6000396000f3fe6080604052600080fdfea2646970667358221220dc136fb15061bf210fd5bb2bb3d98d044ee3f4283caaf2ed49d5dabf69e1d93864736f6c63430008040033";
const isSuperArgs = (xs) => xs.length > 1;
export class Events__factory extends ContractFactory {
    constructor(...args) {
        if (isSuperArgs(args)) {
            super(...args);
        }
        else {
            super(_abi, _bytecode, args[0]);
        }
    }
    deploy(overrides) {
        return super.deploy(overrides || {});
    }
    getDeployTransaction(overrides) {
        return super.getDeployTransaction(overrides || {});
    }
    attach(address) {
        return super.attach(address);
    }
    connect(signer) {
        return super.connect(signer);
    }
    static createInterface() {
        return new utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    }
}
Events__factory.bytecode = _bytecode;
Events__factory.abi = _abi;
