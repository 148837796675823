var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
/* eslint-disable */
import Long from "long";
import { grpc } from "@improbable-eng/grpc-web";
import _m0 from "protobufjs/minimal";
import { BrowserHeaders } from "browser-headers";
import { ObservationRequest } from "../../gossip/v1/gossip";
export var protobufPackage = "node.v1";
export var ModificationKind;
(function (ModificationKind) {
    ModificationKind[ModificationKind["MODIFICATION_KIND_UNSPECIFIED"] = 0] = "MODIFICATION_KIND_UNSPECIFIED";
    ModificationKind[ModificationKind["MODIFICATION_KIND_ADD"] = 1] = "MODIFICATION_KIND_ADD";
    ModificationKind[ModificationKind["MODIFICATION_KIND_SUBTRACT"] = 2] = "MODIFICATION_KIND_SUBTRACT";
    ModificationKind[ModificationKind["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(ModificationKind || (ModificationKind = {}));
export function modificationKindFromJSON(object) {
    switch (object) {
        case 0:
        case "MODIFICATION_KIND_UNSPECIFIED":
            return ModificationKind.MODIFICATION_KIND_UNSPECIFIED;
        case 1:
        case "MODIFICATION_KIND_ADD":
            return ModificationKind.MODIFICATION_KIND_ADD;
        case 2:
        case "MODIFICATION_KIND_SUBTRACT":
            return ModificationKind.MODIFICATION_KIND_SUBTRACT;
        case -1:
        case "UNRECOGNIZED":
        default:
            return ModificationKind.UNRECOGNIZED;
    }
}
export function modificationKindToJSON(object) {
    switch (object) {
        case ModificationKind.MODIFICATION_KIND_UNSPECIFIED:
            return "MODIFICATION_KIND_UNSPECIFIED";
        case ModificationKind.MODIFICATION_KIND_ADD:
            return "MODIFICATION_KIND_ADD";
        case ModificationKind.MODIFICATION_KIND_SUBTRACT:
            return "MODIFICATION_KIND_SUBTRACT";
        default:
            return "UNKNOWN";
    }
}
export var WormchainWasmInstantiateAllowlistAction;
(function (WormchainWasmInstantiateAllowlistAction) {
    WormchainWasmInstantiateAllowlistAction[WormchainWasmInstantiateAllowlistAction["WORMCHAIN_WASM_INSTANTIATE_ALLOWLIST_ACTION_UNSPECIFIED"] = 0] = "WORMCHAIN_WASM_INSTANTIATE_ALLOWLIST_ACTION_UNSPECIFIED";
    WormchainWasmInstantiateAllowlistAction[WormchainWasmInstantiateAllowlistAction["WORMCHAIN_WASM_INSTANTIATE_ALLOWLIST_ACTION_ADD"] = 1] = "WORMCHAIN_WASM_INSTANTIATE_ALLOWLIST_ACTION_ADD";
    WormchainWasmInstantiateAllowlistAction[WormchainWasmInstantiateAllowlistAction["WORMCHAIN_WASM_INSTANTIATE_ALLOWLIST_ACTION_DELETE"] = 2] = "WORMCHAIN_WASM_INSTANTIATE_ALLOWLIST_ACTION_DELETE";
    WormchainWasmInstantiateAllowlistAction[WormchainWasmInstantiateAllowlistAction["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(WormchainWasmInstantiateAllowlistAction || (WormchainWasmInstantiateAllowlistAction = {}));
export function wormchainWasmInstantiateAllowlistActionFromJSON(object) {
    switch (object) {
        case 0:
        case "WORMCHAIN_WASM_INSTANTIATE_ALLOWLIST_ACTION_UNSPECIFIED":
            return WormchainWasmInstantiateAllowlistAction.WORMCHAIN_WASM_INSTANTIATE_ALLOWLIST_ACTION_UNSPECIFIED;
        case 1:
        case "WORMCHAIN_WASM_INSTANTIATE_ALLOWLIST_ACTION_ADD":
            return WormchainWasmInstantiateAllowlistAction.WORMCHAIN_WASM_INSTANTIATE_ALLOWLIST_ACTION_ADD;
        case 2:
        case "WORMCHAIN_WASM_INSTANTIATE_ALLOWLIST_ACTION_DELETE":
            return WormchainWasmInstantiateAllowlistAction.WORMCHAIN_WASM_INSTANTIATE_ALLOWLIST_ACTION_DELETE;
        case -1:
        case "UNRECOGNIZED":
        default:
            return WormchainWasmInstantiateAllowlistAction.UNRECOGNIZED;
    }
}
export function wormchainWasmInstantiateAllowlistActionToJSON(object) {
    switch (object) {
        case WormchainWasmInstantiateAllowlistAction.WORMCHAIN_WASM_INSTANTIATE_ALLOWLIST_ACTION_UNSPECIFIED:
            return "WORMCHAIN_WASM_INSTANTIATE_ALLOWLIST_ACTION_UNSPECIFIED";
        case WormchainWasmInstantiateAllowlistAction.WORMCHAIN_WASM_INSTANTIATE_ALLOWLIST_ACTION_ADD:
            return "WORMCHAIN_WASM_INSTANTIATE_ALLOWLIST_ACTION_ADD";
        case WormchainWasmInstantiateAllowlistAction.WORMCHAIN_WASM_INSTANTIATE_ALLOWLIST_ACTION_DELETE:
            return "WORMCHAIN_WASM_INSTANTIATE_ALLOWLIST_ACTION_DELETE";
        default:
            return "UNKNOWN";
    }
}
export var IbcUpdateChannelChainModule;
(function (IbcUpdateChannelChainModule) {
    IbcUpdateChannelChainModule[IbcUpdateChannelChainModule["IBC_UPDATE_CHANNEL_CHAIN_MODULE_UNSPECIFIED"] = 0] = "IBC_UPDATE_CHANNEL_CHAIN_MODULE_UNSPECIFIED";
    IbcUpdateChannelChainModule[IbcUpdateChannelChainModule["IBC_UPDATE_CHANNEL_CHAIN_MODULE_RECEIVER"] = 1] = "IBC_UPDATE_CHANNEL_CHAIN_MODULE_RECEIVER";
    IbcUpdateChannelChainModule[IbcUpdateChannelChainModule["IBC_UPDATE_CHANNEL_CHAIN_MODULE_TRANSLATOR"] = 2] = "IBC_UPDATE_CHANNEL_CHAIN_MODULE_TRANSLATOR";
    IbcUpdateChannelChainModule[IbcUpdateChannelChainModule["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(IbcUpdateChannelChainModule || (IbcUpdateChannelChainModule = {}));
export function ibcUpdateChannelChainModuleFromJSON(object) {
    switch (object) {
        case 0:
        case "IBC_UPDATE_CHANNEL_CHAIN_MODULE_UNSPECIFIED":
            return IbcUpdateChannelChainModule.IBC_UPDATE_CHANNEL_CHAIN_MODULE_UNSPECIFIED;
        case 1:
        case "IBC_UPDATE_CHANNEL_CHAIN_MODULE_RECEIVER":
            return IbcUpdateChannelChainModule.IBC_UPDATE_CHANNEL_CHAIN_MODULE_RECEIVER;
        case 2:
        case "IBC_UPDATE_CHANNEL_CHAIN_MODULE_TRANSLATOR":
            return IbcUpdateChannelChainModule.IBC_UPDATE_CHANNEL_CHAIN_MODULE_TRANSLATOR;
        case -1:
        case "UNRECOGNIZED":
        default:
            return IbcUpdateChannelChainModule.UNRECOGNIZED;
    }
}
export function ibcUpdateChannelChainModuleToJSON(object) {
    switch (object) {
        case IbcUpdateChannelChainModule.IBC_UPDATE_CHANNEL_CHAIN_MODULE_UNSPECIFIED:
            return "IBC_UPDATE_CHANNEL_CHAIN_MODULE_UNSPECIFIED";
        case IbcUpdateChannelChainModule.IBC_UPDATE_CHANNEL_CHAIN_MODULE_RECEIVER:
            return "IBC_UPDATE_CHANNEL_CHAIN_MODULE_RECEIVER";
        case IbcUpdateChannelChainModule.IBC_UPDATE_CHANNEL_CHAIN_MODULE_TRANSLATOR:
            return "IBC_UPDATE_CHANNEL_CHAIN_MODULE_TRANSLATOR";
        default:
            return "UNKNOWN";
    }
}
var baseInjectGovernanceVAARequest = {
    currentSetIndex: 0,
    timestamp: 0,
};
export var InjectGovernanceVAARequest = {
    encode: function (message, writer) {
        var e_1, _a;
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.currentSetIndex !== 0) {
            writer.uint32(8).uint32(message.currentSetIndex);
        }
        try {
            for (var _b = __values(message.messages), _c = _b.next(); !_c.done; _c = _b.next()) {
                var v = _c.value;
                GovernanceMessage.encode(v, writer.uint32(18).fork()).ldelim();
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (message.timestamp !== 0) {
            writer.uint32(24).uint32(message.timestamp);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseInjectGovernanceVAARequest);
        message.messages = [];
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.currentSetIndex = reader.uint32();
                    break;
                case 2:
                    message.messages.push(GovernanceMessage.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.timestamp = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var e_2, _a;
        var message = __assign({}, baseInjectGovernanceVAARequest);
        message.messages = [];
        if (object.currentSetIndex !== undefined &&
            object.currentSetIndex !== null) {
            message.currentSetIndex = Number(object.currentSetIndex);
        }
        else {
            message.currentSetIndex = 0;
        }
        if (object.messages !== undefined && object.messages !== null) {
            try {
                for (var _b = __values(object.messages), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var e = _c.value;
                    message.messages.push(GovernanceMessage.fromJSON(e));
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
        if (object.timestamp !== undefined && object.timestamp !== null) {
            message.timestamp = Number(object.timestamp);
        }
        else {
            message.timestamp = 0;
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.currentSetIndex !== undefined &&
            (obj.currentSetIndex = message.currentSetIndex);
        if (message.messages) {
            obj.messages = message.messages.map(function (e) {
                return e ? GovernanceMessage.toJSON(e) : undefined;
            });
        }
        else {
            obj.messages = [];
        }
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        return obj;
    },
    fromPartial: function (object) {
        var e_3, _a;
        var message = __assign({}, baseInjectGovernanceVAARequest);
        message.messages = [];
        if (object.currentSetIndex !== undefined &&
            object.currentSetIndex !== null) {
            message.currentSetIndex = object.currentSetIndex;
        }
        else {
            message.currentSetIndex = 0;
        }
        if (object.messages !== undefined && object.messages !== null) {
            try {
                for (var _b = __values(object.messages), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var e = _c.value;
                    message.messages.push(GovernanceMessage.fromPartial(e));
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_3) throw e_3.error; }
            }
        }
        if (object.timestamp !== undefined && object.timestamp !== null) {
            message.timestamp = object.timestamp;
        }
        else {
            message.timestamp = 0;
        }
        return message;
    },
};
var baseGovernanceMessage = { sequence: "0", nonce: 0 };
export var GovernanceMessage = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.sequence !== "0") {
            writer.uint32(16).uint64(message.sequence);
        }
        if (message.nonce !== 0) {
            writer.uint32(24).uint32(message.nonce);
        }
        if (message.guardianSet !== undefined) {
            GuardianSetUpdate.encode(message.guardianSet, writer.uint32(82).fork()).ldelim();
        }
        if (message.contractUpgrade !== undefined) {
            ContractUpgrade.encode(message.contractUpgrade, writer.uint32(90).fork()).ldelim();
        }
        if (message.bridgeRegisterChain !== undefined) {
            BridgeRegisterChain.encode(message.bridgeRegisterChain, writer.uint32(98).fork()).ldelim();
        }
        if (message.bridgeContractUpgrade !== undefined) {
            BridgeUpgradeContract.encode(message.bridgeContractUpgrade, writer.uint32(106).fork()).ldelim();
        }
        if (message.wormchainStoreCode !== undefined) {
            WormchainStoreCode.encode(message.wormchainStoreCode, writer.uint32(114).fork()).ldelim();
        }
        if (message.wormchainInstantiateContract !== undefined) {
            WormchainInstantiateContract.encode(message.wormchainInstantiateContract, writer.uint32(122).fork()).ldelim();
        }
        if (message.wormchainMigrateContract !== undefined) {
            WormchainMigrateContract.encode(message.wormchainMigrateContract, writer.uint32(130).fork()).ldelim();
        }
        if (message.wormchainWasmInstantiateAllowlist !== undefined) {
            WormchainWasmInstantiateAllowlist.encode(message.wormchainWasmInstantiateAllowlist, writer.uint32(186).fork()).ldelim();
        }
        if (message.gatewayScheduleUpgrade !== undefined) {
            GatewayScheduleUpgrade.encode(message.gatewayScheduleUpgrade, writer.uint32(194).fork()).ldelim();
        }
        if (message.gatewayCancelUpgrade !== undefined) {
            GatewayCancelUpgrade.encode(message.gatewayCancelUpgrade, writer.uint32(202).fork()).ldelim();
        }
        if (message.gatewayIbcComposabilityMwSetContract !== undefined) {
            GatewayIbcComposabilityMwSetContract.encode(message.gatewayIbcComposabilityMwSetContract, writer.uint32(210).fork()).ldelim();
        }
        if (message.accountantModifyBalance !== undefined) {
            AccountantModifyBalance.encode(message.accountantModifyBalance, writer.uint32(138).fork()).ldelim();
        }
        if (message.circleIntegrationUpdateWormholeFinality !== undefined) {
            CircleIntegrationUpdateWormholeFinality.encode(message.circleIntegrationUpdateWormholeFinality, writer.uint32(146).fork()).ldelim();
        }
        if (message.circleIntegrationRegisterEmitterAndDomain !== undefined) {
            CircleIntegrationRegisterEmitterAndDomain.encode(message.circleIntegrationRegisterEmitterAndDomain, writer.uint32(154).fork()).ldelim();
        }
        if (message.circleIntegrationUpgradeContractImplementation !== undefined) {
            CircleIntegrationUpgradeContractImplementation.encode(message.circleIntegrationUpgradeContractImplementation, writer.uint32(162).fork()).ldelim();
        }
        if (message.ibcUpdateChannelChain !== undefined) {
            IbcUpdateChannelChain.encode(message.ibcUpdateChannelChain, writer.uint32(170).fork()).ldelim();
        }
        if (message.wormholeRelayerSetDefaultDeliveryProvider !== undefined) {
            WormholeRelayerSetDefaultDeliveryProvider.encode(message.wormholeRelayerSetDefaultDeliveryProvider, writer.uint32(178).fork()).ldelim();
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseGovernanceMessage);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 2:
                    message.sequence = longToString(reader.uint64());
                    break;
                case 3:
                    message.nonce = reader.uint32();
                    break;
                case 10:
                    message.guardianSet = GuardianSetUpdate.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.contractUpgrade = ContractUpgrade.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.bridgeRegisterChain = BridgeRegisterChain.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.bridgeContractUpgrade = BridgeUpgradeContract.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.wormchainStoreCode = WormchainStoreCode.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.wormchainInstantiateContract =
                        WormchainInstantiateContract.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.wormchainMigrateContract = WormchainMigrateContract.decode(reader, reader.uint32());
                    break;
                case 23:
                    message.wormchainWasmInstantiateAllowlist =
                        WormchainWasmInstantiateAllowlist.decode(reader, reader.uint32());
                    break;
                case 24:
                    message.gatewayScheduleUpgrade = GatewayScheduleUpgrade.decode(reader, reader.uint32());
                    break;
                case 25:
                    message.gatewayCancelUpgrade = GatewayCancelUpgrade.decode(reader, reader.uint32());
                    break;
                case 26:
                    message.gatewayIbcComposabilityMwSetContract =
                        GatewayIbcComposabilityMwSetContract.decode(reader, reader.uint32());
                    break;
                case 17:
                    message.accountantModifyBalance = AccountantModifyBalance.decode(reader, reader.uint32());
                    break;
                case 18:
                    message.circleIntegrationUpdateWormholeFinality =
                        CircleIntegrationUpdateWormholeFinality.decode(reader, reader.uint32());
                    break;
                case 19:
                    message.circleIntegrationRegisterEmitterAndDomain =
                        CircleIntegrationRegisterEmitterAndDomain.decode(reader, reader.uint32());
                    break;
                case 20:
                    message.circleIntegrationUpgradeContractImplementation =
                        CircleIntegrationUpgradeContractImplementation.decode(reader, reader.uint32());
                    break;
                case 21:
                    message.ibcUpdateChannelChain = IbcUpdateChannelChain.decode(reader, reader.uint32());
                    break;
                case 22:
                    message.wormholeRelayerSetDefaultDeliveryProvider =
                        WormholeRelayerSetDefaultDeliveryProvider.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseGovernanceMessage);
        if (object.sequence !== undefined && object.sequence !== null) {
            message.sequence = String(object.sequence);
        }
        else {
            message.sequence = "0";
        }
        if (object.nonce !== undefined && object.nonce !== null) {
            message.nonce = Number(object.nonce);
        }
        else {
            message.nonce = 0;
        }
        if (object.guardianSet !== undefined && object.guardianSet !== null) {
            message.guardianSet = GuardianSetUpdate.fromJSON(object.guardianSet);
        }
        else {
            message.guardianSet = undefined;
        }
        if (object.contractUpgrade !== undefined &&
            object.contractUpgrade !== null) {
            message.contractUpgrade = ContractUpgrade.fromJSON(object.contractUpgrade);
        }
        else {
            message.contractUpgrade = undefined;
        }
        if (object.bridgeRegisterChain !== undefined &&
            object.bridgeRegisterChain !== null) {
            message.bridgeRegisterChain = BridgeRegisterChain.fromJSON(object.bridgeRegisterChain);
        }
        else {
            message.bridgeRegisterChain = undefined;
        }
        if (object.bridgeContractUpgrade !== undefined &&
            object.bridgeContractUpgrade !== null) {
            message.bridgeContractUpgrade = BridgeUpgradeContract.fromJSON(object.bridgeContractUpgrade);
        }
        else {
            message.bridgeContractUpgrade = undefined;
        }
        if (object.wormchainStoreCode !== undefined &&
            object.wormchainStoreCode !== null) {
            message.wormchainStoreCode = WormchainStoreCode.fromJSON(object.wormchainStoreCode);
        }
        else {
            message.wormchainStoreCode = undefined;
        }
        if (object.wormchainInstantiateContract !== undefined &&
            object.wormchainInstantiateContract !== null) {
            message.wormchainInstantiateContract =
                WormchainInstantiateContract.fromJSON(object.wormchainInstantiateContract);
        }
        else {
            message.wormchainInstantiateContract = undefined;
        }
        if (object.wormchainMigrateContract !== undefined &&
            object.wormchainMigrateContract !== null) {
            message.wormchainMigrateContract = WormchainMigrateContract.fromJSON(object.wormchainMigrateContract);
        }
        else {
            message.wormchainMigrateContract = undefined;
        }
        if (object.wormchainWasmInstantiateAllowlist !== undefined &&
            object.wormchainWasmInstantiateAllowlist !== null) {
            message.wormchainWasmInstantiateAllowlist =
                WormchainWasmInstantiateAllowlist.fromJSON(object.wormchainWasmInstantiateAllowlist);
        }
        else {
            message.wormchainWasmInstantiateAllowlist = undefined;
        }
        if (object.gatewayScheduleUpgrade !== undefined &&
            object.gatewayScheduleUpgrade !== null) {
            message.gatewayScheduleUpgrade = GatewayScheduleUpgrade.fromJSON(object.gatewayScheduleUpgrade);
        }
        else {
            message.gatewayScheduleUpgrade = undefined;
        }
        if (object.gatewayCancelUpgrade !== undefined &&
            object.gatewayCancelUpgrade !== null) {
            message.gatewayCancelUpgrade = GatewayCancelUpgrade.fromJSON(object.gatewayCancelUpgrade);
        }
        else {
            message.gatewayCancelUpgrade = undefined;
        }
        if (object.gatewayIbcComposabilityMwSetContract !== undefined &&
            object.gatewayIbcComposabilityMwSetContract !== null) {
            message.gatewayIbcComposabilityMwSetContract =
                GatewayIbcComposabilityMwSetContract.fromJSON(object.gatewayIbcComposabilityMwSetContract);
        }
        else {
            message.gatewayIbcComposabilityMwSetContract = undefined;
        }
        if (object.accountantModifyBalance !== undefined &&
            object.accountantModifyBalance !== null) {
            message.accountantModifyBalance = AccountantModifyBalance.fromJSON(object.accountantModifyBalance);
        }
        else {
            message.accountantModifyBalance = undefined;
        }
        if (object.circleIntegrationUpdateWormholeFinality !== undefined &&
            object.circleIntegrationUpdateWormholeFinality !== null) {
            message.circleIntegrationUpdateWormholeFinality =
                CircleIntegrationUpdateWormholeFinality.fromJSON(object.circleIntegrationUpdateWormholeFinality);
        }
        else {
            message.circleIntegrationUpdateWormholeFinality = undefined;
        }
        if (object.circleIntegrationRegisterEmitterAndDomain !== undefined &&
            object.circleIntegrationRegisterEmitterAndDomain !== null) {
            message.circleIntegrationRegisterEmitterAndDomain =
                CircleIntegrationRegisterEmitterAndDomain.fromJSON(object.circleIntegrationRegisterEmitterAndDomain);
        }
        else {
            message.circleIntegrationRegisterEmitterAndDomain = undefined;
        }
        if (object.circleIntegrationUpgradeContractImplementation !== undefined &&
            object.circleIntegrationUpgradeContractImplementation !== null) {
            message.circleIntegrationUpgradeContractImplementation =
                CircleIntegrationUpgradeContractImplementation.fromJSON(object.circleIntegrationUpgradeContractImplementation);
        }
        else {
            message.circleIntegrationUpgradeContractImplementation = undefined;
        }
        if (object.ibcUpdateChannelChain !== undefined &&
            object.ibcUpdateChannelChain !== null) {
            message.ibcUpdateChannelChain = IbcUpdateChannelChain.fromJSON(object.ibcUpdateChannelChain);
        }
        else {
            message.ibcUpdateChannelChain = undefined;
        }
        if (object.wormholeRelayerSetDefaultDeliveryProvider !== undefined &&
            object.wormholeRelayerSetDefaultDeliveryProvider !== null) {
            message.wormholeRelayerSetDefaultDeliveryProvider =
                WormholeRelayerSetDefaultDeliveryProvider.fromJSON(object.wormholeRelayerSetDefaultDeliveryProvider);
        }
        else {
            message.wormholeRelayerSetDefaultDeliveryProvider = undefined;
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.sequence !== undefined && (obj.sequence = message.sequence);
        message.nonce !== undefined && (obj.nonce = message.nonce);
        message.guardianSet !== undefined &&
            (obj.guardianSet = message.guardianSet
                ? GuardianSetUpdate.toJSON(message.guardianSet)
                : undefined);
        message.contractUpgrade !== undefined &&
            (obj.contractUpgrade = message.contractUpgrade
                ? ContractUpgrade.toJSON(message.contractUpgrade)
                : undefined);
        message.bridgeRegisterChain !== undefined &&
            (obj.bridgeRegisterChain = message.bridgeRegisterChain
                ? BridgeRegisterChain.toJSON(message.bridgeRegisterChain)
                : undefined);
        message.bridgeContractUpgrade !== undefined &&
            (obj.bridgeContractUpgrade = message.bridgeContractUpgrade
                ? BridgeUpgradeContract.toJSON(message.bridgeContractUpgrade)
                : undefined);
        message.wormchainStoreCode !== undefined &&
            (obj.wormchainStoreCode = message.wormchainStoreCode
                ? WormchainStoreCode.toJSON(message.wormchainStoreCode)
                : undefined);
        message.wormchainInstantiateContract !== undefined &&
            (obj.wormchainInstantiateContract = message.wormchainInstantiateContract
                ? WormchainInstantiateContract.toJSON(message.wormchainInstantiateContract)
                : undefined);
        message.wormchainMigrateContract !== undefined &&
            (obj.wormchainMigrateContract = message.wormchainMigrateContract
                ? WormchainMigrateContract.toJSON(message.wormchainMigrateContract)
                : undefined);
        message.wormchainWasmInstantiateAllowlist !== undefined &&
            (obj.wormchainWasmInstantiateAllowlist =
                message.wormchainWasmInstantiateAllowlist
                    ? WormchainWasmInstantiateAllowlist.toJSON(message.wormchainWasmInstantiateAllowlist)
                    : undefined);
        message.gatewayScheduleUpgrade !== undefined &&
            (obj.gatewayScheduleUpgrade = message.gatewayScheduleUpgrade
                ? GatewayScheduleUpgrade.toJSON(message.gatewayScheduleUpgrade)
                : undefined);
        message.gatewayCancelUpgrade !== undefined &&
            (obj.gatewayCancelUpgrade = message.gatewayCancelUpgrade
                ? GatewayCancelUpgrade.toJSON(message.gatewayCancelUpgrade)
                : undefined);
        message.gatewayIbcComposabilityMwSetContract !== undefined &&
            (obj.gatewayIbcComposabilityMwSetContract =
                message.gatewayIbcComposabilityMwSetContract
                    ? GatewayIbcComposabilityMwSetContract.toJSON(message.gatewayIbcComposabilityMwSetContract)
                    : undefined);
        message.accountantModifyBalance !== undefined &&
            (obj.accountantModifyBalance = message.accountantModifyBalance
                ? AccountantModifyBalance.toJSON(message.accountantModifyBalance)
                : undefined);
        message.circleIntegrationUpdateWormholeFinality !== undefined &&
            (obj.circleIntegrationUpdateWormholeFinality =
                message.circleIntegrationUpdateWormholeFinality
                    ? CircleIntegrationUpdateWormholeFinality.toJSON(message.circleIntegrationUpdateWormholeFinality)
                    : undefined);
        message.circleIntegrationRegisterEmitterAndDomain !== undefined &&
            (obj.circleIntegrationRegisterEmitterAndDomain =
                message.circleIntegrationRegisterEmitterAndDomain
                    ? CircleIntegrationRegisterEmitterAndDomain.toJSON(message.circleIntegrationRegisterEmitterAndDomain)
                    : undefined);
        message.circleIntegrationUpgradeContractImplementation !== undefined &&
            (obj.circleIntegrationUpgradeContractImplementation =
                message.circleIntegrationUpgradeContractImplementation
                    ? CircleIntegrationUpgradeContractImplementation.toJSON(message.circleIntegrationUpgradeContractImplementation)
                    : undefined);
        message.ibcUpdateChannelChain !== undefined &&
            (obj.ibcUpdateChannelChain = message.ibcUpdateChannelChain
                ? IbcUpdateChannelChain.toJSON(message.ibcUpdateChannelChain)
                : undefined);
        message.wormholeRelayerSetDefaultDeliveryProvider !== undefined &&
            (obj.wormholeRelayerSetDefaultDeliveryProvider =
                message.wormholeRelayerSetDefaultDeliveryProvider
                    ? WormholeRelayerSetDefaultDeliveryProvider.toJSON(message.wormholeRelayerSetDefaultDeliveryProvider)
                    : undefined);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseGovernanceMessage);
        if (object.sequence !== undefined && object.sequence !== null) {
            message.sequence = object.sequence;
        }
        else {
            message.sequence = "0";
        }
        if (object.nonce !== undefined && object.nonce !== null) {
            message.nonce = object.nonce;
        }
        else {
            message.nonce = 0;
        }
        if (object.guardianSet !== undefined && object.guardianSet !== null) {
            message.guardianSet = GuardianSetUpdate.fromPartial(object.guardianSet);
        }
        else {
            message.guardianSet = undefined;
        }
        if (object.contractUpgrade !== undefined &&
            object.contractUpgrade !== null) {
            message.contractUpgrade = ContractUpgrade.fromPartial(object.contractUpgrade);
        }
        else {
            message.contractUpgrade = undefined;
        }
        if (object.bridgeRegisterChain !== undefined &&
            object.bridgeRegisterChain !== null) {
            message.bridgeRegisterChain = BridgeRegisterChain.fromPartial(object.bridgeRegisterChain);
        }
        else {
            message.bridgeRegisterChain = undefined;
        }
        if (object.bridgeContractUpgrade !== undefined &&
            object.bridgeContractUpgrade !== null) {
            message.bridgeContractUpgrade = BridgeUpgradeContract.fromPartial(object.bridgeContractUpgrade);
        }
        else {
            message.bridgeContractUpgrade = undefined;
        }
        if (object.wormchainStoreCode !== undefined &&
            object.wormchainStoreCode !== null) {
            message.wormchainStoreCode = WormchainStoreCode.fromPartial(object.wormchainStoreCode);
        }
        else {
            message.wormchainStoreCode = undefined;
        }
        if (object.wormchainInstantiateContract !== undefined &&
            object.wormchainInstantiateContract !== null) {
            message.wormchainInstantiateContract =
                WormchainInstantiateContract.fromPartial(object.wormchainInstantiateContract);
        }
        else {
            message.wormchainInstantiateContract = undefined;
        }
        if (object.wormchainMigrateContract !== undefined &&
            object.wormchainMigrateContract !== null) {
            message.wormchainMigrateContract = WormchainMigrateContract.fromPartial(object.wormchainMigrateContract);
        }
        else {
            message.wormchainMigrateContract = undefined;
        }
        if (object.wormchainWasmInstantiateAllowlist !== undefined &&
            object.wormchainWasmInstantiateAllowlist !== null) {
            message.wormchainWasmInstantiateAllowlist =
                WormchainWasmInstantiateAllowlist.fromPartial(object.wormchainWasmInstantiateAllowlist);
        }
        else {
            message.wormchainWasmInstantiateAllowlist = undefined;
        }
        if (object.gatewayScheduleUpgrade !== undefined &&
            object.gatewayScheduleUpgrade !== null) {
            message.gatewayScheduleUpgrade = GatewayScheduleUpgrade.fromPartial(object.gatewayScheduleUpgrade);
        }
        else {
            message.gatewayScheduleUpgrade = undefined;
        }
        if (object.gatewayCancelUpgrade !== undefined &&
            object.gatewayCancelUpgrade !== null) {
            message.gatewayCancelUpgrade = GatewayCancelUpgrade.fromPartial(object.gatewayCancelUpgrade);
        }
        else {
            message.gatewayCancelUpgrade = undefined;
        }
        if (object.gatewayIbcComposabilityMwSetContract !== undefined &&
            object.gatewayIbcComposabilityMwSetContract !== null) {
            message.gatewayIbcComposabilityMwSetContract =
                GatewayIbcComposabilityMwSetContract.fromPartial(object.gatewayIbcComposabilityMwSetContract);
        }
        else {
            message.gatewayIbcComposabilityMwSetContract = undefined;
        }
        if (object.accountantModifyBalance !== undefined &&
            object.accountantModifyBalance !== null) {
            message.accountantModifyBalance = AccountantModifyBalance.fromPartial(object.accountantModifyBalance);
        }
        else {
            message.accountantModifyBalance = undefined;
        }
        if (object.circleIntegrationUpdateWormholeFinality !== undefined &&
            object.circleIntegrationUpdateWormholeFinality !== null) {
            message.circleIntegrationUpdateWormholeFinality =
                CircleIntegrationUpdateWormholeFinality.fromPartial(object.circleIntegrationUpdateWormholeFinality);
        }
        else {
            message.circleIntegrationUpdateWormholeFinality = undefined;
        }
        if (object.circleIntegrationRegisterEmitterAndDomain !== undefined &&
            object.circleIntegrationRegisterEmitterAndDomain !== null) {
            message.circleIntegrationRegisterEmitterAndDomain =
                CircleIntegrationRegisterEmitterAndDomain.fromPartial(object.circleIntegrationRegisterEmitterAndDomain);
        }
        else {
            message.circleIntegrationRegisterEmitterAndDomain = undefined;
        }
        if (object.circleIntegrationUpgradeContractImplementation !== undefined &&
            object.circleIntegrationUpgradeContractImplementation !== null) {
            message.circleIntegrationUpgradeContractImplementation =
                CircleIntegrationUpgradeContractImplementation.fromPartial(object.circleIntegrationUpgradeContractImplementation);
        }
        else {
            message.circleIntegrationUpgradeContractImplementation = undefined;
        }
        if (object.ibcUpdateChannelChain !== undefined &&
            object.ibcUpdateChannelChain !== null) {
            message.ibcUpdateChannelChain = IbcUpdateChannelChain.fromPartial(object.ibcUpdateChannelChain);
        }
        else {
            message.ibcUpdateChannelChain = undefined;
        }
        if (object.wormholeRelayerSetDefaultDeliveryProvider !== undefined &&
            object.wormholeRelayerSetDefaultDeliveryProvider !== null) {
            message.wormholeRelayerSetDefaultDeliveryProvider =
                WormholeRelayerSetDefaultDeliveryProvider.fromPartial(object.wormholeRelayerSetDefaultDeliveryProvider);
        }
        else {
            message.wormholeRelayerSetDefaultDeliveryProvider = undefined;
        }
        return message;
    },
};
var baseInjectGovernanceVAAResponse = {};
export var InjectGovernanceVAAResponse = {
    encode: function (message, writer) {
        var e_4, _a;
        if (writer === void 0) { writer = _m0.Writer.create(); }
        try {
            for (var _b = __values(message.digests), _c = _b.next(); !_c.done; _c = _b.next()) {
                var v = _c.value;
                writer.uint32(10).bytes(v);
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_4) throw e_4.error; }
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseInjectGovernanceVAAResponse);
        message.digests = [];
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.digests.push(reader.bytes());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var e_5, _a;
        var message = __assign({}, baseInjectGovernanceVAAResponse);
        message.digests = [];
        if (object.digests !== undefined && object.digests !== null) {
            try {
                for (var _b = __values(object.digests), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var e = _c.value;
                    message.digests.push(bytesFromBase64(e));
                }
            }
            catch (e_5_1) { e_5 = { error: e_5_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_5) throw e_5.error; }
            }
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        if (message.digests) {
            obj.digests = message.digests.map(function (e) {
                return base64FromBytes(e !== undefined ? e : new Uint8Array());
            });
        }
        else {
            obj.digests = [];
        }
        return obj;
    },
    fromPartial: function (object) {
        var e_6, _a;
        var message = __assign({}, baseInjectGovernanceVAAResponse);
        message.digests = [];
        if (object.digests !== undefined && object.digests !== null) {
            try {
                for (var _b = __values(object.digests), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var e = _c.value;
                    message.digests.push(e);
                }
            }
            catch (e_6_1) { e_6 = { error: e_6_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_6) throw e_6.error; }
            }
        }
        return message;
    },
};
var baseGuardianSetUpdate = {};
export var GuardianSetUpdate = {
    encode: function (message, writer) {
        var e_7, _a;
        if (writer === void 0) { writer = _m0.Writer.create(); }
        try {
            for (var _b = __values(message.guardians), _c = _b.next(); !_c.done; _c = _b.next()) {
                var v = _c.value;
                GuardianSetUpdate_Guardian.encode(v, writer.uint32(26).fork()).ldelim();
            }
        }
        catch (e_7_1) { e_7 = { error: e_7_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_7) throw e_7.error; }
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseGuardianSetUpdate);
        message.guardians = [];
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 3:
                    message.guardians.push(GuardianSetUpdate_Guardian.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var e_8, _a;
        var message = __assign({}, baseGuardianSetUpdate);
        message.guardians = [];
        if (object.guardians !== undefined && object.guardians !== null) {
            try {
                for (var _b = __values(object.guardians), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var e = _c.value;
                    message.guardians.push(GuardianSetUpdate_Guardian.fromJSON(e));
                }
            }
            catch (e_8_1) { e_8 = { error: e_8_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_8) throw e_8.error; }
            }
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        if (message.guardians) {
            obj.guardians = message.guardians.map(function (e) {
                return e ? GuardianSetUpdate_Guardian.toJSON(e) : undefined;
            });
        }
        else {
            obj.guardians = [];
        }
        return obj;
    },
    fromPartial: function (object) {
        var e_9, _a;
        var message = __assign({}, baseGuardianSetUpdate);
        message.guardians = [];
        if (object.guardians !== undefined && object.guardians !== null) {
            try {
                for (var _b = __values(object.guardians), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var e = _c.value;
                    message.guardians.push(GuardianSetUpdate_Guardian.fromPartial(e));
                }
            }
            catch (e_9_1) { e_9 = { error: e_9_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_9) throw e_9.error; }
            }
        }
        return message;
    },
};
var baseGuardianSetUpdate_Guardian = { pubkey: "", name: "" };
export var GuardianSetUpdate_Guardian = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.pubkey !== "") {
            writer.uint32(10).string(message.pubkey);
        }
        if (message.name !== "") {
            writer.uint32(18).string(message.name);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseGuardianSetUpdate_Guardian);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.pubkey = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseGuardianSetUpdate_Guardian);
        if (object.pubkey !== undefined && object.pubkey !== null) {
            message.pubkey = String(object.pubkey);
        }
        else {
            message.pubkey = "";
        }
        if (object.name !== undefined && object.name !== null) {
            message.name = String(object.name);
        }
        else {
            message.name = "";
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.pubkey !== undefined && (obj.pubkey = message.pubkey);
        message.name !== undefined && (obj.name = message.name);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseGuardianSetUpdate_Guardian);
        if (object.pubkey !== undefined && object.pubkey !== null) {
            message.pubkey = object.pubkey;
        }
        else {
            message.pubkey = "";
        }
        if (object.name !== undefined && object.name !== null) {
            message.name = object.name;
        }
        else {
            message.name = "";
        }
        return message;
    },
};
var baseGuardianKey = { unsafeDeterministicKey: false };
export var GuardianKey = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.data.length !== 0) {
            writer.uint32(10).bytes(message.data);
        }
        if (message.unsafeDeterministicKey === true) {
            writer.uint32(16).bool(message.unsafeDeterministicKey);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseGuardianKey);
        message.data = new Uint8Array();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.data = reader.bytes();
                    break;
                case 2:
                    message.unsafeDeterministicKey = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseGuardianKey);
        message.data = new Uint8Array();
        if (object.data !== undefined && object.data !== null) {
            message.data = bytesFromBase64(object.data);
        }
        if (object.unsafeDeterministicKey !== undefined &&
            object.unsafeDeterministicKey !== null) {
            message.unsafeDeterministicKey = Boolean(object.unsafeDeterministicKey);
        }
        else {
            message.unsafeDeterministicKey = false;
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.data !== undefined &&
            (obj.data = base64FromBytes(message.data !== undefined ? message.data : new Uint8Array()));
        message.unsafeDeterministicKey !== undefined &&
            (obj.unsafeDeterministicKey = message.unsafeDeterministicKey);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseGuardianKey);
        if (object.data !== undefined && object.data !== null) {
            message.data = object.data;
        }
        else {
            message.data = new Uint8Array();
        }
        if (object.unsafeDeterministicKey !== undefined &&
            object.unsafeDeterministicKey !== null) {
            message.unsafeDeterministicKey = object.unsafeDeterministicKey;
        }
        else {
            message.unsafeDeterministicKey = false;
        }
        return message;
    },
};
var baseBridgeRegisterChain = {
    module: "",
    chainId: 0,
    emitterAddress: "",
};
export var BridgeRegisterChain = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.module !== "") {
            writer.uint32(10).string(message.module);
        }
        if (message.chainId !== 0) {
            writer.uint32(16).uint32(message.chainId);
        }
        if (message.emitterAddress !== "") {
            writer.uint32(26).string(message.emitterAddress);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseBridgeRegisterChain);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.module = reader.string();
                    break;
                case 2:
                    message.chainId = reader.uint32();
                    break;
                case 3:
                    message.emitterAddress = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseBridgeRegisterChain);
        if (object.module !== undefined && object.module !== null) {
            message.module = String(object.module);
        }
        else {
            message.module = "";
        }
        if (object.chainId !== undefined && object.chainId !== null) {
            message.chainId = Number(object.chainId);
        }
        else {
            message.chainId = 0;
        }
        if (object.emitterAddress !== undefined && object.emitterAddress !== null) {
            message.emitterAddress = String(object.emitterAddress);
        }
        else {
            message.emitterAddress = "";
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.module !== undefined && (obj.module = message.module);
        message.chainId !== undefined && (obj.chainId = message.chainId);
        message.emitterAddress !== undefined &&
            (obj.emitterAddress = message.emitterAddress);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseBridgeRegisterChain);
        if (object.module !== undefined && object.module !== null) {
            message.module = object.module;
        }
        else {
            message.module = "";
        }
        if (object.chainId !== undefined && object.chainId !== null) {
            message.chainId = object.chainId;
        }
        else {
            message.chainId = 0;
        }
        if (object.emitterAddress !== undefined && object.emitterAddress !== null) {
            message.emitterAddress = object.emitterAddress;
        }
        else {
            message.emitterAddress = "";
        }
        return message;
    },
};
var baseAccountantModifyBalance = {
    module: "",
    targetChainId: 0,
    sequence: "0",
    chainId: 0,
    tokenChain: 0,
    tokenAddress: "",
    kind: 0,
    amount: "",
    reason: "",
};
export var AccountantModifyBalance = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.module !== "") {
            writer.uint32(10).string(message.module);
        }
        if (message.targetChainId !== 0) {
            writer.uint32(16).uint32(message.targetChainId);
        }
        if (message.sequence !== "0") {
            writer.uint32(24).uint64(message.sequence);
        }
        if (message.chainId !== 0) {
            writer.uint32(32).uint32(message.chainId);
        }
        if (message.tokenChain !== 0) {
            writer.uint32(40).uint32(message.tokenChain);
        }
        if (message.tokenAddress !== "") {
            writer.uint32(50).string(message.tokenAddress);
        }
        if (message.kind !== 0) {
            writer.uint32(56).int32(message.kind);
        }
        if (message.amount !== "") {
            writer.uint32(66).string(message.amount);
        }
        if (message.reason !== "") {
            writer.uint32(74).string(message.reason);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseAccountantModifyBalance);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.module = reader.string();
                    break;
                case 2:
                    message.targetChainId = reader.uint32();
                    break;
                case 3:
                    message.sequence = longToString(reader.uint64());
                    break;
                case 4:
                    message.chainId = reader.uint32();
                    break;
                case 5:
                    message.tokenChain = reader.uint32();
                    break;
                case 6:
                    message.tokenAddress = reader.string();
                    break;
                case 7:
                    message.kind = reader.int32();
                    break;
                case 8:
                    message.amount = reader.string();
                    break;
                case 9:
                    message.reason = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseAccountantModifyBalance);
        if (object.module !== undefined && object.module !== null) {
            message.module = String(object.module);
        }
        else {
            message.module = "";
        }
        if (object.targetChainId !== undefined && object.targetChainId !== null) {
            message.targetChainId = Number(object.targetChainId);
        }
        else {
            message.targetChainId = 0;
        }
        if (object.sequence !== undefined && object.sequence !== null) {
            message.sequence = String(object.sequence);
        }
        else {
            message.sequence = "0";
        }
        if (object.chainId !== undefined && object.chainId !== null) {
            message.chainId = Number(object.chainId);
        }
        else {
            message.chainId = 0;
        }
        if (object.tokenChain !== undefined && object.tokenChain !== null) {
            message.tokenChain = Number(object.tokenChain);
        }
        else {
            message.tokenChain = 0;
        }
        if (object.tokenAddress !== undefined && object.tokenAddress !== null) {
            message.tokenAddress = String(object.tokenAddress);
        }
        else {
            message.tokenAddress = "";
        }
        if (object.kind !== undefined && object.kind !== null) {
            message.kind = modificationKindFromJSON(object.kind);
        }
        else {
            message.kind = 0;
        }
        if (object.amount !== undefined && object.amount !== null) {
            message.amount = String(object.amount);
        }
        else {
            message.amount = "";
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = String(object.reason);
        }
        else {
            message.reason = "";
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.module !== undefined && (obj.module = message.module);
        message.targetChainId !== undefined &&
            (obj.targetChainId = message.targetChainId);
        message.sequence !== undefined && (obj.sequence = message.sequence);
        message.chainId !== undefined && (obj.chainId = message.chainId);
        message.tokenChain !== undefined && (obj.tokenChain = message.tokenChain);
        message.tokenAddress !== undefined &&
            (obj.tokenAddress = message.tokenAddress);
        message.kind !== undefined &&
            (obj.kind = modificationKindToJSON(message.kind));
        message.amount !== undefined && (obj.amount = message.amount);
        message.reason !== undefined && (obj.reason = message.reason);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseAccountantModifyBalance);
        if (object.module !== undefined && object.module !== null) {
            message.module = object.module;
        }
        else {
            message.module = "";
        }
        if (object.targetChainId !== undefined && object.targetChainId !== null) {
            message.targetChainId = object.targetChainId;
        }
        else {
            message.targetChainId = 0;
        }
        if (object.sequence !== undefined && object.sequence !== null) {
            message.sequence = object.sequence;
        }
        else {
            message.sequence = "0";
        }
        if (object.chainId !== undefined && object.chainId !== null) {
            message.chainId = object.chainId;
        }
        else {
            message.chainId = 0;
        }
        if (object.tokenChain !== undefined && object.tokenChain !== null) {
            message.tokenChain = object.tokenChain;
        }
        else {
            message.tokenChain = 0;
        }
        if (object.tokenAddress !== undefined && object.tokenAddress !== null) {
            message.tokenAddress = object.tokenAddress;
        }
        else {
            message.tokenAddress = "";
        }
        if (object.kind !== undefined && object.kind !== null) {
            message.kind = object.kind;
        }
        else {
            message.kind = 0;
        }
        if (object.amount !== undefined && object.amount !== null) {
            message.amount = object.amount;
        }
        else {
            message.amount = "";
        }
        if (object.reason !== undefined && object.reason !== null) {
            message.reason = object.reason;
        }
        else {
            message.reason = "";
        }
        return message;
    },
};
var baseContractUpgrade = { chainId: 0, newContract: "" };
export var ContractUpgrade = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.chainId !== 0) {
            writer.uint32(8).uint32(message.chainId);
        }
        if (message.newContract !== "") {
            writer.uint32(18).string(message.newContract);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseContractUpgrade);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.chainId = reader.uint32();
                    break;
                case 2:
                    message.newContract = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseContractUpgrade);
        if (object.chainId !== undefined && object.chainId !== null) {
            message.chainId = Number(object.chainId);
        }
        else {
            message.chainId = 0;
        }
        if (object.newContract !== undefined && object.newContract !== null) {
            message.newContract = String(object.newContract);
        }
        else {
            message.newContract = "";
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.chainId !== undefined && (obj.chainId = message.chainId);
        message.newContract !== undefined &&
            (obj.newContract = message.newContract);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseContractUpgrade);
        if (object.chainId !== undefined && object.chainId !== null) {
            message.chainId = object.chainId;
        }
        else {
            message.chainId = 0;
        }
        if (object.newContract !== undefined && object.newContract !== null) {
            message.newContract = object.newContract;
        }
        else {
            message.newContract = "";
        }
        return message;
    },
};
var baseBridgeUpgradeContract = {
    module: "",
    targetChainId: 0,
    newContract: "",
};
export var BridgeUpgradeContract = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.module !== "") {
            writer.uint32(10).string(message.module);
        }
        if (message.targetChainId !== 0) {
            writer.uint32(16).uint32(message.targetChainId);
        }
        if (message.newContract !== "") {
            writer.uint32(26).string(message.newContract);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseBridgeUpgradeContract);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.module = reader.string();
                    break;
                case 2:
                    message.targetChainId = reader.uint32();
                    break;
                case 3:
                    message.newContract = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseBridgeUpgradeContract);
        if (object.module !== undefined && object.module !== null) {
            message.module = String(object.module);
        }
        else {
            message.module = "";
        }
        if (object.targetChainId !== undefined && object.targetChainId !== null) {
            message.targetChainId = Number(object.targetChainId);
        }
        else {
            message.targetChainId = 0;
        }
        if (object.newContract !== undefined && object.newContract !== null) {
            message.newContract = String(object.newContract);
        }
        else {
            message.newContract = "";
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.module !== undefined && (obj.module = message.module);
        message.targetChainId !== undefined &&
            (obj.targetChainId = message.targetChainId);
        message.newContract !== undefined &&
            (obj.newContract = message.newContract);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseBridgeUpgradeContract);
        if (object.module !== undefined && object.module !== null) {
            message.module = object.module;
        }
        else {
            message.module = "";
        }
        if (object.targetChainId !== undefined && object.targetChainId !== null) {
            message.targetChainId = object.targetChainId;
        }
        else {
            message.targetChainId = 0;
        }
        if (object.newContract !== undefined && object.newContract !== null) {
            message.newContract = object.newContract;
        }
        else {
            message.newContract = "";
        }
        return message;
    },
};
var baseWormchainStoreCode = { wasmHash: "" };
export var WormchainStoreCode = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.wasmHash !== "") {
            writer.uint32(10).string(message.wasmHash);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseWormchainStoreCode);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.wasmHash = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseWormchainStoreCode);
        if (object.wasmHash !== undefined && object.wasmHash !== null) {
            message.wasmHash = String(object.wasmHash);
        }
        else {
            message.wasmHash = "";
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.wasmHash !== undefined && (obj.wasmHash = message.wasmHash);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseWormchainStoreCode);
        if (object.wasmHash !== undefined && object.wasmHash !== null) {
            message.wasmHash = object.wasmHash;
        }
        else {
            message.wasmHash = "";
        }
        return message;
    },
};
var baseWormchainInstantiateContract = {
    codeId: "0",
    label: "",
    instantiationMsg: "",
};
export var WormchainInstantiateContract = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.codeId !== "0") {
            writer.uint32(8).uint64(message.codeId);
        }
        if (message.label !== "") {
            writer.uint32(18).string(message.label);
        }
        if (message.instantiationMsg !== "") {
            writer.uint32(26).string(message.instantiationMsg);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseWormchainInstantiateContract);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.codeId = longToString(reader.uint64());
                    break;
                case 2:
                    message.label = reader.string();
                    break;
                case 3:
                    message.instantiationMsg = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseWormchainInstantiateContract);
        if (object.codeId !== undefined && object.codeId !== null) {
            message.codeId = String(object.codeId);
        }
        else {
            message.codeId = "0";
        }
        if (object.label !== undefined && object.label !== null) {
            message.label = String(object.label);
        }
        else {
            message.label = "";
        }
        if (object.instantiationMsg !== undefined &&
            object.instantiationMsg !== null) {
            message.instantiationMsg = String(object.instantiationMsg);
        }
        else {
            message.instantiationMsg = "";
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.codeId !== undefined && (obj.codeId = message.codeId);
        message.label !== undefined && (obj.label = message.label);
        message.instantiationMsg !== undefined &&
            (obj.instantiationMsg = message.instantiationMsg);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseWormchainInstantiateContract);
        if (object.codeId !== undefined && object.codeId !== null) {
            message.codeId = object.codeId;
        }
        else {
            message.codeId = "0";
        }
        if (object.label !== undefined && object.label !== null) {
            message.label = object.label;
        }
        else {
            message.label = "";
        }
        if (object.instantiationMsg !== undefined &&
            object.instantiationMsg !== null) {
            message.instantiationMsg = object.instantiationMsg;
        }
        else {
            message.instantiationMsg = "";
        }
        return message;
    },
};
var baseWormchainMigrateContract = {
    codeId: "0",
    contract: "",
    instantiationMsg: "",
};
export var WormchainMigrateContract = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.codeId !== "0") {
            writer.uint32(8).uint64(message.codeId);
        }
        if (message.contract !== "") {
            writer.uint32(18).string(message.contract);
        }
        if (message.instantiationMsg !== "") {
            writer.uint32(26).string(message.instantiationMsg);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseWormchainMigrateContract);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.codeId = longToString(reader.uint64());
                    break;
                case 2:
                    message.contract = reader.string();
                    break;
                case 3:
                    message.instantiationMsg = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseWormchainMigrateContract);
        if (object.codeId !== undefined && object.codeId !== null) {
            message.codeId = String(object.codeId);
        }
        else {
            message.codeId = "0";
        }
        if (object.contract !== undefined && object.contract !== null) {
            message.contract = String(object.contract);
        }
        else {
            message.contract = "";
        }
        if (object.instantiationMsg !== undefined &&
            object.instantiationMsg !== null) {
            message.instantiationMsg = String(object.instantiationMsg);
        }
        else {
            message.instantiationMsg = "";
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.codeId !== undefined && (obj.codeId = message.codeId);
        message.contract !== undefined && (obj.contract = message.contract);
        message.instantiationMsg !== undefined &&
            (obj.instantiationMsg = message.instantiationMsg);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseWormchainMigrateContract);
        if (object.codeId !== undefined && object.codeId !== null) {
            message.codeId = object.codeId;
        }
        else {
            message.codeId = "0";
        }
        if (object.contract !== undefined && object.contract !== null) {
            message.contract = object.contract;
        }
        else {
            message.contract = "";
        }
        if (object.instantiationMsg !== undefined &&
            object.instantiationMsg !== null) {
            message.instantiationMsg = object.instantiationMsg;
        }
        else {
            message.instantiationMsg = "";
        }
        return message;
    },
};
var baseWormchainWasmInstantiateAllowlist = {
    codeId: "0",
    contract: "",
    action: 0,
};
export var WormchainWasmInstantiateAllowlist = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.codeId !== "0") {
            writer.uint32(8).uint64(message.codeId);
        }
        if (message.contract !== "") {
            writer.uint32(18).string(message.contract);
        }
        if (message.action !== 0) {
            writer.uint32(24).int32(message.action);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseWormchainWasmInstantiateAllowlist);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.codeId = longToString(reader.uint64());
                    break;
                case 2:
                    message.contract = reader.string();
                    break;
                case 3:
                    message.action = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseWormchainWasmInstantiateAllowlist);
        if (object.codeId !== undefined && object.codeId !== null) {
            message.codeId = String(object.codeId);
        }
        else {
            message.codeId = "0";
        }
        if (object.contract !== undefined && object.contract !== null) {
            message.contract = String(object.contract);
        }
        else {
            message.contract = "";
        }
        if (object.action !== undefined && object.action !== null) {
            message.action = wormchainWasmInstantiateAllowlistActionFromJSON(object.action);
        }
        else {
            message.action = 0;
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.codeId !== undefined && (obj.codeId = message.codeId);
        message.contract !== undefined && (obj.contract = message.contract);
        message.action !== undefined &&
            (obj.action = wormchainWasmInstantiateAllowlistActionToJSON(message.action));
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseWormchainWasmInstantiateAllowlist);
        if (object.codeId !== undefined && object.codeId !== null) {
            message.codeId = object.codeId;
        }
        else {
            message.codeId = "0";
        }
        if (object.contract !== undefined && object.contract !== null) {
            message.contract = object.contract;
        }
        else {
            message.contract = "";
        }
        if (object.action !== undefined && object.action !== null) {
            message.action = object.action;
        }
        else {
            message.action = 0;
        }
        return message;
    },
};
var baseGatewayIbcComposabilityMwSetContract = { contract: "" };
export var GatewayIbcComposabilityMwSetContract = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.contract !== "") {
            writer.uint32(10).string(message.contract);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseGatewayIbcComposabilityMwSetContract);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.contract = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseGatewayIbcComposabilityMwSetContract);
        if (object.contract !== undefined && object.contract !== null) {
            message.contract = String(object.contract);
        }
        else {
            message.contract = "";
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.contract !== undefined && (obj.contract = message.contract);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseGatewayIbcComposabilityMwSetContract);
        if (object.contract !== undefined && object.contract !== null) {
            message.contract = object.contract;
        }
        else {
            message.contract = "";
        }
        return message;
    },
};
var baseGatewayScheduleUpgrade = { name: "", height: "0" };
export var GatewayScheduleUpgrade = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.name !== "") {
            writer.uint32(10).string(message.name);
        }
        if (message.height !== "0") {
            writer.uint32(16).uint64(message.height);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseGatewayScheduleUpgrade);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.height = longToString(reader.uint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseGatewayScheduleUpgrade);
        if (object.name !== undefined && object.name !== null) {
            message.name = String(object.name);
        }
        else {
            message.name = "";
        }
        if (object.height !== undefined && object.height !== null) {
            message.height = String(object.height);
        }
        else {
            message.height = "0";
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.name !== undefined && (obj.name = message.name);
        message.height !== undefined && (obj.height = message.height);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseGatewayScheduleUpgrade);
        if (object.name !== undefined && object.name !== null) {
            message.name = object.name;
        }
        else {
            message.name = "";
        }
        if (object.height !== undefined && object.height !== null) {
            message.height = object.height;
        }
        else {
            message.height = "0";
        }
        return message;
    },
};
var baseGatewayCancelUpgrade = {};
export var GatewayCancelUpgrade = {
    encode: function (_, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseGatewayCancelUpgrade);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (_) {
        var message = __assign({}, baseGatewayCancelUpgrade);
        return message;
    },
    toJSON: function (_) {
        var obj = {};
        return obj;
    },
    fromPartial: function (_) {
        var message = __assign({}, baseGatewayCancelUpgrade);
        return message;
    },
};
var baseCircleIntegrationUpdateWormholeFinality = {
    finality: 0,
    targetChainId: 0,
};
export var CircleIntegrationUpdateWormholeFinality = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.finality !== 0) {
            writer.uint32(8).uint32(message.finality);
        }
        if (message.targetChainId !== 0) {
            writer.uint32(16).uint32(message.targetChainId);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseCircleIntegrationUpdateWormholeFinality);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.finality = reader.uint32();
                    break;
                case 2:
                    message.targetChainId = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseCircleIntegrationUpdateWormholeFinality);
        if (object.finality !== undefined && object.finality !== null) {
            message.finality = Number(object.finality);
        }
        else {
            message.finality = 0;
        }
        if (object.targetChainId !== undefined && object.targetChainId !== null) {
            message.targetChainId = Number(object.targetChainId);
        }
        else {
            message.targetChainId = 0;
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.finality !== undefined && (obj.finality = message.finality);
        message.targetChainId !== undefined &&
            (obj.targetChainId = message.targetChainId);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseCircleIntegrationUpdateWormholeFinality);
        if (object.finality !== undefined && object.finality !== null) {
            message.finality = object.finality;
        }
        else {
            message.finality = 0;
        }
        if (object.targetChainId !== undefined && object.targetChainId !== null) {
            message.targetChainId = object.targetChainId;
        }
        else {
            message.targetChainId = 0;
        }
        return message;
    },
};
var baseCircleIntegrationRegisterEmitterAndDomain = {
    foreignEmitterChainId: 0,
    foreignEmitterAddress: "",
    circleDomain: 0,
    targetChainId: 0,
};
export var CircleIntegrationRegisterEmitterAndDomain = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.foreignEmitterChainId !== 0) {
            writer.uint32(8).uint32(message.foreignEmitterChainId);
        }
        if (message.foreignEmitterAddress !== "") {
            writer.uint32(18).string(message.foreignEmitterAddress);
        }
        if (message.circleDomain !== 0) {
            writer.uint32(24).uint32(message.circleDomain);
        }
        if (message.targetChainId !== 0) {
            writer.uint32(32).uint32(message.targetChainId);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseCircleIntegrationRegisterEmitterAndDomain);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.foreignEmitterChainId = reader.uint32();
                    break;
                case 2:
                    message.foreignEmitterAddress = reader.string();
                    break;
                case 3:
                    message.circleDomain = reader.uint32();
                    break;
                case 4:
                    message.targetChainId = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseCircleIntegrationRegisterEmitterAndDomain);
        if (object.foreignEmitterChainId !== undefined &&
            object.foreignEmitterChainId !== null) {
            message.foreignEmitterChainId = Number(object.foreignEmitterChainId);
        }
        else {
            message.foreignEmitterChainId = 0;
        }
        if (object.foreignEmitterAddress !== undefined &&
            object.foreignEmitterAddress !== null) {
            message.foreignEmitterAddress = String(object.foreignEmitterAddress);
        }
        else {
            message.foreignEmitterAddress = "";
        }
        if (object.circleDomain !== undefined && object.circleDomain !== null) {
            message.circleDomain = Number(object.circleDomain);
        }
        else {
            message.circleDomain = 0;
        }
        if (object.targetChainId !== undefined && object.targetChainId !== null) {
            message.targetChainId = Number(object.targetChainId);
        }
        else {
            message.targetChainId = 0;
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.foreignEmitterChainId !== undefined &&
            (obj.foreignEmitterChainId = message.foreignEmitterChainId);
        message.foreignEmitterAddress !== undefined &&
            (obj.foreignEmitterAddress = message.foreignEmitterAddress);
        message.circleDomain !== undefined &&
            (obj.circleDomain = message.circleDomain);
        message.targetChainId !== undefined &&
            (obj.targetChainId = message.targetChainId);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseCircleIntegrationRegisterEmitterAndDomain);
        if (object.foreignEmitterChainId !== undefined &&
            object.foreignEmitterChainId !== null) {
            message.foreignEmitterChainId = object.foreignEmitterChainId;
        }
        else {
            message.foreignEmitterChainId = 0;
        }
        if (object.foreignEmitterAddress !== undefined &&
            object.foreignEmitterAddress !== null) {
            message.foreignEmitterAddress = object.foreignEmitterAddress;
        }
        else {
            message.foreignEmitterAddress = "";
        }
        if (object.circleDomain !== undefined && object.circleDomain !== null) {
            message.circleDomain = object.circleDomain;
        }
        else {
            message.circleDomain = 0;
        }
        if (object.targetChainId !== undefined && object.targetChainId !== null) {
            message.targetChainId = object.targetChainId;
        }
        else {
            message.targetChainId = 0;
        }
        return message;
    },
};
var baseCircleIntegrationUpgradeContractImplementation = {
    newImplementationAddress: "",
    targetChainId: 0,
};
export var CircleIntegrationUpgradeContractImplementation = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.newImplementationAddress !== "") {
            writer.uint32(10).string(message.newImplementationAddress);
        }
        if (message.targetChainId !== 0) {
            writer.uint32(16).uint32(message.targetChainId);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseCircleIntegrationUpgradeContractImplementation);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.newImplementationAddress = reader.string();
                    break;
                case 2:
                    message.targetChainId = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseCircleIntegrationUpgradeContractImplementation);
        if (object.newImplementationAddress !== undefined &&
            object.newImplementationAddress !== null) {
            message.newImplementationAddress = String(object.newImplementationAddress);
        }
        else {
            message.newImplementationAddress = "";
        }
        if (object.targetChainId !== undefined && object.targetChainId !== null) {
            message.targetChainId = Number(object.targetChainId);
        }
        else {
            message.targetChainId = 0;
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.newImplementationAddress !== undefined &&
            (obj.newImplementationAddress = message.newImplementationAddress);
        message.targetChainId !== undefined &&
            (obj.targetChainId = message.targetChainId);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseCircleIntegrationUpgradeContractImplementation);
        if (object.newImplementationAddress !== undefined &&
            object.newImplementationAddress !== null) {
            message.newImplementationAddress = object.newImplementationAddress;
        }
        else {
            message.newImplementationAddress = "";
        }
        if (object.targetChainId !== undefined && object.targetChainId !== null) {
            message.targetChainId = object.targetChainId;
        }
        else {
            message.targetChainId = 0;
        }
        return message;
    },
};
var baseIbcUpdateChannelChain = {
    targetChainId: 0,
    channelId: "",
    chainId: 0,
    module: 0,
};
export var IbcUpdateChannelChain = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.targetChainId !== 0) {
            writer.uint32(8).uint32(message.targetChainId);
        }
        if (message.channelId !== "") {
            writer.uint32(18).string(message.channelId);
        }
        if (message.chainId !== 0) {
            writer.uint32(24).uint32(message.chainId);
        }
        if (message.module !== 0) {
            writer.uint32(32).int32(message.module);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseIbcUpdateChannelChain);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.targetChainId = reader.uint32();
                    break;
                case 2:
                    message.channelId = reader.string();
                    break;
                case 3:
                    message.chainId = reader.uint32();
                    break;
                case 4:
                    message.module = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseIbcUpdateChannelChain);
        if (object.targetChainId !== undefined && object.targetChainId !== null) {
            message.targetChainId = Number(object.targetChainId);
        }
        else {
            message.targetChainId = 0;
        }
        if (object.channelId !== undefined && object.channelId !== null) {
            message.channelId = String(object.channelId);
        }
        else {
            message.channelId = "";
        }
        if (object.chainId !== undefined && object.chainId !== null) {
            message.chainId = Number(object.chainId);
        }
        else {
            message.chainId = 0;
        }
        if (object.module !== undefined && object.module !== null) {
            message.module = ibcUpdateChannelChainModuleFromJSON(object.module);
        }
        else {
            message.module = 0;
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.targetChainId !== undefined &&
            (obj.targetChainId = message.targetChainId);
        message.channelId !== undefined && (obj.channelId = message.channelId);
        message.chainId !== undefined && (obj.chainId = message.chainId);
        message.module !== undefined &&
            (obj.module = ibcUpdateChannelChainModuleToJSON(message.module));
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseIbcUpdateChannelChain);
        if (object.targetChainId !== undefined && object.targetChainId !== null) {
            message.targetChainId = object.targetChainId;
        }
        else {
            message.targetChainId = 0;
        }
        if (object.channelId !== undefined && object.channelId !== null) {
            message.channelId = object.channelId;
        }
        else {
            message.channelId = "";
        }
        if (object.chainId !== undefined && object.chainId !== null) {
            message.chainId = object.chainId;
        }
        else {
            message.chainId = 0;
        }
        if (object.module !== undefined && object.module !== null) {
            message.module = object.module;
        }
        else {
            message.module = 0;
        }
        return message;
    },
};
var baseWormholeRelayerSetDefaultDeliveryProvider = {
    chainId: 0,
    newDefaultDeliveryProviderAddress: "",
};
export var WormholeRelayerSetDefaultDeliveryProvider = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.chainId !== 0) {
            writer.uint32(8).uint32(message.chainId);
        }
        if (message.newDefaultDeliveryProviderAddress !== "") {
            writer.uint32(18).string(message.newDefaultDeliveryProviderAddress);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseWormholeRelayerSetDefaultDeliveryProvider);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.chainId = reader.uint32();
                    break;
                case 2:
                    message.newDefaultDeliveryProviderAddress = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseWormholeRelayerSetDefaultDeliveryProvider);
        if (object.chainId !== undefined && object.chainId !== null) {
            message.chainId = Number(object.chainId);
        }
        else {
            message.chainId = 0;
        }
        if (object.newDefaultDeliveryProviderAddress !== undefined &&
            object.newDefaultDeliveryProviderAddress !== null) {
            message.newDefaultDeliveryProviderAddress = String(object.newDefaultDeliveryProviderAddress);
        }
        else {
            message.newDefaultDeliveryProviderAddress = "";
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.chainId !== undefined && (obj.chainId = message.chainId);
        message.newDefaultDeliveryProviderAddress !== undefined &&
            (obj.newDefaultDeliveryProviderAddress =
                message.newDefaultDeliveryProviderAddress);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseWormholeRelayerSetDefaultDeliveryProvider);
        if (object.chainId !== undefined && object.chainId !== null) {
            message.chainId = object.chainId;
        }
        else {
            message.chainId = 0;
        }
        if (object.newDefaultDeliveryProviderAddress !== undefined &&
            object.newDefaultDeliveryProviderAddress !== null) {
            message.newDefaultDeliveryProviderAddress =
                object.newDefaultDeliveryProviderAddress;
        }
        else {
            message.newDefaultDeliveryProviderAddress = "";
        }
        return message;
    },
};
var baseFindMissingMessagesRequest = {
    emitterChain: 0,
    emitterAddress: "",
    rpcBackfill: false,
    backfillNodes: "",
};
export var FindMissingMessagesRequest = {
    encode: function (message, writer) {
        var e_10, _a;
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.emitterChain !== 0) {
            writer.uint32(8).uint32(message.emitterChain);
        }
        if (message.emitterAddress !== "") {
            writer.uint32(18).string(message.emitterAddress);
        }
        if (message.rpcBackfill === true) {
            writer.uint32(24).bool(message.rpcBackfill);
        }
        try {
            for (var _b = __values(message.backfillNodes), _c = _b.next(); !_c.done; _c = _b.next()) {
                var v = _c.value;
                writer.uint32(34).string(v);
            }
        }
        catch (e_10_1) { e_10 = { error: e_10_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_10) throw e_10.error; }
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseFindMissingMessagesRequest);
        message.backfillNodes = [];
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.emitterChain = reader.uint32();
                    break;
                case 2:
                    message.emitterAddress = reader.string();
                    break;
                case 3:
                    message.rpcBackfill = reader.bool();
                    break;
                case 4:
                    message.backfillNodes.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var e_11, _a;
        var message = __assign({}, baseFindMissingMessagesRequest);
        message.backfillNodes = [];
        if (object.emitterChain !== undefined && object.emitterChain !== null) {
            message.emitterChain = Number(object.emitterChain);
        }
        else {
            message.emitterChain = 0;
        }
        if (object.emitterAddress !== undefined && object.emitterAddress !== null) {
            message.emitterAddress = String(object.emitterAddress);
        }
        else {
            message.emitterAddress = "";
        }
        if (object.rpcBackfill !== undefined && object.rpcBackfill !== null) {
            message.rpcBackfill = Boolean(object.rpcBackfill);
        }
        else {
            message.rpcBackfill = false;
        }
        if (object.backfillNodes !== undefined && object.backfillNodes !== null) {
            try {
                for (var _b = __values(object.backfillNodes), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var e = _c.value;
                    message.backfillNodes.push(String(e));
                }
            }
            catch (e_11_1) { e_11 = { error: e_11_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_11) throw e_11.error; }
            }
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.emitterChain !== undefined &&
            (obj.emitterChain = message.emitterChain);
        message.emitterAddress !== undefined &&
            (obj.emitterAddress = message.emitterAddress);
        message.rpcBackfill !== undefined &&
            (obj.rpcBackfill = message.rpcBackfill);
        if (message.backfillNodes) {
            obj.backfillNodes = message.backfillNodes.map(function (e) { return e; });
        }
        else {
            obj.backfillNodes = [];
        }
        return obj;
    },
    fromPartial: function (object) {
        var e_12, _a;
        var message = __assign({}, baseFindMissingMessagesRequest);
        message.backfillNodes = [];
        if (object.emitterChain !== undefined && object.emitterChain !== null) {
            message.emitterChain = object.emitterChain;
        }
        else {
            message.emitterChain = 0;
        }
        if (object.emitterAddress !== undefined && object.emitterAddress !== null) {
            message.emitterAddress = object.emitterAddress;
        }
        else {
            message.emitterAddress = "";
        }
        if (object.rpcBackfill !== undefined && object.rpcBackfill !== null) {
            message.rpcBackfill = object.rpcBackfill;
        }
        else {
            message.rpcBackfill = false;
        }
        if (object.backfillNodes !== undefined && object.backfillNodes !== null) {
            try {
                for (var _b = __values(object.backfillNodes), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var e = _c.value;
                    message.backfillNodes.push(e);
                }
            }
            catch (e_12_1) { e_12 = { error: e_12_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_12) throw e_12.error; }
            }
        }
        return message;
    },
};
var baseFindMissingMessagesResponse = {
    missingMessages: "",
    firstSequence: "0",
    lastSequence: "0",
};
export var FindMissingMessagesResponse = {
    encode: function (message, writer) {
        var e_13, _a;
        if (writer === void 0) { writer = _m0.Writer.create(); }
        try {
            for (var _b = __values(message.missingMessages), _c = _b.next(); !_c.done; _c = _b.next()) {
                var v = _c.value;
                writer.uint32(10).string(v);
            }
        }
        catch (e_13_1) { e_13 = { error: e_13_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_13) throw e_13.error; }
        }
        if (message.firstSequence !== "0") {
            writer.uint32(16).uint64(message.firstSequence);
        }
        if (message.lastSequence !== "0") {
            writer.uint32(24).uint64(message.lastSequence);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseFindMissingMessagesResponse);
        message.missingMessages = [];
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.missingMessages.push(reader.string());
                    break;
                case 2:
                    message.firstSequence = longToString(reader.uint64());
                    break;
                case 3:
                    message.lastSequence = longToString(reader.uint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var e_14, _a;
        var message = __assign({}, baseFindMissingMessagesResponse);
        message.missingMessages = [];
        if (object.missingMessages !== undefined &&
            object.missingMessages !== null) {
            try {
                for (var _b = __values(object.missingMessages), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var e = _c.value;
                    message.missingMessages.push(String(e));
                }
            }
            catch (e_14_1) { e_14 = { error: e_14_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_14) throw e_14.error; }
            }
        }
        if (object.firstSequence !== undefined && object.firstSequence !== null) {
            message.firstSequence = String(object.firstSequence);
        }
        else {
            message.firstSequence = "0";
        }
        if (object.lastSequence !== undefined && object.lastSequence !== null) {
            message.lastSequence = String(object.lastSequence);
        }
        else {
            message.lastSequence = "0";
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        if (message.missingMessages) {
            obj.missingMessages = message.missingMessages.map(function (e) { return e; });
        }
        else {
            obj.missingMessages = [];
        }
        message.firstSequence !== undefined &&
            (obj.firstSequence = message.firstSequence);
        message.lastSequence !== undefined &&
            (obj.lastSequence = message.lastSequence);
        return obj;
    },
    fromPartial: function (object) {
        var e_15, _a;
        var message = __assign({}, baseFindMissingMessagesResponse);
        message.missingMessages = [];
        if (object.missingMessages !== undefined &&
            object.missingMessages !== null) {
            try {
                for (var _b = __values(object.missingMessages), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var e = _c.value;
                    message.missingMessages.push(e);
                }
            }
            catch (e_15_1) { e_15 = { error: e_15_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_15) throw e_15.error; }
            }
        }
        if (object.firstSequence !== undefined && object.firstSequence !== null) {
            message.firstSequence = object.firstSequence;
        }
        else {
            message.firstSequence = "0";
        }
        if (object.lastSequence !== undefined && object.lastSequence !== null) {
            message.lastSequence = object.lastSequence;
        }
        else {
            message.lastSequence = "0";
        }
        return message;
    },
};
var baseSendObservationRequestRequest = {};
export var SendObservationRequestRequest = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.observationRequest !== undefined) {
            ObservationRequest.encode(message.observationRequest, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseSendObservationRequestRequest);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.observationRequest = ObservationRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseSendObservationRequestRequest);
        if (object.observationRequest !== undefined &&
            object.observationRequest !== null) {
            message.observationRequest = ObservationRequest.fromJSON(object.observationRequest);
        }
        else {
            message.observationRequest = undefined;
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.observationRequest !== undefined &&
            (obj.observationRequest = message.observationRequest
                ? ObservationRequest.toJSON(message.observationRequest)
                : undefined);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseSendObservationRequestRequest);
        if (object.observationRequest !== undefined &&
            object.observationRequest !== null) {
            message.observationRequest = ObservationRequest.fromPartial(object.observationRequest);
        }
        else {
            message.observationRequest = undefined;
        }
        return message;
    },
};
var baseSendObservationRequestResponse = {};
export var SendObservationRequestResponse = {
    encode: function (_, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseSendObservationRequestResponse);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (_) {
        var message = __assign({}, baseSendObservationRequestResponse);
        return message;
    },
    toJSON: function (_) {
        var obj = {};
        return obj;
    },
    fromPartial: function (_) {
        var message = __assign({}, baseSendObservationRequestResponse);
        return message;
    },
};
var baseChainGovernorStatusRequest = {};
export var ChainGovernorStatusRequest = {
    encode: function (_, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseChainGovernorStatusRequest);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (_) {
        var message = __assign({}, baseChainGovernorStatusRequest);
        return message;
    },
    toJSON: function (_) {
        var obj = {};
        return obj;
    },
    fromPartial: function (_) {
        var message = __assign({}, baseChainGovernorStatusRequest);
        return message;
    },
};
var baseChainGovernorStatusResponse = { response: "" };
export var ChainGovernorStatusResponse = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.response !== "") {
            writer.uint32(10).string(message.response);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseChainGovernorStatusResponse);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.response = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseChainGovernorStatusResponse);
        if (object.response !== undefined && object.response !== null) {
            message.response = String(object.response);
        }
        else {
            message.response = "";
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.response !== undefined && (obj.response = message.response);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseChainGovernorStatusResponse);
        if (object.response !== undefined && object.response !== null) {
            message.response = object.response;
        }
        else {
            message.response = "";
        }
        return message;
    },
};
var baseChainGovernorReloadRequest = {};
export var ChainGovernorReloadRequest = {
    encode: function (_, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseChainGovernorReloadRequest);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (_) {
        var message = __assign({}, baseChainGovernorReloadRequest);
        return message;
    },
    toJSON: function (_) {
        var obj = {};
        return obj;
    },
    fromPartial: function (_) {
        var message = __assign({}, baseChainGovernorReloadRequest);
        return message;
    },
};
var baseChainGovernorReloadResponse = { response: "" };
export var ChainGovernorReloadResponse = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.response !== "") {
            writer.uint32(10).string(message.response);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseChainGovernorReloadResponse);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.response = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseChainGovernorReloadResponse);
        if (object.response !== undefined && object.response !== null) {
            message.response = String(object.response);
        }
        else {
            message.response = "";
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.response !== undefined && (obj.response = message.response);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseChainGovernorReloadResponse);
        if (object.response !== undefined && object.response !== null) {
            message.response = object.response;
        }
        else {
            message.response = "";
        }
        return message;
    },
};
var baseChainGovernorDropPendingVAARequest = { vaaId: "" };
export var ChainGovernorDropPendingVAARequest = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.vaaId !== "") {
            writer.uint32(10).string(message.vaaId);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseChainGovernorDropPendingVAARequest);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.vaaId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseChainGovernorDropPendingVAARequest);
        if (object.vaaId !== undefined && object.vaaId !== null) {
            message.vaaId = String(object.vaaId);
        }
        else {
            message.vaaId = "";
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.vaaId !== undefined && (obj.vaaId = message.vaaId);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseChainGovernorDropPendingVAARequest);
        if (object.vaaId !== undefined && object.vaaId !== null) {
            message.vaaId = object.vaaId;
        }
        else {
            message.vaaId = "";
        }
        return message;
    },
};
var baseChainGovernorDropPendingVAAResponse = { response: "" };
export var ChainGovernorDropPendingVAAResponse = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.response !== "") {
            writer.uint32(10).string(message.response);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseChainGovernorDropPendingVAAResponse);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.response = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseChainGovernorDropPendingVAAResponse);
        if (object.response !== undefined && object.response !== null) {
            message.response = String(object.response);
        }
        else {
            message.response = "";
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.response !== undefined && (obj.response = message.response);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseChainGovernorDropPendingVAAResponse);
        if (object.response !== undefined && object.response !== null) {
            message.response = object.response;
        }
        else {
            message.response = "";
        }
        return message;
    },
};
var baseChainGovernorReleasePendingVAARequest = { vaaId: "" };
export var ChainGovernorReleasePendingVAARequest = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.vaaId !== "") {
            writer.uint32(10).string(message.vaaId);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseChainGovernorReleasePendingVAARequest);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.vaaId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseChainGovernorReleasePendingVAARequest);
        if (object.vaaId !== undefined && object.vaaId !== null) {
            message.vaaId = String(object.vaaId);
        }
        else {
            message.vaaId = "";
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.vaaId !== undefined && (obj.vaaId = message.vaaId);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseChainGovernorReleasePendingVAARequest);
        if (object.vaaId !== undefined && object.vaaId !== null) {
            message.vaaId = object.vaaId;
        }
        else {
            message.vaaId = "";
        }
        return message;
    },
};
var baseChainGovernorReleasePendingVAAResponse = { response: "" };
export var ChainGovernorReleasePendingVAAResponse = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.response !== "") {
            writer.uint32(10).string(message.response);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseChainGovernorReleasePendingVAAResponse);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.response = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseChainGovernorReleasePendingVAAResponse);
        if (object.response !== undefined && object.response !== null) {
            message.response = String(object.response);
        }
        else {
            message.response = "";
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.response !== undefined && (obj.response = message.response);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseChainGovernorReleasePendingVAAResponse);
        if (object.response !== undefined && object.response !== null) {
            message.response = object.response;
        }
        else {
            message.response = "";
        }
        return message;
    },
};
var baseChainGovernorResetReleaseTimerRequest = { vaaId: "" };
export var ChainGovernorResetReleaseTimerRequest = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.vaaId !== "") {
            writer.uint32(10).string(message.vaaId);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseChainGovernorResetReleaseTimerRequest);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.vaaId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseChainGovernorResetReleaseTimerRequest);
        if (object.vaaId !== undefined && object.vaaId !== null) {
            message.vaaId = String(object.vaaId);
        }
        else {
            message.vaaId = "";
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.vaaId !== undefined && (obj.vaaId = message.vaaId);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseChainGovernorResetReleaseTimerRequest);
        if (object.vaaId !== undefined && object.vaaId !== null) {
            message.vaaId = object.vaaId;
        }
        else {
            message.vaaId = "";
        }
        return message;
    },
};
var baseChainGovernorResetReleaseTimerResponse = { response: "" };
export var ChainGovernorResetReleaseTimerResponse = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.response !== "") {
            writer.uint32(10).string(message.response);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseChainGovernorResetReleaseTimerResponse);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.response = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseChainGovernorResetReleaseTimerResponse);
        if (object.response !== undefined && object.response !== null) {
            message.response = String(object.response);
        }
        else {
            message.response = "";
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.response !== undefined && (obj.response = message.response);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseChainGovernorResetReleaseTimerResponse);
        if (object.response !== undefined && object.response !== null) {
            message.response = object.response;
        }
        else {
            message.response = "";
        }
        return message;
    },
};
var basePurgePythNetVaasRequest = { daysOld: "0", logOnly: false };
export var PurgePythNetVaasRequest = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.daysOld !== "0") {
            writer.uint32(8).uint64(message.daysOld);
        }
        if (message.logOnly === true) {
            writer.uint32(16).bool(message.logOnly);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, basePurgePythNetVaasRequest);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.daysOld = longToString(reader.uint64());
                    break;
                case 2:
                    message.logOnly = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, basePurgePythNetVaasRequest);
        if (object.daysOld !== undefined && object.daysOld !== null) {
            message.daysOld = String(object.daysOld);
        }
        else {
            message.daysOld = "0";
        }
        if (object.logOnly !== undefined && object.logOnly !== null) {
            message.logOnly = Boolean(object.logOnly);
        }
        else {
            message.logOnly = false;
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.daysOld !== undefined && (obj.daysOld = message.daysOld);
        message.logOnly !== undefined && (obj.logOnly = message.logOnly);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, basePurgePythNetVaasRequest);
        if (object.daysOld !== undefined && object.daysOld !== null) {
            message.daysOld = object.daysOld;
        }
        else {
            message.daysOld = "0";
        }
        if (object.logOnly !== undefined && object.logOnly !== null) {
            message.logOnly = object.logOnly;
        }
        else {
            message.logOnly = false;
        }
        return message;
    },
};
var basePurgePythNetVaasResponse = { response: "" };
export var PurgePythNetVaasResponse = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.response !== "") {
            writer.uint32(10).string(message.response);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, basePurgePythNetVaasResponse);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.response = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, basePurgePythNetVaasResponse);
        if (object.response !== undefined && object.response !== null) {
            message.response = String(object.response);
        }
        else {
            message.response = "";
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.response !== undefined && (obj.response = message.response);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, basePurgePythNetVaasResponse);
        if (object.response !== undefined && object.response !== null) {
            message.response = object.response;
        }
        else {
            message.response = "";
        }
        return message;
    },
};
var baseSignExistingVAARequest = {
    newGuardianAddrs: "",
    newGuardianSetIndex: 0,
};
export var SignExistingVAARequest = {
    encode: function (message, writer) {
        var e_16, _a;
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.vaa.length !== 0) {
            writer.uint32(10).bytes(message.vaa);
        }
        try {
            for (var _b = __values(message.newGuardianAddrs), _c = _b.next(); !_c.done; _c = _b.next()) {
                var v = _c.value;
                writer.uint32(18).string(v);
            }
        }
        catch (e_16_1) { e_16 = { error: e_16_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_16) throw e_16.error; }
        }
        if (message.newGuardianSetIndex !== 0) {
            writer.uint32(24).uint32(message.newGuardianSetIndex);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseSignExistingVAARequest);
        message.newGuardianAddrs = [];
        message.vaa = new Uint8Array();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.vaa = reader.bytes();
                    break;
                case 2:
                    message.newGuardianAddrs.push(reader.string());
                    break;
                case 3:
                    message.newGuardianSetIndex = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var e_17, _a;
        var message = __assign({}, baseSignExistingVAARequest);
        message.newGuardianAddrs = [];
        message.vaa = new Uint8Array();
        if (object.vaa !== undefined && object.vaa !== null) {
            message.vaa = bytesFromBase64(object.vaa);
        }
        if (object.newGuardianAddrs !== undefined &&
            object.newGuardianAddrs !== null) {
            try {
                for (var _b = __values(object.newGuardianAddrs), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var e = _c.value;
                    message.newGuardianAddrs.push(String(e));
                }
            }
            catch (e_17_1) { e_17 = { error: e_17_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_17) throw e_17.error; }
            }
        }
        if (object.newGuardianSetIndex !== undefined &&
            object.newGuardianSetIndex !== null) {
            message.newGuardianSetIndex = Number(object.newGuardianSetIndex);
        }
        else {
            message.newGuardianSetIndex = 0;
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.vaa !== undefined &&
            (obj.vaa = base64FromBytes(message.vaa !== undefined ? message.vaa : new Uint8Array()));
        if (message.newGuardianAddrs) {
            obj.newGuardianAddrs = message.newGuardianAddrs.map(function (e) { return e; });
        }
        else {
            obj.newGuardianAddrs = [];
        }
        message.newGuardianSetIndex !== undefined &&
            (obj.newGuardianSetIndex = message.newGuardianSetIndex);
        return obj;
    },
    fromPartial: function (object) {
        var e_18, _a;
        var message = __assign({}, baseSignExistingVAARequest);
        message.newGuardianAddrs = [];
        if (object.vaa !== undefined && object.vaa !== null) {
            message.vaa = object.vaa;
        }
        else {
            message.vaa = new Uint8Array();
        }
        if (object.newGuardianAddrs !== undefined &&
            object.newGuardianAddrs !== null) {
            try {
                for (var _b = __values(object.newGuardianAddrs), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var e = _c.value;
                    message.newGuardianAddrs.push(e);
                }
            }
            catch (e_18_1) { e_18 = { error: e_18_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_18) throw e_18.error; }
            }
        }
        if (object.newGuardianSetIndex !== undefined &&
            object.newGuardianSetIndex !== null) {
            message.newGuardianSetIndex = object.newGuardianSetIndex;
        }
        else {
            message.newGuardianSetIndex = 0;
        }
        return message;
    },
};
var baseSignExistingVAAResponse = {};
export var SignExistingVAAResponse = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.vaa.length !== 0) {
            writer.uint32(10).bytes(message.vaa);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseSignExistingVAAResponse);
        message.vaa = new Uint8Array();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.vaa = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseSignExistingVAAResponse);
        message.vaa = new Uint8Array();
        if (object.vaa !== undefined && object.vaa !== null) {
            message.vaa = bytesFromBase64(object.vaa);
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.vaa !== undefined &&
            (obj.vaa = base64FromBytes(message.vaa !== undefined ? message.vaa : new Uint8Array()));
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseSignExistingVAAResponse);
        if (object.vaa !== undefined && object.vaa !== null) {
            message.vaa = object.vaa;
        }
        else {
            message.vaa = new Uint8Array();
        }
        return message;
    },
};
var baseDumpRPCsRequest = {};
export var DumpRPCsRequest = {
    encode: function (_, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseDumpRPCsRequest);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (_) {
        var message = __assign({}, baseDumpRPCsRequest);
        return message;
    },
    toJSON: function (_) {
        var obj = {};
        return obj;
    },
    fromPartial: function (_) {
        var message = __assign({}, baseDumpRPCsRequest);
        return message;
    },
};
var baseDumpRPCsResponse = {};
export var DumpRPCsResponse = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        Object.entries(message.response).forEach(function (_a) {
            var _b = __read(_a, 2), key = _b[0], value = _b[1];
            DumpRPCsResponse_ResponseEntry.encode({ key: key, value: value }, writer.uint32(10).fork()).ldelim();
        });
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseDumpRPCsResponse);
        message.response = {};
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    var entry1 = DumpRPCsResponse_ResponseEntry.decode(reader, reader.uint32());
                    if (entry1.value !== undefined) {
                        message.response[entry1.key] = entry1.value;
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseDumpRPCsResponse);
        message.response = {};
        if (object.response !== undefined && object.response !== null) {
            Object.entries(object.response).forEach(function (_a) {
                var _b = __read(_a, 2), key = _b[0], value = _b[1];
                message.response[key] = String(value);
            });
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        obj.response = {};
        if (message.response) {
            Object.entries(message.response).forEach(function (_a) {
                var _b = __read(_a, 2), k = _b[0], v = _b[1];
                obj.response[k] = v;
            });
        }
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseDumpRPCsResponse);
        message.response = {};
        if (object.response !== undefined && object.response !== null) {
            Object.entries(object.response).forEach(function (_a) {
                var _b = __read(_a, 2), key = _b[0], value = _b[1];
                if (value !== undefined) {
                    message.response[key] = String(value);
                }
            });
        }
        return message;
    },
};
var baseDumpRPCsResponse_ResponseEntry = { key: "", value: "" };
export var DumpRPCsResponse_ResponseEntry = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== "") {
            writer.uint32(18).string(message.value);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseDumpRPCsResponse_ResponseEntry);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseDumpRPCsResponse_ResponseEntry);
        if (object.key !== undefined && object.key !== null) {
            message.key = String(object.key);
        }
        else {
            message.key = "";
        }
        if (object.value !== undefined && object.value !== null) {
            message.value = String(object.value);
        }
        else {
            message.value = "";
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.key !== undefined && (obj.key = message.key);
        message.value !== undefined && (obj.value = message.value);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseDumpRPCsResponse_ResponseEntry);
        if (object.key !== undefined && object.key !== null) {
            message.key = object.key;
        }
        else {
            message.key = "";
        }
        if (object.value !== undefined && object.value !== null) {
            message.value = object.value;
        }
        else {
            message.value = "";
        }
        return message;
    },
};
var baseGetAndObserveMissingVAAsRequest = { url: "", apiKey: "" };
export var GetAndObserveMissingVAAsRequest = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.url !== "") {
            writer.uint32(10).string(message.url);
        }
        if (message.apiKey !== "") {
            writer.uint32(18).string(message.apiKey);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseGetAndObserveMissingVAAsRequest);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.url = reader.string();
                    break;
                case 2:
                    message.apiKey = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseGetAndObserveMissingVAAsRequest);
        if (object.url !== undefined && object.url !== null) {
            message.url = String(object.url);
        }
        else {
            message.url = "";
        }
        if (object.apiKey !== undefined && object.apiKey !== null) {
            message.apiKey = String(object.apiKey);
        }
        else {
            message.apiKey = "";
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.url !== undefined && (obj.url = message.url);
        message.apiKey !== undefined && (obj.apiKey = message.apiKey);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseGetAndObserveMissingVAAsRequest);
        if (object.url !== undefined && object.url !== null) {
            message.url = object.url;
        }
        else {
            message.url = "";
        }
        if (object.apiKey !== undefined && object.apiKey !== null) {
            message.apiKey = object.apiKey;
        }
        else {
            message.apiKey = "";
        }
        return message;
    },
};
var baseGetAndObserveMissingVAAsResponse = { response: "" };
export var GetAndObserveMissingVAAsResponse = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.response !== "") {
            writer.uint32(10).string(message.response);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseGetAndObserveMissingVAAsResponse);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.response = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseGetAndObserveMissingVAAsResponse);
        if (object.response !== undefined && object.response !== null) {
            message.response = String(object.response);
        }
        else {
            message.response = "";
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.response !== undefined && (obj.response = message.response);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseGetAndObserveMissingVAAsResponse);
        if (object.response !== undefined && object.response !== null) {
            message.response = object.response;
        }
        else {
            message.response = "";
        }
        return message;
    },
};
var NodePrivilegedServiceClientImpl = /** @class */ (function () {
    function NodePrivilegedServiceClientImpl(rpc) {
        this.rpc = rpc;
        this.InjectGovernanceVAA = this.InjectGovernanceVAA.bind(this);
        this.FindMissingMessages = this.FindMissingMessages.bind(this);
        this.SendObservationRequest = this.SendObservationRequest.bind(this);
        this.ChainGovernorStatus = this.ChainGovernorStatus.bind(this);
        this.ChainGovernorReload = this.ChainGovernorReload.bind(this);
        this.ChainGovernorDropPendingVAA =
            this.ChainGovernorDropPendingVAA.bind(this);
        this.ChainGovernorReleasePendingVAA =
            this.ChainGovernorReleasePendingVAA.bind(this);
        this.ChainGovernorResetReleaseTimer =
            this.ChainGovernorResetReleaseTimer.bind(this);
        this.PurgePythNetVaas = this.PurgePythNetVaas.bind(this);
        this.SignExistingVAA = this.SignExistingVAA.bind(this);
        this.DumpRPCs = this.DumpRPCs.bind(this);
        this.GetAndObserveMissingVAAs = this.GetAndObserveMissingVAAs.bind(this);
    }
    NodePrivilegedServiceClientImpl.prototype.InjectGovernanceVAA = function (request, metadata) {
        return this.rpc.unary(NodePrivilegedServiceInjectGovernanceVAADesc, InjectGovernanceVAARequest.fromPartial(request), metadata);
    };
    NodePrivilegedServiceClientImpl.prototype.FindMissingMessages = function (request, metadata) {
        return this.rpc.unary(NodePrivilegedServiceFindMissingMessagesDesc, FindMissingMessagesRequest.fromPartial(request), metadata);
    };
    NodePrivilegedServiceClientImpl.prototype.SendObservationRequest = function (request, metadata) {
        return this.rpc.unary(NodePrivilegedServiceSendObservationRequestDesc, SendObservationRequestRequest.fromPartial(request), metadata);
    };
    NodePrivilegedServiceClientImpl.prototype.ChainGovernorStatus = function (request, metadata) {
        return this.rpc.unary(NodePrivilegedServiceChainGovernorStatusDesc, ChainGovernorStatusRequest.fromPartial(request), metadata);
    };
    NodePrivilegedServiceClientImpl.prototype.ChainGovernorReload = function (request, metadata) {
        return this.rpc.unary(NodePrivilegedServiceChainGovernorReloadDesc, ChainGovernorReloadRequest.fromPartial(request), metadata);
    };
    NodePrivilegedServiceClientImpl.prototype.ChainGovernorDropPendingVAA = function (request, metadata) {
        return this.rpc.unary(NodePrivilegedServiceChainGovernorDropPendingVAADesc, ChainGovernorDropPendingVAARequest.fromPartial(request), metadata);
    };
    NodePrivilegedServiceClientImpl.prototype.ChainGovernorReleasePendingVAA = function (request, metadata) {
        return this.rpc.unary(NodePrivilegedServiceChainGovernorReleasePendingVAADesc, ChainGovernorReleasePendingVAARequest.fromPartial(request), metadata);
    };
    NodePrivilegedServiceClientImpl.prototype.ChainGovernorResetReleaseTimer = function (request, metadata) {
        return this.rpc.unary(NodePrivilegedServiceChainGovernorResetReleaseTimerDesc, ChainGovernorResetReleaseTimerRequest.fromPartial(request), metadata);
    };
    NodePrivilegedServiceClientImpl.prototype.PurgePythNetVaas = function (request, metadata) {
        return this.rpc.unary(NodePrivilegedServicePurgePythNetVaasDesc, PurgePythNetVaasRequest.fromPartial(request), metadata);
    };
    NodePrivilegedServiceClientImpl.prototype.SignExistingVAA = function (request, metadata) {
        return this.rpc.unary(NodePrivilegedServiceSignExistingVAADesc, SignExistingVAARequest.fromPartial(request), metadata);
    };
    NodePrivilegedServiceClientImpl.prototype.DumpRPCs = function (request, metadata) {
        return this.rpc.unary(NodePrivilegedServiceDumpRPCsDesc, DumpRPCsRequest.fromPartial(request), metadata);
    };
    NodePrivilegedServiceClientImpl.prototype.GetAndObserveMissingVAAs = function (request, metadata) {
        return this.rpc.unary(NodePrivilegedServiceGetAndObserveMissingVAAsDesc, GetAndObserveMissingVAAsRequest.fromPartial(request), metadata);
    };
    return NodePrivilegedServiceClientImpl;
}());
export { NodePrivilegedServiceClientImpl };
export var NodePrivilegedServiceDesc = {
    serviceName: "node.v1.NodePrivilegedService",
};
export var NodePrivilegedServiceInjectGovernanceVAADesc = {
    methodName: "InjectGovernanceVAA",
    service: NodePrivilegedServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary: function () {
            return InjectGovernanceVAARequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary: function (data) {
            return __assign(__assign({}, InjectGovernanceVAAResponse.decode(data)), { toObject: function () {
                    return this;
                } });
        },
    },
};
export var NodePrivilegedServiceFindMissingMessagesDesc = {
    methodName: "FindMissingMessages",
    service: NodePrivilegedServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary: function () {
            return FindMissingMessagesRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary: function (data) {
            return __assign(__assign({}, FindMissingMessagesResponse.decode(data)), { toObject: function () {
                    return this;
                } });
        },
    },
};
export var NodePrivilegedServiceSendObservationRequestDesc = {
    methodName: "SendObservationRequest",
    service: NodePrivilegedServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary: function () {
            return SendObservationRequestRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary: function (data) {
            return __assign(__assign({}, SendObservationRequestResponse.decode(data)), { toObject: function () {
                    return this;
                } });
        },
    },
};
export var NodePrivilegedServiceChainGovernorStatusDesc = {
    methodName: "ChainGovernorStatus",
    service: NodePrivilegedServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary: function () {
            return ChainGovernorStatusRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary: function (data) {
            return __assign(__assign({}, ChainGovernorStatusResponse.decode(data)), { toObject: function () {
                    return this;
                } });
        },
    },
};
export var NodePrivilegedServiceChainGovernorReloadDesc = {
    methodName: "ChainGovernorReload",
    service: NodePrivilegedServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary: function () {
            return ChainGovernorReloadRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary: function (data) {
            return __assign(__assign({}, ChainGovernorReloadResponse.decode(data)), { toObject: function () {
                    return this;
                } });
        },
    },
};
export var NodePrivilegedServiceChainGovernorDropPendingVAADesc = {
    methodName: "ChainGovernorDropPendingVAA",
    service: NodePrivilegedServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary: function () {
            return ChainGovernorDropPendingVAARequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary: function (data) {
            return __assign(__assign({}, ChainGovernorDropPendingVAAResponse.decode(data)), { toObject: function () {
                    return this;
                } });
        },
    },
};
export var NodePrivilegedServiceChainGovernorReleasePendingVAADesc = {
    methodName: "ChainGovernorReleasePendingVAA",
    service: NodePrivilegedServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary: function () {
            return ChainGovernorReleasePendingVAARequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary: function (data) {
            return __assign(__assign({}, ChainGovernorReleasePendingVAAResponse.decode(data)), { toObject: function () {
                    return this;
                } });
        },
    },
};
export var NodePrivilegedServiceChainGovernorResetReleaseTimerDesc = {
    methodName: "ChainGovernorResetReleaseTimer",
    service: NodePrivilegedServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary: function () {
            return ChainGovernorResetReleaseTimerRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary: function (data) {
            return __assign(__assign({}, ChainGovernorResetReleaseTimerResponse.decode(data)), { toObject: function () {
                    return this;
                } });
        },
    },
};
export var NodePrivilegedServicePurgePythNetVaasDesc = {
    methodName: "PurgePythNetVaas",
    service: NodePrivilegedServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary: function () {
            return PurgePythNetVaasRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary: function (data) {
            return __assign(__assign({}, PurgePythNetVaasResponse.decode(data)), { toObject: function () {
                    return this;
                } });
        },
    },
};
export var NodePrivilegedServiceSignExistingVAADesc = {
    methodName: "SignExistingVAA",
    service: NodePrivilegedServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary: function () {
            return SignExistingVAARequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary: function (data) {
            return __assign(__assign({}, SignExistingVAAResponse.decode(data)), { toObject: function () {
                    return this;
                } });
        },
    },
};
export var NodePrivilegedServiceDumpRPCsDesc = {
    methodName: "DumpRPCs",
    service: NodePrivilegedServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary: function () {
            return DumpRPCsRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary: function (data) {
            return __assign(__assign({}, DumpRPCsResponse.decode(data)), { toObject: function () {
                    return this;
                } });
        },
    },
};
export var NodePrivilegedServiceGetAndObserveMissingVAAsDesc = {
    methodName: "GetAndObserveMissingVAAs",
    service: NodePrivilegedServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary: function () {
            return GetAndObserveMissingVAAsRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary: function (data) {
            return __assign(__assign({}, GetAndObserveMissingVAAsResponse.decode(data)), { toObject: function () {
                    return this;
                } });
        },
    },
};
var GrpcWebImpl = /** @class */ (function () {
    function GrpcWebImpl(host, options) {
        this.host = host;
        this.options = options;
    }
    GrpcWebImpl.prototype.unary = function (methodDesc, _request, metadata) {
        var _this = this;
        var _a;
        var request = __assign(__assign({}, _request), methodDesc.requestType);
        var maybeCombinedMetadata = metadata && this.options.metadata
            ? new BrowserHeaders(__assign(__assign({}, (_a = this.options) === null || _a === void 0 ? void 0 : _a.metadata.headersMap), metadata === null || metadata === void 0 ? void 0 : metadata.headersMap))
            : metadata || this.options.metadata;
        return new Promise(function (resolve, reject) {
            grpc.unary(methodDesc, {
                request: request,
                host: _this.host,
                metadata: maybeCombinedMetadata,
                transport: _this.options.transport,
                debug: _this.options.debug,
                onEnd: function (response) {
                    if (response.status === grpc.Code.OK) {
                        resolve(response.message);
                    }
                    else {
                        var err = new Error(response.statusMessage);
                        err.code = response.status;
                        err.metadata = response.trailers;
                        reject(err);
                    }
                },
            });
        });
    };
    return GrpcWebImpl;
}());
export { GrpcWebImpl };
var globalThis = (function () {
    if (typeof globalThis !== "undefined")
        return globalThis;
    if (typeof self !== "undefined")
        return self;
    if (typeof window !== "undefined")
        return window;
    if (typeof global !== "undefined")
        return global;
    throw "Unable to locate global object";
})();
var atob = globalThis.atob ||
    (function (b64) { return globalThis.Buffer.from(b64, "base64").toString("binary"); });
function bytesFromBase64(b64) {
    var bin = atob(b64);
    var arr = new Uint8Array(bin.length);
    for (var i = 0; i < bin.length; ++i) {
        arr[i] = bin.charCodeAt(i);
    }
    return arr;
}
var btoa = globalThis.btoa ||
    (function (bin) { return globalThis.Buffer.from(bin, "binary").toString("base64"); });
function base64FromBytes(arr) {
    var e_19, _a;
    var bin = [];
    try {
        for (var arr_1 = __values(arr), arr_1_1 = arr_1.next(); !arr_1_1.done; arr_1_1 = arr_1.next()) {
            var byte = arr_1_1.value;
            bin.push(String.fromCharCode(byte));
        }
    }
    catch (e_19_1) { e_19 = { error: e_19_1 }; }
    finally {
        try {
            if (arr_1_1 && !arr_1_1.done && (_a = arr_1.return)) _a.call(arr_1);
        }
        finally { if (e_19) throw e_19.error; }
    }
    return btoa(bin.join(""));
}
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
