var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CHAINS } from "../../utils";
import { parseVaa } from "../../vaa";
import { getWormholeRelayer } from "../consts";
import { parseWormholeRelayerSend, parseEVMExecutionInfoV1, } from "../structs";
import { vaaKeyToVaaKeyStruct } from "./helpers";
import { getSignedVAAWithRetry } from "../../rpc";
export function resendRaw(signer, sourceChain, targetChain, environment, vaaKey, newGasLimit, newReceiverValue, deliveryProviderAddress, overrides, wormholeRelayerAddress) {
    return __awaiter(this, void 0, void 0, function* () {
        const provider = signer.provider;
        if (!provider)
            throw Error("No provider on signer");
        const wormholeRelayer = getWormholeRelayer(sourceChain, environment, signer, wormholeRelayerAddress);
        return wormholeRelayer.resendToEvm(vaaKeyToVaaKeyStruct(vaaKey), CHAINS[targetChain], newReceiverValue, newGasLimit, deliveryProviderAddress, overrides);
    });
}
export function resend(signer, sourceChain, targetChain, environment, vaaKey, newGasLimit, newReceiverValue, deliveryProviderAddress, wormholeRPCs, overrides, extraGrpcOpts = {}, optionalParams) {
    return __awaiter(this, void 0, void 0, function* () {
        const targetChainId = CHAINS[targetChain];
        const originalVAA = yield getSignedVAAWithRetry(wormholeRPCs, vaaKey.chainId, vaaKey.emitterAddress.toString("hex"), vaaKey.sequence.toBigInt().toString(), extraGrpcOpts, 2000, 4);
        if (!originalVAA.vaaBytes)
            throw Error("original VAA not found");
        const originalVAAparsed = parseWormholeRelayerSend(parseVaa(Buffer.from(originalVAA.vaaBytes)).payload);
        if (!originalVAAparsed)
            throw Error("original VAA not a valid delivery VAA.");
        const [originalExecutionInfo] = parseEVMExecutionInfoV1(originalVAAparsed.encodedExecutionInfo, 0);
        const originalGasLimit = originalExecutionInfo.gasLimit;
        const originalRefund = originalExecutionInfo.targetChainRefundPerGasUnused;
        const originalReceiverValue = originalVAAparsed.requestedReceiverValue;
        const originalTargetChain = originalVAAparsed.targetChainId;
        if (originalTargetChain != targetChainId) {
            throw Error(`Target chain of original VAA (${originalTargetChain}) does not match target chain of resend (${targetChainId})`);
        }
        if (newReceiverValue < originalReceiverValue) {
            throw Error(`New receiver value too low. Minimum is ${originalReceiverValue.toString()}`);
        }
        if (newGasLimit < originalGasLimit) {
            throw Error(`New gas limit too low. Minimum is ${originalReceiverValue.toString()}`);
        }
        const wormholeRelayer = getWormholeRelayer(sourceChain, environment, signer, optionalParams === null || optionalParams === void 0 ? void 0 : optionalParams.wormholeRelayerAddress);
        const [deliveryPrice, refundPerUnitGas] = yield wormholeRelayer["quoteEVMDeliveryPrice(uint16,uint256,uint256,address)"](targetChainId, newReceiverValue || 0, newGasLimit, deliveryProviderAddress);
        const value = yield ((overrides === null || overrides === void 0 ? void 0 : overrides.value) || 0);
        if (!deliveryPrice.eq(value)) {
            throw new Error(`Expected a payment of ${deliveryPrice.toString()} wei; received ${value.toString()} wei`);
        }
        if (refundPerUnitGas < originalRefund) {
            throw Error(`New refund per unit gas too low. Minimum is ${originalRefund.toString()}.`);
        }
        return resendRaw(signer, sourceChain, targetChain, environment, vaaKey, newGasLimit, newReceiverValue, deliveryProviderAddress, overrides, optionalParams === null || optionalParams === void 0 ? void 0 : optionalParams.wormholeRelayerAddress);
    });
}
