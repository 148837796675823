var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ethers } from "ethers";
import { IWormholeRelayer__factory } from "../../ethers-relayer-contracts";
import { toChainName, CHAIN_ID_TO_NAME, } from "../../utils";
import { parseVaa } from "../../vaa";
import { getWormholeRelayerAddress } from "../consts";
import { RelayerPayloadId, packOverrides, parseEVMExecutionInfoV1, parseWormholeRelayerPayloadType, parseWormholeRelayerSend, parseVaaKey, parseCCTPKey, } from "../structs";
import { getCCTPMessageLogURL, getDefaultProvider, getWormscanInfo, } from "./helpers";
import { getWormholeRelayerInfo } from "./info";
export function manualDelivery(sourceChain, sourceTransaction, infoRequest, getQuoteOnly, overrides, signer) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const info = yield getWormholeRelayerInfo(sourceChain, sourceTransaction, infoRequest);
        const environment = (infoRequest === null || infoRequest === void 0 ? void 0 : infoRequest.environment) || "MAINNET";
        const sourceProvider = (infoRequest === null || infoRequest === void 0 ? void 0 : infoRequest.sourceChainProvider) ||
            getDefaultProvider(environment, sourceChain);
        const receipt = yield sourceProvider.getTransactionReceipt(sourceTransaction);
        const wormholeRelayerAddress = ((_a = infoRequest === null || infoRequest === void 0 ? void 0 : infoRequest.wormholeRelayerAddresses) === null || _a === void 0 ? void 0 : _a.get(sourceChain)) ||
            getWormholeRelayerAddress(sourceChain, environment);
        const response = yield (yield getWormscanInfo(environment, info.sourceChain, info.sourceDeliverySequenceNumber, wormholeRelayerAddress)).json();
        const signedVaa = response.data.vaa;
        const signedVaaBuffer = Buffer.from(signedVaa, "base64");
        const result = {
            quote: deliveryBudget(info.deliveryInstruction, overrides),
            targetChain: CHAIN_ID_TO_NAME[info.deliveryInstruction.targetChainId],
            txHash: undefined,
        };
        if (getQuoteOnly) {
            return result;
        }
        else {
            if (!signer) {
                throw new Error("no signer provided");
            }
            const deliveryReceipt = yield deliver(signedVaaBuffer, signer, environment, overrides, sourceChain, receipt);
            result.txHash = deliveryReceipt.transactionHash;
            return result;
        }
    });
}
export function deliver(deliveryVaa, signer, environment = "MAINNET", overrides, sourceChain, sourceReceipt) {
    return __awaiter(this, void 0, void 0, function* () {
        const { budget, deliveryInstruction, deliveryHash } = extractDeliveryArguments(deliveryVaa, overrides);
        const additionalMessages = yield fetchAdditionalMessages(deliveryInstruction.messageKeys, environment, sourceChain, sourceReceipt);
        const wormholeRelayerAddress = getWormholeRelayerAddress(toChainName(deliveryInstruction.targetChainId), environment);
        const wormholeRelayer = IWormholeRelayer__factory.connect(wormholeRelayerAddress, signer);
        const gasEstimate = yield wormholeRelayer.estimateGas.deliver(additionalMessages, deliveryVaa, signer.getAddress(), overrides ? packOverrides(overrides) : new Uint8Array(), { value: budget });
        const tx = yield wormholeRelayer.deliver(additionalMessages, deliveryVaa, signer.getAddress(), overrides ? packOverrides(overrides) : new Uint8Array(), { value: budget, gasLimit: gasEstimate.mul(2) });
        const rx = yield tx.wait();
        return rx;
    });
}
export function deliveryBudget(delivery, overrides) {
    const receiverValue = (overrides === null || overrides === void 0 ? void 0 : overrides.newReceiverValue)
        ? overrides.newReceiverValue
        : delivery.requestedReceiverValue.add(delivery.extraReceiverValue);
    const getMaxRefund = (encodedDeliveryInfo) => {
        const [deliveryInfo] = parseEVMExecutionInfoV1(encodedDeliveryInfo, 0);
        return deliveryInfo.targetChainRefundPerGasUnused.mul(deliveryInfo.gasLimit);
    };
    const maxRefund = getMaxRefund((overrides === null || overrides === void 0 ? void 0 : overrides.newExecutionInfo)
        ? overrides.newExecutionInfo
        : delivery.encodedExecutionInfo);
    return receiverValue.add(maxRefund);
}
export function extractDeliveryArguments(vaa, overrides) {
    const parsedVaa = parseVaa(vaa);
    const payloadType = parseWormholeRelayerPayloadType(parsedVaa.payload);
    if (payloadType !== RelayerPayloadId.Delivery) {
        throw new Error(`Expected delivery payload type, got ${RelayerPayloadId[payloadType]}`);
    }
    const deliveryInstruction = parseWormholeRelayerSend(parsedVaa.payload);
    const budget = deliveryBudget(deliveryInstruction, overrides);
    return {
        budget,
        deliveryInstruction: deliveryInstruction,
        deliveryHash: parsedVaa.hash.toString("hex"),
    };
}
export function fetchAdditionalMessages(additionalMessageKeys, environment, sourceChain, sourceReceipt) {
    return __awaiter(this, void 0, void 0, function* () {
        const messages = yield Promise.all(additionalMessageKeys.map((messageKey) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            if (messageKey.keyType === 1) {
                const vaaKey = parseVaaKey(messageKey.key);
                const signedVaa = (_a = (yield yield (yield getWormscanInfo(environment, CHAIN_ID_TO_NAME[vaaKey.chainId], vaaKey.sequence.toNumber(), "0x" + vaaKey.emitterAddress.toString("hex"))).json()).data) === null || _a === void 0 ? void 0 : _a.vaa;
                if (!signedVaa) {
                    throw new Error(`No signed VAA available on WormScan for vaaKey ${JSON.stringify(vaaKey)}`);
                }
                return Buffer.from(signedVaa, "base64");
            }
            else if (messageKey.keyType === 2) {
                const cctpKey = parseCCTPKey(messageKey.key);
                if (!sourceReceipt)
                    throw new Error("No source receipt provided - needed to obtain CCTP message");
                if (!environment)
                    throw new Error("No environment provided - needed to obtain CCTP message");
                if (!sourceChain)
                    throw new Error("No source chain provided - needed to obtain CCTP message");
                const response = yield getCCTPMessageLogURL(cctpKey, sourceChain, sourceReceipt, environment);
                // Try to get attestation
                const attestationResponse = yield fetch((response === null || response === void 0 ? void 0 : response.url) || "");
                const attestationResponseJson = yield attestationResponse.json();
                const attestation = attestationResponseJson.attestation;
                if (!attestation) {
                    throw new Error(`Unable to get attestation from Circle, for cctp key ${JSON.stringify(cctpKey)}, message ${response === null || response === void 0 ? void 0 : response.message}`);
                }
                return Buffer.from(new ethers.utils.AbiCoder()
                    .encode(["bytes", "bytes"], [(response === null || response === void 0 ? void 0 : response.message) || [], attestation])
                    .substring(2), "hex");
            }
            else {
                throw new Error(`Message key type unknown: ${messageKey.keyType} (messageKey ${messageKey.key})`);
            }
        })));
        return messages;
    });
}
