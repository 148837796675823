// Contract upgrade
export const authorizeUpgrade = (address, vaa) => {
    if (!address)
        throw new Error("Need bridge address.");
    return {
        function: `${address}::contract_upgrade::submit_vaa_entry`,
        type_arguments: [],
        arguments: [vaa],
    };
};
export const upgradeContract = (address, metadataSerialized, code) => {
    if (!address)
        throw new Error("Need bridge address.");
    return {
        function: `${address}::contract_upgrade::upgrade`,
        type_arguments: [],
        arguments: [metadataSerialized, code],
    };
};
export const migrateContract = (address) => {
    if (!address)
        throw new Error("Need bridge address.");
    return {
        function: `${address}::contract_upgrade::migrate`,
        type_arguments: [],
        arguments: [],
    };
};
