var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SUI_CLOCK_OBJECT_ID, TransactionBlock, } from "@mysten/sui.js";
import { createWrappedOnAlgorand, createWrappedOnAptos, createWrappedOnNear, createWrappedOnSolana, createWrappedOnTerra, createWrappedOnXpla, } from ".";
import { Bridge__factory } from "../ethers-contracts";
import { getPackageId, getWrappedCoinType, uint8ArrayToBCS } from "../sui";
export function updateWrappedOnEth(tokenBridgeAddress, signer, signedVAA, overrides = {}) {
    return __awaiter(this, void 0, void 0, function* () {
        const bridge = Bridge__factory.connect(tokenBridgeAddress, signer);
        const v = yield bridge.updateWrapped(signedVAA, overrides);
        const receipt = yield v.wait();
        return receipt;
    });
}
export const updateWrappedOnTerra = createWrappedOnTerra;
export const updateWrappedOnXpla = createWrappedOnXpla;
export const updateWrappedOnSolana = createWrappedOnSolana;
export const updateWrappedOnAlgorand = createWrappedOnAlgorand;
export const updateWrappedOnNear = createWrappedOnNear;
export const updateWrappedOnAptos = createWrappedOnAptos;
export function updateWrappedOnSui(provider, coreBridgeStateObjectId, tokenBridgeStateObjectId, coinPackageId, attestVAA, coreBridgePackageId, tokenBridgePackageId) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        [coreBridgePackageId, tokenBridgePackageId] = yield Promise.all([
            coreBridgePackageId
                ? Promise.resolve(coreBridgePackageId)
                : getPackageId(provider, coreBridgeStateObjectId),
            tokenBridgePackageId
                ? Promise.resolve(tokenBridgePackageId)
                : getPackageId(provider, tokenBridgeStateObjectId),
        ]);
        // Get coin metadata
        const coinType = getWrappedCoinType(coinPackageId);
        const coinMetadataObjectId = (_a = (yield provider.getCoinMetadata({ coinType }))) === null || _a === void 0 ? void 0 : _a.id;
        if (!coinMetadataObjectId) {
            throw new Error(`Coin metadata object not found for coin type ${coinType}.`);
        }
        // Get verified VAA
        const tx = new TransactionBlock();
        const [vaa] = tx.moveCall({
            target: `${coreBridgePackageId}::vaa::parse_and_verify`,
            arguments: [
                tx.object(coreBridgeStateObjectId),
                tx.pure(uint8ArrayToBCS(attestVAA)),
                tx.object(SUI_CLOCK_OBJECT_ID),
            ],
        });
        // Get TokenBridgeMessage
        const [message] = tx.moveCall({
            target: `${tokenBridgePackageId}::vaa::verify_only_once`,
            arguments: [tx.object(tokenBridgeStateObjectId), vaa],
        });
        // Construct complete registration payload
        tx.moveCall({
            target: `${tokenBridgePackageId}::create_wrapped::update_attestation`,
            arguments: [
                tx.object(tokenBridgeStateObjectId),
                tx.object(coinMetadataObjectId),
                message,
            ],
            typeArguments: [coinType],
        });
        return tx;
    });
}
