var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import nearAPI from "near-api-js";
import BN from "bn.js";
export function logNearGas(result, comment) {
    const { totalGasBurned, totalTokensBurned } = result.receipts_outcome.reduce((acc, receipt) => {
        acc.totalGasBurned += receipt.outcome.gas_burnt;
        acc.totalTokensBurned += nearAPI.utils.format.formatNearAmount(receipt.outcome.tokens_burnt);
        return acc;
    }, {
        totalGasBurned: result.transaction_outcome.outcome.gas_burnt,
        totalTokensBurned: nearAPI.utils.format.formatNearAmount(result.transaction_outcome.outcome.tokens_burnt),
    });
    console.log(comment, "totalGasBurned", totalGasBurned, "totalTokensBurned", totalTokensBurned);
}
export function hashAccount(provider, tokenBridge, account) {
    return __awaiter(this, void 0, void 0, function* () {
        // Near can have account names up to 64 bytes, but wormhole only supports 32
        // As a result, we have to hash our account names with sha256
        const [isRegistered, accountHash] = yield callFunctionNear(provider, tokenBridge, "hash_account", { account });
        return {
            isRegistered,
            accountHash,
        };
    });
}
export function hashLookup(provider, tokenBridge, hash) {
    return __awaiter(this, void 0, void 0, function* () {
        const [found, value] = yield callFunctionNear(provider, tokenBridge, "hash_lookup", {
            hash,
        });
        return {
            found,
            value,
        };
    });
}
export function registerAccount(account, tokenBridge) {
    return {
        contractId: tokenBridge,
        methodName: "register_account",
        args: { account },
        gas: new BN("100000000000000"),
        attachedDeposit: new BN("2000000000000000000000"),
    };
}
export function callFunctionNear(provider, accountId, methodName, args) {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield provider.query({
            request_type: "call_function",
            account_id: accountId,
            method_name: methodName,
            args_base64: args
                ? Buffer.from(JSON.stringify(args)).toString("base64")
                : "",
            finality: "final",
        });
        return JSON.parse(Buffer.from(response.result).toString());
    });
}
