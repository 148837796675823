/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Contract, utils } from "ethers";
const _abi = [
    {
        type: "function",
        name: "chainId",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "uint16",
                internalType: "uint16",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "evmChainId",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "uint256",
                internalType: "uint256",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "getCurrentGuardianSetIndex",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "uint32",
                internalType: "uint32",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "getGuardianSet",
        inputs: [
            {
                name: "index",
                type: "uint32",
                internalType: "uint32",
            },
        ],
        outputs: [
            {
                name: "",
                type: "tuple",
                internalType: "struct IWormhole.GuardianSet",
                components: [
                    {
                        name: "keys",
                        type: "address[]",
                        internalType: "address[]",
                    },
                    {
                        name: "expirationTime",
                        type: "uint32",
                        internalType: "uint32",
                    },
                ],
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "getGuardianSetExpiry",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "uint32",
                internalType: "uint32",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "getImplementation",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "address",
                internalType: "address",
            },
        ],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "governanceActionIsConsumed",
        inputs: [
            {
                name: "hash",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
        outputs: [
            {
                name: "",
                type: "bool",
                internalType: "bool",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "governanceChainId",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "uint16",
                internalType: "uint16",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "governanceContract",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "initialize",
        inputs: [],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "isFork",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "bool",
                internalType: "bool",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "isInitialized",
        inputs: [
            {
                name: "impl",
                type: "address",
                internalType: "address",
            },
        ],
        outputs: [
            {
                name: "",
                type: "bool",
                internalType: "bool",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "messageFee",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "uint256",
                internalType: "uint256",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "nextSequence",
        inputs: [
            {
                name: "emitter",
                type: "address",
                internalType: "address",
            },
        ],
        outputs: [
            {
                name: "",
                type: "uint64",
                internalType: "uint64",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "parseAndVerifyVM",
        inputs: [
            {
                name: "encodedVM",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [
            {
                name: "vm",
                type: "tuple",
                internalType: "struct IWormhole.VM",
                components: [
                    {
                        name: "version",
                        type: "uint8",
                        internalType: "uint8",
                    },
                    {
                        name: "timestamp",
                        type: "uint32",
                        internalType: "uint32",
                    },
                    {
                        name: "nonce",
                        type: "uint32",
                        internalType: "uint32",
                    },
                    {
                        name: "emitterChainId",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "emitterAddress",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "sequence",
                        type: "uint64",
                        internalType: "uint64",
                    },
                    {
                        name: "consistencyLevel",
                        type: "uint8",
                        internalType: "uint8",
                    },
                    {
                        name: "payload",
                        type: "bytes",
                        internalType: "bytes",
                    },
                    {
                        name: "guardianSetIndex",
                        type: "uint32",
                        internalType: "uint32",
                    },
                    {
                        name: "signatures",
                        type: "tuple[]",
                        internalType: "struct IWormhole.Signature[]",
                        components: [
                            {
                                name: "r",
                                type: "bytes32",
                                internalType: "bytes32",
                            },
                            {
                                name: "s",
                                type: "bytes32",
                                internalType: "bytes32",
                            },
                            {
                                name: "v",
                                type: "uint8",
                                internalType: "uint8",
                            },
                            {
                                name: "guardianIndex",
                                type: "uint8",
                                internalType: "uint8",
                            },
                        ],
                    },
                    {
                        name: "hash",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                ],
            },
            {
                name: "valid",
                type: "bool",
                internalType: "bool",
            },
            {
                name: "reason",
                type: "string",
                internalType: "string",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "parseContractUpgrade",
        inputs: [
            {
                name: "encodedUpgrade",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [
            {
                name: "cu",
                type: "tuple",
                internalType: "struct IWormhole.ContractUpgrade",
                components: [
                    {
                        name: "module",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "action",
                        type: "uint8",
                        internalType: "uint8",
                    },
                    {
                        name: "chain",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "newContract",
                        type: "address",
                        internalType: "address",
                    },
                ],
            },
        ],
        stateMutability: "pure",
    },
    {
        type: "function",
        name: "parseGuardianSetUpgrade",
        inputs: [
            {
                name: "encodedUpgrade",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [
            {
                name: "gsu",
                type: "tuple",
                internalType: "struct IWormhole.GuardianSetUpgrade",
                components: [
                    {
                        name: "module",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "action",
                        type: "uint8",
                        internalType: "uint8",
                    },
                    {
                        name: "chain",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "newGuardianSet",
                        type: "tuple",
                        internalType: "struct IWormhole.GuardianSet",
                        components: [
                            {
                                name: "keys",
                                type: "address[]",
                                internalType: "address[]",
                            },
                            {
                                name: "expirationTime",
                                type: "uint32",
                                internalType: "uint32",
                            },
                        ],
                    },
                    {
                        name: "newGuardianSetIndex",
                        type: "uint32",
                        internalType: "uint32",
                    },
                ],
            },
        ],
        stateMutability: "pure",
    },
    {
        type: "function",
        name: "parseRecoverChainId",
        inputs: [
            {
                name: "encodedRecoverChainId",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [
            {
                name: "rci",
                type: "tuple",
                internalType: "struct IWormhole.RecoverChainId",
                components: [
                    {
                        name: "module",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "action",
                        type: "uint8",
                        internalType: "uint8",
                    },
                    {
                        name: "evmChainId",
                        type: "uint256",
                        internalType: "uint256",
                    },
                    {
                        name: "newChainId",
                        type: "uint16",
                        internalType: "uint16",
                    },
                ],
            },
        ],
        stateMutability: "pure",
    },
    {
        type: "function",
        name: "parseSetMessageFee",
        inputs: [
            {
                name: "encodedSetMessageFee",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [
            {
                name: "smf",
                type: "tuple",
                internalType: "struct IWormhole.SetMessageFee",
                components: [
                    {
                        name: "module",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "action",
                        type: "uint8",
                        internalType: "uint8",
                    },
                    {
                        name: "chain",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "messageFee",
                        type: "uint256",
                        internalType: "uint256",
                    },
                ],
            },
        ],
        stateMutability: "pure",
    },
    {
        type: "function",
        name: "parseTransferFees",
        inputs: [
            {
                name: "encodedTransferFees",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [
            {
                name: "tf",
                type: "tuple",
                internalType: "struct IWormhole.TransferFees",
                components: [
                    {
                        name: "module",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "action",
                        type: "uint8",
                        internalType: "uint8",
                    },
                    {
                        name: "chain",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "amount",
                        type: "uint256",
                        internalType: "uint256",
                    },
                    {
                        name: "recipient",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                ],
            },
        ],
        stateMutability: "pure",
    },
    {
        type: "function",
        name: "parseVM",
        inputs: [
            {
                name: "encodedVM",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [
            {
                name: "vm",
                type: "tuple",
                internalType: "struct IWormhole.VM",
                components: [
                    {
                        name: "version",
                        type: "uint8",
                        internalType: "uint8",
                    },
                    {
                        name: "timestamp",
                        type: "uint32",
                        internalType: "uint32",
                    },
                    {
                        name: "nonce",
                        type: "uint32",
                        internalType: "uint32",
                    },
                    {
                        name: "emitterChainId",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "emitterAddress",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "sequence",
                        type: "uint64",
                        internalType: "uint64",
                    },
                    {
                        name: "consistencyLevel",
                        type: "uint8",
                        internalType: "uint8",
                    },
                    {
                        name: "payload",
                        type: "bytes",
                        internalType: "bytes",
                    },
                    {
                        name: "guardianSetIndex",
                        type: "uint32",
                        internalType: "uint32",
                    },
                    {
                        name: "signatures",
                        type: "tuple[]",
                        internalType: "struct IWormhole.Signature[]",
                        components: [
                            {
                                name: "r",
                                type: "bytes32",
                                internalType: "bytes32",
                            },
                            {
                                name: "s",
                                type: "bytes32",
                                internalType: "bytes32",
                            },
                            {
                                name: "v",
                                type: "uint8",
                                internalType: "uint8",
                            },
                            {
                                name: "guardianIndex",
                                type: "uint8",
                                internalType: "uint8",
                            },
                        ],
                    },
                    {
                        name: "hash",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                ],
            },
        ],
        stateMutability: "pure",
    },
    {
        type: "function",
        name: "publishMessage",
        inputs: [
            {
                name: "nonce",
                type: "uint32",
                internalType: "uint32",
            },
            {
                name: "payload",
                type: "bytes",
                internalType: "bytes",
            },
            {
                name: "consistencyLevel",
                type: "uint8",
                internalType: "uint8",
            },
        ],
        outputs: [
            {
                name: "sequence",
                type: "uint64",
                internalType: "uint64",
            },
        ],
        stateMutability: "payable",
    },
    {
        type: "function",
        name: "quorum",
        inputs: [
            {
                name: "numGuardians",
                type: "uint256",
                internalType: "uint256",
            },
        ],
        outputs: [
            {
                name: "numSignaturesRequiredForQuorum",
                type: "uint256",
                internalType: "uint256",
            },
        ],
        stateMutability: "pure",
    },
    {
        type: "function",
        name: "submitContractUpgrade",
        inputs: [
            {
                name: "_vm",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "submitNewGuardianSet",
        inputs: [
            {
                name: "_vm",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "submitRecoverChainId",
        inputs: [
            {
                name: "_vm",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "submitSetMessageFee",
        inputs: [
            {
                name: "_vm",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "submitTransferFees",
        inputs: [
            {
                name: "_vm",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "upgradeImpl",
        inputs: [
            {
                name: "newImplementation",
                type: "address",
                internalType: "address",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "verifySignatures",
        inputs: [
            {
                name: "hash",
                type: "bytes32",
                internalType: "bytes32",
            },
            {
                name: "signatures",
                type: "tuple[]",
                internalType: "struct IWormhole.Signature[]",
                components: [
                    {
                        name: "r",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "s",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "v",
                        type: "uint8",
                        internalType: "uint8",
                    },
                    {
                        name: "guardianIndex",
                        type: "uint8",
                        internalType: "uint8",
                    },
                ],
            },
            {
                name: "guardianSet",
                type: "tuple",
                internalType: "struct IWormhole.GuardianSet",
                components: [
                    {
                        name: "keys",
                        type: "address[]",
                        internalType: "address[]",
                    },
                    {
                        name: "expirationTime",
                        type: "uint32",
                        internalType: "uint32",
                    },
                ],
            },
        ],
        outputs: [
            {
                name: "valid",
                type: "bool",
                internalType: "bool",
            },
            {
                name: "reason",
                type: "string",
                internalType: "string",
            },
        ],
        stateMutability: "pure",
    },
    {
        type: "function",
        name: "verifyVM",
        inputs: [
            {
                name: "vm",
                type: "tuple",
                internalType: "struct IWormhole.VM",
                components: [
                    {
                        name: "version",
                        type: "uint8",
                        internalType: "uint8",
                    },
                    {
                        name: "timestamp",
                        type: "uint32",
                        internalType: "uint32",
                    },
                    {
                        name: "nonce",
                        type: "uint32",
                        internalType: "uint32",
                    },
                    {
                        name: "emitterChainId",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "emitterAddress",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "sequence",
                        type: "uint64",
                        internalType: "uint64",
                    },
                    {
                        name: "consistencyLevel",
                        type: "uint8",
                        internalType: "uint8",
                    },
                    {
                        name: "payload",
                        type: "bytes",
                        internalType: "bytes",
                    },
                    {
                        name: "guardianSetIndex",
                        type: "uint32",
                        internalType: "uint32",
                    },
                    {
                        name: "signatures",
                        type: "tuple[]",
                        internalType: "struct IWormhole.Signature[]",
                        components: [
                            {
                                name: "r",
                                type: "bytes32",
                                internalType: "bytes32",
                            },
                            {
                                name: "s",
                                type: "bytes32",
                                internalType: "bytes32",
                            },
                            {
                                name: "v",
                                type: "uint8",
                                internalType: "uint8",
                            },
                            {
                                name: "guardianIndex",
                                type: "uint8",
                                internalType: "uint8",
                            },
                        ],
                    },
                    {
                        name: "hash",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                ],
            },
        ],
        outputs: [
            {
                name: "valid",
                type: "bool",
                internalType: "bool",
            },
            {
                name: "reason",
                type: "string",
                internalType: "string",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "event",
        name: "ContractUpgraded",
        inputs: [
            {
                name: "oldContract",
                type: "address",
                indexed: true,
                internalType: "address",
            },
            {
                name: "newContract",
                type: "address",
                indexed: true,
                internalType: "address",
            },
        ],
        anonymous: false,
    },
    {
        type: "event",
        name: "GuardianSetAdded",
        inputs: [
            {
                name: "index",
                type: "uint32",
                indexed: true,
                internalType: "uint32",
            },
        ],
        anonymous: false,
    },
    {
        type: "event",
        name: "LogMessagePublished",
        inputs: [
            {
                name: "sender",
                type: "address",
                indexed: true,
                internalType: "address",
            },
            {
                name: "sequence",
                type: "uint64",
                indexed: false,
                internalType: "uint64",
            },
            {
                name: "nonce",
                type: "uint32",
                indexed: false,
                internalType: "uint32",
            },
            {
                name: "payload",
                type: "bytes",
                indexed: false,
                internalType: "bytes",
            },
            {
                name: "consistencyLevel",
                type: "uint8",
                indexed: false,
                internalType: "uint8",
            },
        ],
        anonymous: false,
    },
];
export class IMyWormhole__factory {
    static createInterface() {
        return new utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    }
}
IMyWormhole__factory.abi = _abi;
