/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { utils, Contract, ContractFactory } from "ethers";
const _abi = [
    {
        type: "event",
        name: "SlotFound",
        inputs: [
            {
                name: "who",
                type: "address",
                indexed: false,
                internalType: "address",
            },
            {
                name: "fsig",
                type: "bytes4",
                indexed: false,
                internalType: "bytes4",
            },
            {
                name: "keysHash",
                type: "bytes32",
                indexed: false,
                internalType: "bytes32",
            },
            {
                name: "slot",
                type: "uint256",
                indexed: false,
                internalType: "uint256",
            },
        ],
        anonymous: false,
    },
    {
        type: "event",
        name: "WARNING_UninitedSlot",
        inputs: [
            {
                name: "who",
                type: "address",
                indexed: false,
                internalType: "address",
            },
            {
                name: "slot",
                type: "uint256",
                indexed: false,
                internalType: "uint256",
            },
        ],
        anonymous: false,
    },
];
const _bytecode = "0x60566037600b82828239805160001a607314602a57634e487b7160e01b600052600060045260246000fd5b30600052607381538281f3fe73000000000000000000000000000000000000000030146080604052600080fdfea2646970667358221220c19dbe4dcc8651803983b827e33a92ded5bd1fc6349c7af17d2b2dc75f4eb7b564736f6c63430008040033";
const isSuperArgs = (xs) => xs.length > 1;
export class StdStorageSafe__factory extends ContractFactory {
    constructor(...args) {
        if (isSuperArgs(args)) {
            super(...args);
        }
        else {
            super(_abi, _bytecode, args[0]);
        }
    }
    deploy(overrides) {
        return super.deploy(overrides || {});
    }
    getDeployTransaction(overrides) {
        return super.getDeployTransaction(overrides || {});
    }
    attach(address) {
        return super.attach(address);
    }
    connect(signer) {
        return super.connect(signer);
    }
    static createInterface() {
        return new utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    }
}
StdStorageSafe__factory.bytecode = _bytecode;
StdStorageSafe__factory.abi = _abi;
