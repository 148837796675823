/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Contract, utils } from "ethers";
const _abi = [
    {
        type: "function",
        name: "deliveryAttempted",
        inputs: [
            {
                name: "deliveryHash",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
        outputs: [
            {
                name: "attempted",
                type: "bool",
                internalType: "bool",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "deliveryFailureBlock",
        inputs: [
            {
                name: "deliveryHash",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
        outputs: [
            {
                name: "blockNumber",
                type: "uint256",
                internalType: "uint256",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "deliverySuccessBlock",
        inputs: [
            {
                name: "deliveryHash",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
        outputs: [
            {
                name: "blockNumber",
                type: "uint256",
                internalType: "uint256",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "getRegisteredWormholeRelayerContract",
        inputs: [
            {
                name: "chainId",
                type: "uint16",
                internalType: "uint16",
            },
        ],
        outputs: [
            {
                name: "",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "event",
        name: "SendEvent",
        inputs: [
            {
                name: "sequence",
                type: "uint64",
                indexed: true,
                internalType: "uint64",
            },
            {
                name: "deliveryQuote",
                type: "uint256",
                indexed: false,
                internalType: "uint256",
            },
            {
                name: "paymentForExtraReceiverValue",
                type: "uint256",
                indexed: false,
                internalType: "uint256",
            },
        ],
        anonymous: false,
    },
];
export class IWormholeRelayerBase__factory {
    static createInterface() {
        return new utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    }
}
IWormholeRelayerBase__factory.abi = _abi;
