var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
export var protobufPackage = "gossip.v1";
var baseGossipMessage = {};
export var GossipMessage = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.signedObservation !== undefined) {
            SignedObservation.encode(message.signedObservation, writer.uint32(18).fork()).ldelim();
        }
        if (message.signedHeartbeat !== undefined) {
            SignedHeartbeat.encode(message.signedHeartbeat, writer.uint32(26).fork()).ldelim();
        }
        if (message.signedVaaWithQuorum !== undefined) {
            SignedVAAWithQuorum.encode(message.signedVaaWithQuorum, writer.uint32(34).fork()).ldelim();
        }
        if (message.signedObservationRequest !== undefined) {
            SignedObservationRequest.encode(message.signedObservationRequest, writer.uint32(42).fork()).ldelim();
        }
        if (message.signedBatchObservation !== undefined) {
            SignedBatchObservation.encode(message.signedBatchObservation, writer.uint32(50).fork()).ldelim();
        }
        if (message.signedBatchVaaWithQuorum !== undefined) {
            SignedBatchVAAWithQuorum.encode(message.signedBatchVaaWithQuorum, writer.uint32(58).fork()).ldelim();
        }
        if (message.signedChainGovernorConfig !== undefined) {
            SignedChainGovernorConfig.encode(message.signedChainGovernorConfig, writer.uint32(66).fork()).ldelim();
        }
        if (message.signedChainGovernorStatus !== undefined) {
            SignedChainGovernorStatus.encode(message.signedChainGovernorStatus, writer.uint32(74).fork()).ldelim();
        }
        if (message.signedQueryRequest !== undefined) {
            SignedQueryRequest.encode(message.signedQueryRequest, writer.uint32(82).fork()).ldelim();
        }
        if (message.signedQueryResponse !== undefined) {
            SignedQueryResponse.encode(message.signedQueryResponse, writer.uint32(90).fork()).ldelim();
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseGossipMessage);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 2:
                    message.signedObservation = SignedObservation.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.signedHeartbeat = SignedHeartbeat.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.signedVaaWithQuorum = SignedVAAWithQuorum.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.signedObservationRequest = SignedObservationRequest.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.signedBatchObservation = SignedBatchObservation.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.signedBatchVaaWithQuorum = SignedBatchVAAWithQuorum.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.signedChainGovernorConfig = SignedChainGovernorConfig.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.signedChainGovernorStatus = SignedChainGovernorStatus.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.signedQueryRequest = SignedQueryRequest.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.signedQueryResponse = SignedQueryResponse.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseGossipMessage);
        if (object.signedObservation !== undefined &&
            object.signedObservation !== null) {
            message.signedObservation = SignedObservation.fromJSON(object.signedObservation);
        }
        else {
            message.signedObservation = undefined;
        }
        if (object.signedHeartbeat !== undefined &&
            object.signedHeartbeat !== null) {
            message.signedHeartbeat = SignedHeartbeat.fromJSON(object.signedHeartbeat);
        }
        else {
            message.signedHeartbeat = undefined;
        }
        if (object.signedVaaWithQuorum !== undefined &&
            object.signedVaaWithQuorum !== null) {
            message.signedVaaWithQuorum = SignedVAAWithQuorum.fromJSON(object.signedVaaWithQuorum);
        }
        else {
            message.signedVaaWithQuorum = undefined;
        }
        if (object.signedObservationRequest !== undefined &&
            object.signedObservationRequest !== null) {
            message.signedObservationRequest = SignedObservationRequest.fromJSON(object.signedObservationRequest);
        }
        else {
            message.signedObservationRequest = undefined;
        }
        if (object.signedBatchObservation !== undefined &&
            object.signedBatchObservation !== null) {
            message.signedBatchObservation = SignedBatchObservation.fromJSON(object.signedBatchObservation);
        }
        else {
            message.signedBatchObservation = undefined;
        }
        if (object.signedBatchVaaWithQuorum !== undefined &&
            object.signedBatchVaaWithQuorum !== null) {
            message.signedBatchVaaWithQuorum = SignedBatchVAAWithQuorum.fromJSON(object.signedBatchVaaWithQuorum);
        }
        else {
            message.signedBatchVaaWithQuorum = undefined;
        }
        if (object.signedChainGovernorConfig !== undefined &&
            object.signedChainGovernorConfig !== null) {
            message.signedChainGovernorConfig = SignedChainGovernorConfig.fromJSON(object.signedChainGovernorConfig);
        }
        else {
            message.signedChainGovernorConfig = undefined;
        }
        if (object.signedChainGovernorStatus !== undefined &&
            object.signedChainGovernorStatus !== null) {
            message.signedChainGovernorStatus = SignedChainGovernorStatus.fromJSON(object.signedChainGovernorStatus);
        }
        else {
            message.signedChainGovernorStatus = undefined;
        }
        if (object.signedQueryRequest !== undefined &&
            object.signedQueryRequest !== null) {
            message.signedQueryRequest = SignedQueryRequest.fromJSON(object.signedQueryRequest);
        }
        else {
            message.signedQueryRequest = undefined;
        }
        if (object.signedQueryResponse !== undefined &&
            object.signedQueryResponse !== null) {
            message.signedQueryResponse = SignedQueryResponse.fromJSON(object.signedQueryResponse);
        }
        else {
            message.signedQueryResponse = undefined;
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.signedObservation !== undefined &&
            (obj.signedObservation = message.signedObservation
                ? SignedObservation.toJSON(message.signedObservation)
                : undefined);
        message.signedHeartbeat !== undefined &&
            (obj.signedHeartbeat = message.signedHeartbeat
                ? SignedHeartbeat.toJSON(message.signedHeartbeat)
                : undefined);
        message.signedVaaWithQuorum !== undefined &&
            (obj.signedVaaWithQuorum = message.signedVaaWithQuorum
                ? SignedVAAWithQuorum.toJSON(message.signedVaaWithQuorum)
                : undefined);
        message.signedObservationRequest !== undefined &&
            (obj.signedObservationRequest = message.signedObservationRequest
                ? SignedObservationRequest.toJSON(message.signedObservationRequest)
                : undefined);
        message.signedBatchObservation !== undefined &&
            (obj.signedBatchObservation = message.signedBatchObservation
                ? SignedBatchObservation.toJSON(message.signedBatchObservation)
                : undefined);
        message.signedBatchVaaWithQuorum !== undefined &&
            (obj.signedBatchVaaWithQuorum = message.signedBatchVaaWithQuorum
                ? SignedBatchVAAWithQuorum.toJSON(message.signedBatchVaaWithQuorum)
                : undefined);
        message.signedChainGovernorConfig !== undefined &&
            (obj.signedChainGovernorConfig = message.signedChainGovernorConfig
                ? SignedChainGovernorConfig.toJSON(message.signedChainGovernorConfig)
                : undefined);
        message.signedChainGovernorStatus !== undefined &&
            (obj.signedChainGovernorStatus = message.signedChainGovernorStatus
                ? SignedChainGovernorStatus.toJSON(message.signedChainGovernorStatus)
                : undefined);
        message.signedQueryRequest !== undefined &&
            (obj.signedQueryRequest = message.signedQueryRequest
                ? SignedQueryRequest.toJSON(message.signedQueryRequest)
                : undefined);
        message.signedQueryResponse !== undefined &&
            (obj.signedQueryResponse = message.signedQueryResponse
                ? SignedQueryResponse.toJSON(message.signedQueryResponse)
                : undefined);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseGossipMessage);
        if (object.signedObservation !== undefined &&
            object.signedObservation !== null) {
            message.signedObservation = SignedObservation.fromPartial(object.signedObservation);
        }
        else {
            message.signedObservation = undefined;
        }
        if (object.signedHeartbeat !== undefined &&
            object.signedHeartbeat !== null) {
            message.signedHeartbeat = SignedHeartbeat.fromPartial(object.signedHeartbeat);
        }
        else {
            message.signedHeartbeat = undefined;
        }
        if (object.signedVaaWithQuorum !== undefined &&
            object.signedVaaWithQuorum !== null) {
            message.signedVaaWithQuorum = SignedVAAWithQuorum.fromPartial(object.signedVaaWithQuorum);
        }
        else {
            message.signedVaaWithQuorum = undefined;
        }
        if (object.signedObservationRequest !== undefined &&
            object.signedObservationRequest !== null) {
            message.signedObservationRequest = SignedObservationRequest.fromPartial(object.signedObservationRequest);
        }
        else {
            message.signedObservationRequest = undefined;
        }
        if (object.signedBatchObservation !== undefined &&
            object.signedBatchObservation !== null) {
            message.signedBatchObservation = SignedBatchObservation.fromPartial(object.signedBatchObservation);
        }
        else {
            message.signedBatchObservation = undefined;
        }
        if (object.signedBatchVaaWithQuorum !== undefined &&
            object.signedBatchVaaWithQuorum !== null) {
            message.signedBatchVaaWithQuorum = SignedBatchVAAWithQuorum.fromPartial(object.signedBatchVaaWithQuorum);
        }
        else {
            message.signedBatchVaaWithQuorum = undefined;
        }
        if (object.signedChainGovernorConfig !== undefined &&
            object.signedChainGovernorConfig !== null) {
            message.signedChainGovernorConfig = SignedChainGovernorConfig.fromPartial(object.signedChainGovernorConfig);
        }
        else {
            message.signedChainGovernorConfig = undefined;
        }
        if (object.signedChainGovernorStatus !== undefined &&
            object.signedChainGovernorStatus !== null) {
            message.signedChainGovernorStatus = SignedChainGovernorStatus.fromPartial(object.signedChainGovernorStatus);
        }
        else {
            message.signedChainGovernorStatus = undefined;
        }
        if (object.signedQueryRequest !== undefined &&
            object.signedQueryRequest !== null) {
            message.signedQueryRequest = SignedQueryRequest.fromPartial(object.signedQueryRequest);
        }
        else {
            message.signedQueryRequest = undefined;
        }
        if (object.signedQueryResponse !== undefined &&
            object.signedQueryResponse !== null) {
            message.signedQueryResponse = SignedQueryResponse.fromPartial(object.signedQueryResponse);
        }
        else {
            message.signedQueryResponse = undefined;
        }
        return message;
    },
};
var baseSignedHeartbeat = {};
export var SignedHeartbeat = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.heartbeat.length !== 0) {
            writer.uint32(10).bytes(message.heartbeat);
        }
        if (message.signature.length !== 0) {
            writer.uint32(18).bytes(message.signature);
        }
        if (message.guardianAddr.length !== 0) {
            writer.uint32(26).bytes(message.guardianAddr);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseSignedHeartbeat);
        message.heartbeat = new Uint8Array();
        message.signature = new Uint8Array();
        message.guardianAddr = new Uint8Array();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.heartbeat = reader.bytes();
                    break;
                case 2:
                    message.signature = reader.bytes();
                    break;
                case 3:
                    message.guardianAddr = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseSignedHeartbeat);
        message.heartbeat = new Uint8Array();
        message.signature = new Uint8Array();
        message.guardianAddr = new Uint8Array();
        if (object.heartbeat !== undefined && object.heartbeat !== null) {
            message.heartbeat = bytesFromBase64(object.heartbeat);
        }
        if (object.signature !== undefined && object.signature !== null) {
            message.signature = bytesFromBase64(object.signature);
        }
        if (object.guardianAddr !== undefined && object.guardianAddr !== null) {
            message.guardianAddr = bytesFromBase64(object.guardianAddr);
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.heartbeat !== undefined &&
            (obj.heartbeat = base64FromBytes(message.heartbeat !== undefined ? message.heartbeat : new Uint8Array()));
        message.signature !== undefined &&
            (obj.signature = base64FromBytes(message.signature !== undefined ? message.signature : new Uint8Array()));
        message.guardianAddr !== undefined &&
            (obj.guardianAddr = base64FromBytes(message.guardianAddr !== undefined
                ? message.guardianAddr
                : new Uint8Array()));
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseSignedHeartbeat);
        if (object.heartbeat !== undefined && object.heartbeat !== null) {
            message.heartbeat = object.heartbeat;
        }
        else {
            message.heartbeat = new Uint8Array();
        }
        if (object.signature !== undefined && object.signature !== null) {
            message.signature = object.signature;
        }
        else {
            message.signature = new Uint8Array();
        }
        if (object.guardianAddr !== undefined && object.guardianAddr !== null) {
            message.guardianAddr = object.guardianAddr;
        }
        else {
            message.guardianAddr = new Uint8Array();
        }
        return message;
    },
};
var baseHeartbeat = {
    nodeName: "",
    counter: "0",
    timestamp: "0",
    version: "",
    guardianAddr: "",
    bootTimestamp: "0",
    features: "",
};
export var Heartbeat = {
    encode: function (message, writer) {
        var e_1, _a, e_2, _b;
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.nodeName !== "") {
            writer.uint32(10).string(message.nodeName);
        }
        if (message.counter !== "0") {
            writer.uint32(16).int64(message.counter);
        }
        if (message.timestamp !== "0") {
            writer.uint32(24).int64(message.timestamp);
        }
        try {
            for (var _c = __values(message.networks), _d = _c.next(); !_d.done; _d = _c.next()) {
                var v = _d.value;
                Heartbeat_Network.encode(v, writer.uint32(34).fork()).ldelim();
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (message.version !== "") {
            writer.uint32(42).string(message.version);
        }
        if (message.guardianAddr !== "") {
            writer.uint32(50).string(message.guardianAddr);
        }
        if (message.bootTimestamp !== "0") {
            writer.uint32(56).int64(message.bootTimestamp);
        }
        try {
            for (var _e = __values(message.features), _f = _e.next(); !_f.done; _f = _e.next()) {
                var v = _f.value;
                writer.uint32(66).string(v);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
            }
            finally { if (e_2) throw e_2.error; }
        }
        if (message.p2pNodeId.length !== 0) {
            writer.uint32(74).bytes(message.p2pNodeId);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseHeartbeat);
        message.networks = [];
        message.features = [];
        message.p2pNodeId = new Uint8Array();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.nodeName = reader.string();
                    break;
                case 2:
                    message.counter = longToString(reader.int64());
                    break;
                case 3:
                    message.timestamp = longToString(reader.int64());
                    break;
                case 4:
                    message.networks.push(Heartbeat_Network.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.version = reader.string();
                    break;
                case 6:
                    message.guardianAddr = reader.string();
                    break;
                case 7:
                    message.bootTimestamp = longToString(reader.int64());
                    break;
                case 8:
                    message.features.push(reader.string());
                    break;
                case 9:
                    message.p2pNodeId = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var e_3, _a, e_4, _b;
        var message = __assign({}, baseHeartbeat);
        message.networks = [];
        message.features = [];
        message.p2pNodeId = new Uint8Array();
        if (object.nodeName !== undefined && object.nodeName !== null) {
            message.nodeName = String(object.nodeName);
        }
        else {
            message.nodeName = "";
        }
        if (object.counter !== undefined && object.counter !== null) {
            message.counter = String(object.counter);
        }
        else {
            message.counter = "0";
        }
        if (object.timestamp !== undefined && object.timestamp !== null) {
            message.timestamp = String(object.timestamp);
        }
        else {
            message.timestamp = "0";
        }
        if (object.networks !== undefined && object.networks !== null) {
            try {
                for (var _c = __values(object.networks), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var e = _d.value;
                    message.networks.push(Heartbeat_Network.fromJSON(e));
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                }
                finally { if (e_3) throw e_3.error; }
            }
        }
        if (object.version !== undefined && object.version !== null) {
            message.version = String(object.version);
        }
        else {
            message.version = "";
        }
        if (object.guardianAddr !== undefined && object.guardianAddr !== null) {
            message.guardianAddr = String(object.guardianAddr);
        }
        else {
            message.guardianAddr = "";
        }
        if (object.bootTimestamp !== undefined && object.bootTimestamp !== null) {
            message.bootTimestamp = String(object.bootTimestamp);
        }
        else {
            message.bootTimestamp = "0";
        }
        if (object.features !== undefined && object.features !== null) {
            try {
                for (var _e = __values(object.features), _f = _e.next(); !_f.done; _f = _e.next()) {
                    var e = _f.value;
                    message.features.push(String(e));
                }
            }
            catch (e_4_1) { e_4 = { error: e_4_1 }; }
            finally {
                try {
                    if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                }
                finally { if (e_4) throw e_4.error; }
            }
        }
        if (object.p2pNodeId !== undefined && object.p2pNodeId !== null) {
            message.p2pNodeId = bytesFromBase64(object.p2pNodeId);
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.nodeName !== undefined && (obj.nodeName = message.nodeName);
        message.counter !== undefined && (obj.counter = message.counter);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        if (message.networks) {
            obj.networks = message.networks.map(function (e) {
                return e ? Heartbeat_Network.toJSON(e) : undefined;
            });
        }
        else {
            obj.networks = [];
        }
        message.version !== undefined && (obj.version = message.version);
        message.guardianAddr !== undefined &&
            (obj.guardianAddr = message.guardianAddr);
        message.bootTimestamp !== undefined &&
            (obj.bootTimestamp = message.bootTimestamp);
        if (message.features) {
            obj.features = message.features.map(function (e) { return e; });
        }
        else {
            obj.features = [];
        }
        message.p2pNodeId !== undefined &&
            (obj.p2pNodeId = base64FromBytes(message.p2pNodeId !== undefined ? message.p2pNodeId : new Uint8Array()));
        return obj;
    },
    fromPartial: function (object) {
        var e_5, _a, e_6, _b;
        var message = __assign({}, baseHeartbeat);
        message.networks = [];
        message.features = [];
        if (object.nodeName !== undefined && object.nodeName !== null) {
            message.nodeName = object.nodeName;
        }
        else {
            message.nodeName = "";
        }
        if (object.counter !== undefined && object.counter !== null) {
            message.counter = object.counter;
        }
        else {
            message.counter = "0";
        }
        if (object.timestamp !== undefined && object.timestamp !== null) {
            message.timestamp = object.timestamp;
        }
        else {
            message.timestamp = "0";
        }
        if (object.networks !== undefined && object.networks !== null) {
            try {
                for (var _c = __values(object.networks), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var e = _d.value;
                    message.networks.push(Heartbeat_Network.fromPartial(e));
                }
            }
            catch (e_5_1) { e_5 = { error: e_5_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                }
                finally { if (e_5) throw e_5.error; }
            }
        }
        if (object.version !== undefined && object.version !== null) {
            message.version = object.version;
        }
        else {
            message.version = "";
        }
        if (object.guardianAddr !== undefined && object.guardianAddr !== null) {
            message.guardianAddr = object.guardianAddr;
        }
        else {
            message.guardianAddr = "";
        }
        if (object.bootTimestamp !== undefined && object.bootTimestamp !== null) {
            message.bootTimestamp = object.bootTimestamp;
        }
        else {
            message.bootTimestamp = "0";
        }
        if (object.features !== undefined && object.features !== null) {
            try {
                for (var _e = __values(object.features), _f = _e.next(); !_f.done; _f = _e.next()) {
                    var e = _f.value;
                    message.features.push(e);
                }
            }
            catch (e_6_1) { e_6 = { error: e_6_1 }; }
            finally {
                try {
                    if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                }
                finally { if (e_6) throw e_6.error; }
            }
        }
        if (object.p2pNodeId !== undefined && object.p2pNodeId !== null) {
            message.p2pNodeId = object.p2pNodeId;
        }
        else {
            message.p2pNodeId = new Uint8Array();
        }
        return message;
    },
};
var baseHeartbeat_Network = {
    id: 0,
    height: "0",
    contractAddress: "",
    errorCount: "0",
    safeHeight: "0",
    finalizedHeight: "0",
};
export var Heartbeat_Network = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.id !== 0) {
            writer.uint32(8).uint32(message.id);
        }
        if (message.height !== "0") {
            writer.uint32(16).int64(message.height);
        }
        if (message.contractAddress !== "") {
            writer.uint32(26).string(message.contractAddress);
        }
        if (message.errorCount !== "0") {
            writer.uint32(32).uint64(message.errorCount);
        }
        if (message.safeHeight !== "0") {
            writer.uint32(40).int64(message.safeHeight);
        }
        if (message.finalizedHeight !== "0") {
            writer.uint32(48).int64(message.finalizedHeight);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseHeartbeat_Network);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.uint32();
                    break;
                case 2:
                    message.height = longToString(reader.int64());
                    break;
                case 3:
                    message.contractAddress = reader.string();
                    break;
                case 4:
                    message.errorCount = longToString(reader.uint64());
                    break;
                case 5:
                    message.safeHeight = longToString(reader.int64());
                    break;
                case 6:
                    message.finalizedHeight = longToString(reader.int64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseHeartbeat_Network);
        if (object.id !== undefined && object.id !== null) {
            message.id = Number(object.id);
        }
        else {
            message.id = 0;
        }
        if (object.height !== undefined && object.height !== null) {
            message.height = String(object.height);
        }
        else {
            message.height = "0";
        }
        if (object.contractAddress !== undefined &&
            object.contractAddress !== null) {
            message.contractAddress = String(object.contractAddress);
        }
        else {
            message.contractAddress = "";
        }
        if (object.errorCount !== undefined && object.errorCount !== null) {
            message.errorCount = String(object.errorCount);
        }
        else {
            message.errorCount = "0";
        }
        if (object.safeHeight !== undefined && object.safeHeight !== null) {
            message.safeHeight = String(object.safeHeight);
        }
        else {
            message.safeHeight = "0";
        }
        if (object.finalizedHeight !== undefined &&
            object.finalizedHeight !== null) {
            message.finalizedHeight = String(object.finalizedHeight);
        }
        else {
            message.finalizedHeight = "0";
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.height !== undefined && (obj.height = message.height);
        message.contractAddress !== undefined &&
            (obj.contractAddress = message.contractAddress);
        message.errorCount !== undefined && (obj.errorCount = message.errorCount);
        message.safeHeight !== undefined && (obj.safeHeight = message.safeHeight);
        message.finalizedHeight !== undefined &&
            (obj.finalizedHeight = message.finalizedHeight);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseHeartbeat_Network);
        if (object.id !== undefined && object.id !== null) {
            message.id = object.id;
        }
        else {
            message.id = 0;
        }
        if (object.height !== undefined && object.height !== null) {
            message.height = object.height;
        }
        else {
            message.height = "0";
        }
        if (object.contractAddress !== undefined &&
            object.contractAddress !== null) {
            message.contractAddress = object.contractAddress;
        }
        else {
            message.contractAddress = "";
        }
        if (object.errorCount !== undefined && object.errorCount !== null) {
            message.errorCount = object.errorCount;
        }
        else {
            message.errorCount = "0";
        }
        if (object.safeHeight !== undefined && object.safeHeight !== null) {
            message.safeHeight = object.safeHeight;
        }
        else {
            message.safeHeight = "0";
        }
        if (object.finalizedHeight !== undefined &&
            object.finalizedHeight !== null) {
            message.finalizedHeight = object.finalizedHeight;
        }
        else {
            message.finalizedHeight = "0";
        }
        return message;
    },
};
var baseSignedObservation = { messageId: "" };
export var SignedObservation = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.addr.length !== 0) {
            writer.uint32(10).bytes(message.addr);
        }
        if (message.hash.length !== 0) {
            writer.uint32(18).bytes(message.hash);
        }
        if (message.signature.length !== 0) {
            writer.uint32(26).bytes(message.signature);
        }
        if (message.txHash.length !== 0) {
            writer.uint32(34).bytes(message.txHash);
        }
        if (message.messageId !== "") {
            writer.uint32(42).string(message.messageId);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseSignedObservation);
        message.addr = new Uint8Array();
        message.hash = new Uint8Array();
        message.signature = new Uint8Array();
        message.txHash = new Uint8Array();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.addr = reader.bytes();
                    break;
                case 2:
                    message.hash = reader.bytes();
                    break;
                case 3:
                    message.signature = reader.bytes();
                    break;
                case 4:
                    message.txHash = reader.bytes();
                    break;
                case 5:
                    message.messageId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseSignedObservation);
        message.addr = new Uint8Array();
        message.hash = new Uint8Array();
        message.signature = new Uint8Array();
        message.txHash = new Uint8Array();
        if (object.addr !== undefined && object.addr !== null) {
            message.addr = bytesFromBase64(object.addr);
        }
        if (object.hash !== undefined && object.hash !== null) {
            message.hash = bytesFromBase64(object.hash);
        }
        if (object.signature !== undefined && object.signature !== null) {
            message.signature = bytesFromBase64(object.signature);
        }
        if (object.txHash !== undefined && object.txHash !== null) {
            message.txHash = bytesFromBase64(object.txHash);
        }
        if (object.messageId !== undefined && object.messageId !== null) {
            message.messageId = String(object.messageId);
        }
        else {
            message.messageId = "";
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.addr !== undefined &&
            (obj.addr = base64FromBytes(message.addr !== undefined ? message.addr : new Uint8Array()));
        message.hash !== undefined &&
            (obj.hash = base64FromBytes(message.hash !== undefined ? message.hash : new Uint8Array()));
        message.signature !== undefined &&
            (obj.signature = base64FromBytes(message.signature !== undefined ? message.signature : new Uint8Array()));
        message.txHash !== undefined &&
            (obj.txHash = base64FromBytes(message.txHash !== undefined ? message.txHash : new Uint8Array()));
        message.messageId !== undefined && (obj.messageId = message.messageId);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseSignedObservation);
        if (object.addr !== undefined && object.addr !== null) {
            message.addr = object.addr;
        }
        else {
            message.addr = new Uint8Array();
        }
        if (object.hash !== undefined && object.hash !== null) {
            message.hash = object.hash;
        }
        else {
            message.hash = new Uint8Array();
        }
        if (object.signature !== undefined && object.signature !== null) {
            message.signature = object.signature;
        }
        else {
            message.signature = new Uint8Array();
        }
        if (object.txHash !== undefined && object.txHash !== null) {
            message.txHash = object.txHash;
        }
        else {
            message.txHash = new Uint8Array();
        }
        if (object.messageId !== undefined && object.messageId !== null) {
            message.messageId = object.messageId;
        }
        else {
            message.messageId = "";
        }
        return message;
    },
};
var baseSignedVAAWithQuorum = {};
export var SignedVAAWithQuorum = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.vaa.length !== 0) {
            writer.uint32(10).bytes(message.vaa);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseSignedVAAWithQuorum);
        message.vaa = new Uint8Array();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.vaa = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseSignedVAAWithQuorum);
        message.vaa = new Uint8Array();
        if (object.vaa !== undefined && object.vaa !== null) {
            message.vaa = bytesFromBase64(object.vaa);
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.vaa !== undefined &&
            (obj.vaa = base64FromBytes(message.vaa !== undefined ? message.vaa : new Uint8Array()));
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseSignedVAAWithQuorum);
        if (object.vaa !== undefined && object.vaa !== null) {
            message.vaa = object.vaa;
        }
        else {
            message.vaa = new Uint8Array();
        }
        return message;
    },
};
var baseSignedObservationRequest = {};
export var SignedObservationRequest = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.observationRequest.length !== 0) {
            writer.uint32(10).bytes(message.observationRequest);
        }
        if (message.signature.length !== 0) {
            writer.uint32(18).bytes(message.signature);
        }
        if (message.guardianAddr.length !== 0) {
            writer.uint32(26).bytes(message.guardianAddr);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseSignedObservationRequest);
        message.observationRequest = new Uint8Array();
        message.signature = new Uint8Array();
        message.guardianAddr = new Uint8Array();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.observationRequest = reader.bytes();
                    break;
                case 2:
                    message.signature = reader.bytes();
                    break;
                case 3:
                    message.guardianAddr = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseSignedObservationRequest);
        message.observationRequest = new Uint8Array();
        message.signature = new Uint8Array();
        message.guardianAddr = new Uint8Array();
        if (object.observationRequest !== undefined &&
            object.observationRequest !== null) {
            message.observationRequest = bytesFromBase64(object.observationRequest);
        }
        if (object.signature !== undefined && object.signature !== null) {
            message.signature = bytesFromBase64(object.signature);
        }
        if (object.guardianAddr !== undefined && object.guardianAddr !== null) {
            message.guardianAddr = bytesFromBase64(object.guardianAddr);
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.observationRequest !== undefined &&
            (obj.observationRequest = base64FromBytes(message.observationRequest !== undefined
                ? message.observationRequest
                : new Uint8Array()));
        message.signature !== undefined &&
            (obj.signature = base64FromBytes(message.signature !== undefined ? message.signature : new Uint8Array()));
        message.guardianAddr !== undefined &&
            (obj.guardianAddr = base64FromBytes(message.guardianAddr !== undefined
                ? message.guardianAddr
                : new Uint8Array()));
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseSignedObservationRequest);
        if (object.observationRequest !== undefined &&
            object.observationRequest !== null) {
            message.observationRequest = object.observationRequest;
        }
        else {
            message.observationRequest = new Uint8Array();
        }
        if (object.signature !== undefined && object.signature !== null) {
            message.signature = object.signature;
        }
        else {
            message.signature = new Uint8Array();
        }
        if (object.guardianAddr !== undefined && object.guardianAddr !== null) {
            message.guardianAddr = object.guardianAddr;
        }
        else {
            message.guardianAddr = new Uint8Array();
        }
        return message;
    },
};
var baseObservationRequest = { chainId: 0 };
export var ObservationRequest = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.chainId !== 0) {
            writer.uint32(8).uint32(message.chainId);
        }
        if (message.txHash.length !== 0) {
            writer.uint32(18).bytes(message.txHash);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseObservationRequest);
        message.txHash = new Uint8Array();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.chainId = reader.uint32();
                    break;
                case 2:
                    message.txHash = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseObservationRequest);
        message.txHash = new Uint8Array();
        if (object.chainId !== undefined && object.chainId !== null) {
            message.chainId = Number(object.chainId);
        }
        else {
            message.chainId = 0;
        }
        if (object.txHash !== undefined && object.txHash !== null) {
            message.txHash = bytesFromBase64(object.txHash);
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.chainId !== undefined && (obj.chainId = message.chainId);
        message.txHash !== undefined &&
            (obj.txHash = base64FromBytes(message.txHash !== undefined ? message.txHash : new Uint8Array()));
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseObservationRequest);
        if (object.chainId !== undefined && object.chainId !== null) {
            message.chainId = object.chainId;
        }
        else {
            message.chainId = 0;
        }
        if (object.txHash !== undefined && object.txHash !== null) {
            message.txHash = object.txHash;
        }
        else {
            message.txHash = new Uint8Array();
        }
        return message;
    },
};
var baseSignedBatchObservation = {
    chainId: 0,
    nonce: 0,
    batchId: "",
};
export var SignedBatchObservation = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.addr.length !== 0) {
            writer.uint32(10).bytes(message.addr);
        }
        if (message.hash.length !== 0) {
            writer.uint32(18).bytes(message.hash);
        }
        if (message.signature.length !== 0) {
            writer.uint32(26).bytes(message.signature);
        }
        if (message.txId.length !== 0) {
            writer.uint32(34).bytes(message.txId);
        }
        if (message.chainId !== 0) {
            writer.uint32(40).uint32(message.chainId);
        }
        if (message.nonce !== 0) {
            writer.uint32(48).uint32(message.nonce);
        }
        if (message.batchId !== "") {
            writer.uint32(58).string(message.batchId);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseSignedBatchObservation);
        message.addr = new Uint8Array();
        message.hash = new Uint8Array();
        message.signature = new Uint8Array();
        message.txId = new Uint8Array();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.addr = reader.bytes();
                    break;
                case 2:
                    message.hash = reader.bytes();
                    break;
                case 3:
                    message.signature = reader.bytes();
                    break;
                case 4:
                    message.txId = reader.bytes();
                    break;
                case 5:
                    message.chainId = reader.uint32();
                    break;
                case 6:
                    message.nonce = reader.uint32();
                    break;
                case 7:
                    message.batchId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseSignedBatchObservation);
        message.addr = new Uint8Array();
        message.hash = new Uint8Array();
        message.signature = new Uint8Array();
        message.txId = new Uint8Array();
        if (object.addr !== undefined && object.addr !== null) {
            message.addr = bytesFromBase64(object.addr);
        }
        if (object.hash !== undefined && object.hash !== null) {
            message.hash = bytesFromBase64(object.hash);
        }
        if (object.signature !== undefined && object.signature !== null) {
            message.signature = bytesFromBase64(object.signature);
        }
        if (object.txId !== undefined && object.txId !== null) {
            message.txId = bytesFromBase64(object.txId);
        }
        if (object.chainId !== undefined && object.chainId !== null) {
            message.chainId = Number(object.chainId);
        }
        else {
            message.chainId = 0;
        }
        if (object.nonce !== undefined && object.nonce !== null) {
            message.nonce = Number(object.nonce);
        }
        else {
            message.nonce = 0;
        }
        if (object.batchId !== undefined && object.batchId !== null) {
            message.batchId = String(object.batchId);
        }
        else {
            message.batchId = "";
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.addr !== undefined &&
            (obj.addr = base64FromBytes(message.addr !== undefined ? message.addr : new Uint8Array()));
        message.hash !== undefined &&
            (obj.hash = base64FromBytes(message.hash !== undefined ? message.hash : new Uint8Array()));
        message.signature !== undefined &&
            (obj.signature = base64FromBytes(message.signature !== undefined ? message.signature : new Uint8Array()));
        message.txId !== undefined &&
            (obj.txId = base64FromBytes(message.txId !== undefined ? message.txId : new Uint8Array()));
        message.chainId !== undefined && (obj.chainId = message.chainId);
        message.nonce !== undefined && (obj.nonce = message.nonce);
        message.batchId !== undefined && (obj.batchId = message.batchId);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseSignedBatchObservation);
        if (object.addr !== undefined && object.addr !== null) {
            message.addr = object.addr;
        }
        else {
            message.addr = new Uint8Array();
        }
        if (object.hash !== undefined && object.hash !== null) {
            message.hash = object.hash;
        }
        else {
            message.hash = new Uint8Array();
        }
        if (object.signature !== undefined && object.signature !== null) {
            message.signature = object.signature;
        }
        else {
            message.signature = new Uint8Array();
        }
        if (object.txId !== undefined && object.txId !== null) {
            message.txId = object.txId;
        }
        else {
            message.txId = new Uint8Array();
        }
        if (object.chainId !== undefined && object.chainId !== null) {
            message.chainId = object.chainId;
        }
        else {
            message.chainId = 0;
        }
        if (object.nonce !== undefined && object.nonce !== null) {
            message.nonce = object.nonce;
        }
        else {
            message.nonce = 0;
        }
        if (object.batchId !== undefined && object.batchId !== null) {
            message.batchId = object.batchId;
        }
        else {
            message.batchId = "";
        }
        return message;
    },
};
var baseSignedBatchVAAWithQuorum = {
    chainId: 0,
    nonce: 0,
    batchId: "",
};
export var SignedBatchVAAWithQuorum = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.batchVaa.length !== 0) {
            writer.uint32(10).bytes(message.batchVaa);
        }
        if (message.chainId !== 0) {
            writer.uint32(16).uint32(message.chainId);
        }
        if (message.txId.length !== 0) {
            writer.uint32(26).bytes(message.txId);
        }
        if (message.nonce !== 0) {
            writer.uint32(32).uint32(message.nonce);
        }
        if (message.batchId !== "") {
            writer.uint32(42).string(message.batchId);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseSignedBatchVAAWithQuorum);
        message.batchVaa = new Uint8Array();
        message.txId = new Uint8Array();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.batchVaa = reader.bytes();
                    break;
                case 2:
                    message.chainId = reader.uint32();
                    break;
                case 3:
                    message.txId = reader.bytes();
                    break;
                case 4:
                    message.nonce = reader.uint32();
                    break;
                case 5:
                    message.batchId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseSignedBatchVAAWithQuorum);
        message.batchVaa = new Uint8Array();
        message.txId = new Uint8Array();
        if (object.batchVaa !== undefined && object.batchVaa !== null) {
            message.batchVaa = bytesFromBase64(object.batchVaa);
        }
        if (object.chainId !== undefined && object.chainId !== null) {
            message.chainId = Number(object.chainId);
        }
        else {
            message.chainId = 0;
        }
        if (object.txId !== undefined && object.txId !== null) {
            message.txId = bytesFromBase64(object.txId);
        }
        if (object.nonce !== undefined && object.nonce !== null) {
            message.nonce = Number(object.nonce);
        }
        else {
            message.nonce = 0;
        }
        if (object.batchId !== undefined && object.batchId !== null) {
            message.batchId = String(object.batchId);
        }
        else {
            message.batchId = "";
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.batchVaa !== undefined &&
            (obj.batchVaa = base64FromBytes(message.batchVaa !== undefined ? message.batchVaa : new Uint8Array()));
        message.chainId !== undefined && (obj.chainId = message.chainId);
        message.txId !== undefined &&
            (obj.txId = base64FromBytes(message.txId !== undefined ? message.txId : new Uint8Array()));
        message.nonce !== undefined && (obj.nonce = message.nonce);
        message.batchId !== undefined && (obj.batchId = message.batchId);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseSignedBatchVAAWithQuorum);
        if (object.batchVaa !== undefined && object.batchVaa !== null) {
            message.batchVaa = object.batchVaa;
        }
        else {
            message.batchVaa = new Uint8Array();
        }
        if (object.chainId !== undefined && object.chainId !== null) {
            message.chainId = object.chainId;
        }
        else {
            message.chainId = 0;
        }
        if (object.txId !== undefined && object.txId !== null) {
            message.txId = object.txId;
        }
        else {
            message.txId = new Uint8Array();
        }
        if (object.nonce !== undefined && object.nonce !== null) {
            message.nonce = object.nonce;
        }
        else {
            message.nonce = 0;
        }
        if (object.batchId !== undefined && object.batchId !== null) {
            message.batchId = object.batchId;
        }
        else {
            message.batchId = "";
        }
        return message;
    },
};
var baseSignedChainGovernorConfig = {};
export var SignedChainGovernorConfig = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.config.length !== 0) {
            writer.uint32(10).bytes(message.config);
        }
        if (message.signature.length !== 0) {
            writer.uint32(18).bytes(message.signature);
        }
        if (message.guardianAddr.length !== 0) {
            writer.uint32(26).bytes(message.guardianAddr);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseSignedChainGovernorConfig);
        message.config = new Uint8Array();
        message.signature = new Uint8Array();
        message.guardianAddr = new Uint8Array();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.config = reader.bytes();
                    break;
                case 2:
                    message.signature = reader.bytes();
                    break;
                case 3:
                    message.guardianAddr = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseSignedChainGovernorConfig);
        message.config = new Uint8Array();
        message.signature = new Uint8Array();
        message.guardianAddr = new Uint8Array();
        if (object.config !== undefined && object.config !== null) {
            message.config = bytesFromBase64(object.config);
        }
        if (object.signature !== undefined && object.signature !== null) {
            message.signature = bytesFromBase64(object.signature);
        }
        if (object.guardianAddr !== undefined && object.guardianAddr !== null) {
            message.guardianAddr = bytesFromBase64(object.guardianAddr);
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.config !== undefined &&
            (obj.config = base64FromBytes(message.config !== undefined ? message.config : new Uint8Array()));
        message.signature !== undefined &&
            (obj.signature = base64FromBytes(message.signature !== undefined ? message.signature : new Uint8Array()));
        message.guardianAddr !== undefined &&
            (obj.guardianAddr = base64FromBytes(message.guardianAddr !== undefined
                ? message.guardianAddr
                : new Uint8Array()));
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseSignedChainGovernorConfig);
        if (object.config !== undefined && object.config !== null) {
            message.config = object.config;
        }
        else {
            message.config = new Uint8Array();
        }
        if (object.signature !== undefined && object.signature !== null) {
            message.signature = object.signature;
        }
        else {
            message.signature = new Uint8Array();
        }
        if (object.guardianAddr !== undefined && object.guardianAddr !== null) {
            message.guardianAddr = object.guardianAddr;
        }
        else {
            message.guardianAddr = new Uint8Array();
        }
        return message;
    },
};
var baseChainGovernorConfig = {
    nodeName: "",
    counter: "0",
    timestamp: "0",
};
export var ChainGovernorConfig = {
    encode: function (message, writer) {
        var e_7, _a, e_8, _b;
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.nodeName !== "") {
            writer.uint32(10).string(message.nodeName);
        }
        if (message.counter !== "0") {
            writer.uint32(16).int64(message.counter);
        }
        if (message.timestamp !== "0") {
            writer.uint32(24).int64(message.timestamp);
        }
        try {
            for (var _c = __values(message.chains), _d = _c.next(); !_d.done; _d = _c.next()) {
                var v = _d.value;
                ChainGovernorConfig_Chain.encode(v, writer.uint32(34).fork()).ldelim();
            }
        }
        catch (e_7_1) { e_7 = { error: e_7_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_7) throw e_7.error; }
        }
        try {
            for (var _e = __values(message.tokens), _f = _e.next(); !_f.done; _f = _e.next()) {
                var v = _f.value;
                ChainGovernorConfig_Token.encode(v, writer.uint32(42).fork()).ldelim();
            }
        }
        catch (e_8_1) { e_8 = { error: e_8_1 }; }
        finally {
            try {
                if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
            }
            finally { if (e_8) throw e_8.error; }
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseChainGovernorConfig);
        message.chains = [];
        message.tokens = [];
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.nodeName = reader.string();
                    break;
                case 2:
                    message.counter = longToString(reader.int64());
                    break;
                case 3:
                    message.timestamp = longToString(reader.int64());
                    break;
                case 4:
                    message.chains.push(ChainGovernorConfig_Chain.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.tokens.push(ChainGovernorConfig_Token.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var e_9, _a, e_10, _b;
        var message = __assign({}, baseChainGovernorConfig);
        message.chains = [];
        message.tokens = [];
        if (object.nodeName !== undefined && object.nodeName !== null) {
            message.nodeName = String(object.nodeName);
        }
        else {
            message.nodeName = "";
        }
        if (object.counter !== undefined && object.counter !== null) {
            message.counter = String(object.counter);
        }
        else {
            message.counter = "0";
        }
        if (object.timestamp !== undefined && object.timestamp !== null) {
            message.timestamp = String(object.timestamp);
        }
        else {
            message.timestamp = "0";
        }
        if (object.chains !== undefined && object.chains !== null) {
            try {
                for (var _c = __values(object.chains), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var e = _d.value;
                    message.chains.push(ChainGovernorConfig_Chain.fromJSON(e));
                }
            }
            catch (e_9_1) { e_9 = { error: e_9_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                }
                finally { if (e_9) throw e_9.error; }
            }
        }
        if (object.tokens !== undefined && object.tokens !== null) {
            try {
                for (var _e = __values(object.tokens), _f = _e.next(); !_f.done; _f = _e.next()) {
                    var e = _f.value;
                    message.tokens.push(ChainGovernorConfig_Token.fromJSON(e));
                }
            }
            catch (e_10_1) { e_10 = { error: e_10_1 }; }
            finally {
                try {
                    if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                }
                finally { if (e_10) throw e_10.error; }
            }
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.nodeName !== undefined && (obj.nodeName = message.nodeName);
        message.counter !== undefined && (obj.counter = message.counter);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        if (message.chains) {
            obj.chains = message.chains.map(function (e) {
                return e ? ChainGovernorConfig_Chain.toJSON(e) : undefined;
            });
        }
        else {
            obj.chains = [];
        }
        if (message.tokens) {
            obj.tokens = message.tokens.map(function (e) {
                return e ? ChainGovernorConfig_Token.toJSON(e) : undefined;
            });
        }
        else {
            obj.tokens = [];
        }
        return obj;
    },
    fromPartial: function (object) {
        var e_11, _a, e_12, _b;
        var message = __assign({}, baseChainGovernorConfig);
        message.chains = [];
        message.tokens = [];
        if (object.nodeName !== undefined && object.nodeName !== null) {
            message.nodeName = object.nodeName;
        }
        else {
            message.nodeName = "";
        }
        if (object.counter !== undefined && object.counter !== null) {
            message.counter = object.counter;
        }
        else {
            message.counter = "0";
        }
        if (object.timestamp !== undefined && object.timestamp !== null) {
            message.timestamp = object.timestamp;
        }
        else {
            message.timestamp = "0";
        }
        if (object.chains !== undefined && object.chains !== null) {
            try {
                for (var _c = __values(object.chains), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var e = _d.value;
                    message.chains.push(ChainGovernorConfig_Chain.fromPartial(e));
                }
            }
            catch (e_11_1) { e_11 = { error: e_11_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                }
                finally { if (e_11) throw e_11.error; }
            }
        }
        if (object.tokens !== undefined && object.tokens !== null) {
            try {
                for (var _e = __values(object.tokens), _f = _e.next(); !_f.done; _f = _e.next()) {
                    var e = _f.value;
                    message.tokens.push(ChainGovernorConfig_Token.fromPartial(e));
                }
            }
            catch (e_12_1) { e_12 = { error: e_12_1 }; }
            finally {
                try {
                    if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                }
                finally { if (e_12) throw e_12.error; }
            }
        }
        return message;
    },
};
var baseChainGovernorConfig_Chain = {
    chainId: 0,
    notionalLimit: "0",
    bigTransactionSize: "0",
};
export var ChainGovernorConfig_Chain = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.chainId !== 0) {
            writer.uint32(8).uint32(message.chainId);
        }
        if (message.notionalLimit !== "0") {
            writer.uint32(16).uint64(message.notionalLimit);
        }
        if (message.bigTransactionSize !== "0") {
            writer.uint32(24).uint64(message.bigTransactionSize);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseChainGovernorConfig_Chain);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.chainId = reader.uint32();
                    break;
                case 2:
                    message.notionalLimit = longToString(reader.uint64());
                    break;
                case 3:
                    message.bigTransactionSize = longToString(reader.uint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseChainGovernorConfig_Chain);
        if (object.chainId !== undefined && object.chainId !== null) {
            message.chainId = Number(object.chainId);
        }
        else {
            message.chainId = 0;
        }
        if (object.notionalLimit !== undefined && object.notionalLimit !== null) {
            message.notionalLimit = String(object.notionalLimit);
        }
        else {
            message.notionalLimit = "0";
        }
        if (object.bigTransactionSize !== undefined &&
            object.bigTransactionSize !== null) {
            message.bigTransactionSize = String(object.bigTransactionSize);
        }
        else {
            message.bigTransactionSize = "0";
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.chainId !== undefined && (obj.chainId = message.chainId);
        message.notionalLimit !== undefined &&
            (obj.notionalLimit = message.notionalLimit);
        message.bigTransactionSize !== undefined &&
            (obj.bigTransactionSize = message.bigTransactionSize);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseChainGovernorConfig_Chain);
        if (object.chainId !== undefined && object.chainId !== null) {
            message.chainId = object.chainId;
        }
        else {
            message.chainId = 0;
        }
        if (object.notionalLimit !== undefined && object.notionalLimit !== null) {
            message.notionalLimit = object.notionalLimit;
        }
        else {
            message.notionalLimit = "0";
        }
        if (object.bigTransactionSize !== undefined &&
            object.bigTransactionSize !== null) {
            message.bigTransactionSize = object.bigTransactionSize;
        }
        else {
            message.bigTransactionSize = "0";
        }
        return message;
    },
};
var baseChainGovernorConfig_Token = {
    originChainId: 0,
    originAddress: "",
    price: 0,
};
export var ChainGovernorConfig_Token = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.originChainId !== 0) {
            writer.uint32(8).uint32(message.originChainId);
        }
        if (message.originAddress !== "") {
            writer.uint32(18).string(message.originAddress);
        }
        if (message.price !== 0) {
            writer.uint32(29).float(message.price);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseChainGovernorConfig_Token);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.originChainId = reader.uint32();
                    break;
                case 2:
                    message.originAddress = reader.string();
                    break;
                case 3:
                    message.price = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseChainGovernorConfig_Token);
        if (object.originChainId !== undefined && object.originChainId !== null) {
            message.originChainId = Number(object.originChainId);
        }
        else {
            message.originChainId = 0;
        }
        if (object.originAddress !== undefined && object.originAddress !== null) {
            message.originAddress = String(object.originAddress);
        }
        else {
            message.originAddress = "";
        }
        if (object.price !== undefined && object.price !== null) {
            message.price = Number(object.price);
        }
        else {
            message.price = 0;
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.originChainId !== undefined &&
            (obj.originChainId = message.originChainId);
        message.originAddress !== undefined &&
            (obj.originAddress = message.originAddress);
        message.price !== undefined && (obj.price = message.price);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseChainGovernorConfig_Token);
        if (object.originChainId !== undefined && object.originChainId !== null) {
            message.originChainId = object.originChainId;
        }
        else {
            message.originChainId = 0;
        }
        if (object.originAddress !== undefined && object.originAddress !== null) {
            message.originAddress = object.originAddress;
        }
        else {
            message.originAddress = "";
        }
        if (object.price !== undefined && object.price !== null) {
            message.price = object.price;
        }
        else {
            message.price = 0;
        }
        return message;
    },
};
var baseSignedChainGovernorStatus = {};
export var SignedChainGovernorStatus = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.status.length !== 0) {
            writer.uint32(10).bytes(message.status);
        }
        if (message.signature.length !== 0) {
            writer.uint32(18).bytes(message.signature);
        }
        if (message.guardianAddr.length !== 0) {
            writer.uint32(26).bytes(message.guardianAddr);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseSignedChainGovernorStatus);
        message.status = new Uint8Array();
        message.signature = new Uint8Array();
        message.guardianAddr = new Uint8Array();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.status = reader.bytes();
                    break;
                case 2:
                    message.signature = reader.bytes();
                    break;
                case 3:
                    message.guardianAddr = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseSignedChainGovernorStatus);
        message.status = new Uint8Array();
        message.signature = new Uint8Array();
        message.guardianAddr = new Uint8Array();
        if (object.status !== undefined && object.status !== null) {
            message.status = bytesFromBase64(object.status);
        }
        if (object.signature !== undefined && object.signature !== null) {
            message.signature = bytesFromBase64(object.signature);
        }
        if (object.guardianAddr !== undefined && object.guardianAddr !== null) {
            message.guardianAddr = bytesFromBase64(object.guardianAddr);
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.status !== undefined &&
            (obj.status = base64FromBytes(message.status !== undefined ? message.status : new Uint8Array()));
        message.signature !== undefined &&
            (obj.signature = base64FromBytes(message.signature !== undefined ? message.signature : new Uint8Array()));
        message.guardianAddr !== undefined &&
            (obj.guardianAddr = base64FromBytes(message.guardianAddr !== undefined
                ? message.guardianAddr
                : new Uint8Array()));
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseSignedChainGovernorStatus);
        if (object.status !== undefined && object.status !== null) {
            message.status = object.status;
        }
        else {
            message.status = new Uint8Array();
        }
        if (object.signature !== undefined && object.signature !== null) {
            message.signature = object.signature;
        }
        else {
            message.signature = new Uint8Array();
        }
        if (object.guardianAddr !== undefined && object.guardianAddr !== null) {
            message.guardianAddr = object.guardianAddr;
        }
        else {
            message.guardianAddr = new Uint8Array();
        }
        return message;
    },
};
var baseChainGovernorStatus = {
    nodeName: "",
    counter: "0",
    timestamp: "0",
};
export var ChainGovernorStatus = {
    encode: function (message, writer) {
        var e_13, _a;
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.nodeName !== "") {
            writer.uint32(10).string(message.nodeName);
        }
        if (message.counter !== "0") {
            writer.uint32(16).int64(message.counter);
        }
        if (message.timestamp !== "0") {
            writer.uint32(24).int64(message.timestamp);
        }
        try {
            for (var _b = __values(message.chains), _c = _b.next(); !_c.done; _c = _b.next()) {
                var v = _c.value;
                ChainGovernorStatus_Chain.encode(v, writer.uint32(34).fork()).ldelim();
            }
        }
        catch (e_13_1) { e_13 = { error: e_13_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_13) throw e_13.error; }
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseChainGovernorStatus);
        message.chains = [];
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.nodeName = reader.string();
                    break;
                case 2:
                    message.counter = longToString(reader.int64());
                    break;
                case 3:
                    message.timestamp = longToString(reader.int64());
                    break;
                case 4:
                    message.chains.push(ChainGovernorStatus_Chain.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var e_14, _a;
        var message = __assign({}, baseChainGovernorStatus);
        message.chains = [];
        if (object.nodeName !== undefined && object.nodeName !== null) {
            message.nodeName = String(object.nodeName);
        }
        else {
            message.nodeName = "";
        }
        if (object.counter !== undefined && object.counter !== null) {
            message.counter = String(object.counter);
        }
        else {
            message.counter = "0";
        }
        if (object.timestamp !== undefined && object.timestamp !== null) {
            message.timestamp = String(object.timestamp);
        }
        else {
            message.timestamp = "0";
        }
        if (object.chains !== undefined && object.chains !== null) {
            try {
                for (var _b = __values(object.chains), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var e = _c.value;
                    message.chains.push(ChainGovernorStatus_Chain.fromJSON(e));
                }
            }
            catch (e_14_1) { e_14 = { error: e_14_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_14) throw e_14.error; }
            }
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.nodeName !== undefined && (obj.nodeName = message.nodeName);
        message.counter !== undefined && (obj.counter = message.counter);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp);
        if (message.chains) {
            obj.chains = message.chains.map(function (e) {
                return e ? ChainGovernorStatus_Chain.toJSON(e) : undefined;
            });
        }
        else {
            obj.chains = [];
        }
        return obj;
    },
    fromPartial: function (object) {
        var e_15, _a;
        var message = __assign({}, baseChainGovernorStatus);
        message.chains = [];
        if (object.nodeName !== undefined && object.nodeName !== null) {
            message.nodeName = object.nodeName;
        }
        else {
            message.nodeName = "";
        }
        if (object.counter !== undefined && object.counter !== null) {
            message.counter = object.counter;
        }
        else {
            message.counter = "0";
        }
        if (object.timestamp !== undefined && object.timestamp !== null) {
            message.timestamp = object.timestamp;
        }
        else {
            message.timestamp = "0";
        }
        if (object.chains !== undefined && object.chains !== null) {
            try {
                for (var _b = __values(object.chains), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var e = _c.value;
                    message.chains.push(ChainGovernorStatus_Chain.fromPartial(e));
                }
            }
            catch (e_15_1) { e_15 = { error: e_15_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_15) throw e_15.error; }
            }
        }
        return message;
    },
};
var baseChainGovernorStatus_EnqueuedVAA = {
    sequence: "0",
    releaseTime: 0,
    notionalValue: "0",
    txHash: "",
};
export var ChainGovernorStatus_EnqueuedVAA = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.sequence !== "0") {
            writer.uint32(8).uint64(message.sequence);
        }
        if (message.releaseTime !== 0) {
            writer.uint32(16).uint32(message.releaseTime);
        }
        if (message.notionalValue !== "0") {
            writer.uint32(24).uint64(message.notionalValue);
        }
        if (message.txHash !== "") {
            writer.uint32(34).string(message.txHash);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseChainGovernorStatus_EnqueuedVAA);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sequence = longToString(reader.uint64());
                    break;
                case 2:
                    message.releaseTime = reader.uint32();
                    break;
                case 3:
                    message.notionalValue = longToString(reader.uint64());
                    break;
                case 4:
                    message.txHash = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseChainGovernorStatus_EnqueuedVAA);
        if (object.sequence !== undefined && object.sequence !== null) {
            message.sequence = String(object.sequence);
        }
        else {
            message.sequence = "0";
        }
        if (object.releaseTime !== undefined && object.releaseTime !== null) {
            message.releaseTime = Number(object.releaseTime);
        }
        else {
            message.releaseTime = 0;
        }
        if (object.notionalValue !== undefined && object.notionalValue !== null) {
            message.notionalValue = String(object.notionalValue);
        }
        else {
            message.notionalValue = "0";
        }
        if (object.txHash !== undefined && object.txHash !== null) {
            message.txHash = String(object.txHash);
        }
        else {
            message.txHash = "";
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.sequence !== undefined && (obj.sequence = message.sequence);
        message.releaseTime !== undefined &&
            (obj.releaseTime = message.releaseTime);
        message.notionalValue !== undefined &&
            (obj.notionalValue = message.notionalValue);
        message.txHash !== undefined && (obj.txHash = message.txHash);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseChainGovernorStatus_EnqueuedVAA);
        if (object.sequence !== undefined && object.sequence !== null) {
            message.sequence = object.sequence;
        }
        else {
            message.sequence = "0";
        }
        if (object.releaseTime !== undefined && object.releaseTime !== null) {
            message.releaseTime = object.releaseTime;
        }
        else {
            message.releaseTime = 0;
        }
        if (object.notionalValue !== undefined && object.notionalValue !== null) {
            message.notionalValue = object.notionalValue;
        }
        else {
            message.notionalValue = "0";
        }
        if (object.txHash !== undefined && object.txHash !== null) {
            message.txHash = object.txHash;
        }
        else {
            message.txHash = "";
        }
        return message;
    },
};
var baseChainGovernorStatus_Emitter = {
    emitterAddress: "",
    totalEnqueuedVaas: "0",
};
export var ChainGovernorStatus_Emitter = {
    encode: function (message, writer) {
        var e_16, _a;
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.emitterAddress !== "") {
            writer.uint32(10).string(message.emitterAddress);
        }
        if (message.totalEnqueuedVaas !== "0") {
            writer.uint32(16).uint64(message.totalEnqueuedVaas);
        }
        try {
            for (var _b = __values(message.enqueuedVaas), _c = _b.next(); !_c.done; _c = _b.next()) {
                var v = _c.value;
                ChainGovernorStatus_EnqueuedVAA.encode(v, writer.uint32(26).fork()).ldelim();
            }
        }
        catch (e_16_1) { e_16 = { error: e_16_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_16) throw e_16.error; }
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseChainGovernorStatus_Emitter);
        message.enqueuedVaas = [];
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.emitterAddress = reader.string();
                    break;
                case 2:
                    message.totalEnqueuedVaas = longToString(reader.uint64());
                    break;
                case 3:
                    message.enqueuedVaas.push(ChainGovernorStatus_EnqueuedVAA.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var e_17, _a;
        var message = __assign({}, baseChainGovernorStatus_Emitter);
        message.enqueuedVaas = [];
        if (object.emitterAddress !== undefined && object.emitterAddress !== null) {
            message.emitterAddress = String(object.emitterAddress);
        }
        else {
            message.emitterAddress = "";
        }
        if (object.totalEnqueuedVaas !== undefined &&
            object.totalEnqueuedVaas !== null) {
            message.totalEnqueuedVaas = String(object.totalEnqueuedVaas);
        }
        else {
            message.totalEnqueuedVaas = "0";
        }
        if (object.enqueuedVaas !== undefined && object.enqueuedVaas !== null) {
            try {
                for (var _b = __values(object.enqueuedVaas), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var e = _c.value;
                    message.enqueuedVaas.push(ChainGovernorStatus_EnqueuedVAA.fromJSON(e));
                }
            }
            catch (e_17_1) { e_17 = { error: e_17_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_17) throw e_17.error; }
            }
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.emitterAddress !== undefined &&
            (obj.emitterAddress = message.emitterAddress);
        message.totalEnqueuedVaas !== undefined &&
            (obj.totalEnqueuedVaas = message.totalEnqueuedVaas);
        if (message.enqueuedVaas) {
            obj.enqueuedVaas = message.enqueuedVaas.map(function (e) {
                return e ? ChainGovernorStatus_EnqueuedVAA.toJSON(e) : undefined;
            });
        }
        else {
            obj.enqueuedVaas = [];
        }
        return obj;
    },
    fromPartial: function (object) {
        var e_18, _a;
        var message = __assign({}, baseChainGovernorStatus_Emitter);
        message.enqueuedVaas = [];
        if (object.emitterAddress !== undefined && object.emitterAddress !== null) {
            message.emitterAddress = object.emitterAddress;
        }
        else {
            message.emitterAddress = "";
        }
        if (object.totalEnqueuedVaas !== undefined &&
            object.totalEnqueuedVaas !== null) {
            message.totalEnqueuedVaas = object.totalEnqueuedVaas;
        }
        else {
            message.totalEnqueuedVaas = "0";
        }
        if (object.enqueuedVaas !== undefined && object.enqueuedVaas !== null) {
            try {
                for (var _b = __values(object.enqueuedVaas), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var e = _c.value;
                    message.enqueuedVaas.push(ChainGovernorStatus_EnqueuedVAA.fromPartial(e));
                }
            }
            catch (e_18_1) { e_18 = { error: e_18_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_18) throw e_18.error; }
            }
        }
        return message;
    },
};
var baseChainGovernorStatus_Chain = {
    chainId: 0,
    remainingAvailableNotional: "0",
};
export var ChainGovernorStatus_Chain = {
    encode: function (message, writer) {
        var e_19, _a;
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.chainId !== 0) {
            writer.uint32(8).uint32(message.chainId);
        }
        if (message.remainingAvailableNotional !== "0") {
            writer.uint32(16).uint64(message.remainingAvailableNotional);
        }
        try {
            for (var _b = __values(message.emitters), _c = _b.next(); !_c.done; _c = _b.next()) {
                var v = _c.value;
                ChainGovernorStatus_Emitter.encode(v, writer.uint32(26).fork()).ldelim();
            }
        }
        catch (e_19_1) { e_19 = { error: e_19_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_19) throw e_19.error; }
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseChainGovernorStatus_Chain);
        message.emitters = [];
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.chainId = reader.uint32();
                    break;
                case 2:
                    message.remainingAvailableNotional = longToString(reader.uint64());
                    break;
                case 3:
                    message.emitters.push(ChainGovernorStatus_Emitter.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var e_20, _a;
        var message = __assign({}, baseChainGovernorStatus_Chain);
        message.emitters = [];
        if (object.chainId !== undefined && object.chainId !== null) {
            message.chainId = Number(object.chainId);
        }
        else {
            message.chainId = 0;
        }
        if (object.remainingAvailableNotional !== undefined &&
            object.remainingAvailableNotional !== null) {
            message.remainingAvailableNotional = String(object.remainingAvailableNotional);
        }
        else {
            message.remainingAvailableNotional = "0";
        }
        if (object.emitters !== undefined && object.emitters !== null) {
            try {
                for (var _b = __values(object.emitters), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var e = _c.value;
                    message.emitters.push(ChainGovernorStatus_Emitter.fromJSON(e));
                }
            }
            catch (e_20_1) { e_20 = { error: e_20_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_20) throw e_20.error; }
            }
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.chainId !== undefined && (obj.chainId = message.chainId);
        message.remainingAvailableNotional !== undefined &&
            (obj.remainingAvailableNotional = message.remainingAvailableNotional);
        if (message.emitters) {
            obj.emitters = message.emitters.map(function (e) {
                return e ? ChainGovernorStatus_Emitter.toJSON(e) : undefined;
            });
        }
        else {
            obj.emitters = [];
        }
        return obj;
    },
    fromPartial: function (object) {
        var e_21, _a;
        var message = __assign({}, baseChainGovernorStatus_Chain);
        message.emitters = [];
        if (object.chainId !== undefined && object.chainId !== null) {
            message.chainId = object.chainId;
        }
        else {
            message.chainId = 0;
        }
        if (object.remainingAvailableNotional !== undefined &&
            object.remainingAvailableNotional !== null) {
            message.remainingAvailableNotional = object.remainingAvailableNotional;
        }
        else {
            message.remainingAvailableNotional = "0";
        }
        if (object.emitters !== undefined && object.emitters !== null) {
            try {
                for (var _b = __values(object.emitters), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var e = _c.value;
                    message.emitters.push(ChainGovernorStatus_Emitter.fromPartial(e));
                }
            }
            catch (e_21_1) { e_21 = { error: e_21_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_21) throw e_21.error; }
            }
        }
        return message;
    },
};
var baseSignedQueryRequest = {};
export var SignedQueryRequest = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.queryRequest.length !== 0) {
            writer.uint32(10).bytes(message.queryRequest);
        }
        if (message.signature.length !== 0) {
            writer.uint32(18).bytes(message.signature);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseSignedQueryRequest);
        message.queryRequest = new Uint8Array();
        message.signature = new Uint8Array();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.queryRequest = reader.bytes();
                    break;
                case 2:
                    message.signature = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseSignedQueryRequest);
        message.queryRequest = new Uint8Array();
        message.signature = new Uint8Array();
        if (object.queryRequest !== undefined && object.queryRequest !== null) {
            message.queryRequest = bytesFromBase64(object.queryRequest);
        }
        if (object.signature !== undefined && object.signature !== null) {
            message.signature = bytesFromBase64(object.signature);
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.queryRequest !== undefined &&
            (obj.queryRequest = base64FromBytes(message.queryRequest !== undefined
                ? message.queryRequest
                : new Uint8Array()));
        message.signature !== undefined &&
            (obj.signature = base64FromBytes(message.signature !== undefined ? message.signature : new Uint8Array()));
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseSignedQueryRequest);
        if (object.queryRequest !== undefined && object.queryRequest !== null) {
            message.queryRequest = object.queryRequest;
        }
        else {
            message.queryRequest = new Uint8Array();
        }
        if (object.signature !== undefined && object.signature !== null) {
            message.signature = object.signature;
        }
        else {
            message.signature = new Uint8Array();
        }
        return message;
    },
};
var baseSignedQueryResponse = {};
export var SignedQueryResponse = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.queryResponse.length !== 0) {
            writer.uint32(10).bytes(message.queryResponse);
        }
        if (message.signature.length !== 0) {
            writer.uint32(18).bytes(message.signature);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseSignedQueryResponse);
        message.queryResponse = new Uint8Array();
        message.signature = new Uint8Array();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.queryResponse = reader.bytes();
                    break;
                case 2:
                    message.signature = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseSignedQueryResponse);
        message.queryResponse = new Uint8Array();
        message.signature = new Uint8Array();
        if (object.queryResponse !== undefined && object.queryResponse !== null) {
            message.queryResponse = bytesFromBase64(object.queryResponse);
        }
        if (object.signature !== undefined && object.signature !== null) {
            message.signature = bytesFromBase64(object.signature);
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.queryResponse !== undefined &&
            (obj.queryResponse = base64FromBytes(message.queryResponse !== undefined
                ? message.queryResponse
                : new Uint8Array()));
        message.signature !== undefined &&
            (obj.signature = base64FromBytes(message.signature !== undefined ? message.signature : new Uint8Array()));
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseSignedQueryResponse);
        if (object.queryResponse !== undefined && object.queryResponse !== null) {
            message.queryResponse = object.queryResponse;
        }
        else {
            message.queryResponse = new Uint8Array();
        }
        if (object.signature !== undefined && object.signature !== null) {
            message.signature = object.signature;
        }
        else {
            message.signature = new Uint8Array();
        }
        return message;
    },
};
var globalThis = (function () {
    if (typeof globalThis !== "undefined")
        return globalThis;
    if (typeof self !== "undefined")
        return self;
    if (typeof window !== "undefined")
        return window;
    if (typeof global !== "undefined")
        return global;
    throw "Unable to locate global object";
})();
var atob = globalThis.atob ||
    (function (b64) { return globalThis.Buffer.from(b64, "base64").toString("binary"); });
function bytesFromBase64(b64) {
    var bin = atob(b64);
    var arr = new Uint8Array(bin.length);
    for (var i = 0; i < bin.length; ++i) {
        arr[i] = bin.charCodeAt(i);
    }
    return arr;
}
var btoa = globalThis.btoa ||
    (function (bin) { return globalThis.Buffer.from(bin, "binary").toString("base64"); });
function base64FromBytes(arr) {
    var e_22, _a;
    var bin = [];
    try {
        for (var arr_1 = __values(arr), arr_1_1 = arr_1.next(); !arr_1_1.done; arr_1_1 = arr_1.next()) {
            var byte = arr_1_1.value;
            bin.push(String.fromCharCode(byte));
        }
    }
    catch (e_22_1) { e_22 = { error: e_22_1 }; }
    finally {
        try {
            if (arr_1_1 && !arr_1_1.done && (_a = arr_1.return)) _a.call(arr_1);
        }
        finally { if (e_22) throw e_22.error; }
    }
    return btoa(bin.join(""));
}
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
