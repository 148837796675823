var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Keypair, Transaction, } from "@solana/web3.js";
import { signSendAndConfirmTransaction, modifySignTransaction, } from "./utils";
import { createPostVaaInstruction, createVerifySignaturesInstructions, } from "./wormhole";
import { isBytes, parseVaa } from "../vaa/wormhole";
/**
 * @deprecated Please use {@link postVaa} instead, which allows
 * retries and commitment to be configured in {@link ConfirmOptions}.
 */
export function postVaaWithRetry(connection, signTransaction, wormholeProgramId, payer, vaa, maxRetries, commitment) {
    return __awaiter(this, void 0, void 0, function* () {
        const { unsignedTransactions, signers } = yield createPostSignedVaaTransactions(connection, wormholeProgramId, payer, vaa, commitment);
        const postVaaTransaction = unsignedTransactions.pop();
        const options = {
            commitment,
            maxRetries,
        };
        const responses = [];
        for (const transaction of unsignedTransactions) {
            const response = yield signSendAndConfirmTransaction(connection, payer, modifySignTransaction(signTransaction, ...signers), transaction, options);
            responses.push(response);
        }
        // While the signature_set is used to create the final instruction, it doesn't need to sign it.
        responses.push(yield signSendAndConfirmTransaction(connection, payer, signTransaction, postVaaTransaction, options));
        return responses;
    });
}
export function postVaa(connection, signTransaction, wormholeProgramId, payer, vaa, options, asyncVerifySignatures = true) {
    return __awaiter(this, void 0, void 0, function* () {
        const { unsignedTransactions, signers } = yield createPostSignedVaaTransactions(connection, wormholeProgramId, payer, vaa, options === null || options === void 0 ? void 0 : options.commitment);
        const postVaaTransaction = unsignedTransactions.pop();
        const verifySignatures = (transaction) => __awaiter(this, void 0, void 0, function* () {
            return signSendAndConfirmTransaction(connection, payer, modifySignTransaction(signTransaction, ...signers), transaction, options);
        });
        const output = [];
        if (asyncVerifySignatures) {
            const verified = yield Promise.all(unsignedTransactions.map((transaction) => __awaiter(this, void 0, void 0, function* () { return verifySignatures(transaction); })));
            output.push(...verified);
        }
        else {
            for (const transaction of unsignedTransactions) {
                output.push(yield verifySignatures(transaction));
            }
        }
        output.push(yield signSendAndConfirmTransaction(connection, payer, signTransaction, postVaaTransaction, options));
        return output;
    });
}
/** Send transactions for `verify_signatures` and `post_vaa` instructions.
 *
 * Using a signed VAA, execute transactions generated by {@link verifySignatures} and
 * {@link postVaa}. At most 4 transactions are sent (up to 3 from signature verification
 * and 1 to post VAA data to an account).
 *
 * @param {Connection} connection - Solana web3 connection
 * @param {PublicKeyInitData} wormholeProgramId - wormhole program address
 * @param {web3.Keypair} payer - transaction signer address
 * @param {Buffer} signedVaa - bytes of signed VAA
 * @param {Commitment} [options] - Solana commitment
 *
 */
export function createPostSignedVaaTransactions(connection, wormholeProgramId, payer, vaa, commitment) {
    return __awaiter(this, void 0, void 0, function* () {
        const parsed = isBytes(vaa) ? parseVaa(vaa) : vaa;
        const signatureSet = Keypair.generate();
        const verifySignaturesInstructions = yield createVerifySignaturesInstructions(connection, wormholeProgramId, payer, parsed, signatureSet.publicKey, commitment);
        const unsignedTransactions = [];
        for (let i = 0; i < verifySignaturesInstructions.length; i += 2) {
            unsignedTransactions.push(new Transaction().add(...verifySignaturesInstructions.slice(i, i + 2)));
        }
        unsignedTransactions.push(new Transaction().add(createPostVaaInstruction(wormholeProgramId, payer, parsed, signatureSet.publicKey)));
        return {
            unsignedTransactions,
            signers: [signatureSet],
        };
    });
}
