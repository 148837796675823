var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Keypair, PublicKey, } from "@solana/web3.js";
/**
 *
 * @param payers
 * @returns
 */
export function signTransactionFactory(...payers) {
    return modifySignTransaction((transaction) => Promise.resolve(transaction), ...payers);
}
export function modifySignTransaction(signTransaction, ...payers) {
    return (transaction) => {
        for (const payer of payers) {
            transaction.partialSign(payer);
        }
        return signTransaction(transaction);
    };
}
/**
 * Wrapper for {@link Keypair} resembling Solana web3 browser wallet
 */
export class NodeWallet {
    constructor(payer) {
        this.payer = payer;
        this.signTransaction = signTransactionFactory(this.payer);
    }
    static fromSecretKey(secretKey, options) {
        return new NodeWallet(Keypair.fromSecretKey(secretKey, options));
    }
    publicKey() {
        return this.payer.publicKey;
    }
    pubkey() {
        return this.publicKey();
    }
    key() {
        return this.publicKey();
    }
    toString() {
        return this.publicKey().toString();
    }
    keypair() {
        return this.payer;
    }
    signer() {
        return this.keypair();
    }
}
/**
 * The transactions provided to this function should be ready to send.
 * This function will do the following:
 * 1. Add the {@param payer} as the feePayer and latest blockhash to the {@link Transaction}.
 * 2. Sign using {@param signTransaction}.
 * 3. Send raw transaction.
 * 4. Confirm transaction.
 */
export function signSendAndConfirmTransaction(connection, payer, signTransaction, unsignedTransaction, options) {
    return __awaiter(this, void 0, void 0, function* () {
        const commitment = options === null || options === void 0 ? void 0 : options.commitment;
        const { blockhash, lastValidBlockHeight } = yield connection.getLatestBlockhash(commitment);
        unsignedTransaction.recentBlockhash = blockhash;
        unsignedTransaction.feePayer = new PublicKey(payer);
        // Sign transaction, broadcast, and confirm
        const signed = yield signTransaction(unsignedTransaction);
        const signature = yield connection.sendRawTransaction(signed.serialize(), options);
        const response = yield connection.confirmTransaction({
            blockhash,
            lastValidBlockHeight,
            signature,
        }, commitment);
        return { signature, response };
    });
}
/**
 * @deprecated Please use {@link signSendAndConfirmTransaction} instead, which allows
 * retries to be configured in {@link ConfirmOptions}.
 *
 * The transactions provided to this function should be ready to send.
 * This function will do the following:
 * 1. Add the {@param payer} as the feePayer and latest blockhash to the {@link Transaction}.
 * 2. Sign using {@param signTransaction}.
 * 3. Send raw transaction.
 * 4. Confirm transaction.
 */
export function sendAndConfirmTransactionsWithRetry(connection, signTransaction, payer, unsignedTransactions, maxRetries = 0, options) {
    return __awaiter(this, void 0, void 0, function* () {
        if (unsignedTransactions.length == 0) {
            return Promise.reject("No transactions provided to send.");
        }
        const commitment = options === null || options === void 0 ? void 0 : options.commitment;
        let currentRetries = 0;
        const output = [];
        for (const transaction of unsignedTransactions) {
            while (currentRetries <= maxRetries) {
                try {
                    const latest = yield connection.getLatestBlockhash(commitment);
                    transaction.recentBlockhash = latest.blockhash;
                    transaction.feePayer = new PublicKey(payer);
                    const signed = yield signTransaction(transaction).catch((e) => null);
                    if (signed === null) {
                        return Promise.reject("Failed to sign transaction.");
                    }
                    const signature = yield connection.sendRawTransaction(signed.serialize(), options);
                    const response = yield connection.confirmTransaction(Object.assign({ signature }, latest), commitment);
                    output.push({ signature, response });
                    break;
                }
                catch (e) {
                    console.error(e);
                    ++currentRetries;
                }
            }
            if (currentRetries > maxRetries) {
                return Promise.reject("Reached the maximum number of retries.");
            }
        }
        return Promise.resolve(output);
    });
}
