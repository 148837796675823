/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Contract, utils } from "ethers";
const _abi = [
    {
        type: "function",
        name: "assetConversionBuffer",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
        ],
        outputs: [
            {
                name: "buffer",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "bufferDenominator",
                type: "uint16",
                internalType: "uint16",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "chainId",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "uint16",
                internalType: "uint16",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "confirmOwnershipTransferRequest",
        inputs: [],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "coreRelayer",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "address",
                internalType: "address",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "deliverGasOverhead",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
        ],
        outputs: [
            {
                name: "",
                type: "uint256",
                internalType: "Gas",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "gasPrice",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
        ],
        outputs: [
            {
                name: "",
                type: "uint256",
                internalType: "GasPrice",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "isInitialized",
        inputs: [
            {
                name: "impl",
                type: "address",
                internalType: "address",
            },
        ],
        outputs: [
            {
                name: "",
                type: "bool",
                internalType: "bool",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "maximumBudget",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
        ],
        outputs: [
            {
                name: "",
                type: "uint256",
                internalType: "TargetNative",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "nativeCurrencyPrice",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
        ],
        outputs: [
            {
                name: "",
                type: "uint256",
                internalType: "WeiPrice",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "owner",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "address",
                internalType: "address",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "pendingOwner",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "address",
                internalType: "address",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "pricingWallet",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "address",
                internalType: "address",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "rewardAddress",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "address",
                internalType: "address payable",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "submitOwnershipTransferRequest",
        inputs: [
            {
                name: "thisRelayerChainId",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "newOwner",
                type: "address",
                internalType: "address",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "targetChainAddress",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
        ],
        outputs: [
            {
                name: "",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "updateAssetConversionBuffer",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "buffer",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "bufferDenominator",
                type: "uint16",
                internalType: "uint16",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "updateAssetConversionBuffers",
        inputs: [
            {
                name: "updates",
                type: "tuple[]",
                internalType: "struct DeliveryProviderStructs.AssetConversionBufferUpdate[]",
                components: [
                    {
                        name: "chainId",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "buffer",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "bufferDenominator",
                        type: "uint16",
                        internalType: "uint16",
                    },
                ],
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "updateConfig",
        inputs: [
            {
                name: "updates",
                type: "tuple[]",
                internalType: "struct DeliveryProviderStructs.Update[]",
                components: [
                    {
                        name: "updateAssetConversionBuffer",
                        type: "bool",
                        internalType: "bool",
                    },
                    {
                        name: "updateDeliverGasOverhead",
                        type: "bool",
                        internalType: "bool",
                    },
                    {
                        name: "updatePrice",
                        type: "bool",
                        internalType: "bool",
                    },
                    {
                        name: "updateTargetChainAddress",
                        type: "bool",
                        internalType: "bool",
                    },
                    {
                        name: "updateMaximumBudget",
                        type: "bool",
                        internalType: "bool",
                    },
                    {
                        name: "updateSupportedChain",
                        type: "bool",
                        internalType: "bool",
                    },
                    {
                        name: "isSupported",
                        type: "bool",
                        internalType: "bool",
                    },
                    {
                        name: "chainId",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "buffer",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "bufferDenominator",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "newGasOverhead",
                        type: "uint256",
                        internalType: "Gas",
                    },
                    {
                        name: "gasPrice",
                        type: "uint256",
                        internalType: "GasPrice",
                    },
                    {
                        name: "nativeCurrencyPrice",
                        type: "uint256",
                        internalType: "WeiPrice",
                    },
                    {
                        name: "targetChainAddress",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "maximumTotalBudget",
                        type: "uint256",
                        internalType: "Wei",
                    },
                ],
            },
            {
                name: "coreConfig",
                type: "tuple",
                internalType: "struct DeliveryProviderStructs.CoreConfig",
                components: [
                    {
                        name: "updateWormholeRelayer",
                        type: "bool",
                        internalType: "bool",
                    },
                    {
                        name: "updateRewardAddress",
                        type: "bool",
                        internalType: "bool",
                    },
                    {
                        name: "updateSupportedKeyTypes",
                        type: "bool",
                        internalType: "bool",
                    },
                    {
                        name: "coreRelayer",
                        type: "address",
                        internalType: "address payable",
                    },
                    {
                        name: "rewardAddress",
                        type: "address",
                        internalType: "address payable",
                    },
                    {
                        name: "supportedKeyTypesBitmap",
                        type: "uint256",
                        internalType: "uint256",
                    },
                ],
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "updateDeliverGasOverhead",
        inputs: [
            {
                name: "chainId",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "newGasOverhead",
                type: "uint256",
                internalType: "Gas",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "updateDeliverGasOverheads",
        inputs: [
            {
                name: "overheadUpdates",
                type: "tuple[]",
                internalType: "struct DeliveryProviderStructs.DeliverGasOverheadUpdate[]",
                components: [
                    {
                        name: "chainId",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "newGasOverhead",
                        type: "uint256",
                        internalType: "Gas",
                    },
                ],
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "updateMaximumBudget",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "maximumTotalBudget",
                type: "uint256",
                internalType: "Wei",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "updateMaximumBudgets",
        inputs: [
            {
                name: "updates",
                type: "tuple[]",
                internalType: "struct DeliveryProviderStructs.MaximumBudgetUpdate[]",
                components: [
                    {
                        name: "chainId",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "maximumTotalBudget",
                        type: "uint256",
                        internalType: "Wei",
                    },
                ],
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "updatePrice",
        inputs: [
            {
                name: "updateChainId",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "updateGasPrice",
                type: "uint256",
                internalType: "GasPrice",
            },
            {
                name: "updateNativeCurrencyPrice",
                type: "uint256",
                internalType: "WeiPrice",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "updatePrices",
        inputs: [
            {
                name: "updates",
                type: "tuple[]",
                internalType: "struct DeliveryProviderStructs.UpdatePrice[]",
                components: [
                    {
                        name: "chainId",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "gasPrice",
                        type: "uint256",
                        internalType: "GasPrice",
                    },
                    {
                        name: "nativeCurrencyPrice",
                        type: "uint256",
                        internalType: "WeiPrice",
                    },
                ],
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "updatePricingWallet",
        inputs: [
            {
                name: "newPricingWallet",
                type: "address",
                internalType: "address",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "updateRewardAddress",
        inputs: [
            {
                name: "newAddress",
                type: "address",
                internalType: "address payable",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "updateSupportedChain",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "isSupported",
                type: "bool",
                internalType: "bool",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "updateSupportedChains",
        inputs: [
            {
                name: "updates",
                type: "tuple[]",
                internalType: "struct DeliveryProviderStructs.SupportedChainUpdate[]",
                components: [
                    {
                        name: "chainId",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "isSupported",
                        type: "bool",
                        internalType: "bool",
                    },
                ],
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "updateSupportedMessageKeyTypes",
        inputs: [
            {
                name: "keyType",
                type: "uint8",
                internalType: "uint8",
            },
            {
                name: "supported",
                type: "bool",
                internalType: "bool",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "updateTargetChainAddress",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "newAddress",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "updateTargetChainAddresses",
        inputs: [
            {
                name: "updates",
                type: "tuple[]",
                internalType: "struct DeliveryProviderStructs.TargetChainUpdate[]",
                components: [
                    {
                        name: "chainId",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "targetChainAddress",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                ],
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "updateWormholeRelayer",
        inputs: [
            {
                name: "newAddress",
                type: "address",
                internalType: "address payable",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "upgrade",
        inputs: [
            {
                name: "deliveryProviderChainId",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "newImplementation",
                type: "address",
                internalType: "address",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "event",
        name: "AdminChanged",
        inputs: [
            {
                name: "previousAdmin",
                type: "address",
                indexed: false,
                internalType: "address",
            },
            {
                name: "newAdmin",
                type: "address",
                indexed: false,
                internalType: "address",
            },
        ],
        anonymous: false,
    },
    {
        type: "event",
        name: "AssetConversionBufferUpdated",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                indexed: false,
                internalType: "uint16",
            },
            {
                name: "buffer",
                type: "uint16",
                indexed: false,
                internalType: "uint16",
            },
            {
                name: "bufferDenominator",
                type: "uint16",
                indexed: false,
                internalType: "uint16",
            },
        ],
        anonymous: false,
    },
    {
        type: "event",
        name: "BeaconUpgraded",
        inputs: [
            {
                name: "beacon",
                type: "address",
                indexed: true,
                internalType: "address",
            },
        ],
        anonymous: false,
    },
    {
        type: "event",
        name: "ChainSupportUpdated",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                indexed: false,
                internalType: "uint16",
            },
            {
                name: "isSupported",
                type: "bool",
                indexed: false,
                internalType: "bool",
            },
        ],
        anonymous: false,
    },
    {
        type: "event",
        name: "ContractUpgraded",
        inputs: [
            {
                name: "oldContract",
                type: "address",
                indexed: true,
                internalType: "address",
            },
            {
                name: "newContract",
                type: "address",
                indexed: true,
                internalType: "address",
            },
        ],
        anonymous: false,
    },
    {
        type: "event",
        name: "DeliverGasOverheadUpdated",
        inputs: [
            {
                name: "oldGasOverhead",
                type: "uint256",
                indexed: true,
                internalType: "Gas",
            },
            {
                name: "newGasOverhead",
                type: "uint256",
                indexed: true,
                internalType: "Gas",
            },
        ],
        anonymous: false,
    },
    {
        type: "event",
        name: "OwnershipTransfered",
        inputs: [
            {
                name: "oldOwner",
                type: "address",
                indexed: true,
                internalType: "address",
            },
            {
                name: "newOwner",
                type: "address",
                indexed: true,
                internalType: "address",
            },
        ],
        anonymous: false,
    },
    {
        type: "event",
        name: "RewardAddressUpdated",
        inputs: [
            {
                name: "newAddress",
                type: "address",
                indexed: true,
                internalType: "address",
            },
        ],
        anonymous: false,
    },
    {
        type: "event",
        name: "TargetChainAddressUpdated",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                indexed: true,
                internalType: "uint16",
            },
            {
                name: "newAddress",
                type: "bytes32",
                indexed: true,
                internalType: "bytes32",
            },
        ],
        anonymous: false,
    },
    {
        type: "event",
        name: "Upgraded",
        inputs: [
            {
                name: "implementation",
                type: "address",
                indexed: true,
                internalType: "address",
            },
        ],
        anonymous: false,
    },
    {
        type: "event",
        name: "WormholeRelayerUpdated",
        inputs: [
            {
                name: "coreRelayer",
                type: "address",
                indexed: false,
                internalType: "address",
            },
        ],
        anonymous: false,
    },
    {
        type: "error",
        name: "AddressIsZero",
        inputs: [],
    },
    {
        type: "error",
        name: "CallerMustBeOwner",
        inputs: [],
    },
    {
        type: "error",
        name: "CallerMustBeOwnerOrPricingWallet",
        inputs: [],
    },
    {
        type: "error",
        name: "CallerMustBePendingOwner",
        inputs: [],
    },
    {
        type: "error",
        name: "ChainIdIsZero",
        inputs: [],
    },
    {
        type: "error",
        name: "FailedToInitializeImplementation",
        inputs: [
            {
                name: "reason",
                type: "string",
                internalType: "string",
            },
        ],
    },
    {
        type: "error",
        name: "GasPriceIsZero",
        inputs: [],
    },
    {
        type: "error",
        name: "NativeCurrencyPriceIsZero",
        inputs: [],
    },
    {
        type: "error",
        name: "WrongChainId",
        inputs: [],
    },
];
export class DeliveryProviderGovernance__factory {
    static createInterface() {
        return new utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    }
}
DeliveryProviderGovernance__factory.abi = _abi;
