/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { utils, Contract, ContractFactory } from "ethers";
const _abi = [
    {
        type: "function",
        name: "receiveWormholeMessages",
        inputs: [
            {
                name: "payload",
                type: "bytes",
                internalType: "bytes",
            },
            {
                name: "",
                type: "bytes[]",
                internalType: "bytes[]",
            },
            {
                name: "",
                type: "bytes32",
                internalType: "bytes32",
            },
            {
                name: "",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
        outputs: [],
        stateMutability: "payable",
    },
    {
        type: "error",
        name: "OutOfBounds",
        inputs: [
            {
                name: "offset",
                type: "uint256",
                internalType: "uint256",
            },
            {
                name: "length",
                type: "uint256",
                internalType: "uint256",
            },
        ],
    },
];
const _bytecode = "0x6080806040523461001657610276908161001c8239f35b600080fdfe608060405260048036101561001357600080fd5b600090813560e01c63529dca321461002a57600080fd5b60a03660031901126101a25767ffffffffffffffff9181358381116101a25761005690369084016101fe565b9160249384359080821161019e573660238301121561019e57818301359181831161018c578260051b9087602080956100908286016101a6565b90815201928201019236841161018857888201925b84841061015e57505050505060643561ffff81160361015a5780840151935180821161013f57506100dd6100d8856101e2565b6101a6565b908482526100ea856101e2565b8282019590601f1901368737845b835181101561013b5760f881901b6001600160f81b031916861a8482018401536000198114610129576001016100f8565b634e487b7160e01b8652601185528786fd5b5085fd5b856044939260405193633d71388b60e21b8552840152820152fd5b8280fd5b833582811161018457869161017983928d36918801016101fe565b8152019301926100a5565b8880fd5b8680fd5b634e487b7160e01b8552604184528685fd5b8380fd5b5080fd5b6040519190601f01601f1916820167ffffffffffffffff8111838210176101cc57604052565b634e487b7160e01b600052604160045260246000fd5b67ffffffffffffffff81116101cc57601f01601f191660200190565b81601f8201121561023b578035906102186100d8836101e2565b928284526020838301011161023b57816000926020809301838601378301015290565b600080fdfea2646970667358221220606c092382f2e010276244aafa31b922113ca92575fcb27d0caa447cf948028f64736f6c63430008130033";
const isSuperArgs = (xs) => xs.length > 1;
export class BigRevertBufferIntegration__factory extends ContractFactory {
    constructor(...args) {
        if (isSuperArgs(args)) {
            super(...args);
        }
        else {
            super(_abi, _bytecode, args[0]);
        }
    }
    deploy(overrides) {
        return super.deploy(overrides || {});
    }
    getDeployTransaction(overrides) {
        return super.getDeployTransaction(overrides || {});
    }
    attach(address) {
        return super.attach(address);
    }
    connect(signer) {
        return super.connect(signer);
    }
    static createInterface() {
        return new utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    }
}
BigRevertBufferIntegration__factory.bytecode = _bytecode;
BigRevertBufferIntegration__factory.abi = _abi;
