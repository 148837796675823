/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { utils, Contract, ContractFactory } from "ethers";
const _abi = [
    {
        type: "function",
        name: "checkAndExecuteUpgradeMigration",
        inputs: [],
        outputs: [],
        stateMutability: "view",
    },
];
const _bytecode = "0x60808060405234601357606b908160198239f35b600080fdfe6004361015600c57600080fd5b6000803560e01c632c75470f14602157600080fd5b346032578060031936011260325780f35b80fdfea2646970667358221220afa7d2f19018ed544b1ef2d71d47d7322f27a19a1c480390109d9634c3d0a67464736f6c63430008130033";
const isSuperArgs = (xs) => xs.length > 1;
export class Brick__factory extends ContractFactory {
    constructor(...args) {
        if (isSuperArgs(args)) {
            super(...args);
        }
        else {
            super(_abi, _bytecode, args[0]);
        }
    }
    deploy(overrides) {
        return super.deploy(overrides || {});
    }
    getDeployTransaction(overrides) {
        return super.getDeployTransaction(overrides || {});
    }
    attach(address) {
        return super.attach(address);
    }
    connect(signer) {
        return super.connect(signer);
    }
    static createInterface() {
        return new utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    }
}
Brick__factory.bytecode = _bytecode;
Brick__factory.abi = _abi;
