var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SUI_CLOCK_OBJECT_ID, TransactionBlock, } from "@mysten/sui.js";
import { Keypair, PublicKey, Transaction, } from "@solana/web3.js";
import { MsgExecuteContract } from "@terra-money/terra.js";
import { MsgExecuteContract as XplaMsgExecuteContract } from "@xpla/xpla.js";
import { OnApplicationComplete, bigIntToBytes, decodeAddress, getApplicationAddress, makeApplicationCallTxnFromObject, makePaymentTxnWithSuggestedParamsFromObject, } from "algosdk";
import BN from "bn.js";
import { getIsWrappedAssetNear } from ".";
import { getMessageFee, optin } from "../algorand";
import { attestToken as attestTokenAptos } from "../aptos";
import { isNativeDenomXpla } from "../cosmwasm";
import { Bridge__factory } from "../ethers-contracts";
import { createBridgeFeeTransferInstruction } from "../solana";
import { createAttestTokenInstruction } from "../solana/tokenBridge";
import { getPackageId } from "../sui/utils";
import { isNativeDenom } from "../terra";
import { callFunctionNear, hashAccount, textToHexString, textToUint8Array, uint8ArrayToHex, } from "../utils";
import { safeBigIntToNumber } from "../utils/bigint";
import { createNonce } from "../utils/createNonce";
export function attestFromEth(tokenBridgeAddress, signer, tokenAddress, overrides = {}) {
    return __awaiter(this, void 0, void 0, function* () {
        const bridge = Bridge__factory.connect(tokenBridgeAddress, signer);
        const v = yield bridge.attestToken(tokenAddress, createNonce(), overrides);
        const receipt = yield v.wait();
        return receipt;
    });
}
export function attestFromTerra(tokenBridgeAddress, walletAddress, asset) {
    return __awaiter(this, void 0, void 0, function* () {
        const nonce = Math.round(Math.random() * 100000);
        const isNativeAsset = isNativeDenom(asset);
        return new MsgExecuteContract(walletAddress, tokenBridgeAddress, {
            create_asset_meta: {
                asset_info: isNativeAsset
                    ? {
                        native_token: { denom: asset },
                    }
                    : {
                        token: {
                            contract_addr: asset,
                        },
                    },
                nonce: nonce,
            },
        });
    });
}
export function attestFromXpla(tokenBridgeAddress, walletAddress, asset) {
    const nonce = Math.round(Math.random() * 100000);
    const isNativeAsset = isNativeDenomXpla(asset);
    return new XplaMsgExecuteContract(walletAddress, tokenBridgeAddress, {
        create_asset_meta: {
            asset_info: isNativeAsset
                ? {
                    native_token: { denom: asset },
                }
                : {
                    token: {
                        contract_addr: asset,
                    },
                },
            nonce: nonce,
        },
    });
}
export function attestFromSolana(connection, bridgeAddress, tokenBridgeAddress, payerAddress, mintAddress, commitment) {
    return __awaiter(this, void 0, void 0, function* () {
        const nonce = createNonce().readUInt32LE(0);
        const transferIx = yield createBridgeFeeTransferInstruction(connection, bridgeAddress, payerAddress);
        const messageKey = Keypair.generate();
        const attestIx = createAttestTokenInstruction(tokenBridgeAddress, bridgeAddress, payerAddress, mintAddress, messageKey.publicKey, nonce);
        const transaction = new Transaction().add(transferIx, attestIx);
        const { blockhash } = yield connection.getLatestBlockhash(commitment);
        transaction.recentBlockhash = blockhash;
        transaction.feePayer = new PublicKey(payerAddress);
        transaction.partialSign(messageKey);
        return transaction;
    });
}
/**
 * Attest an already created asset
 * If you create a new asset on algorand and want to transfer it elsewhere,
 * you create an attestation for it on algorand... pass that vaa to the target chain..
 * submit it.. then you can transfer from algorand to that target chain
 * @param client An Algodv2 client
 * @param tokenBridgeId The ID of the token bridge
 * @param senderAcct The account paying fees
 * @param assetId The asset index
 * @returns Transaction ID
 */
export function attestFromAlgorand(client, tokenBridgeId, bridgeId, senderAddr, assetId) {
    return __awaiter(this, void 0, void 0, function* () {
        const tbAddr = getApplicationAddress(tokenBridgeId);
        const decTbAddr = decodeAddress(tbAddr).publicKey;
        const aa = uint8ArrayToHex(decTbAddr);
        const txs = [];
        // "attestFromAlgorand::emitterAddr"
        const { addr: emitterAddr, txs: emitterOptInTxs } = yield optin(client, senderAddr, bridgeId, BigInt(0), aa);
        txs.push(...emitterOptInTxs);
        let creatorAddr = "";
        let creatorAcctInfo;
        const bPgmName = textToUint8Array("attestToken");
        if (assetId !== BigInt(0)) {
            const assetInfo = yield client
                .getAssetByID(safeBigIntToNumber(assetId))
                .do();
            creatorAcctInfo = yield client
                .accountInformation(assetInfo.params.creator)
                .do();
            if (creatorAcctInfo["auth-addr"] === tbAddr) {
                throw new Error("Cannot re-attest wormhole assets");
            }
        }
        const result = yield optin(client, senderAddr, tokenBridgeId, assetId, textToHexString("native"));
        creatorAddr = result.addr;
        txs.push(...result.txs);
        const suggParams = yield client.getTransactionParams().do();
        const firstTxn = makeApplicationCallTxnFromObject({
            from: senderAddr,
            appIndex: safeBigIntToNumber(tokenBridgeId),
            onComplete: OnApplicationComplete.NoOpOC,
            appArgs: [textToUint8Array("nop")],
            suggestedParams: suggParams,
        });
        txs.push({ tx: firstTxn, signer: null });
        const mfee = yield getMessageFee(client, bridgeId);
        if (mfee > BigInt(0)) {
            const feeTxn = makePaymentTxnWithSuggestedParamsFromObject({
                from: senderAddr,
                suggestedParams: suggParams,
                to: getApplicationAddress(tokenBridgeId),
                amount: mfee,
            });
            txs.push({ tx: feeTxn, signer: null });
        }
        let accts = [
            emitterAddr,
            creatorAddr,
            getApplicationAddress(bridgeId),
        ];
        if (creatorAcctInfo) {
            accts.push(creatorAcctInfo["address"]);
        }
        let appTxn = makeApplicationCallTxnFromObject({
            appArgs: [bPgmName, bigIntToBytes(assetId, 8)],
            accounts: accts,
            appIndex: safeBigIntToNumber(tokenBridgeId),
            foreignApps: [safeBigIntToNumber(bridgeId)],
            foreignAssets: [safeBigIntToNumber(assetId)],
            from: senderAddr,
            onComplete: OnApplicationComplete.NoOpOC,
            suggestedParams: suggParams,
        });
        if (mfee > BigInt(0)) {
            appTxn.fee *= 3;
        }
        else {
            appTxn.fee *= 2;
        }
        txs.push({ tx: appTxn, signer: null });
        return txs;
    });
}
export function attestTokenFromNear(provider, coreBridge, tokenBridge, asset) {
    return __awaiter(this, void 0, void 0, function* () {
        const options = [];
        const messageFee = yield callFunctionNear(provider, coreBridge, "message_fee");
        if (!getIsWrappedAssetNear(tokenBridge, asset)) {
            const { isRegistered } = yield hashAccount(provider, tokenBridge, asset);
            if (!isRegistered) {
                // The account has not been registered. The first user to attest a non-wormhole token pays for the space
                options.push({
                    contractId: tokenBridge,
                    methodName: "register_account",
                    args: { account: asset },
                    gas: new BN("100000000000000"),
                    attachedDeposit: new BN("2000000000000000000000"), // 0.002 NEAR
                });
            }
        }
        options.push({
            contractId: tokenBridge,
            methodName: "attest_token",
            args: { token: asset, message_fee: messageFee },
            attachedDeposit: new BN("3000000000000000000000").add(new BN(messageFee)),
            gas: new BN("100000000000000"),
        });
        return options;
    });
}
export function attestNearFromNear(provider, coreBridge, tokenBridge) {
    return __awaiter(this, void 0, void 0, function* () {
        const messageFee = (yield callFunctionNear(provider, coreBridge, "message_fee")) + 1;
        return {
            contractId: tokenBridge,
            methodName: "attest_near",
            args: { message_fee: messageFee },
            attachedDeposit: new BN(messageFee),
            gas: new BN("100000000000000"),
        };
    });
}
/**
 * Attest given token from Aptos.
 * @param tokenBridgeAddress Address of token bridge
 * @param tokenChain Origin chain ID
 * @param tokenAddress Address of token on origin chain
 * @returns Transaction payload
 */
export function attestFromAptos(tokenBridgeAddress, tokenChain, tokenAddress) {
    return attestTokenAptos(tokenBridgeAddress, tokenChain, tokenAddress);
}
export function attestFromSui(provider, coreBridgeStateObjectId, tokenBridgeStateObjectId, coinType, feeAmount = BigInt(0), coreBridgePackageId, tokenBridgePackageId) {
    return __awaiter(this, void 0, void 0, function* () {
        const metadata = yield provider.getCoinMetadata({ coinType });
        if (metadata === null || metadata.id === null) {
            throw new Error(`Coin metadata ID for type ${coinType} not found`);
        }
        [coreBridgePackageId, tokenBridgePackageId] = yield Promise.all([
            coreBridgePackageId
                ? Promise.resolve(coreBridgePackageId)
                : getPackageId(provider, coreBridgeStateObjectId),
            tokenBridgePackageId
                ? Promise.resolve(tokenBridgePackageId)
                : getPackageId(provider, tokenBridgeStateObjectId),
        ]);
        const tx = new TransactionBlock();
        const [feeCoin] = tx.splitCoins(tx.gas, [tx.pure(feeAmount)]);
        const [messageTicket] = tx.moveCall({
            target: `${tokenBridgePackageId}::attest_token::attest_token`,
            arguments: [
                tx.object(tokenBridgeStateObjectId),
                tx.object(metadata.id),
                tx.pure(createNonce().readUInt32LE()),
            ],
            typeArguments: [coinType],
        });
        tx.moveCall({
            target: `${coreBridgePackageId}::publish_message::publish_message`,
            arguments: [
                tx.object(coreBridgeStateObjectId),
                feeCoin,
                messageTicket,
                tx.object(SUI_CLOCK_OBJECT_ID),
            ],
        });
        return tx;
    });
}
