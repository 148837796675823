var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { keccak256 } from "ethers/lib/utils";
import { isNativeDenom } from "../terra";
import { CHAIN_ID_COSMOSHUB, CHAIN_ID_EVMOS, CHAIN_ID_WORMCHAIN, CHAIN_ID_INJECTIVE, CHAIN_ID_SEI, CHAIN_ID_XPLA, coalesceCosmWasmChainId, isTerraChain, CHAIN_ID_KUJIRA, CHAIN_ID_NEUTRON, CHAIN_ID_OSMOSIS, CHAIN_ID_CELESTIA, CHAIN_ID_STARGAZE, CHAIN_ID_SEDA, CHAIN_ID_DYMENSION, CHAIN_ID_PROVENANCE, } from "../utils";
export const isNativeDenomInjective = (denom) => denom === "inj";
export const isNativeDenomXpla = (denom) => denom === "axpla";
export const isNativeDenomSei = (denom) => denom === "usei";
export const isNativeDenomWormchain = (denom) => denom === "uworm";
export const isNativeDenomOsmosis = (denom) => denom === "uosmo";
export const isNativeDenomCosmosHub = (denom) => denom === "uatom";
export const isNativeDenomEvmos = (denom) => denom === "aevmos" || denom === "atevmos";
export const isNativeDenomKujira = (denom) => denom === "ukuji";
export const isNativeDenomNeutron = (denom) => denom === "untrn";
export const isNativeDenomCelestia = (denom) => denom === "utia";
export const isNativeDenomStargaze = (denom) => denom === "ustars";
export const isNativeDenomSeda = (denom) => denom === "aseda";
export const isNativeDenomDymension = (denom) => denom === "adym";
export const isNativeDenomProvenance = (denom) => denom === "nhash";
export function isNativeCosmWasmDenom(chainId, address) {
    return ((isTerraChain(chainId) && isNativeDenom(address)) ||
        (chainId === CHAIN_ID_INJECTIVE && isNativeDenomInjective(address)) ||
        (chainId === CHAIN_ID_XPLA && isNativeDenomXpla(address)) ||
        (chainId === CHAIN_ID_SEI && isNativeDenomSei(address)) ||
        (chainId === CHAIN_ID_WORMCHAIN && isNativeDenomWormchain(address)) ||
        (chainId === CHAIN_ID_OSMOSIS && isNativeDenomOsmosis(address)) ||
        (chainId === CHAIN_ID_COSMOSHUB && isNativeDenomCosmosHub(address)) ||
        (chainId === CHAIN_ID_EVMOS && isNativeDenomEvmos(address)) ||
        (chainId === CHAIN_ID_KUJIRA && isNativeDenomKujira(address)) ||
        (chainId === CHAIN_ID_NEUTRON && isNativeDenomNeutron(address)) ||
        (chainId === CHAIN_ID_CELESTIA && isNativeDenomCelestia(address)) ||
        (chainId === CHAIN_ID_STARGAZE && isNativeDenomStargaze(address)) ||
        (chainId === CHAIN_ID_SEDA && isNativeDenomSeda(address)) ||
        (chainId === CHAIN_ID_DYMENSION && isNativeDenomDymension(address)) ||
        (chainId === CHAIN_ID_PROVENANCE && isNativeDenomProvenance(address)));
}
export function buildTokenId(chain, address) {
    const chainId = coalesceCosmWasmChainId(chain);
    return ((isNativeCosmWasmDenom(chainId, address) ? "01" : "00") +
        keccak256(Buffer.from(address, "utf-8")).substring(4));
}
// returns the TokenId corresponding to the ExternalTokenId
// see cosmwasm token_addresses.rs
export const queryExternalId = (client, tokenBridgeAddress, externalTokenId) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d, _e;
    try {
        const response = yield client.wasm.contractQuery(tokenBridgeAddress, {
            external_id: {
                external_id: Buffer.from(externalTokenId, "hex").toString("base64"),
            },
        });
        return (
        // response depends on the token type
        ((_a = response.token_id.Bank) === null || _a === void 0 ? void 0 : _a.denom) ||
            ((_c = (_b = response.token_id.Contract) === null || _b === void 0 ? void 0 : _b.NativeCW20) === null || _c === void 0 ? void 0 : _c.contract_address) ||
            ((_e = (_d = response.token_id.Contract) === null || _d === void 0 ? void 0 : _d.ForeignToken) === null || _e === void 0 ? void 0 : _e.foreign_address));
    }
    catch (_f) {
        return null;
    }
});
