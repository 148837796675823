/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { utils, Contract, ContractFactory } from "ethers";
const _abi = [
    {
        type: "function",
        name: "setup",
        inputs: [
            {
                name: "implementation",
                type: "address",
                internalType: "address",
            },
            {
                name: "chainId",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "wormhole",
                type: "address",
                internalType: "address",
            },
            {
                name: "governanceChainId",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "governanceContract",
                type: "bytes32",
                internalType: "bytes32",
            },
            {
                name: "tokenImplementation",
                type: "address",
                internalType: "address",
            },
            {
                name: "WETH",
                type: "address",
                internalType: "address",
            },
            {
                name: "finality",
                type: "uint8",
                internalType: "uint8",
            },
            {
                name: "evmChainId",
                type: "uint256",
                internalType: "uint256",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "event",
        name: "AdminChanged",
        inputs: [
            {
                name: "previousAdmin",
                type: "address",
                indexed: false,
                internalType: "address",
            },
            {
                name: "newAdmin",
                type: "address",
                indexed: false,
                internalType: "address",
            },
        ],
        anonymous: false,
    },
    {
        type: "event",
        name: "BeaconUpgraded",
        inputs: [
            {
                name: "beacon",
                type: "address",
                indexed: true,
                internalType: "address",
            },
        ],
        anonymous: false,
    },
    {
        type: "event",
        name: "Upgraded",
        inputs: [
            {
                name: "implementation",
                type: "address",
                indexed: true,
                internalType: "address",
            },
        ],
        anonymous: false,
    },
];
const _bytecode = "0x608060405234801561001057600080fd5b506103ba806100206000396000f3fe608060405234801561001057600080fd5b506004361061002b5760003560e01c8063b5d93f2614610030575b600080fd5b61004361003e3660046102e2565b610045565b005b60028054600080546001600160a01b038b166001600160a01b031990911617905561ffff888116620100000263ffffffff19909216908b1617179055600385905561008f84610109565b600480546001600160a01b0319166001600160a01b0385161790556002805464ff00000000191664010000000060ff8516021790556100cd81610186565b6100d6896101cf565b6100fe896001600160a01b03166000908152600760205260409020805460ff19166001179055565b505050505050505050565b6001600160a01b0381166101645760405162461bcd60e51b815260206004820152601e60248201527f696e76616c696420696d706c656d656e746174696f6e2061646472657373000060448201526064015b60405180910390fd5b600180546001600160a01b0319166001600160a01b0392909216919091179055565b4681146101ca5760405162461bcd60e51b81526020600482015260126024820152711a5b9d985b1a5908195d9b50da185a5b925960721b604482015260640161015b565b600c55565b6101d88161020f565b6040516001600160a01b038216907fbc7cd75a20ee27fd9adebab32041f755214dbc6bffa90cc0225b39da2e5c2d3b90600090a250565b803b6102735760405162461bcd60e51b815260206004820152602d60248201527f455243313936373a206e657720696d706c656d656e746174696f6e206973206e60448201526c1bdd08184818dbdb9d1c9858dd609a1b606482015260840161015b565b7f360894a13ba1a3210667c828492db98dca3e2076cc3735a920a3ca505d382bbc80546001600160a01b0319166001600160a01b0392909216919091179055565b80356001600160a01b03811681146102cb57600080fd5b919050565b803561ffff811681146102cb57600080fd5b60008060008060008060008060006101208a8c031215610300578485fd5b6103098a6102b4565b985061031760208b016102d0565b975061032560408b016102b4565b965061033360608b016102d0565b955060808a0135945061034860a08b016102b4565b935061035660c08b016102b4565b925060e08a013560ff8116811461036b578283fd5b809250506101008a01359050929598509295985092959856fea2646970667358221220c97885b431f6aa5c7e811250b3ebe414e8bc0fbabffc1be179d152874440857b64736f6c63430008040033";
const isSuperArgs = (xs) => xs.length > 1;
export class BridgeSetup__factory extends ContractFactory {
    constructor(...args) {
        if (isSuperArgs(args)) {
            super(...args);
        }
        else {
            super(_abi, _bytecode, args[0]);
        }
    }
    deploy(overrides) {
        return super.deploy(overrides || {});
    }
    getDeployTransaction(overrides) {
        return super.getDeployTransaction(overrides || {});
    }
    attach(address) {
        return super.attach(address);
    }
    connect(signer) {
        return super.connect(signer);
    }
    static createInterface() {
        return new utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    }
}
BridgeSetup__factory.bytecode = _bytecode;
BridgeSetup__factory.abi = _abi;
