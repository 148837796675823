"use strict";

// Wordlists
// See: https://github.com/bitcoin/bips/blob/master/bip-0039/bip-0039-wordlists.md

import { logger, Wordlist } from "./wordlist";

import { wordlists } from "./wordlists";

export {
    logger,
    Wordlist,
    wordlists
}
