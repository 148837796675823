/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Contract, utils } from "ethers";
const _abi = [
    {
        type: "function",
        name: "WETH",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "address",
                internalType: "contract IWETH",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "_parseTransferCommon",
        inputs: [
            {
                name: "encoded",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [
            {
                name: "transfer",
                type: "tuple",
                internalType: "struct ITokenBridge.Transfer",
                components: [
                    {
                        name: "payloadID",
                        type: "uint8",
                        internalType: "uint8",
                    },
                    {
                        name: "amount",
                        type: "uint256",
                        internalType: "uint256",
                    },
                    {
                        name: "tokenAddress",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "tokenChain",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "to",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "toChain",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "fee",
                        type: "uint256",
                        internalType: "uint256",
                    },
                ],
            },
        ],
        stateMutability: "pure",
    },
    {
        type: "function",
        name: "attestToken",
        inputs: [
            {
                name: "tokenAddress",
                type: "address",
                internalType: "address",
            },
            {
                name: "nonce",
                type: "uint32",
                internalType: "uint32",
            },
        ],
        outputs: [
            {
                name: "sequence",
                type: "uint64",
                internalType: "uint64",
            },
        ],
        stateMutability: "payable",
    },
    {
        type: "function",
        name: "bridgeContracts",
        inputs: [
            {
                name: "chainId_",
                type: "uint16",
                internalType: "uint16",
            },
        ],
        outputs: [
            {
                name: "",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "chainId",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "uint16",
                internalType: "uint16",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "completeTransfer",
        inputs: [
            {
                name: "encodedVm",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "completeTransferAndUnwrapETH",
        inputs: [
            {
                name: "encodedVm",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "completeTransferAndUnwrapETHWithPayload",
        inputs: [
            {
                name: "encodedVm",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [
            {
                name: "",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "completeTransferWithPayload",
        inputs: [
            {
                name: "encodedVm",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [
            {
                name: "",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "createWrapped",
        inputs: [
            {
                name: "encodedVm",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [
            {
                name: "token",
                type: "address",
                internalType: "address",
            },
        ],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "encodeAssetMeta",
        inputs: [
            {
                name: "meta",
                type: "tuple",
                internalType: "struct ITokenBridge.AssetMeta",
                components: [
                    {
                        name: "payloadID",
                        type: "uint8",
                        internalType: "uint8",
                    },
                    {
                        name: "tokenAddress",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "tokenChain",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "decimals",
                        type: "uint8",
                        internalType: "uint8",
                    },
                    {
                        name: "symbol",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "name",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                ],
            },
        ],
        outputs: [
            {
                name: "encoded",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        stateMutability: "pure",
    },
    {
        type: "function",
        name: "encodeTransfer",
        inputs: [
            {
                name: "transfer",
                type: "tuple",
                internalType: "struct ITokenBridge.Transfer",
                components: [
                    {
                        name: "payloadID",
                        type: "uint8",
                        internalType: "uint8",
                    },
                    {
                        name: "amount",
                        type: "uint256",
                        internalType: "uint256",
                    },
                    {
                        name: "tokenAddress",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "tokenChain",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "to",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "toChain",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "fee",
                        type: "uint256",
                        internalType: "uint256",
                    },
                ],
            },
        ],
        outputs: [
            {
                name: "encoded",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        stateMutability: "pure",
    },
    {
        type: "function",
        name: "encodeTransferWithPayload",
        inputs: [
            {
                name: "transfer",
                type: "tuple",
                internalType: "struct ITokenBridge.TransferWithPayload",
                components: [
                    {
                        name: "payloadID",
                        type: "uint8",
                        internalType: "uint8",
                    },
                    {
                        name: "amount",
                        type: "uint256",
                        internalType: "uint256",
                    },
                    {
                        name: "tokenAddress",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "tokenChain",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "to",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "toChain",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "fromAddress",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "payload",
                        type: "bytes",
                        internalType: "bytes",
                    },
                ],
            },
        ],
        outputs: [
            {
                name: "encoded",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        stateMutability: "pure",
    },
    {
        type: "function",
        name: "evmChainId",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "uint256",
                internalType: "uint256",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "finality",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "uint8",
                internalType: "uint8",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "governanceActionIsConsumed",
        inputs: [
            {
                name: "hash",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
        outputs: [
            {
                name: "",
                type: "bool",
                internalType: "bool",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "governanceChainId",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "uint16",
                internalType: "uint16",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "governanceContract",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "implementation",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "address",
                internalType: "address",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "initialize",
        inputs: [],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "isFork",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "bool",
                internalType: "bool",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "isInitialized",
        inputs: [
            {
                name: "impl",
                type: "address",
                internalType: "address",
            },
        ],
        outputs: [
            {
                name: "",
                type: "bool",
                internalType: "bool",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "isTransferCompleted",
        inputs: [
            {
                name: "hash",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
        outputs: [
            {
                name: "",
                type: "bool",
                internalType: "bool",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "isWrappedAsset",
        inputs: [
            {
                name: "token",
                type: "address",
                internalType: "address",
            },
        ],
        outputs: [
            {
                name: "",
                type: "bool",
                internalType: "bool",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "outstandingBridged",
        inputs: [
            {
                name: "token",
                type: "address",
                internalType: "address",
            },
        ],
        outputs: [
            {
                name: "",
                type: "uint256",
                internalType: "uint256",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "parseAssetMeta",
        inputs: [
            {
                name: "encoded",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [
            {
                name: "meta",
                type: "tuple",
                internalType: "struct ITokenBridge.AssetMeta",
                components: [
                    {
                        name: "payloadID",
                        type: "uint8",
                        internalType: "uint8",
                    },
                    {
                        name: "tokenAddress",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "tokenChain",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "decimals",
                        type: "uint8",
                        internalType: "uint8",
                    },
                    {
                        name: "symbol",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "name",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                ],
            },
        ],
        stateMutability: "pure",
    },
    {
        type: "function",
        name: "parsePayloadID",
        inputs: [
            {
                name: "encoded",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [
            {
                name: "payloadID",
                type: "uint8",
                internalType: "uint8",
            },
        ],
        stateMutability: "pure",
    },
    {
        type: "function",
        name: "parseRecoverChainId",
        inputs: [
            {
                name: "encodedRecoverChainId",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [
            {
                name: "rci",
                type: "tuple",
                internalType: "struct ITokenBridge.RecoverChainId",
                components: [
                    {
                        name: "module",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "action",
                        type: "uint8",
                        internalType: "uint8",
                    },
                    {
                        name: "evmChainId",
                        type: "uint256",
                        internalType: "uint256",
                    },
                    {
                        name: "newChainId",
                        type: "uint16",
                        internalType: "uint16",
                    },
                ],
            },
        ],
        stateMutability: "pure",
    },
    {
        type: "function",
        name: "parseRegisterChain",
        inputs: [
            {
                name: "encoded",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [
            {
                name: "chain",
                type: "tuple",
                internalType: "struct ITokenBridge.RegisterChain",
                components: [
                    {
                        name: "module",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "action",
                        type: "uint8",
                        internalType: "uint8",
                    },
                    {
                        name: "chainId",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "emitterChainID",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "emitterAddress",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                ],
            },
        ],
        stateMutability: "pure",
    },
    {
        type: "function",
        name: "parseTransfer",
        inputs: [
            {
                name: "encoded",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [
            {
                name: "transfer",
                type: "tuple",
                internalType: "struct ITokenBridge.Transfer",
                components: [
                    {
                        name: "payloadID",
                        type: "uint8",
                        internalType: "uint8",
                    },
                    {
                        name: "amount",
                        type: "uint256",
                        internalType: "uint256",
                    },
                    {
                        name: "tokenAddress",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "tokenChain",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "to",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "toChain",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "fee",
                        type: "uint256",
                        internalType: "uint256",
                    },
                ],
            },
        ],
        stateMutability: "pure",
    },
    {
        type: "function",
        name: "parseTransferWithPayload",
        inputs: [
            {
                name: "encoded",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [
            {
                name: "transfer",
                type: "tuple",
                internalType: "struct ITokenBridge.TransferWithPayload",
                components: [
                    {
                        name: "payloadID",
                        type: "uint8",
                        internalType: "uint8",
                    },
                    {
                        name: "amount",
                        type: "uint256",
                        internalType: "uint256",
                    },
                    {
                        name: "tokenAddress",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "tokenChain",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "to",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "toChain",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "fromAddress",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "payload",
                        type: "bytes",
                        internalType: "bytes",
                    },
                ],
            },
        ],
        stateMutability: "pure",
    },
    {
        type: "function",
        name: "parseUpgrade",
        inputs: [
            {
                name: "encoded",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [
            {
                name: "chain",
                type: "tuple",
                internalType: "struct ITokenBridge.UpgradeContract",
                components: [
                    {
                        name: "module",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "action",
                        type: "uint8",
                        internalType: "uint8",
                    },
                    {
                        name: "chainId",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "newContract",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                ],
            },
        ],
        stateMutability: "pure",
    },
    {
        type: "function",
        name: "registerChain",
        inputs: [
            {
                name: "encodedVM",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "submitRecoverChainId",
        inputs: [
            {
                name: "encodedVM",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "tokenImplementation",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "address",
                internalType: "address",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "transferTokens",
        inputs: [
            {
                name: "token",
                type: "address",
                internalType: "address",
            },
            {
                name: "amount",
                type: "uint256",
                internalType: "uint256",
            },
            {
                name: "recipientChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "recipient",
                type: "bytes32",
                internalType: "bytes32",
            },
            {
                name: "arbiterFee",
                type: "uint256",
                internalType: "uint256",
            },
            {
                name: "nonce",
                type: "uint32",
                internalType: "uint32",
            },
        ],
        outputs: [
            {
                name: "sequence",
                type: "uint64",
                internalType: "uint64",
            },
        ],
        stateMutability: "payable",
    },
    {
        type: "function",
        name: "transferTokensWithPayload",
        inputs: [
            {
                name: "token",
                type: "address",
                internalType: "address",
            },
            {
                name: "amount",
                type: "uint256",
                internalType: "uint256",
            },
            {
                name: "recipientChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "recipient",
                type: "bytes32",
                internalType: "bytes32",
            },
            {
                name: "nonce",
                type: "uint32",
                internalType: "uint32",
            },
            {
                name: "payload",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [
            {
                name: "sequence",
                type: "uint64",
                internalType: "uint64",
            },
        ],
        stateMutability: "payable",
    },
    {
        type: "function",
        name: "updateWrapped",
        inputs: [
            {
                name: "encodedVm",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [
            {
                name: "token",
                type: "address",
                internalType: "address",
            },
        ],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "upgrade",
        inputs: [
            {
                name: "encodedVM",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "wormhole",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "address",
                internalType: "contract IWormhole",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "wrapAndTransferETH",
        inputs: [
            {
                name: "recipientChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "recipient",
                type: "bytes32",
                internalType: "bytes32",
            },
            {
                name: "arbiterFee",
                type: "uint256",
                internalType: "uint256",
            },
            {
                name: "nonce",
                type: "uint32",
                internalType: "uint32",
            },
        ],
        outputs: [
            {
                name: "sequence",
                type: "uint64",
                internalType: "uint64",
            },
        ],
        stateMutability: "payable",
    },
    {
        type: "function",
        name: "wrapAndTransferETHWithPayload",
        inputs: [
            {
                name: "recipientChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "recipient",
                type: "bytes32",
                internalType: "bytes32",
            },
            {
                name: "nonce",
                type: "uint32",
                internalType: "uint32",
            },
            {
                name: "payload",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [
            {
                name: "sequence",
                type: "uint64",
                internalType: "uint64",
            },
        ],
        stateMutability: "payable",
    },
    {
        type: "function",
        name: "wrappedAsset",
        inputs: [
            {
                name: "tokenChainId",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "tokenAddress",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
        outputs: [
            {
                name: "",
                type: "address",
                internalType: "address",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "event",
        name: "ContractUpgraded",
        inputs: [
            {
                name: "oldContract",
                type: "address",
                indexed: true,
                internalType: "address",
            },
            {
                name: "newContract",
                type: "address",
                indexed: true,
                internalType: "address",
            },
        ],
        anonymous: false,
    },
    {
        type: "event",
        name: "TransferRedeemed",
        inputs: [
            {
                name: "emitterChainId",
                type: "uint16",
                indexed: true,
                internalType: "uint16",
            },
            {
                name: "emitterAddress",
                type: "bytes32",
                indexed: true,
                internalType: "bytes32",
            },
            {
                name: "sequence",
                type: "uint64",
                indexed: true,
                internalType: "uint64",
            },
        ],
        anonymous: false,
    },
];
export class ITokenBridge__factory {
    static createInterface() {
        return new utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    }
}
ITokenBridge__factory.abi = _abi;
