/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { utils, Contract, ContractFactory } from "ethers";
const _abi = [
    {
        type: "function",
        name: "setup",
        inputs: [
            {
                name: "implementation",
                type: "address",
                internalType: "address",
            },
            {
                name: "chainId",
                type: "uint16",
                internalType: "uint16",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "event",
        name: "AdminChanged",
        inputs: [
            {
                name: "previousAdmin",
                type: "address",
                indexed: false,
                internalType: "address",
            },
            {
                name: "newAdmin",
                type: "address",
                indexed: false,
                internalType: "address",
            },
        ],
        anonymous: false,
    },
    {
        type: "event",
        name: "BeaconUpgraded",
        inputs: [
            {
                name: "beacon",
                type: "address",
                indexed: true,
                internalType: "address",
            },
        ],
        anonymous: false,
    },
    {
        type: "event",
        name: "Upgraded",
        inputs: [
            {
                name: "implementation",
                type: "address",
                indexed: true,
                internalType: "address",
            },
        ],
        anonymous: false,
    },
    {
        type: "error",
        name: "FailedToInitializeImplementation",
        inputs: [
            {
                name: "reason",
                type: "string",
                internalType: "string",
            },
        ],
    },
    {
        type: "error",
        name: "ImplementationAddressIsZero",
        inputs: [],
    },
];
const _bytecode = "0x608080604052346100165761029d908161001c8239f35b600080fdfe60806040908082526004908136101561001757600080fd5b600092833560e01c63e410a0c61461002e57600080fd5b346102635780600319360112610263578235906001600160a01b0382169081830361025f5760249384359061ffff821680920361025b57831561024d575086546001600160b01b0319163360101b62010000600160b01b031617178655823b156101f6577f360894a13ba1a3210667c828492db98dca3e2076cc3735a920a3ca505d382bbc80546001600160a01b031916831790558051917fbc7cd75a20ee27fd9adebab32041f755214dbc6bffa90cc0225b39da2e5c2d3b8780a260209283830163204a7f0760e21b81528684528284019367ffffffffffffffff94818110868211176101e45784525188928392905af43d156101db573d8381116101c957825193601f8201601f19908116603f01168501908111858210176101b757835283523d878585013e5b15610160578580f35b8386848793519586946385f11c8360e01b865285015284518093850152815b8381106101a057505060448094508284010152601f80199101168101030190fd5b80860182015187820160440152869450810161017f565b634e487b7160e01b8952604188528689fd5b634e487b7160e01b8852604187528588fd5b60609250610157565b634e487b7160e01b8a5260418952878afd5b5162461bcd60e51b8152602081860152602d818501527f455243313936373a206e657720696d706c656d656e746174696f6e206973206e60448201526c1bdd08184818dbdb9d1c9858dd609a1b6064820152608490fd5b6309e9cf2160e01b81528690fd5b8780fd5b8580fd5b8380fdfea264697066735822122070b3d068aefe4a714025dff1b963f83b8ae50867b26ca000c2c3cc8f5e20315d64736f6c63430008130033";
const isSuperArgs = (xs) => xs.length > 1;
export class DeliveryProviderSetup__factory extends ContractFactory {
    constructor(...args) {
        if (isSuperArgs(args)) {
            super(...args);
        }
        else {
            super(_abi, _bytecode, args[0]);
        }
    }
    deploy(overrides) {
        return super.deploy(overrides || {});
    }
    getDeployTransaction(overrides) {
        return super.getDeployTransaction(overrides || {});
    }
    attach(address) {
        return super.attach(address);
    }
    connect(signer) {
        return super.connect(signer);
    }
    static createInterface() {
        return new utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    }
}
DeliveryProviderSetup__factory.bytecode = _bytecode;
DeliveryProviderSetup__factory.abi = _abi;
