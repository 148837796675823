var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { fromUint8Array } from "js-base64";
import { calcLogicSigAccount, decodeLocalState, hexToNativeAssetBigIntAlgorand, } from "../algorand";
import { Bridge__factory } from "../ethers-contracts";
import { deriveWrappedMintKey, getWrappedMeta } from "../solana/tokenBridge";
import { getTokenCoinType } from "../sui";
import { callFunctionNear, CHAIN_ID_ALGORAND, coalesceChainId, coalesceModuleAddress, getAssetFullyQualifiedType, } from "../utils";
/**
 * Returns a foreign asset address on Ethereum for a provided native chain and asset address, AddressZero if it does not exist
 * @param tokenBridgeAddress
 * @param provider
 * @param originChain
 * @param originAsset zero pad to 32 bytes
 * @returns
 */
export function getForeignAssetEth(tokenBridgeAddress, provider, originChain, originAsset) {
    return __awaiter(this, void 0, void 0, function* () {
        const tokenBridge = Bridge__factory.connect(tokenBridgeAddress, provider);
        try {
            return yield tokenBridge.wrappedAsset(coalesceChainId(originChain), originAsset);
        }
        catch (e) {
            return null;
        }
    });
}
export function getForeignAssetTerra(tokenBridgeAddress, client, originChain, originAsset) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const result = yield client.wasm.contractQuery(tokenBridgeAddress, {
                wrapped_registry: {
                    chain: coalesceChainId(originChain),
                    address: fromUint8Array(originAsset),
                },
            });
            return result.address;
        }
        catch (e) {
            return null;
        }
    });
}
export function getForeignAssetXpla(tokenBridgeAddress, client, originChain, originAsset) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const result = yield client.wasm.contractQuery(tokenBridgeAddress, {
                wrapped_registry: {
                    chain: coalesceChainId(originChain),
                    address: fromUint8Array(originAsset),
                },
            });
            return result.address;
        }
        catch (e) {
            return null;
        }
    });
}
/**
 * Returns a foreign asset address on Solana for a provided native chain and asset address
 * @param connection
 * @param tokenBridgeAddress
 * @param originChain
 * @param originAsset zero pad to 32 bytes
 * @param [commitment]
 * @returns
 */
export function getForeignAssetSolana(connection, tokenBridgeAddress, originChain, originAsset, commitment) {
    return __awaiter(this, void 0, void 0, function* () {
        const mint = deriveWrappedMintKey(tokenBridgeAddress, coalesceChainId(originChain), originAsset);
        return getWrappedMeta(connection, tokenBridgeAddress, mint, commitment)
            .catch((_) => null)
            .then((meta) => (meta === null ? null : mint.toString()));
    });
}
export function getForeignAssetAlgorand(client, tokenBridgeId, chain, contract) {
    return __awaiter(this, void 0, void 0, function* () {
        const chainId = coalesceChainId(chain);
        if (chainId === CHAIN_ID_ALGORAND) {
            return hexToNativeAssetBigIntAlgorand(contract);
        }
        else {
            let { lsa, doesExist } = yield calcLogicSigAccount(client, tokenBridgeId, BigInt(chainId), contract);
            if (!doesExist) {
                return null;
            }
            let asset = yield decodeLocalState(client, tokenBridgeId, lsa.address());
            if (asset.length > 8) {
                const tmp = Buffer.from(asset.slice(0, 8));
                return tmp.readBigUInt64BE(0);
            }
            else
                return null;
        }
    });
}
export function getForeignAssetNear(provider, tokenAccount, chain, contract) {
    return __awaiter(this, void 0, void 0, function* () {
        const ret = yield callFunctionNear(provider, tokenAccount, "get_foreign_asset", {
            chain: coalesceChainId(chain),
            address: contract,
        });
        return ret !== "" ? ret : null;
    });
}
/**
 * Get qualified type of asset on Aptos given its origin info.
 * @param client Client used to transfer data to/from Aptos node
 * @param tokenBridgeAddress Address of token bridge
 * @param originChain Chain ID of chain asset is originally from
 * @param originAddress Asset address on origin chain
 * @returns Fully qualified type of asset on Aptos
 */
export function getForeignAssetAptos(client, tokenBridgeAddress, originChain, originAddress) {
    return __awaiter(this, void 0, void 0, function* () {
        const originChainId = coalesceChainId(originChain);
        const assetFullyQualifiedType = getAssetFullyQualifiedType(tokenBridgeAddress, originChainId, originAddress);
        if (!assetFullyQualifiedType) {
            return null;
        }
        try {
            // check if asset exists and throw if it doesn't
            yield client.getAccountResource(coalesceModuleAddress(assetFullyQualifiedType), `0x1::coin::CoinInfo<${assetFullyQualifiedType}>`);
            return assetFullyQualifiedType;
        }
        catch (e) {
            return null;
        }
    });
}
export function getForeignAssetSui(provider, tokenBridgeStateObjectId, originChain, originAddress) {
    return __awaiter(this, void 0, void 0, function* () {
        const originChainId = coalesceChainId(originChain);
        return getTokenCoinType(provider, tokenBridgeStateObjectId, originAddress, originChainId);
    });
}
