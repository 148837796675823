var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { LogicSigAccount } from "algosdk";
import { id } from "ethers/lib/utils";
import { hexToUint8Array } from "../utils";
import { encodeHex } from "./BigVarint";
export class TmplSig {
    constructor(algoClient) {
        this.algoClient = algoClient;
        this.sourceHash = "";
        this.bytecode = new Uint8Array();
    }
    compile(source) {
        return __awaiter(this, void 0, void 0, function* () {
            const hash = id(source);
            if (hash !== this.sourceHash) {
                const response = yield this.algoClient.compile(source).do();
                this.bytecode = new Uint8Array(Buffer.from(response.result, "base64"));
                this.sourceHash = hash;
            }
        });
    }
    /**
     * Populate data in the TEAL source and return the LogicSig object based on the resulting compiled bytecode.
     * @param data The data to populate fields with.
     * @notes emitterId must be prefixed with '0x'. appAddress must be decoded with algoSDK and prefixed with '0x'.
     * @returns A LogicSig object.
     */
    populate(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const byteString = [
                "0620010181",
                encodeHex(data.addrIdx),
                "4880",
                encodeHex(BigInt(data.emitterId.length / 2)),
                data.emitterId,
                "483110810612443119221244311881",
                encodeHex(data.appId),
                "1244312080",
                encodeHex(BigInt(data.appAddress.length / 2)),
                data.appAddress,
                "124431018100124431093203124431153203124422",
            ].join("");
            this.bytecode = hexToUint8Array(byteString);
            return new LogicSigAccount(this.bytecode);
        });
    }
}
