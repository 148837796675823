// Guardian set upgrade
export const upgradeGuardianSet = (coreBridgeAddress, vaa) => {
    if (!coreBridgeAddress)
        throw new Error("Need core bridge address.");
    return {
        function: `${coreBridgeAddress}::guardian_set_upgrade::submit_vaa_entry`,
        type_arguments: [],
        arguments: [vaa],
    };
};
// Init WH
export const initWormhole = (coreBridgeAddress, chainId, governanceChainId, governanceContract, initialGuardian) => {
    if (!coreBridgeAddress)
        throw new Error("Need core bridge address.");
    return {
        function: `${coreBridgeAddress}::wormhole::init`,
        type_arguments: [],
        arguments: [
            chainId,
            governanceChainId,
            governanceContract,
            initialGuardian,
        ],
    };
};
