var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Types } from "aptos";
import { Bridge__factory } from "../ethers-contracts";
import { getWrappedMeta } from "../solana/nftBridge";
/**
 * Returns whether or not an asset address on Ethereum is a wormhole wrapped asset
 * @param nftBridgeAddress
 * @param provider
 * @param assetAddress
 * @returns
 */
export function getIsWrappedAssetEth(nftBridgeAddress, provider, assetAddress) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!assetAddress)
            return false;
        const tokenBridge = Bridge__factory.connect(nftBridgeAddress, provider);
        return yield tokenBridge.isWrappedAsset(assetAddress);
    });
}
/**
 * Returns whether or not an asset on Solana is a wormhole wrapped asset
 * @param connection
 * @param nftBridgeAddress
 * @param mintAddress
 * @param [commitment]
 * @returns
 */
export function getIsWrappedAssetSolana(connection, nftBridgeAddress, mintAddress, commitment) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!mintAddress) {
            return false;
        }
        return getWrappedMeta(connection, nftBridgeAddress, mintAddress, commitment)
            .catch((_) => null)
            .then((meta) => meta != null);
    });
}
export const getIsWrappedAssetSol = getIsWrappedAssetSolana;
export function getIsWrappedAssetAptos(client, nftBridgeAddress, creatorAddress) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            yield client.getAccountResource(creatorAddress, `${nftBridgeAddress}::state::OriginInfo`);
            return true;
        }
        catch (e) {
            if ((e instanceof Types.ApiError || e.errorCode === "resource_not_found") &&
                e.status === 404) {
                return false;
            }
            throw e;
        }
    });
}
