/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { utils, Contract, ContractFactory } from "ethers";
const _abi = [
    {
        type: "function",
        name: "assetConversionBuffer",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
        ],
        outputs: [
            {
                name: "buffer",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "bufferDenominator",
                type: "uint16",
                internalType: "uint16",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "chainId",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "uint16",
                internalType: "uint16",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "coreRelayer",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "address",
                internalType: "address",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "deliverGasOverhead",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
        ],
        outputs: [
            {
                name: "",
                type: "uint256",
                internalType: "Gas",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "gasPrice",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
        ],
        outputs: [
            {
                name: "",
                type: "uint256",
                internalType: "GasPrice",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "isInitialized",
        inputs: [
            {
                name: "impl",
                type: "address",
                internalType: "address",
            },
        ],
        outputs: [
            {
                name: "",
                type: "bool",
                internalType: "bool",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "maximumBudget",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
        ],
        outputs: [
            {
                name: "",
                type: "uint256",
                internalType: "TargetNative",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "nativeCurrencyPrice",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
        ],
        outputs: [
            {
                name: "",
                type: "uint256",
                internalType: "WeiPrice",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "owner",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "address",
                internalType: "address",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "pendingOwner",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "address",
                internalType: "address",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "pricingWallet",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "address",
                internalType: "address",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "rewardAddress",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "address",
                internalType: "address payable",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "targetChainAddress",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
        ],
        outputs: [
            {
                name: "",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
        stateMutability: "view",
    },
];
const _bytecode = "0x608080604052346100165761036b908161001c8239f35b600080fdfe608060408181526004918236101561001657600080fd5b600092833560e01c9182633171aaec14610304575081633978748a146102d05781633e48e7731461029c5781634713de7a146102655781634798ed541461023c57816349cdb88c146102085781638cf57cb9146101df5781638da5cb5b146101b35781639a8a059214610192578163bb7c0a631461014e578163d60b347f1461010757508063e30c3978146100df5763e8fec1ce146100b457600080fd5b346100db57816003193601126100db5760035490516001600160a01b039091168152602090f35b5080fd5b50346100db57816003193601126100db5760015490516001600160a01b039091168152602090f35b90503461014a57602036600319011261014a5780356001600160a01b03811690819003610146578360ff92849260209652855220541690519015158152f35b8380fd5b8280fd5b839150346100db5760203660031901126100db57359061ffff918281168091036100db579083918152600a602052205490825191818116835260101c166020820152f35b5050346100db57816003193601126100db5761ffff60209254169051908152f35b5050346100db57816003193601126100db579054905160109190911c6001600160a01b03168152602090f35b5050346100db57816003193601126100db57600b5490516001600160a01b039091168152602090f35b90503461014a57602036600319011261014a573561ffff811680910361014a57828291602094526007845220549051908152f35b5050346100db57816003193601126100db5760025490516001600160a01b039091168152602090f35b90503461014a57602036600319011261014a573561ffff811680910361014a57818360019260209552600785522001549051908152f35b90503461014a57602036600319011261014a573561ffff811680910361014a57828291602094526008845220549051908152f35b90503461014a57602036600319011261014a573561ffff811680910361014a57828291602094526006845220549051908152f35b92915034610146576020366003190112610146573561ffff8116809103610146579260209381526009845220548152f3fea264697066735822122098c08bcbb76b80cb38f62626d689191ebce3e4ee7101d71671a06b5219adc5e364736f6c63430008130033";
const isSuperArgs = (xs) => xs.length > 1;
export class DeliveryProviderGetters__factory extends ContractFactory {
    constructor(...args) {
        if (isSuperArgs(args)) {
            super(...args);
        }
        else {
            super(_abi, _bytecode, args[0]);
        }
    }
    deploy(overrides) {
        return super.deploy(overrides || {});
    }
    getDeployTransaction(overrides) {
        return super.getDeployTransaction(overrides || {});
    }
    attach(address) {
        return super.attach(address);
    }
    connect(signer) {
        return super.connect(signer);
    }
    static createInterface() {
        return new utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    }
}
DeliveryProviderGetters__factory.bytecode = _bytecode;
DeliveryProviderGetters__factory.abi = _abi;
