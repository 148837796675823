/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Contract, utils } from "ethers";
const _abi = [
    {
        type: "function",
        name: "kevm",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "address",
                internalType: "contract KEVMCheatsBase",
            },
        ],
        stateMutability: "view",
    },
];
export class KEVMCheats__factory {
    static createInterface() {
        return new utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    }
}
KEVMCheats__factory.abi = _abi;
