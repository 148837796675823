import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { PublicKey, } from "@solana/web3.js";
import { TOKEN_METADATA_PROGRAM_ID, deriveTokenMetadataKey } from "../../utils";
import { getPostMessageAccounts } from "../../wormhole";
import { deriveAuthoritySignerKey, deriveCustodyKey, deriveCustodySignerKey, deriveNftBridgeConfigKey, } from "../accounts";
import { createReadOnlyNftBridgeProgramInterface } from "../program";
export function createTransferNativeInstruction(nftBridgeProgramId, wormholeProgramId, payer, message, from, mint, nonce, targetAddress, targetChain) {
    const methods = createReadOnlyNftBridgeProgramInterface(nftBridgeProgramId).methods.transferNative(nonce, Buffer.from(targetAddress), targetChain);
    // @ts-ignore
    return methods._ixFn(...methods._args, {
        accounts: getTransferNativeAccounts(nftBridgeProgramId, wormholeProgramId, payer, message, from, mint),
        signers: undefined,
        remainingAccounts: undefined,
        preInstructions: undefined,
        postInstructions: undefined,
    });
}
export function getTransferNativeAccounts(nftBridgeProgramId, wormholeProgramId, payer, message, from, mint) {
    const { bridge: wormholeBridge, message: wormholeMessage, emitter: wormholeEmitter, sequence: wormholeSequence, feeCollector: wormholeFeeCollector, clock, rent, systemProgram, } = getPostMessageAccounts(wormholeProgramId, payer, nftBridgeProgramId, message);
    return {
        payer: new PublicKey(payer),
        config: deriveNftBridgeConfigKey(nftBridgeProgramId),
        from: new PublicKey(from),
        mint: new PublicKey(mint),
        splMetadata: deriveTokenMetadataKey(mint),
        custody: deriveCustodyKey(nftBridgeProgramId, mint),
        authoritySigner: deriveAuthoritySignerKey(nftBridgeProgramId),
        custodySigner: deriveCustodySignerKey(nftBridgeProgramId),
        wormholeBridge,
        wormholeMessage,
        wormholeEmitter,
        wormholeSequence,
        wormholeFeeCollector,
        clock,
        rent,
        systemProgram,
        tokenProgram: TOKEN_PROGRAM_ID,
        splMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
        wormholeProgram: new PublicKey(wormholeProgramId),
    };
}
