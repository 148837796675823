/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { utils, Contract, ContractFactory } from "ethers";
const _abi = [
    {
        type: "error",
        name: "OutOfBounds",
        inputs: [
            {
                name: "offset",
                type: "uint256",
                internalType: "uint256",
            },
            {
                name: "length",
                type: "uint256",
                internalType: "uint256",
            },
        ],
    },
];
const _bytecode = "0x60808060405234601757603a9081601d823930815050f35b600080fdfe600080fdfea26469706673582212201c86e6e3c269ffacd091eefebddca19c4eeb4b3b87921e8e3198c8fd10f575f564736f6c63430008130033";
const isSuperArgs = (xs) => xs.length > 1;
export class BytesParsing__factory extends ContractFactory {
    constructor(...args) {
        if (isSuperArgs(args)) {
            super(...args);
        }
        else {
            super(_abi, _bytecode, args[0]);
        }
    }
    deploy(overrides) {
        return super.deploy(overrides || {});
    }
    getDeployTransaction(overrides) {
        return super.getDeployTransaction(overrides || {});
    }
    attach(address) {
        return super.attach(address);
    }
    connect(signer) {
        return super.connect(signer);
    }
    static createInterface() {
        return new utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    }
}
BytesParsing__factory.bytecode = _bytecode;
BytesParsing__factory.abi = _abi;
