var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
/* eslint-disable */
import Long from "long";
import { grpc } from "@improbable-eng/grpc-web";
import _m0 from "protobufjs/minimal";
import { BrowserHeaders } from "browser-headers";
import { SignedBatchVAAWithQuorum, Heartbeat } from "../../gossip/v1/gossip";
export var protobufPackage = "publicrpc.v1";
export var ChainID;
(function (ChainID) {
    ChainID[ChainID["CHAIN_ID_UNSPECIFIED"] = 0] = "CHAIN_ID_UNSPECIFIED";
    ChainID[ChainID["CHAIN_ID_SOLANA"] = 1] = "CHAIN_ID_SOLANA";
    ChainID[ChainID["CHAIN_ID_ETHEREUM"] = 2] = "CHAIN_ID_ETHEREUM";
    ChainID[ChainID["CHAIN_ID_TERRA"] = 3] = "CHAIN_ID_TERRA";
    ChainID[ChainID["CHAIN_ID_BSC"] = 4] = "CHAIN_ID_BSC";
    ChainID[ChainID["CHAIN_ID_POLYGON"] = 5] = "CHAIN_ID_POLYGON";
    ChainID[ChainID["CHAIN_ID_AVALANCHE"] = 6] = "CHAIN_ID_AVALANCHE";
    ChainID[ChainID["CHAIN_ID_OASIS"] = 7] = "CHAIN_ID_OASIS";
    ChainID[ChainID["CHAIN_ID_ALGORAND"] = 8] = "CHAIN_ID_ALGORAND";
    ChainID[ChainID["CHAIN_ID_AURORA"] = 9] = "CHAIN_ID_AURORA";
    ChainID[ChainID["CHAIN_ID_FANTOM"] = 10] = "CHAIN_ID_FANTOM";
    ChainID[ChainID["CHAIN_ID_KARURA"] = 11] = "CHAIN_ID_KARURA";
    ChainID[ChainID["CHAIN_ID_ACALA"] = 12] = "CHAIN_ID_ACALA";
    ChainID[ChainID["CHAIN_ID_KLAYTN"] = 13] = "CHAIN_ID_KLAYTN";
    ChainID[ChainID["CHAIN_ID_CELO"] = 14] = "CHAIN_ID_CELO";
    ChainID[ChainID["CHAIN_ID_NEAR"] = 15] = "CHAIN_ID_NEAR";
    ChainID[ChainID["CHAIN_ID_MOONBEAM"] = 16] = "CHAIN_ID_MOONBEAM";
    ChainID[ChainID["CHAIN_ID_NEON"] = 17] = "CHAIN_ID_NEON";
    ChainID[ChainID["CHAIN_ID_TERRA2"] = 18] = "CHAIN_ID_TERRA2";
    ChainID[ChainID["CHAIN_ID_INJECTIVE"] = 19] = "CHAIN_ID_INJECTIVE";
    ChainID[ChainID["CHAIN_ID_OSMOSIS"] = 20] = "CHAIN_ID_OSMOSIS";
    ChainID[ChainID["CHAIN_ID_SUI"] = 21] = "CHAIN_ID_SUI";
    ChainID[ChainID["CHAIN_ID_APTOS"] = 22] = "CHAIN_ID_APTOS";
    ChainID[ChainID["CHAIN_ID_ARBITRUM"] = 23] = "CHAIN_ID_ARBITRUM";
    ChainID[ChainID["CHAIN_ID_OPTIMISM"] = 24] = "CHAIN_ID_OPTIMISM";
    ChainID[ChainID["CHAIN_ID_GNOSIS"] = 25] = "CHAIN_ID_GNOSIS";
    ChainID[ChainID["CHAIN_ID_PYTHNET"] = 26] = "CHAIN_ID_PYTHNET";
    ChainID[ChainID["CHAIN_ID_XPLA"] = 28] = "CHAIN_ID_XPLA";
    ChainID[ChainID["CHAIN_ID_BTC"] = 29] = "CHAIN_ID_BTC";
    ChainID[ChainID["CHAIN_ID_BASE"] = 30] = "CHAIN_ID_BASE";
    ChainID[ChainID["CHAIN_ID_SEI"] = 32] = "CHAIN_ID_SEI";
    /** CHAIN_ID_SCROLL - CHAIN_ID_ROOTSTOCK = 33; */
    ChainID[ChainID["CHAIN_ID_SCROLL"] = 34] = "CHAIN_ID_SCROLL";
    ChainID[ChainID["CHAIN_ID_SEPOLIA"] = 10002] = "CHAIN_ID_SEPOLIA";
    ChainID[ChainID["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(ChainID || (ChainID = {}));
export function chainIDFromJSON(object) {
    switch (object) {
        case 0:
        case "CHAIN_ID_UNSPECIFIED":
            return ChainID.CHAIN_ID_UNSPECIFIED;
        case 1:
        case "CHAIN_ID_SOLANA":
            return ChainID.CHAIN_ID_SOLANA;
        case 2:
        case "CHAIN_ID_ETHEREUM":
            return ChainID.CHAIN_ID_ETHEREUM;
        case 3:
        case "CHAIN_ID_TERRA":
            return ChainID.CHAIN_ID_TERRA;
        case 4:
        case "CHAIN_ID_BSC":
            return ChainID.CHAIN_ID_BSC;
        case 5:
        case "CHAIN_ID_POLYGON":
            return ChainID.CHAIN_ID_POLYGON;
        case 6:
        case "CHAIN_ID_AVALANCHE":
            return ChainID.CHAIN_ID_AVALANCHE;
        case 7:
        case "CHAIN_ID_OASIS":
            return ChainID.CHAIN_ID_OASIS;
        case 8:
        case "CHAIN_ID_ALGORAND":
            return ChainID.CHAIN_ID_ALGORAND;
        case 9:
        case "CHAIN_ID_AURORA":
            return ChainID.CHAIN_ID_AURORA;
        case 10:
        case "CHAIN_ID_FANTOM":
            return ChainID.CHAIN_ID_FANTOM;
        case 11:
        case "CHAIN_ID_KARURA":
            return ChainID.CHAIN_ID_KARURA;
        case 12:
        case "CHAIN_ID_ACALA":
            return ChainID.CHAIN_ID_ACALA;
        case 13:
        case "CHAIN_ID_KLAYTN":
            return ChainID.CHAIN_ID_KLAYTN;
        case 14:
        case "CHAIN_ID_CELO":
            return ChainID.CHAIN_ID_CELO;
        case 15:
        case "CHAIN_ID_NEAR":
            return ChainID.CHAIN_ID_NEAR;
        case 16:
        case "CHAIN_ID_MOONBEAM":
            return ChainID.CHAIN_ID_MOONBEAM;
        case 17:
        case "CHAIN_ID_NEON":
            return ChainID.CHAIN_ID_NEON;
        case 18:
        case "CHAIN_ID_TERRA2":
            return ChainID.CHAIN_ID_TERRA2;
        case 19:
        case "CHAIN_ID_INJECTIVE":
            return ChainID.CHAIN_ID_INJECTIVE;
        case 20:
        case "CHAIN_ID_OSMOSIS":
            return ChainID.CHAIN_ID_OSMOSIS;
        case 21:
        case "CHAIN_ID_SUI":
            return ChainID.CHAIN_ID_SUI;
        case 22:
        case "CHAIN_ID_APTOS":
            return ChainID.CHAIN_ID_APTOS;
        case 23:
        case "CHAIN_ID_ARBITRUM":
            return ChainID.CHAIN_ID_ARBITRUM;
        case 24:
        case "CHAIN_ID_OPTIMISM":
            return ChainID.CHAIN_ID_OPTIMISM;
        case 25:
        case "CHAIN_ID_GNOSIS":
            return ChainID.CHAIN_ID_GNOSIS;
        case 26:
        case "CHAIN_ID_PYTHNET":
            return ChainID.CHAIN_ID_PYTHNET;
        case 28:
        case "CHAIN_ID_XPLA":
            return ChainID.CHAIN_ID_XPLA;
        case 29:
        case "CHAIN_ID_BTC":
            return ChainID.CHAIN_ID_BTC;
        case 30:
        case "CHAIN_ID_BASE":
            return ChainID.CHAIN_ID_BASE;
        case 32:
        case "CHAIN_ID_SEI":
            return ChainID.CHAIN_ID_SEI;
        case 34:
        case "CHAIN_ID_SCROLL":
            return ChainID.CHAIN_ID_SCROLL;
        case 10002:
        case "CHAIN_ID_SEPOLIA":
            return ChainID.CHAIN_ID_SEPOLIA;
        case -1:
        case "UNRECOGNIZED":
        default:
            return ChainID.UNRECOGNIZED;
    }
}
export function chainIDToJSON(object) {
    switch (object) {
        case ChainID.CHAIN_ID_UNSPECIFIED:
            return "CHAIN_ID_UNSPECIFIED";
        case ChainID.CHAIN_ID_SOLANA:
            return "CHAIN_ID_SOLANA";
        case ChainID.CHAIN_ID_ETHEREUM:
            return "CHAIN_ID_ETHEREUM";
        case ChainID.CHAIN_ID_TERRA:
            return "CHAIN_ID_TERRA";
        case ChainID.CHAIN_ID_BSC:
            return "CHAIN_ID_BSC";
        case ChainID.CHAIN_ID_POLYGON:
            return "CHAIN_ID_POLYGON";
        case ChainID.CHAIN_ID_AVALANCHE:
            return "CHAIN_ID_AVALANCHE";
        case ChainID.CHAIN_ID_OASIS:
            return "CHAIN_ID_OASIS";
        case ChainID.CHAIN_ID_ALGORAND:
            return "CHAIN_ID_ALGORAND";
        case ChainID.CHAIN_ID_AURORA:
            return "CHAIN_ID_AURORA";
        case ChainID.CHAIN_ID_FANTOM:
            return "CHAIN_ID_FANTOM";
        case ChainID.CHAIN_ID_KARURA:
            return "CHAIN_ID_KARURA";
        case ChainID.CHAIN_ID_ACALA:
            return "CHAIN_ID_ACALA";
        case ChainID.CHAIN_ID_KLAYTN:
            return "CHAIN_ID_KLAYTN";
        case ChainID.CHAIN_ID_CELO:
            return "CHAIN_ID_CELO";
        case ChainID.CHAIN_ID_NEAR:
            return "CHAIN_ID_NEAR";
        case ChainID.CHAIN_ID_MOONBEAM:
            return "CHAIN_ID_MOONBEAM";
        case ChainID.CHAIN_ID_NEON:
            return "CHAIN_ID_NEON";
        case ChainID.CHAIN_ID_TERRA2:
            return "CHAIN_ID_TERRA2";
        case ChainID.CHAIN_ID_INJECTIVE:
            return "CHAIN_ID_INJECTIVE";
        case ChainID.CHAIN_ID_OSMOSIS:
            return "CHAIN_ID_OSMOSIS";
        case ChainID.CHAIN_ID_SUI:
            return "CHAIN_ID_SUI";
        case ChainID.CHAIN_ID_APTOS:
            return "CHAIN_ID_APTOS";
        case ChainID.CHAIN_ID_ARBITRUM:
            return "CHAIN_ID_ARBITRUM";
        case ChainID.CHAIN_ID_OPTIMISM:
            return "CHAIN_ID_OPTIMISM";
        case ChainID.CHAIN_ID_GNOSIS:
            return "CHAIN_ID_GNOSIS";
        case ChainID.CHAIN_ID_PYTHNET:
            return "CHAIN_ID_PYTHNET";
        case ChainID.CHAIN_ID_XPLA:
            return "CHAIN_ID_XPLA";
        case ChainID.CHAIN_ID_BTC:
            return "CHAIN_ID_BTC";
        case ChainID.CHAIN_ID_BASE:
            return "CHAIN_ID_BASE";
        case ChainID.CHAIN_ID_SEI:
            return "CHAIN_ID_SEI";
        case ChainID.CHAIN_ID_SCROLL:
            return "CHAIN_ID_SCROLL";
        case ChainID.CHAIN_ID_SEPOLIA:
            return "CHAIN_ID_SEPOLIA";
        default:
            return "UNKNOWN";
    }
}
var baseMessageID = {
    emitterChain: 0,
    emitterAddress: "",
    sequence: "0",
};
export var MessageID = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.emitterChain !== 0) {
            writer.uint32(8).int32(message.emitterChain);
        }
        if (message.emitterAddress !== "") {
            writer.uint32(18).string(message.emitterAddress);
        }
        if (message.sequence !== "0") {
            writer.uint32(24).uint64(message.sequence);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseMessageID);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.emitterChain = reader.int32();
                    break;
                case 2:
                    message.emitterAddress = reader.string();
                    break;
                case 3:
                    message.sequence = longToString(reader.uint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseMessageID);
        if (object.emitterChain !== undefined && object.emitterChain !== null) {
            message.emitterChain = chainIDFromJSON(object.emitterChain);
        }
        else {
            message.emitterChain = 0;
        }
        if (object.emitterAddress !== undefined && object.emitterAddress !== null) {
            message.emitterAddress = String(object.emitterAddress);
        }
        else {
            message.emitterAddress = "";
        }
        if (object.sequence !== undefined && object.sequence !== null) {
            message.sequence = String(object.sequence);
        }
        else {
            message.sequence = "0";
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.emitterChain !== undefined &&
            (obj.emitterChain = chainIDToJSON(message.emitterChain));
        message.emitterAddress !== undefined &&
            (obj.emitterAddress = message.emitterAddress);
        message.sequence !== undefined && (obj.sequence = message.sequence);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseMessageID);
        if (object.emitterChain !== undefined && object.emitterChain !== null) {
            message.emitterChain = object.emitterChain;
        }
        else {
            message.emitterChain = 0;
        }
        if (object.emitterAddress !== undefined && object.emitterAddress !== null) {
            message.emitterAddress = object.emitterAddress;
        }
        else {
            message.emitterAddress = "";
        }
        if (object.sequence !== undefined && object.sequence !== null) {
            message.sequence = object.sequence;
        }
        else {
            message.sequence = "0";
        }
        return message;
    },
};
var baseBatchID = { emitterChain: 0, nonce: 0 };
export var BatchID = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.emitterChain !== 0) {
            writer.uint32(8).int32(message.emitterChain);
        }
        if (message.txId.length !== 0) {
            writer.uint32(18).bytes(message.txId);
        }
        if (message.nonce !== 0) {
            writer.uint32(24).uint32(message.nonce);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseBatchID);
        message.txId = new Uint8Array();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.emitterChain = reader.int32();
                    break;
                case 2:
                    message.txId = reader.bytes();
                    break;
                case 3:
                    message.nonce = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseBatchID);
        message.txId = new Uint8Array();
        if (object.emitterChain !== undefined && object.emitterChain !== null) {
            message.emitterChain = chainIDFromJSON(object.emitterChain);
        }
        else {
            message.emitterChain = 0;
        }
        if (object.txId !== undefined && object.txId !== null) {
            message.txId = bytesFromBase64(object.txId);
        }
        if (object.nonce !== undefined && object.nonce !== null) {
            message.nonce = Number(object.nonce);
        }
        else {
            message.nonce = 0;
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.emitterChain !== undefined &&
            (obj.emitterChain = chainIDToJSON(message.emitterChain));
        message.txId !== undefined &&
            (obj.txId = base64FromBytes(message.txId !== undefined ? message.txId : new Uint8Array()));
        message.nonce !== undefined && (obj.nonce = message.nonce);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseBatchID);
        if (object.emitterChain !== undefined && object.emitterChain !== null) {
            message.emitterChain = object.emitterChain;
        }
        else {
            message.emitterChain = 0;
        }
        if (object.txId !== undefined && object.txId !== null) {
            message.txId = object.txId;
        }
        else {
            message.txId = new Uint8Array();
        }
        if (object.nonce !== undefined && object.nonce !== null) {
            message.nonce = object.nonce;
        }
        else {
            message.nonce = 0;
        }
        return message;
    },
};
var baseGetSignedVAARequest = {};
export var GetSignedVAARequest = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.messageId !== undefined) {
            MessageID.encode(message.messageId, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseGetSignedVAARequest);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.messageId = MessageID.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseGetSignedVAARequest);
        if (object.messageId !== undefined && object.messageId !== null) {
            message.messageId = MessageID.fromJSON(object.messageId);
        }
        else {
            message.messageId = undefined;
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.messageId !== undefined &&
            (obj.messageId = message.messageId
                ? MessageID.toJSON(message.messageId)
                : undefined);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseGetSignedVAARequest);
        if (object.messageId !== undefined && object.messageId !== null) {
            message.messageId = MessageID.fromPartial(object.messageId);
        }
        else {
            message.messageId = undefined;
        }
        return message;
    },
};
var baseGetSignedVAAResponse = {};
export var GetSignedVAAResponse = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.vaaBytes.length !== 0) {
            writer.uint32(10).bytes(message.vaaBytes);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseGetSignedVAAResponse);
        message.vaaBytes = new Uint8Array();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.vaaBytes = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseGetSignedVAAResponse);
        message.vaaBytes = new Uint8Array();
        if (object.vaaBytes !== undefined && object.vaaBytes !== null) {
            message.vaaBytes = bytesFromBase64(object.vaaBytes);
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.vaaBytes !== undefined &&
            (obj.vaaBytes = base64FromBytes(message.vaaBytes !== undefined ? message.vaaBytes : new Uint8Array()));
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseGetSignedVAAResponse);
        if (object.vaaBytes !== undefined && object.vaaBytes !== null) {
            message.vaaBytes = object.vaaBytes;
        }
        else {
            message.vaaBytes = new Uint8Array();
        }
        return message;
    },
};
var baseGetSignedBatchVAARequest = {};
export var GetSignedBatchVAARequest = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.batchId !== undefined) {
            BatchID.encode(message.batchId, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseGetSignedBatchVAARequest);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.batchId = BatchID.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseGetSignedBatchVAARequest);
        if (object.batchId !== undefined && object.batchId !== null) {
            message.batchId = BatchID.fromJSON(object.batchId);
        }
        else {
            message.batchId = undefined;
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.batchId !== undefined &&
            (obj.batchId = message.batchId
                ? BatchID.toJSON(message.batchId)
                : undefined);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseGetSignedBatchVAARequest);
        if (object.batchId !== undefined && object.batchId !== null) {
            message.batchId = BatchID.fromPartial(object.batchId);
        }
        else {
            message.batchId = undefined;
        }
        return message;
    },
};
var baseGetSignedBatchVAAResponse = {};
export var GetSignedBatchVAAResponse = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.signedBatchVaa !== undefined) {
            SignedBatchVAAWithQuorum.encode(message.signedBatchVaa, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseGetSignedBatchVAAResponse);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.signedBatchVaa = SignedBatchVAAWithQuorum.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseGetSignedBatchVAAResponse);
        if (object.signedBatchVaa !== undefined && object.signedBatchVaa !== null) {
            message.signedBatchVaa = SignedBatchVAAWithQuorum.fromJSON(object.signedBatchVaa);
        }
        else {
            message.signedBatchVaa = undefined;
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.signedBatchVaa !== undefined &&
            (obj.signedBatchVaa = message.signedBatchVaa
                ? SignedBatchVAAWithQuorum.toJSON(message.signedBatchVaa)
                : undefined);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseGetSignedBatchVAAResponse);
        if (object.signedBatchVaa !== undefined && object.signedBatchVaa !== null) {
            message.signedBatchVaa = SignedBatchVAAWithQuorum.fromPartial(object.signedBatchVaa);
        }
        else {
            message.signedBatchVaa = undefined;
        }
        return message;
    },
};
var baseGetLastHeartbeatsRequest = {};
export var GetLastHeartbeatsRequest = {
    encode: function (_, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseGetLastHeartbeatsRequest);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (_) {
        var message = __assign({}, baseGetLastHeartbeatsRequest);
        return message;
    },
    toJSON: function (_) {
        var obj = {};
        return obj;
    },
    fromPartial: function (_) {
        var message = __assign({}, baseGetLastHeartbeatsRequest);
        return message;
    },
};
var baseGetLastHeartbeatsResponse = {};
export var GetLastHeartbeatsResponse = {
    encode: function (message, writer) {
        var e_1, _a;
        if (writer === void 0) { writer = _m0.Writer.create(); }
        try {
            for (var _b = __values(message.entries), _c = _b.next(); !_c.done; _c = _b.next()) {
                var v = _c.value;
                GetLastHeartbeatsResponse_Entry.encode(v, writer.uint32(10).fork()).ldelim();
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseGetLastHeartbeatsResponse);
        message.entries = [];
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.entries.push(GetLastHeartbeatsResponse_Entry.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var e_2, _a;
        var message = __assign({}, baseGetLastHeartbeatsResponse);
        message.entries = [];
        if (object.entries !== undefined && object.entries !== null) {
            try {
                for (var _b = __values(object.entries), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var e = _c.value;
                    message.entries.push(GetLastHeartbeatsResponse_Entry.fromJSON(e));
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        if (message.entries) {
            obj.entries = message.entries.map(function (e) {
                return e ? GetLastHeartbeatsResponse_Entry.toJSON(e) : undefined;
            });
        }
        else {
            obj.entries = [];
        }
        return obj;
    },
    fromPartial: function (object) {
        var e_3, _a;
        var message = __assign({}, baseGetLastHeartbeatsResponse);
        message.entries = [];
        if (object.entries !== undefined && object.entries !== null) {
            try {
                for (var _b = __values(object.entries), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var e = _c.value;
                    message.entries.push(GetLastHeartbeatsResponse_Entry.fromPartial(e));
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_3) throw e_3.error; }
            }
        }
        return message;
    },
};
var baseGetLastHeartbeatsResponse_Entry = {
    verifiedGuardianAddr: "",
    p2pNodeAddr: "",
};
export var GetLastHeartbeatsResponse_Entry = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.verifiedGuardianAddr !== "") {
            writer.uint32(10).string(message.verifiedGuardianAddr);
        }
        if (message.p2pNodeAddr !== "") {
            writer.uint32(18).string(message.p2pNodeAddr);
        }
        if (message.rawHeartbeat !== undefined) {
            Heartbeat.encode(message.rawHeartbeat, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseGetLastHeartbeatsResponse_Entry);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.verifiedGuardianAddr = reader.string();
                    break;
                case 2:
                    message.p2pNodeAddr = reader.string();
                    break;
                case 3:
                    message.rawHeartbeat = Heartbeat.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseGetLastHeartbeatsResponse_Entry);
        if (object.verifiedGuardianAddr !== undefined &&
            object.verifiedGuardianAddr !== null) {
            message.verifiedGuardianAddr = String(object.verifiedGuardianAddr);
        }
        else {
            message.verifiedGuardianAddr = "";
        }
        if (object.p2pNodeAddr !== undefined && object.p2pNodeAddr !== null) {
            message.p2pNodeAddr = String(object.p2pNodeAddr);
        }
        else {
            message.p2pNodeAddr = "";
        }
        if (object.rawHeartbeat !== undefined && object.rawHeartbeat !== null) {
            message.rawHeartbeat = Heartbeat.fromJSON(object.rawHeartbeat);
        }
        else {
            message.rawHeartbeat = undefined;
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.verifiedGuardianAddr !== undefined &&
            (obj.verifiedGuardianAddr = message.verifiedGuardianAddr);
        message.p2pNodeAddr !== undefined &&
            (obj.p2pNodeAddr = message.p2pNodeAddr);
        message.rawHeartbeat !== undefined &&
            (obj.rawHeartbeat = message.rawHeartbeat
                ? Heartbeat.toJSON(message.rawHeartbeat)
                : undefined);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseGetLastHeartbeatsResponse_Entry);
        if (object.verifiedGuardianAddr !== undefined &&
            object.verifiedGuardianAddr !== null) {
            message.verifiedGuardianAddr = object.verifiedGuardianAddr;
        }
        else {
            message.verifiedGuardianAddr = "";
        }
        if (object.p2pNodeAddr !== undefined && object.p2pNodeAddr !== null) {
            message.p2pNodeAddr = object.p2pNodeAddr;
        }
        else {
            message.p2pNodeAddr = "";
        }
        if (object.rawHeartbeat !== undefined && object.rawHeartbeat !== null) {
            message.rawHeartbeat = Heartbeat.fromPartial(object.rawHeartbeat);
        }
        else {
            message.rawHeartbeat = undefined;
        }
        return message;
    },
};
var baseGetCurrentGuardianSetRequest = {};
export var GetCurrentGuardianSetRequest = {
    encode: function (_, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseGetCurrentGuardianSetRequest);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (_) {
        var message = __assign({}, baseGetCurrentGuardianSetRequest);
        return message;
    },
    toJSON: function (_) {
        var obj = {};
        return obj;
    },
    fromPartial: function (_) {
        var message = __assign({}, baseGetCurrentGuardianSetRequest);
        return message;
    },
};
var baseGetCurrentGuardianSetResponse = {};
export var GetCurrentGuardianSetResponse = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.guardianSet !== undefined) {
            GuardianSet.encode(message.guardianSet, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseGetCurrentGuardianSetResponse);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.guardianSet = GuardianSet.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseGetCurrentGuardianSetResponse);
        if (object.guardianSet !== undefined && object.guardianSet !== null) {
            message.guardianSet = GuardianSet.fromJSON(object.guardianSet);
        }
        else {
            message.guardianSet = undefined;
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.guardianSet !== undefined &&
            (obj.guardianSet = message.guardianSet
                ? GuardianSet.toJSON(message.guardianSet)
                : undefined);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseGetCurrentGuardianSetResponse);
        if (object.guardianSet !== undefined && object.guardianSet !== null) {
            message.guardianSet = GuardianSet.fromPartial(object.guardianSet);
        }
        else {
            message.guardianSet = undefined;
        }
        return message;
    },
};
var baseGuardianSet = { index: 0, addresses: "" };
export var GuardianSet = {
    encode: function (message, writer) {
        var e_4, _a;
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.index !== 0) {
            writer.uint32(8).uint32(message.index);
        }
        try {
            for (var _b = __values(message.addresses), _c = _b.next(); !_c.done; _c = _b.next()) {
                var v = _c.value;
                writer.uint32(18).string(v);
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_4) throw e_4.error; }
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseGuardianSet);
        message.addresses = [];
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.index = reader.uint32();
                    break;
                case 2:
                    message.addresses.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var e_5, _a;
        var message = __assign({}, baseGuardianSet);
        message.addresses = [];
        if (object.index !== undefined && object.index !== null) {
            message.index = Number(object.index);
        }
        else {
            message.index = 0;
        }
        if (object.addresses !== undefined && object.addresses !== null) {
            try {
                for (var _b = __values(object.addresses), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var e = _c.value;
                    message.addresses.push(String(e));
                }
            }
            catch (e_5_1) { e_5 = { error: e_5_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_5) throw e_5.error; }
            }
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.index !== undefined && (obj.index = message.index);
        if (message.addresses) {
            obj.addresses = message.addresses.map(function (e) { return e; });
        }
        else {
            obj.addresses = [];
        }
        return obj;
    },
    fromPartial: function (object) {
        var e_6, _a;
        var message = __assign({}, baseGuardianSet);
        message.addresses = [];
        if (object.index !== undefined && object.index !== null) {
            message.index = object.index;
        }
        else {
            message.index = 0;
        }
        if (object.addresses !== undefined && object.addresses !== null) {
            try {
                for (var _b = __values(object.addresses), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var e = _c.value;
                    message.addresses.push(e);
                }
            }
            catch (e_6_1) { e_6 = { error: e_6_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_6) throw e_6.error; }
            }
        }
        return message;
    },
};
var baseGovernorGetAvailableNotionalByChainRequest = {};
export var GovernorGetAvailableNotionalByChainRequest = {
    encode: function (_, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseGovernorGetAvailableNotionalByChainRequest);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (_) {
        var message = __assign({}, baseGovernorGetAvailableNotionalByChainRequest);
        return message;
    },
    toJSON: function (_) {
        var obj = {};
        return obj;
    },
    fromPartial: function (_) {
        var message = __assign({}, baseGovernorGetAvailableNotionalByChainRequest);
        return message;
    },
};
var baseGovernorGetAvailableNotionalByChainResponse = {};
export var GovernorGetAvailableNotionalByChainResponse = {
    encode: function (message, writer) {
        var e_7, _a;
        if (writer === void 0) { writer = _m0.Writer.create(); }
        try {
            for (var _b = __values(message.entries), _c = _b.next(); !_c.done; _c = _b.next()) {
                var v = _c.value;
                GovernorGetAvailableNotionalByChainResponse_Entry.encode(v, writer.uint32(10).fork()).ldelim();
            }
        }
        catch (e_7_1) { e_7 = { error: e_7_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_7) throw e_7.error; }
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseGovernorGetAvailableNotionalByChainResponse);
        message.entries = [];
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.entries.push(GovernorGetAvailableNotionalByChainResponse_Entry.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var e_8, _a;
        var message = __assign({}, baseGovernorGetAvailableNotionalByChainResponse);
        message.entries = [];
        if (object.entries !== undefined && object.entries !== null) {
            try {
                for (var _b = __values(object.entries), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var e = _c.value;
                    message.entries.push(GovernorGetAvailableNotionalByChainResponse_Entry.fromJSON(e));
                }
            }
            catch (e_8_1) { e_8 = { error: e_8_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_8) throw e_8.error; }
            }
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        if (message.entries) {
            obj.entries = message.entries.map(function (e) {
                return e
                    ? GovernorGetAvailableNotionalByChainResponse_Entry.toJSON(e)
                    : undefined;
            });
        }
        else {
            obj.entries = [];
        }
        return obj;
    },
    fromPartial: function (object) {
        var e_9, _a;
        var message = __assign({}, baseGovernorGetAvailableNotionalByChainResponse);
        message.entries = [];
        if (object.entries !== undefined && object.entries !== null) {
            try {
                for (var _b = __values(object.entries), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var e = _c.value;
                    message.entries.push(GovernorGetAvailableNotionalByChainResponse_Entry.fromPartial(e));
                }
            }
            catch (e_9_1) { e_9 = { error: e_9_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_9) throw e_9.error; }
            }
        }
        return message;
    },
};
var baseGovernorGetAvailableNotionalByChainResponse_Entry = {
    chainId: 0,
    remainingAvailableNotional: "0",
    notionalLimit: "0",
    bigTransactionSize: "0",
};
export var GovernorGetAvailableNotionalByChainResponse_Entry = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.chainId !== 0) {
            writer.uint32(8).uint32(message.chainId);
        }
        if (message.remainingAvailableNotional !== "0") {
            writer.uint32(16).uint64(message.remainingAvailableNotional);
        }
        if (message.notionalLimit !== "0") {
            writer.uint32(24).uint64(message.notionalLimit);
        }
        if (message.bigTransactionSize !== "0") {
            writer.uint32(32).uint64(message.bigTransactionSize);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseGovernorGetAvailableNotionalByChainResponse_Entry);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.chainId = reader.uint32();
                    break;
                case 2:
                    message.remainingAvailableNotional = longToString(reader.uint64());
                    break;
                case 3:
                    message.notionalLimit = longToString(reader.uint64());
                    break;
                case 4:
                    message.bigTransactionSize = longToString(reader.uint64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseGovernorGetAvailableNotionalByChainResponse_Entry);
        if (object.chainId !== undefined && object.chainId !== null) {
            message.chainId = Number(object.chainId);
        }
        else {
            message.chainId = 0;
        }
        if (object.remainingAvailableNotional !== undefined &&
            object.remainingAvailableNotional !== null) {
            message.remainingAvailableNotional = String(object.remainingAvailableNotional);
        }
        else {
            message.remainingAvailableNotional = "0";
        }
        if (object.notionalLimit !== undefined && object.notionalLimit !== null) {
            message.notionalLimit = String(object.notionalLimit);
        }
        else {
            message.notionalLimit = "0";
        }
        if (object.bigTransactionSize !== undefined &&
            object.bigTransactionSize !== null) {
            message.bigTransactionSize = String(object.bigTransactionSize);
        }
        else {
            message.bigTransactionSize = "0";
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.chainId !== undefined && (obj.chainId = message.chainId);
        message.remainingAvailableNotional !== undefined &&
            (obj.remainingAvailableNotional = message.remainingAvailableNotional);
        message.notionalLimit !== undefined &&
            (obj.notionalLimit = message.notionalLimit);
        message.bigTransactionSize !== undefined &&
            (obj.bigTransactionSize = message.bigTransactionSize);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseGovernorGetAvailableNotionalByChainResponse_Entry);
        if (object.chainId !== undefined && object.chainId !== null) {
            message.chainId = object.chainId;
        }
        else {
            message.chainId = 0;
        }
        if (object.remainingAvailableNotional !== undefined &&
            object.remainingAvailableNotional !== null) {
            message.remainingAvailableNotional = object.remainingAvailableNotional;
        }
        else {
            message.remainingAvailableNotional = "0";
        }
        if (object.notionalLimit !== undefined && object.notionalLimit !== null) {
            message.notionalLimit = object.notionalLimit;
        }
        else {
            message.notionalLimit = "0";
        }
        if (object.bigTransactionSize !== undefined &&
            object.bigTransactionSize !== null) {
            message.bigTransactionSize = object.bigTransactionSize;
        }
        else {
            message.bigTransactionSize = "0";
        }
        return message;
    },
};
var baseGovernorGetEnqueuedVAAsRequest = {};
export var GovernorGetEnqueuedVAAsRequest = {
    encode: function (_, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseGovernorGetEnqueuedVAAsRequest);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (_) {
        var message = __assign({}, baseGovernorGetEnqueuedVAAsRequest);
        return message;
    },
    toJSON: function (_) {
        var obj = {};
        return obj;
    },
    fromPartial: function (_) {
        var message = __assign({}, baseGovernorGetEnqueuedVAAsRequest);
        return message;
    },
};
var baseGovernorGetEnqueuedVAAsResponse = {};
export var GovernorGetEnqueuedVAAsResponse = {
    encode: function (message, writer) {
        var e_10, _a;
        if (writer === void 0) { writer = _m0.Writer.create(); }
        try {
            for (var _b = __values(message.entries), _c = _b.next(); !_c.done; _c = _b.next()) {
                var v = _c.value;
                GovernorGetEnqueuedVAAsResponse_Entry.encode(v, writer.uint32(10).fork()).ldelim();
            }
        }
        catch (e_10_1) { e_10 = { error: e_10_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_10) throw e_10.error; }
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseGovernorGetEnqueuedVAAsResponse);
        message.entries = [];
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.entries.push(GovernorGetEnqueuedVAAsResponse_Entry.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var e_11, _a;
        var message = __assign({}, baseGovernorGetEnqueuedVAAsResponse);
        message.entries = [];
        if (object.entries !== undefined && object.entries !== null) {
            try {
                for (var _b = __values(object.entries), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var e = _c.value;
                    message.entries.push(GovernorGetEnqueuedVAAsResponse_Entry.fromJSON(e));
                }
            }
            catch (e_11_1) { e_11 = { error: e_11_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_11) throw e_11.error; }
            }
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        if (message.entries) {
            obj.entries = message.entries.map(function (e) {
                return e ? GovernorGetEnqueuedVAAsResponse_Entry.toJSON(e) : undefined;
            });
        }
        else {
            obj.entries = [];
        }
        return obj;
    },
    fromPartial: function (object) {
        var e_12, _a;
        var message = __assign({}, baseGovernorGetEnqueuedVAAsResponse);
        message.entries = [];
        if (object.entries !== undefined && object.entries !== null) {
            try {
                for (var _b = __values(object.entries), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var e = _c.value;
                    message.entries.push(GovernorGetEnqueuedVAAsResponse_Entry.fromPartial(e));
                }
            }
            catch (e_12_1) { e_12 = { error: e_12_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_12) throw e_12.error; }
            }
        }
        return message;
    },
};
var baseGovernorGetEnqueuedVAAsResponse_Entry = {
    emitterChain: 0,
    emitterAddress: "",
    sequence: "0",
    releaseTime: 0,
    notionalValue: "0",
    txHash: "",
};
export var GovernorGetEnqueuedVAAsResponse_Entry = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.emitterChain !== 0) {
            writer.uint32(8).uint32(message.emitterChain);
        }
        if (message.emitterAddress !== "") {
            writer.uint32(18).string(message.emitterAddress);
        }
        if (message.sequence !== "0") {
            writer.uint32(24).uint64(message.sequence);
        }
        if (message.releaseTime !== 0) {
            writer.uint32(32).uint32(message.releaseTime);
        }
        if (message.notionalValue !== "0") {
            writer.uint32(40).uint64(message.notionalValue);
        }
        if (message.txHash !== "") {
            writer.uint32(50).string(message.txHash);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseGovernorGetEnqueuedVAAsResponse_Entry);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.emitterChain = reader.uint32();
                    break;
                case 2:
                    message.emitterAddress = reader.string();
                    break;
                case 3:
                    message.sequence = longToString(reader.uint64());
                    break;
                case 4:
                    message.releaseTime = reader.uint32();
                    break;
                case 5:
                    message.notionalValue = longToString(reader.uint64());
                    break;
                case 6:
                    message.txHash = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseGovernorGetEnqueuedVAAsResponse_Entry);
        if (object.emitterChain !== undefined && object.emitterChain !== null) {
            message.emitterChain = Number(object.emitterChain);
        }
        else {
            message.emitterChain = 0;
        }
        if (object.emitterAddress !== undefined && object.emitterAddress !== null) {
            message.emitterAddress = String(object.emitterAddress);
        }
        else {
            message.emitterAddress = "";
        }
        if (object.sequence !== undefined && object.sequence !== null) {
            message.sequence = String(object.sequence);
        }
        else {
            message.sequence = "0";
        }
        if (object.releaseTime !== undefined && object.releaseTime !== null) {
            message.releaseTime = Number(object.releaseTime);
        }
        else {
            message.releaseTime = 0;
        }
        if (object.notionalValue !== undefined && object.notionalValue !== null) {
            message.notionalValue = String(object.notionalValue);
        }
        else {
            message.notionalValue = "0";
        }
        if (object.txHash !== undefined && object.txHash !== null) {
            message.txHash = String(object.txHash);
        }
        else {
            message.txHash = "";
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.emitterChain !== undefined &&
            (obj.emitterChain = message.emitterChain);
        message.emitterAddress !== undefined &&
            (obj.emitterAddress = message.emitterAddress);
        message.sequence !== undefined && (obj.sequence = message.sequence);
        message.releaseTime !== undefined &&
            (obj.releaseTime = message.releaseTime);
        message.notionalValue !== undefined &&
            (obj.notionalValue = message.notionalValue);
        message.txHash !== undefined && (obj.txHash = message.txHash);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseGovernorGetEnqueuedVAAsResponse_Entry);
        if (object.emitterChain !== undefined && object.emitterChain !== null) {
            message.emitterChain = object.emitterChain;
        }
        else {
            message.emitterChain = 0;
        }
        if (object.emitterAddress !== undefined && object.emitterAddress !== null) {
            message.emitterAddress = object.emitterAddress;
        }
        else {
            message.emitterAddress = "";
        }
        if (object.sequence !== undefined && object.sequence !== null) {
            message.sequence = object.sequence;
        }
        else {
            message.sequence = "0";
        }
        if (object.releaseTime !== undefined && object.releaseTime !== null) {
            message.releaseTime = object.releaseTime;
        }
        else {
            message.releaseTime = 0;
        }
        if (object.notionalValue !== undefined && object.notionalValue !== null) {
            message.notionalValue = object.notionalValue;
        }
        else {
            message.notionalValue = "0";
        }
        if (object.txHash !== undefined && object.txHash !== null) {
            message.txHash = object.txHash;
        }
        else {
            message.txHash = "";
        }
        return message;
    },
};
var baseGovernorIsVAAEnqueuedRequest = {};
export var GovernorIsVAAEnqueuedRequest = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.messageId !== undefined) {
            MessageID.encode(message.messageId, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseGovernorIsVAAEnqueuedRequest);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.messageId = MessageID.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseGovernorIsVAAEnqueuedRequest);
        if (object.messageId !== undefined && object.messageId !== null) {
            message.messageId = MessageID.fromJSON(object.messageId);
        }
        else {
            message.messageId = undefined;
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.messageId !== undefined &&
            (obj.messageId = message.messageId
                ? MessageID.toJSON(message.messageId)
                : undefined);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseGovernorIsVAAEnqueuedRequest);
        if (object.messageId !== undefined && object.messageId !== null) {
            message.messageId = MessageID.fromPartial(object.messageId);
        }
        else {
            message.messageId = undefined;
        }
        return message;
    },
};
var baseGovernorIsVAAEnqueuedResponse = { isEnqueued: false };
export var GovernorIsVAAEnqueuedResponse = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.isEnqueued === true) {
            writer.uint32(8).bool(message.isEnqueued);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseGovernorIsVAAEnqueuedResponse);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.isEnqueued = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseGovernorIsVAAEnqueuedResponse);
        if (object.isEnqueued !== undefined && object.isEnqueued !== null) {
            message.isEnqueued = Boolean(object.isEnqueued);
        }
        else {
            message.isEnqueued = false;
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.isEnqueued !== undefined && (obj.isEnqueued = message.isEnqueued);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseGovernorIsVAAEnqueuedResponse);
        if (object.isEnqueued !== undefined && object.isEnqueued !== null) {
            message.isEnqueued = object.isEnqueued;
        }
        else {
            message.isEnqueued = false;
        }
        return message;
    },
};
var baseGovernorGetTokenListRequest = {};
export var GovernorGetTokenListRequest = {
    encode: function (_, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseGovernorGetTokenListRequest);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (_) {
        var message = __assign({}, baseGovernorGetTokenListRequest);
        return message;
    },
    toJSON: function (_) {
        var obj = {};
        return obj;
    },
    fromPartial: function (_) {
        var message = __assign({}, baseGovernorGetTokenListRequest);
        return message;
    },
};
var baseGovernorGetTokenListResponse = {};
export var GovernorGetTokenListResponse = {
    encode: function (message, writer) {
        var e_13, _a;
        if (writer === void 0) { writer = _m0.Writer.create(); }
        try {
            for (var _b = __values(message.entries), _c = _b.next(); !_c.done; _c = _b.next()) {
                var v = _c.value;
                GovernorGetTokenListResponse_Entry.encode(v, writer.uint32(10).fork()).ldelim();
            }
        }
        catch (e_13_1) { e_13 = { error: e_13_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_13) throw e_13.error; }
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseGovernorGetTokenListResponse);
        message.entries = [];
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.entries.push(GovernorGetTokenListResponse_Entry.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var e_14, _a;
        var message = __assign({}, baseGovernorGetTokenListResponse);
        message.entries = [];
        if (object.entries !== undefined && object.entries !== null) {
            try {
                for (var _b = __values(object.entries), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var e = _c.value;
                    message.entries.push(GovernorGetTokenListResponse_Entry.fromJSON(e));
                }
            }
            catch (e_14_1) { e_14 = { error: e_14_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_14) throw e_14.error; }
            }
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        if (message.entries) {
            obj.entries = message.entries.map(function (e) {
                return e ? GovernorGetTokenListResponse_Entry.toJSON(e) : undefined;
            });
        }
        else {
            obj.entries = [];
        }
        return obj;
    },
    fromPartial: function (object) {
        var e_15, _a;
        var message = __assign({}, baseGovernorGetTokenListResponse);
        message.entries = [];
        if (object.entries !== undefined && object.entries !== null) {
            try {
                for (var _b = __values(object.entries), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var e = _c.value;
                    message.entries.push(GovernorGetTokenListResponse_Entry.fromPartial(e));
                }
            }
            catch (e_15_1) { e_15 = { error: e_15_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_15) throw e_15.error; }
            }
        }
        return message;
    },
};
var baseGovernorGetTokenListResponse_Entry = {
    originChainId: 0,
    originAddress: "",
    price: 0,
};
export var GovernorGetTokenListResponse_Entry = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.originChainId !== 0) {
            writer.uint32(8).uint32(message.originChainId);
        }
        if (message.originAddress !== "") {
            writer.uint32(18).string(message.originAddress);
        }
        if (message.price !== 0) {
            writer.uint32(29).float(message.price);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = __assign({}, baseGovernorGetTokenListResponse_Entry);
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.originChainId = reader.uint32();
                    break;
                case 2:
                    message.originAddress = reader.string();
                    break;
                case 3:
                    message.price = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON: function (object) {
        var message = __assign({}, baseGovernorGetTokenListResponse_Entry);
        if (object.originChainId !== undefined && object.originChainId !== null) {
            message.originChainId = Number(object.originChainId);
        }
        else {
            message.originChainId = 0;
        }
        if (object.originAddress !== undefined && object.originAddress !== null) {
            message.originAddress = String(object.originAddress);
        }
        else {
            message.originAddress = "";
        }
        if (object.price !== undefined && object.price !== null) {
            message.price = Number(object.price);
        }
        else {
            message.price = 0;
        }
        return message;
    },
    toJSON: function (message) {
        var obj = {};
        message.originChainId !== undefined &&
            (obj.originChainId = message.originChainId);
        message.originAddress !== undefined &&
            (obj.originAddress = message.originAddress);
        message.price !== undefined && (obj.price = message.price);
        return obj;
    },
    fromPartial: function (object) {
        var message = __assign({}, baseGovernorGetTokenListResponse_Entry);
        if (object.originChainId !== undefined && object.originChainId !== null) {
            message.originChainId = object.originChainId;
        }
        else {
            message.originChainId = 0;
        }
        if (object.originAddress !== undefined && object.originAddress !== null) {
            message.originAddress = object.originAddress;
        }
        else {
            message.originAddress = "";
        }
        if (object.price !== undefined && object.price !== null) {
            message.price = object.price;
        }
        else {
            message.price = 0;
        }
        return message;
    },
};
var PublicRPCServiceClientImpl = /** @class */ (function () {
    function PublicRPCServiceClientImpl(rpc) {
        this.rpc = rpc;
        this.GetLastHeartbeats = this.GetLastHeartbeats.bind(this);
        this.GetSignedVAA = this.GetSignedVAA.bind(this);
        this.GetSignedBatchVAA = this.GetSignedBatchVAA.bind(this);
        this.GetCurrentGuardianSet = this.GetCurrentGuardianSet.bind(this);
        this.GovernorGetAvailableNotionalByChain =
            this.GovernorGetAvailableNotionalByChain.bind(this);
        this.GovernorGetEnqueuedVAAs = this.GovernorGetEnqueuedVAAs.bind(this);
        this.GovernorIsVAAEnqueued = this.GovernorIsVAAEnqueued.bind(this);
        this.GovernorGetTokenList = this.GovernorGetTokenList.bind(this);
    }
    PublicRPCServiceClientImpl.prototype.GetLastHeartbeats = function (request, metadata) {
        return this.rpc.unary(PublicRPCServiceGetLastHeartbeatsDesc, GetLastHeartbeatsRequest.fromPartial(request), metadata);
    };
    PublicRPCServiceClientImpl.prototype.GetSignedVAA = function (request, metadata) {
        return this.rpc.unary(PublicRPCServiceGetSignedVAADesc, GetSignedVAARequest.fromPartial(request), metadata);
    };
    PublicRPCServiceClientImpl.prototype.GetSignedBatchVAA = function (request, metadata) {
        return this.rpc.unary(PublicRPCServiceGetSignedBatchVAADesc, GetSignedBatchVAARequest.fromPartial(request), metadata);
    };
    PublicRPCServiceClientImpl.prototype.GetCurrentGuardianSet = function (request, metadata) {
        return this.rpc.unary(PublicRPCServiceGetCurrentGuardianSetDesc, GetCurrentGuardianSetRequest.fromPartial(request), metadata);
    };
    PublicRPCServiceClientImpl.prototype.GovernorGetAvailableNotionalByChain = function (request, metadata) {
        return this.rpc.unary(PublicRPCServiceGovernorGetAvailableNotionalByChainDesc, GovernorGetAvailableNotionalByChainRequest.fromPartial(request), metadata);
    };
    PublicRPCServiceClientImpl.prototype.GovernorGetEnqueuedVAAs = function (request, metadata) {
        return this.rpc.unary(PublicRPCServiceGovernorGetEnqueuedVAAsDesc, GovernorGetEnqueuedVAAsRequest.fromPartial(request), metadata);
    };
    PublicRPCServiceClientImpl.prototype.GovernorIsVAAEnqueued = function (request, metadata) {
        return this.rpc.unary(PublicRPCServiceGovernorIsVAAEnqueuedDesc, GovernorIsVAAEnqueuedRequest.fromPartial(request), metadata);
    };
    PublicRPCServiceClientImpl.prototype.GovernorGetTokenList = function (request, metadata) {
        return this.rpc.unary(PublicRPCServiceGovernorGetTokenListDesc, GovernorGetTokenListRequest.fromPartial(request), metadata);
    };
    return PublicRPCServiceClientImpl;
}());
export { PublicRPCServiceClientImpl };
export var PublicRPCServiceDesc = {
    serviceName: "publicrpc.v1.PublicRPCService",
};
export var PublicRPCServiceGetLastHeartbeatsDesc = {
    methodName: "GetLastHeartbeats",
    service: PublicRPCServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary: function () {
            return GetLastHeartbeatsRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary: function (data) {
            return __assign(__assign({}, GetLastHeartbeatsResponse.decode(data)), { toObject: function () {
                    return this;
                } });
        },
    },
};
export var PublicRPCServiceGetSignedVAADesc = {
    methodName: "GetSignedVAA",
    service: PublicRPCServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary: function () {
            return GetSignedVAARequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary: function (data) {
            return __assign(__assign({}, GetSignedVAAResponse.decode(data)), { toObject: function () {
                    return this;
                } });
        },
    },
};
export var PublicRPCServiceGetSignedBatchVAADesc = {
    methodName: "GetSignedBatchVAA",
    service: PublicRPCServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary: function () {
            return GetSignedBatchVAARequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary: function (data) {
            return __assign(__assign({}, GetSignedBatchVAAResponse.decode(data)), { toObject: function () {
                    return this;
                } });
        },
    },
};
export var PublicRPCServiceGetCurrentGuardianSetDesc = {
    methodName: "GetCurrentGuardianSet",
    service: PublicRPCServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary: function () {
            return GetCurrentGuardianSetRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary: function (data) {
            return __assign(__assign({}, GetCurrentGuardianSetResponse.decode(data)), { toObject: function () {
                    return this;
                } });
        },
    },
};
export var PublicRPCServiceGovernorGetAvailableNotionalByChainDesc = {
    methodName: "GovernorGetAvailableNotionalByChain",
    service: PublicRPCServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary: function () {
            return GovernorGetAvailableNotionalByChainRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary: function (data) {
            return __assign(__assign({}, GovernorGetAvailableNotionalByChainResponse.decode(data)), { toObject: function () {
                    return this;
                } });
        },
    },
};
export var PublicRPCServiceGovernorGetEnqueuedVAAsDesc = {
    methodName: "GovernorGetEnqueuedVAAs",
    service: PublicRPCServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary: function () {
            return GovernorGetEnqueuedVAAsRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary: function (data) {
            return __assign(__assign({}, GovernorGetEnqueuedVAAsResponse.decode(data)), { toObject: function () {
                    return this;
                } });
        },
    },
};
export var PublicRPCServiceGovernorIsVAAEnqueuedDesc = {
    methodName: "GovernorIsVAAEnqueued",
    service: PublicRPCServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary: function () {
            return GovernorIsVAAEnqueuedRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary: function (data) {
            return __assign(__assign({}, GovernorIsVAAEnqueuedResponse.decode(data)), { toObject: function () {
                    return this;
                } });
        },
    },
};
export var PublicRPCServiceGovernorGetTokenListDesc = {
    methodName: "GovernorGetTokenList",
    service: PublicRPCServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary: function () {
            return GovernorGetTokenListRequest.encode(this).finish();
        },
    },
    responseType: {
        deserializeBinary: function (data) {
            return __assign(__assign({}, GovernorGetTokenListResponse.decode(data)), { toObject: function () {
                    return this;
                } });
        },
    },
};
var GrpcWebImpl = /** @class */ (function () {
    function GrpcWebImpl(host, options) {
        this.host = host;
        this.options = options;
    }
    GrpcWebImpl.prototype.unary = function (methodDesc, _request, metadata) {
        var _this = this;
        var _a;
        var request = __assign(__assign({}, _request), methodDesc.requestType);
        var maybeCombinedMetadata = metadata && this.options.metadata
            ? new BrowserHeaders(__assign(__assign({}, (_a = this.options) === null || _a === void 0 ? void 0 : _a.metadata.headersMap), metadata === null || metadata === void 0 ? void 0 : metadata.headersMap))
            : metadata || this.options.metadata;
        return new Promise(function (resolve, reject) {
            grpc.unary(methodDesc, {
                request: request,
                host: _this.host,
                metadata: maybeCombinedMetadata,
                transport: _this.options.transport,
                debug: _this.options.debug,
                onEnd: function (response) {
                    if (response.status === grpc.Code.OK) {
                        resolve(response.message);
                    }
                    else {
                        var err = new Error(response.statusMessage);
                        err.code = response.status;
                        err.metadata = response.trailers;
                        reject(err);
                    }
                },
            });
        });
    };
    return GrpcWebImpl;
}());
export { GrpcWebImpl };
var globalThis = (function () {
    if (typeof globalThis !== "undefined")
        return globalThis;
    if (typeof self !== "undefined")
        return self;
    if (typeof window !== "undefined")
        return window;
    if (typeof global !== "undefined")
        return global;
    throw "Unable to locate global object";
})();
var atob = globalThis.atob ||
    (function (b64) { return globalThis.Buffer.from(b64, "base64").toString("binary"); });
function bytesFromBase64(b64) {
    var bin = atob(b64);
    var arr = new Uint8Array(bin.length);
    for (var i = 0; i < bin.length; ++i) {
        arr[i] = bin.charCodeAt(i);
    }
    return arr;
}
var btoa = globalThis.btoa ||
    (function (bin) { return globalThis.Buffer.from(bin, "binary").toString("base64"); });
function base64FromBytes(arr) {
    var e_16, _a;
    var bin = [];
    try {
        for (var arr_1 = __values(arr), arr_1_1 = arr_1.next(); !arr_1_1.done; arr_1_1 = arr_1.next()) {
            var byte = arr_1_1.value;
            bin.push(String.fromCharCode(byte));
        }
    }
    catch (e_16_1) { e_16 = { error: e_16_1 }; }
    finally {
        try {
            if (arr_1_1 && !arr_1_1.done && (_a = arr_1.return)) _a.call(arr_1);
        }
        finally { if (e_16) throw e_16.error; }
    }
    return btoa(bin.join(""));
}
function longToString(long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
