var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { PublicKey, } from "@solana/web3.js";
import { CHAIN_ID_SOLANA, tryNativeToUint8Array, } from "../../../utils";
import { deriveAddress, getAccountData } from "../../utils";
export { deriveTokenMetadataKey } from "../../utils/tokenMetadata";
export function deriveWrappedMintKey(tokenBridgeProgramId, tokenChain, tokenAddress) {
    if (tokenChain == CHAIN_ID_SOLANA) {
        throw new Error("tokenChain == CHAIN_ID_SOLANA does not have wrapped mint key");
    }
    if (typeof tokenAddress == "string") {
        tokenAddress = tryNativeToUint8Array(tokenAddress, tokenChain);
    }
    return deriveAddress([
        Buffer.from("wrapped"),
        (() => {
            const buf = Buffer.alloc(2);
            buf.writeUInt16BE(tokenChain);
            return buf;
        })(),
        tokenAddress,
    ], tokenBridgeProgramId);
}
export function deriveWrappedMetaKey(tokenBridgeProgramId, mint) {
    return deriveAddress([Buffer.from("meta"), new PublicKey(mint).toBuffer()], tokenBridgeProgramId);
}
export function getWrappedMeta(connection, tokenBridgeProgramId, mint, commitment) {
    return __awaiter(this, void 0, void 0, function* () {
        return connection
            .getAccountInfo(deriveWrappedMetaKey(tokenBridgeProgramId, mint), commitment)
            .then((info) => WrappedMeta.deserialize(getAccountData(info)));
    });
}
export class WrappedMeta {
    constructor(chain, tokenAddress, originalDecimals, lastUpdatedSequence) {
        this.chain = chain;
        this.tokenAddress = tokenAddress;
        this.originalDecimals = originalDecimals;
        this.lastUpdatedSequence = lastUpdatedSequence;
    }
    static deserialize(data) {
        if (data.length !== 35 && data.length !== 43) {
            throw new Error(`invalid wrapped meta length: ${data.length}`);
        }
        const chain = data.readUInt16LE(0);
        const tokenAddress = data.subarray(2, 34);
        const originalDecimals = data.readUInt8(34);
        const lastUpdatedSequence = data.length === 43 ? data.readBigUInt64LE(35) : undefined;
        return new WrappedMeta(chain, tokenAddress, originalDecimals, lastUpdatedSequence);
    }
}
