/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { utils, Contract, ContractFactory } from "ethers";
const _abi = [
    {
        type: "constructor",
        inputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "upgrade",
        inputs: [
            {
                name: "impl",
                type: "address",
                internalType: "address",
            },
            {
                name: "call",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "event",
        name: "AdminChanged",
        inputs: [
            {
                name: "previousAdmin",
                type: "address",
                indexed: false,
                internalType: "address",
            },
            {
                name: "newAdmin",
                type: "address",
                indexed: false,
                internalType: "address",
            },
        ],
        anonymous: false,
    },
    {
        type: "event",
        name: "BeaconUpgraded",
        inputs: [
            {
                name: "beacon",
                type: "address",
                indexed: true,
                internalType: "address",
            },
        ],
        anonymous: false,
    },
    {
        type: "event",
        name: "Upgraded",
        inputs: [
            {
                name: "implementation",
                type: "address",
                indexed: true,
                internalType: "address",
            },
        ],
        anonymous: false,
    },
];
const _bytecode = "0x60808060405234610016576103b0908161001c8239f35b600080fdfe6080604052600436101561001257600080fd5b6000803560e01c63c987336c1461002857600080fd5b346100b45760403660031901126100b4576004356001600160a01b03811681036100ac5760243567ffffffffffffffff81116100b057366023820112156100b0578060040135918361008161007c856100f8565b6100cd565b9284845236602486830101116100ac57846100a9956024602093018387013784010152610114565b80f35b5080fd5b8280fd5b80fd5b634e487b7160e01b600052604160045260246000fd5b6040519190601f01601f1916820167ffffffffffffffff8111838210176100f357604052565b6100b7565b67ffffffffffffffff81116100f357601f01601f191660200190565b803b156101ad577f360894a13ba1a3210667c828492db98dca3e2076cc3735a920a3ca505d382bbc80546001600160a01b0319166001600160a01b0383169081179091557fbc7cd75a20ee27fd9adebab32041f755214dbc6bffa90cc0225b39da2e5c2d3b600080a28151158015906101a5575b610190575050565b6101a29161019c610208565b91610262565b50565b506000610188565b60405162461bcd60e51b815260206004820152602d60248201527f455243313936373a206e657720696d706c656d656e746174696f6e206973206e60448201526c1bdd08184818dbdb9d1c9858dd609a1b6064820152608490fd5b604051906060820182811067ffffffffffffffff8211176100f35760405260278252660819985a5b195960ca1b6040837f416464726573733a206c6f772d6c6576656c2064656c65676174652063616c6c60208201520152565b9190823b156102ab576000816102a09460208394519201905af43d156102a3573d9061029061007c836100f8565b9182523d6000602084013e6102ff565b90565b6060906102ff565b60405162461bcd60e51b815260206004820152602660248201527f416464726573733a2064656c65676174652063616c6c20746f206e6f6e2d636f6044820152651b9d1c9858dd60d21b6064820152608490fd5b9091901561030b575090565b81511561031b5750805190602001fd5b6040519062461bcd60e51b82528160208060048301528251908160248401526000935b828510610361575050604492506000838284010152601f80199101168101030190fd5b848101820151868601604401529381019385935061033e56fea2646970667358221220c536b6676695638ff648472897529b3771db33669fbb3ef2ecd1afa4278e5d3064736f6c63430008130033";
const isSuperArgs = (xs) => xs.length > 1;
export class Init__factory extends ContractFactory {
    constructor(...args) {
        if (isSuperArgs(args)) {
            super(...args);
        }
        else {
            super(_abi, _bytecode, args[0]);
        }
    }
    deploy(overrides) {
        return super.deploy(overrides || {});
    }
    getDeployTransaction(overrides) {
        return super.getDeployTransaction(overrides || {});
    }
    attach(address) {
        return super.attach(address);
    }
    connect(signer) {
        return super.connect(signer);
    }
    static createInterface() {
        return new utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    }
}
Init__factory.bytecode = _bytecode;
Init__factory.abi = _abi;
