/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { utils, Contract, ContractFactory } from "ethers";
const _abi = [
    {
        type: "event",
        name: "SlotFound",
        inputs: [
            {
                name: "who",
                type: "address",
                indexed: false,
                internalType: "address",
            },
            {
                name: "fsig",
                type: "bytes4",
                indexed: false,
                internalType: "bytes4",
            },
            {
                name: "keysHash",
                type: "bytes32",
                indexed: false,
                internalType: "bytes32",
            },
            {
                name: "slot",
                type: "uint256",
                indexed: false,
                internalType: "uint256",
            },
        ],
        anonymous: false,
    },
    {
        type: "event",
        name: "WARNING_UninitedSlot",
        inputs: [
            {
                name: "who",
                type: "address",
                indexed: false,
                internalType: "address",
            },
            {
                name: "slot",
                type: "uint256",
                indexed: false,
                internalType: "uint256",
            },
        ],
        anonymous: false,
    },
];
const _bytecode = "0x60808060405234601757603a9081601d823930815050f35b600080fdfe600080fdfea264697066735822122099177b0e6c56f13bb3e09c47dafe662211b54a79fa18982299ba228a83afd31d64736f6c63430008130033";
const isSuperArgs = (xs) => xs.length > 1;
export class StdStorageSafe__factory extends ContractFactory {
    constructor(...args) {
        if (isSuperArgs(args)) {
            super(...args);
        }
        else {
            super(_abi, _bytecode, args[0]);
        }
    }
    deploy(overrides) {
        return super.deploy(overrides || {});
    }
    getDeployTransaction(overrides) {
        return super.getDeployTransaction(overrides || {});
    }
    attach(address) {
        return super.attach(address);
    }
    connect(signer) {
        return super.connect(signer);
    }
    static createInterface() {
        return new utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    }
}
StdStorageSafe__factory.bytecode = _bytecode;
StdStorageSafe__factory.abi = _abi;
