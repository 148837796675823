var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SUI_CLOCK_OBJECT_ID, TransactionBlock, } from "@mysten/sui.js";
import { ACCOUNT_SIZE, NATIVE_MINT, TOKEN_PROGRAM_ID, createCloseAccountInstruction, createInitializeAccountInstruction, createTransferInstruction, getMinimumBalanceForRentExemptAccount, getMint, } from "@solana/spl-token";
import { Keypair, PublicKey, SystemProgram, Transaction, } from "@solana/web3.js";
import { MsgExecuteContract } from "@terra-money/terra.js";
import { MsgExecuteContract as XplaMsgExecuteContract } from "@xpla/xpla.js";
import BN from "bn.js";
import { fromUint8Array } from "js-base64";
import { _parseVAAAlgorand, _submitVAAAlgorand, } from "../algorand";
import { completeTransferAndRegister } from "../aptos";
import { Bridge__factory } from "../ethers-contracts";
import { createCompleteTransferNativeInstruction, createCompleteTransferWrappedInstruction, } from "../solana/tokenBridge";
import { getPackageId, getTokenCoinType, uint8ArrayToBCS } from "../sui";
import { CHAIN_ID_NEAR, CHAIN_ID_SOLANA, MAX_VAA_DECIMALS, callFunctionNear, hashLookup, uint8ArrayToHex, } from "../utils";
import { parseTokenTransferVaa } from "../vaa";
import { getForeignAssetNear } from "./getForeignAsset";
export function redeemOnEth(tokenBridgeAddress, signer, signedVAA, overrides = {}) {
    return __awaiter(this, void 0, void 0, function* () {
        const bridge = Bridge__factory.connect(tokenBridgeAddress, signer);
        const v = yield bridge.completeTransfer(signedVAA, overrides);
        const receipt = yield v.wait();
        return receipt;
    });
}
export function redeemOnEthNative(tokenBridgeAddress, signer, signedVAA, overrides = {}) {
    return __awaiter(this, void 0, void 0, function* () {
        const bridge = Bridge__factory.connect(tokenBridgeAddress, signer);
        const v = yield bridge.completeTransferAndUnwrapETH(signedVAA, overrides);
        const receipt = yield v.wait();
        return receipt;
    });
}
export function redeemOnTerra(tokenBridgeAddress, walletAddress, signedVAA) {
    return __awaiter(this, void 0, void 0, function* () {
        return new MsgExecuteContract(walletAddress, tokenBridgeAddress, {
            submit_vaa: {
                data: fromUint8Array(signedVAA),
            },
        });
    });
}
export function redeemOnXpla(tokenBridgeAddress, walletAddress, signedVAA) {
    return new XplaMsgExecuteContract(walletAddress, tokenBridgeAddress, {
        submit_vaa: {
            data: fromUint8Array(signedVAA),
        },
    });
}
export function redeemAndUnwrapOnSolana(connection, bridgeAddress, tokenBridgeAddress, payerAddress, signedVaa, commitment) {
    return __awaiter(this, void 0, void 0, function* () {
        const parsed = parseTokenTransferVaa(signedVaa);
        const targetPublicKey = new PublicKey(parsed.to);
        const targetAmount = yield getMint(connection, NATIVE_MINT, commitment).then((info) => parsed.amount * BigInt(Math.pow(10, info.decimals - MAX_VAA_DECIMALS)));
        const rentBalance = yield getMinimumBalanceForRentExemptAccount(connection, commitment);
        if (Buffer.compare(parsed.tokenAddress, NATIVE_MINT.toBuffer()) != 0) {
            return Promise.reject("tokenAddress != NATIVE_MINT");
        }
        const payerPublicKey = new PublicKey(payerAddress);
        const ancillaryKeypair = Keypair.generate();
        const completeTransferIx = createCompleteTransferNativeInstruction(tokenBridgeAddress, bridgeAddress, payerPublicKey, signedVaa);
        //This will create a temporary account where the wSOL will be moved
        const createAncillaryAccountIx = SystemProgram.createAccount({
            fromPubkey: payerPublicKey,
            newAccountPubkey: ancillaryKeypair.publicKey,
            lamports: rentBalance,
            space: ACCOUNT_SIZE,
            programId: TOKEN_PROGRAM_ID,
        });
        //Initialize the account as a WSOL account, with the original payerAddress as owner
        const initAccountIx = createInitializeAccountInstruction(ancillaryKeypair.publicKey, NATIVE_MINT, payerPublicKey);
        //Send in the amount of wSOL which we want converted to SOL
        const balanceTransferIx = createTransferInstruction(targetPublicKey, ancillaryKeypair.publicKey, payerPublicKey, targetAmount.valueOf());
        //Close the ancillary account for cleanup. Payer address receives any remaining funds
        const closeAccountIx = createCloseAccountInstruction(ancillaryKeypair.publicKey, //account to close
        payerPublicKey, //Remaining funds destination
        payerPublicKey //authority
        );
        const { blockhash } = yield connection.getLatestBlockhash(commitment);
        const transaction = new Transaction();
        transaction.recentBlockhash = blockhash;
        transaction.feePayer = payerPublicKey;
        transaction.add(completeTransferIx, createAncillaryAccountIx, initAccountIx, balanceTransferIx, closeAccountIx);
        transaction.partialSign(ancillaryKeypair);
        return transaction;
    });
}
export function redeemOnSolana(connection, bridgeAddress, tokenBridgeAddress, payerAddress, signedVaa, feeRecipientAddress, commitment) {
    return __awaiter(this, void 0, void 0, function* () {
        const parsed = parseTokenTransferVaa(signedVaa);
        const createCompleteTransferInstruction = parsed.tokenChain == CHAIN_ID_SOLANA
            ? createCompleteTransferNativeInstruction
            : createCompleteTransferWrappedInstruction;
        const transaction = new Transaction().add(createCompleteTransferInstruction(tokenBridgeAddress, bridgeAddress, payerAddress, parsed, feeRecipientAddress));
        const { blockhash } = yield connection.getLatestBlockhash(commitment);
        transaction.recentBlockhash = blockhash;
        transaction.feePayer = new PublicKey(payerAddress);
        return transaction;
    });
}
/**
 * This basically just submits the VAA to Algorand
 * @param client AlgodV2 client
 * @param tokenBridgeId Token bridge ID
 * @param bridgeId Core bridge ID
 * @param vaa The VAA to be redeemed
 * @param acct Sending account
 * @returns Transaction ID(s)
 */
export function redeemOnAlgorand(client, tokenBridgeId, bridgeId, vaa, senderAddr) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield _submitVAAAlgorand(client, tokenBridgeId, bridgeId, vaa, senderAddr);
    });
}
export function redeemOnNear(provider, account, tokenBridge, vaa) {
    return __awaiter(this, void 0, void 0, function* () {
        const options = [];
        const p = _parseVAAAlgorand(vaa);
        if (p.ToChain !== CHAIN_ID_NEAR) {
            throw new Error("Not destined for NEAR");
        }
        const { found, value: receiver } = yield hashLookup(provider, tokenBridge, uint8ArrayToHex(p.ToAddress));
        if (!found) {
            throw new Error("Unregistered receiver (receiving account is not registered)");
        }
        const token = yield getForeignAssetNear(provider, tokenBridge, p.FromChain, p.Contract);
        if (p.Contract !==
            "0000000000000000000000000000000000000000000000000000000000000000") {
            if (token === "" || token === null) {
                throw new Error("Unregistered token (has it been attested?)");
            }
            const bal = yield callFunctionNear(provider, token, "storage_balance_of", {
                account_id: receiver,
            });
            if (bal === null) {
                options.push({
                    contractId: token,
                    methodName: "storage_deposit",
                    args: { account_id: receiver, registration_only: true },
                    gas: new BN("100000000000000"),
                    attachedDeposit: new BN("2000000000000000000000"), // 0.002 NEAR
                });
            }
            if (p.Fee !== undefined &&
                Buffer.compare(p.Fee, Buffer.from("0000000000000000000000000000000000000000000000000000000000000000", "hex")) !== 0) {
                const bal = yield callFunctionNear(provider, token, "storage_balance_of", {
                    account_id: account,
                });
                if (bal === null) {
                    options.push({
                        contractId: token,
                        methodName: "storage_deposit",
                        args: { account_id: account, registration_only: true },
                        gas: new BN("100000000000000"),
                        attachedDeposit: new BN("2000000000000000000000"), // 0.002 NEAR
                    });
                }
            }
        }
        options.push({
            contractId: tokenBridge,
            methodName: "submit_vaa",
            args: {
                vaa: uint8ArrayToHex(vaa),
            },
            attachedDeposit: new BN("100000000000000000000000"),
            gas: new BN("150000000000000"),
        });
        options.push({
            contractId: tokenBridge,
            methodName: "submit_vaa",
            args: {
                vaa: uint8ArrayToHex(vaa),
            },
            attachedDeposit: new BN("100000000000000000000000"),
            gas: new BN("150000000000000"),
        });
        return options;
    });
}
/**
 * Register the token specified in the given VAA in the transfer recipient's account if necessary
 * and complete the transfer.
 * @param client Client used to transfer data to/from Aptos node
 * @param tokenBridgeAddress Address of token bridge
 * @param transferVAA Bytes of transfer VAA
 * @returns Transaction payload
 */
export function redeemOnAptos(client, tokenBridgeAddress, transferVAA) {
    return completeTransferAndRegister(client, tokenBridgeAddress, transferVAA);
}
export function redeemOnSui(provider, coreBridgeStateObjectId, tokenBridgeStateObjectId, transferVAA, coreBridgePackageId, tokenBridgePackageId) {
    return __awaiter(this, void 0, void 0, function* () {
        const { tokenAddress, tokenChain } = parseTokenTransferVaa(transferVAA);
        const coinType = yield getTokenCoinType(provider, tokenBridgeStateObjectId, tokenAddress, tokenChain);
        if (!coinType) {
            throw new Error("Unable to fetch token coinType");
        }
        [coreBridgePackageId, tokenBridgePackageId] = yield Promise.all([
            coreBridgePackageId
                ? Promise.resolve(coreBridgePackageId)
                : getPackageId(provider, coreBridgeStateObjectId),
            tokenBridgePackageId
                ? Promise.resolve(tokenBridgePackageId)
                : getPackageId(provider, tokenBridgeStateObjectId),
        ]);
        const tx = new TransactionBlock();
        const [verifiedVAA] = tx.moveCall({
            target: `${coreBridgePackageId}::vaa::parse_and_verify`,
            arguments: [
                tx.object(coreBridgeStateObjectId),
                tx.pure(uint8ArrayToBCS(transferVAA)),
                tx.object(SUI_CLOCK_OBJECT_ID),
            ],
        });
        const [tokenBridgeMessage] = tx.moveCall({
            target: `${tokenBridgePackageId}::vaa::verify_only_once`,
            arguments: [tx.object(tokenBridgeStateObjectId), verifiedVAA],
        });
        const [relayerReceipt] = tx.moveCall({
            target: `${tokenBridgePackageId}::complete_transfer::authorize_transfer`,
            arguments: [tx.object(tokenBridgeStateObjectId), tokenBridgeMessage],
            typeArguments: [coinType],
        });
        const [coins] = tx.moveCall({
            target: `${tokenBridgePackageId}::complete_transfer::redeem_relayer_payout`,
            arguments: [relayerReceipt],
            typeArguments: [coinType],
        });
        tx.moveCall({
            target: `${tokenBridgePackageId}::coin_utils::return_nonzero`,
            arguments: [coins],
            typeArguments: [coinType],
        });
        return tx;
    });
}
