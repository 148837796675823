/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { utils, Contract, ContractFactory } from "ethers";
const _abi = [
    {
        type: "function",
        name: "nonces",
        inputs: [
            {
                name: "owner_",
                type: "address",
                internalType: "address",
            },
        ],
        outputs: [
            {
                name: "",
                type: "uint256",
                internalType: "uint256",
            },
        ],
        stateMutability: "view",
    },
];
const _bytecode = "0x608060405234801561001057600080fd5b5060c78061001f6000396000f3fe6080604052348015600f57600080fd5b506004361060285760003560e01c80637ecebe0014602d575b600080fd5b605360383660046065565b6001600160a01b03166000908152600e602052604090205490565b60405190815260200160405180910390f35b6000602082840312156075578081fd5b81356001600160a01b0381168114608a578182fd5b939250505056fea2646970667358221220ba8a92b2abb09a5af25f6ea87751402c29e67a2021c6676b32ae777441ee01a664736f6c63430008040033";
const isSuperArgs = (xs) => xs.length > 1;
export class TokenState__factory extends ContractFactory {
    constructor(...args) {
        if (isSuperArgs(args)) {
            super(...args);
        }
        else {
            super(_abi, _bytecode, args[0]);
        }
    }
    deploy(overrides) {
        return super.deploy(overrides || {});
    }
    getDeployTransaction(overrides) {
        return super.getDeployTransaction(overrides || {});
    }
    attach(address) {
        return super.attach(address);
    }
    connect(signer) {
        return super.connect(signer);
    }
    static createInterface() {
        return new utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    }
}
TokenState__factory.bytecode = _bytecode;
TokenState__factory.abi = _abi;
