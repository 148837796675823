var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { decodeAddress, getApplicationAddress } from "algosdk";
import { bech32 } from "bech32";
import { arrayify, sha256, zeroPad, } from "ethers/lib/utils";
import { deriveWormholeEmitterKey } from "../solana/wormhole";
import { uint8ArrayToHex } from "../utils";
export function getEmitterAddressEth(contractAddress) {
    return Buffer.from(zeroPad(arrayify(contractAddress), 32)).toString("hex");
}
export function getEmitterAddressSolana(programAddress) {
    return deriveWormholeEmitterKey(programAddress).toBuffer().toString("hex");
}
export function getEmitterAddressTerra(programAddress) {
    return __awaiter(this, void 0, void 0, function* () {
        return Buffer.from(zeroPad(bech32.fromWords(bech32.decode(programAddress).words), 32)).toString("hex");
    });
}
export const getEmitterAddressInjective = getEmitterAddressTerra;
export const getEmitterAddressXpla = getEmitterAddressTerra;
export function getEmitterAddressAlgorand(appId) {
    const appAddr = getApplicationAddress(appId);
    const decAppAddr = decodeAddress(appAddr).publicKey;
    const aa = uint8ArrayToHex(decAppAddr);
    return aa;
}
export function getEmitterAddressNear(programAddress) {
    return uint8ArrayToHex(arrayify(sha256(Buffer.from(programAddress, "utf8"))));
}
