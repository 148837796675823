/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Contract, utils } from "ethers";
const _abi = [
    {
        type: "function",
        name: "bridgeContracts",
        inputs: [
            {
                name: "chainId_",
                type: "uint16",
                internalType: "uint16",
            },
        ],
        outputs: [
            {
                name: "",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "chainId",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "uint16",
                internalType: "uint16",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "completeTransfer",
        inputs: [
            {
                name: "encodeVm",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "encodeTransfer",
        inputs: [
            {
                name: "transfer",
                type: "tuple",
                internalType: "struct INFTBridge.Transfer",
                components: [
                    {
                        name: "tokenAddress",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "tokenChain",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "symbol",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "name",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "tokenID",
                        type: "uint256",
                        internalType: "uint256",
                    },
                    {
                        name: "uri",
                        type: "string",
                        internalType: "string",
                    },
                    {
                        name: "to",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "toChain",
                        type: "uint16",
                        internalType: "uint16",
                    },
                ],
            },
        ],
        outputs: [
            {
                name: "encoded",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        stateMutability: "pure",
    },
    {
        type: "function",
        name: "evmChainId",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "uint256",
                internalType: "uint256",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "finality",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "uint8",
                internalType: "uint8",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "governanceActionIsConsumed",
        inputs: [
            {
                name: "hash",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
        outputs: [
            {
                name: "",
                type: "bool",
                internalType: "bool",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "governanceChainId",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "uint16",
                internalType: "uint16",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "governanceContract",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "implementation",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "address",
                internalType: "address",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "initialize",
        inputs: [],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "isFork",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "bool",
                internalType: "bool",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "isInitialized",
        inputs: [
            {
                name: "impl",
                type: "address",
                internalType: "address",
            },
        ],
        outputs: [
            {
                name: "",
                type: "bool",
                internalType: "bool",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "isTransferCompleted",
        inputs: [
            {
                name: "hash",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
        outputs: [
            {
                name: "",
                type: "bool",
                internalType: "bool",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "isWrappedAsset",
        inputs: [
            {
                name: "token",
                type: "address",
                internalType: "address",
            },
        ],
        outputs: [
            {
                name: "",
                type: "bool",
                internalType: "bool",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "onERC721Received",
        inputs: [
            {
                name: "operator",
                type: "address",
                internalType: "address",
            },
            {
                name: "",
                type: "address",
                internalType: "address",
            },
            {
                name: "",
                type: "uint256",
                internalType: "uint256",
            },
            {
                name: "",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [
            {
                name: "",
                type: "bytes4",
                internalType: "bytes4",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "parseRecoverChainId",
        inputs: [
            {
                name: "encodedRecoverChainId",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [
            {
                name: "rci",
                type: "tuple",
                internalType: "struct INFTBridge.RecoverChainId",
                components: [
                    {
                        name: "module",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "action",
                        type: "uint8",
                        internalType: "uint8",
                    },
                    {
                        name: "evmChainId",
                        type: "uint256",
                        internalType: "uint256",
                    },
                    {
                        name: "newChainId",
                        type: "uint16",
                        internalType: "uint16",
                    },
                ],
            },
        ],
        stateMutability: "pure",
    },
    {
        type: "function",
        name: "parseRegisterChain",
        inputs: [
            {
                name: "encoded",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [
            {
                name: "chain",
                type: "tuple",
                internalType: "struct INFTBridge.RegisterChain",
                components: [
                    {
                        name: "module",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "action",
                        type: "uint8",
                        internalType: "uint8",
                    },
                    {
                        name: "chainId",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "emitterChainID",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "emitterAddress",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                ],
            },
        ],
        stateMutability: "pure",
    },
    {
        type: "function",
        name: "parseTransfer",
        inputs: [
            {
                name: "encoded",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [
            {
                name: "transfer",
                type: "tuple",
                internalType: "struct INFTBridge.Transfer",
                components: [
                    {
                        name: "tokenAddress",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "tokenChain",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "symbol",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "name",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "tokenID",
                        type: "uint256",
                        internalType: "uint256",
                    },
                    {
                        name: "uri",
                        type: "string",
                        internalType: "string",
                    },
                    {
                        name: "to",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "toChain",
                        type: "uint16",
                        internalType: "uint16",
                    },
                ],
            },
        ],
        stateMutability: "pure",
    },
    {
        type: "function",
        name: "parseUpgrade",
        inputs: [
            {
                name: "encoded",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [
            {
                name: "chain",
                type: "tuple",
                internalType: "struct INFTBridge.UpgradeContract",
                components: [
                    {
                        name: "module",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "action",
                        type: "uint8",
                        internalType: "uint8",
                    },
                    {
                        name: "chainId",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "newContract",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                ],
            },
        ],
        stateMutability: "pure",
    },
    {
        type: "function",
        name: "registerChain",
        inputs: [
            {
                name: "encodedVM",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "splCache",
        inputs: [
            {
                name: "tokenId",
                type: "uint256",
                internalType: "uint256",
            },
        ],
        outputs: [
            {
                name: "",
                type: "tuple",
                internalType: "struct INFTBridge.SPLCache",
                components: [
                    {
                        name: "name",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "symbol",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                ],
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "submitRecoverChainId",
        inputs: [
            {
                name: "encodedVM",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "tokenImplementation",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "address",
                internalType: "address",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "transferNFT",
        inputs: [
            {
                name: "token",
                type: "address",
                internalType: "address",
            },
            {
                name: "tokenID",
                type: "uint256",
                internalType: "uint256",
            },
            {
                name: "recipientChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "recipient",
                type: "bytes32",
                internalType: "bytes32",
            },
            {
                name: "nonce",
                type: "uint32",
                internalType: "uint32",
            },
        ],
        outputs: [
            {
                name: "sequence",
                type: "uint64",
                internalType: "uint64",
            },
        ],
        stateMutability: "payable",
    },
    {
        type: "function",
        name: "upgrade",
        inputs: [
            {
                name: "encodedVM",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "wormhole",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "address",
                internalType: "contract IWormhole",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "wrappedAsset",
        inputs: [
            {
                name: "tokenChainId",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "tokenAddress",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
        outputs: [
            {
                name: "",
                type: "address",
                internalType: "address",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "event",
        name: "ContractUpgraded",
        inputs: [
            {
                name: "oldContract",
                type: "address",
                indexed: true,
                internalType: "address",
            },
            {
                name: "newContract",
                type: "address",
                indexed: true,
                internalType: "address",
            },
        ],
        anonymous: false,
    },
];
export class INFTBridge__factory {
    static createInterface() {
        return new utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    }
}
INFTBridge__factory.abi = _abi;
