var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { PublicKey, } from "@solana/web3.js";
import { deriveAddress, getAccountData } from "../../utils";
import { MessageData } from "../message";
export class PostedMessageData {
    constructor(message) {
        this.message = message;
    }
    static deserialize(data) {
        return new PostedMessageData(MessageData.deserialize(data.subarray(3)));
    }
}
export class PostedVaaData extends PostedMessageData {
}
export function derivePostedVaaKey(wormholeProgramId, hash) {
    return deriveAddress([Buffer.from("PostedVAA"), hash], wormholeProgramId);
}
export function getPostedVaa(connection, wormholeProgramId, hash, commitment) {
    return __awaiter(this, void 0, void 0, function* () {
        return connection
            .getAccountInfo(derivePostedVaaKey(wormholeProgramId, hash), commitment)
            .then((info) => PostedVaaData.deserialize(getAccountData(info)));
    });
}
export function getPostedMessage(connection, messageKey, commitment) {
    return __awaiter(this, void 0, void 0, function* () {
        return connection
            .getAccountInfo(new PublicKey(messageKey), commitment)
            .then((info) => PostedMessageData.deserialize(getAccountData(info)));
    });
}
