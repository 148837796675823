var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { PublicKey, } from "@solana/web3.js";
import { CHAIN_ID_SOLANA, tryNativeToUint8Array, } from "../../../utils";
import { deriveAddress, getAccountData } from "../../utils";
export function deriveEndpointKey(tokenBridgeProgramId, emitterChain, emitterAddress) {
    if (emitterChain == CHAIN_ID_SOLANA) {
        throw new Error("emitterChain == CHAIN_ID_SOLANA cannot exist as foreign token bridge emitter");
    }
    if (typeof emitterAddress == "string") {
        emitterAddress = tryNativeToUint8Array(emitterAddress, emitterChain);
    }
    return deriveAddress([
        (() => {
            const buf = Buffer.alloc(2);
            buf.writeUInt16BE(emitterChain);
            return buf;
        })(),
        emitterAddress,
    ], tokenBridgeProgramId);
}
export function getEndpointRegistration(connection, endpointKey, commitment) {
    return __awaiter(this, void 0, void 0, function* () {
        return connection
            .getAccountInfo(new PublicKey(endpointKey), commitment)
            .then((info) => EndpointRegistration.deserialize(getAccountData(info)));
    });
}
export class EndpointRegistration {
    constructor(chain, contract) {
        this.chain = chain;
        this.contract = contract;
    }
    static deserialize(data) {
        if (data.length != 34) {
            throw new Error("data.length != 34");
        }
        const chain = data.readUInt16LE(0);
        const contract = data.subarray(2, 34);
        return new EndpointRegistration(chain, contract);
    }
}
