const DiscordIcon = () => (
  <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.317 1.4921C18.7873 0.801473 17.147 0.292648 15.4319 0.00122249C15.4007 -0.00440192 15.3695 0.00965379 15.3534 0.0377659C15.1424 0.406965 14.9087 0.888616 14.7451 1.26719C12.9004 0.99545 11.0652 0.99545 9.25832 1.26719C9.09465 0.880201 8.85248 0.406965 8.64057 0.0377659C8.62448 0.0105918 8.59328 -0.00346397 8.56205 0.00122249C6.84791 0.291716 5.20756 0.800541 3.67693 1.4921C3.66368 1.49772 3.65233 1.5071 3.64479 1.51928C0.533392 6.09311 -0.31895 10.5545 0.0991801 14.9606C0.101072 14.9822 0.11337 15.0028 0.130398 15.0159C2.18321 16.4993 4.17171 17.3998 6.12328 17.9967C6.15451 18.0061 6.18761 17.9949 6.20748 17.9695C6.66913 17.3492 7.08064 16.6952 7.43348 16.0073C7.4543 15.967 7.43442 15.9192 7.39186 15.9033C6.73913 15.6597 6.1176 15.3626 5.51973 15.0253C5.47244 14.9981 5.46865 14.9316 5.51216 14.8997C5.63797 14.8069 5.76382 14.7104 5.88396 14.613C5.90569 14.5952 5.93598 14.5914 5.96153 14.6026C9.88928 16.3672 14.1415 16.3672 18.023 14.6026C18.0485 14.5905 18.0788 14.5942 18.1015 14.612C18.2216 14.7095 18.3475 14.8069 18.4742 14.8997C18.5177 14.9316 18.5149 14.9981 18.4676 15.0253C17.8697 15.3692 17.2482 15.6597 16.5945 15.9024C16.552 15.9183 16.533 15.967 16.5538 16.0073C16.9143 16.6942 17.3258 17.3483 17.7789 17.9686C17.7978 17.9949 17.8319 18.0061 17.8631 17.9967C19.8241 17.3998 21.8126 16.4993 23.8654 15.0159C23.8834 15.0028 23.8948 14.9831 23.8967 14.9616C24.3971 9.86763 23.0585 5.4428 20.3482 1.52021C20.3416 1.5071 20.3303 1.49772 20.317 1.4921ZM8.02002 12.2778C6.8375 12.2778 5.86313 11.2095 5.86313 9.89763C5.86313 8.58572 6.8186 7.51748 8.02002 7.51748C9.23087 7.51748 10.1958 8.5951 10.1769 9.89763C10.1769 11.2095 9.22141 12.2778 8.02002 12.2778ZM15.9947 12.2778C14.8123 12.2778 13.8379 11.2095 13.8379 9.89763C13.8379 8.58572 14.7933 7.51748 15.9947 7.51748C17.2056 7.51748 18.1705 8.5951 18.1516 9.89763C18.1516 11.2095 17.2056 12.2778 15.9947 12.2778Z"
      fill="#FF2A57"
    />
  </svg>
);

export default DiscordIcon;
