/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Contract, utils } from "ethers";
const _abi = [
    {
        type: "function",
        name: "getRewardAddress",
        inputs: [],
        outputs: [
            {
                name: "rewardAddress",
                type: "address",
                internalType: "address payable",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "getSupportedKeys",
        inputs: [],
        outputs: [
            {
                name: "bitmap",
                type: "uint256",
                internalType: "uint256",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "getTargetChainAddress",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
        ],
        outputs: [
            {
                name: "deliveryProviderAddress",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "isChainSupported",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
        ],
        outputs: [
            {
                name: "supported",
                type: "bool",
                internalType: "bool",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "isMessageKeyTypeSupported",
        inputs: [
            {
                name: "keyType",
                type: "uint8",
                internalType: "uint8",
            },
        ],
        outputs: [
            {
                name: "supported",
                type: "bool",
                internalType: "bool",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "quoteAssetConversion",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "currentChainAmount",
                type: "uint256",
                internalType: "uint256",
            },
        ],
        outputs: [
            {
                name: "targetChainAmount",
                type: "uint256",
                internalType: "uint256",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "quoteDeliveryPrice",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "receiverValue",
                type: "uint256",
                internalType: "uint256",
            },
            {
                name: "encodedExecutionParams",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [
            {
                name: "nativePriceQuote",
                type: "uint256",
                internalType: "uint256",
            },
            {
                name: "encodedExecutionInfo",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        stateMutability: "view",
    },
];
export class IDeliveryProvider__factory {
    static createInterface() {
        return new utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    }
}
IDeliveryProvider__factory.abi = _abi;
