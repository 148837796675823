/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Contract, utils } from "ethers";
const _abi = [
    {
        type: "function",
        name: "checkAndExecuteUpgradeMigration",
        inputs: [],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "deliveryAttempted",
        inputs: [
            {
                name: "deliveryHash",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
        outputs: [
            {
                name: "attempted",
                type: "bool",
                internalType: "bool",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "deliveryFailureBlock",
        inputs: [
            {
                name: "deliveryHash",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
        outputs: [
            {
                name: "blockNumber",
                type: "uint256",
                internalType: "uint256",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "deliverySuccessBlock",
        inputs: [
            {
                name: "deliveryHash",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
        outputs: [
            {
                name: "blockNumber",
                type: "uint256",
                internalType: "uint256",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "getRegisteredWormholeRelayerContract",
        inputs: [
            {
                name: "chainId",
                type: "uint16",
                internalType: "uint16",
            },
        ],
        outputs: [
            {
                name: "",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "registerWormholeRelayerContract",
        inputs: [
            {
                name: "encodedVm",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "setDefaultDeliveryProvider",
        inputs: [
            {
                name: "encodedVm",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "submitContractUpgrade",
        inputs: [
            {
                name: "encodedVm",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "event",
        name: "AdminChanged",
        inputs: [
            {
                name: "previousAdmin",
                type: "address",
                indexed: false,
                internalType: "address",
            },
            {
                name: "newAdmin",
                type: "address",
                indexed: false,
                internalType: "address",
            },
        ],
        anonymous: false,
    },
    {
        type: "event",
        name: "BeaconUpgraded",
        inputs: [
            {
                name: "beacon",
                type: "address",
                indexed: true,
                internalType: "address",
            },
        ],
        anonymous: false,
    },
    {
        type: "event",
        name: "ContractUpgraded",
        inputs: [
            {
                name: "oldContract",
                type: "address",
                indexed: true,
                internalType: "address",
            },
            {
                name: "newContract",
                type: "address",
                indexed: true,
                internalType: "address",
            },
        ],
        anonymous: false,
    },
    {
        type: "event",
        name: "SendEvent",
        inputs: [
            {
                name: "sequence",
                type: "uint64",
                indexed: true,
                internalType: "uint64",
            },
            {
                name: "deliveryQuote",
                type: "uint256",
                indexed: false,
                internalType: "LocalNative",
            },
            {
                name: "paymentForExtraReceiverValue",
                type: "uint256",
                indexed: false,
                internalType: "LocalNative",
            },
        ],
        anonymous: false,
    },
    {
        type: "event",
        name: "Upgraded",
        inputs: [
            {
                name: "implementation",
                type: "address",
                indexed: true,
                internalType: "address",
            },
        ],
        anonymous: false,
    },
    {
        type: "error",
        name: "ChainAlreadyRegistered",
        inputs: [
            {
                name: "chainId",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "registeredWormholeRelayerContract",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
    },
    {
        type: "error",
        name: "ContractUpgradeFailed",
        inputs: [
            {
                name: "failure",
                type: "bytes",
                internalType: "bytes",
            },
        ],
    },
    {
        type: "error",
        name: "GovernanceActionAlreadyConsumed",
        inputs: [
            {
                name: "hash",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
    },
    {
        type: "error",
        name: "InvalidDefaultDeliveryProvider",
        inputs: [
            {
                name: "defaultDeliveryProvider",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
    },
    {
        type: "error",
        name: "InvalidFork",
        inputs: [],
    },
    {
        type: "error",
        name: "InvalidGovernanceChainId",
        inputs: [
            {
                name: "parsed",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "expected",
                type: "uint16",
                internalType: "uint16",
            },
        ],
    },
    {
        type: "error",
        name: "InvalidGovernanceContract",
        inputs: [
            {
                name: "parsed",
                type: "bytes32",
                internalType: "bytes32",
            },
            {
                name: "expected",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
    },
    {
        type: "error",
        name: "InvalidGovernanceVM",
        inputs: [
            {
                name: "reason",
                type: "string",
                internalType: "string",
            },
        ],
    },
    {
        type: "error",
        name: "InvalidPayloadAction",
        inputs: [
            {
                name: "parsed",
                type: "uint8",
                internalType: "uint8",
            },
            {
                name: "expected",
                type: "uint8",
                internalType: "uint8",
            },
        ],
    },
    {
        type: "error",
        name: "InvalidPayloadChainId",
        inputs: [
            {
                name: "parsed",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "expected",
                type: "uint16",
                internalType: "uint16",
            },
        ],
    },
    {
        type: "error",
        name: "InvalidPayloadLength",
        inputs: [
            {
                name: "received",
                type: "uint256",
                internalType: "uint256",
            },
            {
                name: "expected",
                type: "uint256",
                internalType: "uint256",
            },
        ],
    },
    {
        type: "error",
        name: "InvalidPayloadModule",
        inputs: [
            {
                name: "parsed",
                type: "bytes32",
                internalType: "bytes32",
            },
            {
                name: "expected",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
    },
    {
        type: "error",
        name: "NotAnEvmAddress",
        inputs: [
            {
                name: "",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
    },
];
export class WormholeRelayerGovernance__factory {
    static createInterface() {
        return new utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    }
}
WormholeRelayerGovernance__factory.abi = _abi;
