var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CHAIN_ID_TO_NAME, isChain, CONTRACTS, CHAINS, tryNativeToHexString, parseTransferPayload, } from "../..";
import { BigNumber, ethers } from "ethers";
import { getWormholeRelayerAddress } from "../consts";
import { RelayerPayloadId, RefundStatus, parseEVMExecutionInfoV1, KeyType, parseVaaKey, parseCCTPKey, } from "../structs";
import { getDefaultProvider, printChain, printCCTPDomain, getWormholeLog, parseWormholeLog, getDeliveryHashFromLog, getRelayerTransactionHashFromWormscan, getWormholeRelayerInfoByHash, getWormscanRelayerInfo, getWormscanInfo, estimatedAttestationTimeInSeconds, getCCTPMessageLogURL, } from "./helpers";
import { ERC20__factory } from "../../ethers-contracts";
import { IWormholeRelayer__factory } from "../../ethers-relayer-contracts";
export function getPriceAndRefundInfo(sourceChain, targetChain, gasAmount, optionalParams) {
    return __awaiter(this, void 0, void 0, function* () {
        const environment = (optionalParams === null || optionalParams === void 0 ? void 0 : optionalParams.environment) || "MAINNET";
        const sourceChainProvider = (optionalParams === null || optionalParams === void 0 ? void 0 : optionalParams.sourceChainProvider) ||
            getDefaultProvider(environment, sourceChain);
        if (!sourceChainProvider)
            throw Error("No default RPC for this chain; pass in your own provider (as sourceChainProvider)");
        const wormholeRelayerAddress = (optionalParams === null || optionalParams === void 0 ? void 0 : optionalParams.wormholeRelayerAddress) ||
            getWormholeRelayerAddress(sourceChain, environment);
        const sourceWormholeRelayer = IWormholeRelayer__factory.connect(wormholeRelayerAddress, sourceChainProvider);
        const deliveryProviderAddress = (optionalParams === null || optionalParams === void 0 ? void 0 : optionalParams.deliveryProviderAddress) ||
            (yield sourceWormholeRelayer.getDefaultDeliveryProvider());
        const targetChainId = CHAINS[targetChain];
        const priceAndRefundInfo = yield sourceWormholeRelayer["quoteEVMDeliveryPrice(uint16,uint256,uint256,address)"](targetChainId, (optionalParams === null || optionalParams === void 0 ? void 0 : optionalParams.receiverValue) || 0, gasAmount, deliveryProviderAddress);
        return priceAndRefundInfo;
    });
}
export function getPrice(sourceChain, targetChain, gasAmount, optionalParams) {
    return __awaiter(this, void 0, void 0, function* () {
        const priceAndRefundInfo = yield getPriceAndRefundInfo(sourceChain, targetChain, gasAmount, optionalParams);
        return priceAndRefundInfo[0];
    });
}
export function getWormholeRelayerInfo(sourceChain, sourceTransaction, infoRequest) {
    var _a, _b, _c, _d, _e, _f, _g;
    return __awaiter(this, void 0, void 0, function* () {
        const environment = (infoRequest === null || infoRequest === void 0 ? void 0 : infoRequest.environment) || "MAINNET";
        const sourceChainProvider = (infoRequest === null || infoRequest === void 0 ? void 0 : infoRequest.sourceChainProvider) ||
            getDefaultProvider(environment, sourceChain);
        if (!sourceChainProvider)
            throw Error("No default RPC for this chain; pass in your own provider (as sourceChainProvider)");
        const receipt = yield sourceChainProvider.getTransactionReceipt(sourceTransaction);
        if (!receipt)
            throw Error(`Transaction has not been mined: ${sourceTransaction} on ${sourceChain} (${environment})`);
        const sourceTimestamp = (yield sourceChainProvider.getBlock(receipt.blockNumber)).timestamp * 1000;
        const bridgeAddress = CONTRACTS[environment][sourceChain].core;
        const wormholeRelayerAddress = ((_a = infoRequest === null || infoRequest === void 0 ? void 0 : infoRequest.wormholeRelayerAddresses) === null || _a === void 0 ? void 0 : _a.get(sourceChain)) ||
            getWormholeRelayerAddress(sourceChain, environment);
        if (!bridgeAddress || !wormholeRelayerAddress) {
            throw Error(`Invalid chain ID or network: Chain ${sourceChain}, ${environment}`);
        }
        const deliveryLog = getWormholeLog(receipt, bridgeAddress, tryNativeToHexString(wormholeRelayerAddress, "ethereum"), (infoRequest === null || infoRequest === void 0 ? void 0 : infoRequest.wormholeRelayerWhMessageIndex)
            ? infoRequest.wormholeRelayerWhMessageIndex
            : 0);
        const { type, parsed } = parseWormholeLog(deliveryLog.log);
        if (type === RelayerPayloadId.Redelivery) {
            const redeliveryInstruction = parsed;
            if (!isChain(redeliveryInstruction.deliveryVaaKey.chainId)) {
                throw new Error(`The chain ID specified by this redelivery is invalid: ${redeliveryInstruction.deliveryVaaKey.chainId}`);
            }
            if (!isChain(redeliveryInstruction.targetChainId)) {
                throw new Error(`The target chain ID specified by this redelivery is invalid: ${redeliveryInstruction.targetChainId}`);
            }
            const originalSourceChainName = CHAIN_ID_TO_NAME[redeliveryInstruction.deliveryVaaKey.chainId];
            const modifiedInfoRequest = infoRequest;
            if (modifiedInfoRequest === null || modifiedInfoRequest === void 0 ? void 0 : modifiedInfoRequest.sourceChainProvider) {
                modifiedInfoRequest.sourceChainProvider =
                    (_b = modifiedInfoRequest === null || modifiedInfoRequest === void 0 ? void 0 : modifiedInfoRequest.targetChainProviders) === null || _b === void 0 ? void 0 : _b.get(originalSourceChainName);
            }
            const transactionHash = yield getRelayerTransactionHashFromWormscan(originalSourceChainName, redeliveryInstruction.deliveryVaaKey.sequence.toNumber(), {
                network: infoRequest === null || infoRequest === void 0 ? void 0 : infoRequest.environment,
                provider: (_c = infoRequest === null || infoRequest === void 0 ? void 0 : infoRequest.targetChainProviders) === null || _c === void 0 ? void 0 : _c.get(originalSourceChainName),
                wormholeRelayerAddress: (_d = infoRequest === null || infoRequest === void 0 ? void 0 : infoRequest.wormholeRelayerAddresses) === null || _d === void 0 ? void 0 : _d.get(originalSourceChainName),
            });
            return getWormholeRelayerInfo(originalSourceChainName, transactionHash, modifiedInfoRequest);
        }
        const instruction = parsed;
        const targetChainId = instruction.targetChainId;
        if (!isChain(targetChainId))
            throw Error(`Invalid Chain: ${targetChainId}`);
        const targetChain = CHAIN_ID_TO_NAME[targetChainId];
        const targetChainProvider = ((_e = infoRequest === null || infoRequest === void 0 ? void 0 : infoRequest.targetChainProviders) === null || _e === void 0 ? void 0 : _e.get(targetChain)) ||
            getDefaultProvider(environment, targetChain);
        if (!targetChainProvider) {
            throw Error("No default RPC for this chain; pass in your own provider (as targetChainProvider)");
        }
        const sourceSequence = BigNumber.from(deliveryLog.sequence);
        const deliveryHash = yield getDeliveryHashFromLog(deliveryLog.log, CHAINS[sourceChain], sourceChainProvider, receipt.blockHash);
        let signingOfVaaTimestamp;
        try {
            const vaa = yield getWormscanRelayerInfo(sourceChain, sourceSequence.toNumber(), {
                network: infoRequest === null || infoRequest === void 0 ? void 0 : infoRequest.environment,
                provider: infoRequest === null || infoRequest === void 0 ? void 0 : infoRequest.sourceChainProvider,
                wormholeRelayerAddress: (_f = infoRequest === null || infoRequest === void 0 ? void 0 : infoRequest.wormholeRelayerAddresses) === null || _f === void 0 ? void 0 : _f.get(sourceChain),
            });
            signingOfVaaTimestamp = new Date((_g = (yield vaa.json()).data) === null || _g === void 0 ? void 0 : _g.indexedAt).getTime();
        }
        catch (_h) {
            // wormscan won't work for devnet - so let's hardcode this
            if (environment === "DEVNET") {
                signingOfVaaTimestamp = sourceTimestamp;
            }
        }
        // obtain additional message info
        const additionalMessageInformation = yield Promise.all(instruction.messageKeys.map((messageKey) => __awaiter(this, void 0, void 0, function* () {
            var _j, _k;
            if (messageKey.keyType === 1) {
                // check receipt
                const vaaKey = parseVaaKey(messageKey.key);
                // if token bridge transfer in logs, parse it
                let tokenBridgeLog;
                const tokenBridgeEmitterAddress = tryNativeToHexString(CONTRACTS[environment][sourceChain].token_bridge || "", sourceChain);
                try {
                    if (vaaKey.chainId === CHAINS[sourceChain] &&
                        vaaKey.emitterAddress.toString("hex") ===
                            tokenBridgeEmitterAddress) {
                        tokenBridgeLog = getWormholeLog(receipt, CONTRACTS[environment][sourceChain].core || "", tokenBridgeEmitterAddress, 0, vaaKey.sequence.toNumber());
                    }
                }
                catch (e) {
                    console.log(e);
                }
                if (!tokenBridgeLog)
                    return undefined;
                const parsedTokenInfo = parseTransferPayload(Buffer.from(tokenBridgeLog.payload.substring(2), "hex"));
                const originChainName = CHAIN_ID_TO_NAME[parsedTokenInfo.originChain];
                let signedVaaTimestamp = undefined;
                let tokenName = undefined;
                let tokenSymbol = undefined;
                let tokenDecimals = undefined;
                // Try to get additional token information, assuming it is an ERC20
                try {
                    const tokenProvider = (parsedTokenInfo.originChain === CHAINS[sourceChain]
                        ? infoRequest === null || infoRequest === void 0 ? void 0 : infoRequest.sourceChainProvider
                        : (_j = infoRequest === null || infoRequest === void 0 ? void 0 : infoRequest.targetChainProviders) === null || _j === void 0 ? void 0 : _j.get(originChainName)) ||
                        getDefaultProvider(environment, originChainName);
                    const tokenContract = ERC20__factory.connect("0x" + parsedTokenInfo.originAddress.substring(24), tokenProvider);
                    tokenName = yield tokenContract.name();
                    tokenSymbol = yield tokenContract.symbol();
                    tokenDecimals = yield tokenContract.decimals();
                }
                catch (e) {
                    console.log(e);
                }
                // Try to get wormscan information on if the tokens have been signed
                try {
                    const tokenVaa = yield getWormscanInfo(environment, sourceChain, parseInt(tokenBridgeLog.sequence), CONTRACTS[environment][sourceChain].token_bridge || "");
                    signedVaaTimestamp = new Date((_k = (yield tokenVaa.json()).data) === null || _k === void 0 ? void 0 : _k.indexedAt).getTime();
                }
                catch (_l) { }
                const parsed = {
                    amount: BigNumber.from(parsedTokenInfo.amount)
                        .mul(BigNumber.from(10).pow(tokenDecimals && tokenDecimals > 8 ? tokenDecimals - 8 : 1))
                        .toBigInt(),
                    originAddress: parsedTokenInfo.originAddress,
                    originChain: parsedTokenInfo.originChain,
                    targetAddress: parsedTokenInfo.targetAddress,
                    targetChain: parsedTokenInfo.targetChain,
                    fromAddress: parsedTokenInfo.fromAddress,
                    name: tokenName,
                    symbol: tokenSymbol,
                    decimals: tokenDecimals,
                    signedVaaTimestamp,
                };
                return parsed;
            }
            else if (messageKey.keyType === 2) {
                // check receipt
                const cctpKey = parseCCTPKey(messageKey.key);
                const cctpInfo = yield getCCTPMessageLogURL(cctpKey, sourceChain, receipt, environment);
                const url = (cctpInfo === null || cctpInfo === void 0 ? void 0 : cctpInfo.url) || "";
                // Try to get attestation information on if the tokens have been signed
                let attested = false;
                try {
                    const attestation = yield fetch(url);
                    attested = (yield attestation.json()).status === "complete";
                }
                catch (e) {
                    console.log(e);
                }
                const cctpLog = cctpInfo === null || cctpInfo === void 0 ? void 0 : cctpInfo.cctpLog;
                const parsed = {
                    amount: BigNumber.from(Buffer.from(cctpLog.data.substring(2, 2 + 64), "hex")).toBigInt(),
                    mintRecipient: "0x" + cctpLog.data.substring(2 + 64 + 24, 2 + 128),
                    destinationDomain: BigNumber.from(Buffer.from(cctpLog.data.substring(2 + 128, 2 + 192), "hex")).toNumber(),
                    attested,
                    estimatedAttestationSeconds: estimatedAttestationTimeInSeconds(sourceChain, environment),
                };
                return parsed;
            }
            else {
                return undefined;
            }
        })));
        const targetChainDeliveries = yield getWormholeRelayerInfoByHash(deliveryHash, targetChain, sourceChain, sourceSequence.toNumber(), infoRequest);
        const result = {
            type: RelayerPayloadId.Delivery,
            sourceChain: sourceChain,
            sourceTransactionHash: sourceTransaction,
            sourceDeliverySequenceNumber: sourceSequence.toNumber(),
            deliveryInstruction: instruction,
            sourceTimestamp,
            signingOfVaaTimestamp,
            additionalMessageInformation,
            targetChainStatus: {
                chain: targetChain,
                events: targetChainDeliveries,
            },
        };
        const stringified = stringifyWormholeRelayerInfo(result);
        result.stringified = stringified;
        return result;
    });
}
export function printWormholeRelayerInfo(info) {
    console.log(stringifyWormholeRelayerInfo(info));
}
export function stringifyWormholeRelayerInfo(info, excludeSourceInformation, overrides) {
    let stringifiedInfo = "";
    if (info.type == RelayerPayloadId.Delivery &&
        info.deliveryInstruction.targetAddress.toString("hex") !==
            "0000000000000000000000000000000000000000000000000000000000000000") {
        if (!excludeSourceInformation) {
            stringifiedInfo += `Source chain: ${info.sourceChain}\n`;
            stringifiedInfo += `Source Transaction Hash: ${info.sourceTransactionHash}\n`;
            stringifiedInfo += `Sender: ${"0x" +
                info.deliveryInstruction.senderAddress.toString("hex").substring(24)}\n`;
            stringifiedInfo += `Delivery sequence number: ${info.sourceDeliverySequenceNumber}\n`;
        }
        else {
            stringifiedInfo += `Sender: ${info.deliveryInstruction.senderAddress.toString("hex")}\n`;
        }
        const numMsgs = info.deliveryInstruction.messageKeys.length;
        const payload = info.deliveryInstruction.payload.toString("hex");
        if (payload.length > 0) {
            stringifiedInfo += `\nPayload to be relayed: 0x${payload}\n`;
        }
        if (numMsgs > 0) {
            stringifiedInfo += `\nThe following ${numMsgs === 1 ? "" : `${numMsgs} `}message${numMsgs === 1 ? " was" : "s were"} ${payload.length > 0 ? "also " : ""}requested to be relayed with this delivery:\n`;
            stringifiedInfo += info.deliveryInstruction.messageKeys
                .map((msgKey, i) => {
                var _a;
                let result = "";
                if (msgKey.keyType == KeyType.VAA) {
                    const vaaKey = parseVaaKey(msgKey.key);
                    result += `(Message ${i + 1}): `;
                    result += `Wormhole VAA from ${vaaKey.chainId ? printChain(vaaKey.chainId) : ""}, with emitter address ${(_a = vaaKey.emitterAddress) === null || _a === void 0 ? void 0 : _a.toString("hex")} and sequence number ${vaaKey.sequence}`;
                    if (info.additionalMessageInformation[i]) {
                        const tokenTransferInfo = info.additionalMessageInformation[i];
                        result += `\nThis is a token bridge transfer of ${tokenTransferInfo.decimals
                            ? `${ethers.utils.formatUnits(tokenTransferInfo.amount, tokenTransferInfo.decimals)} `
                            : `${tokenTransferInfo.amount} normalized units of `}${tokenTransferInfo.name
                            ? `${tokenTransferInfo.name} (${tokenTransferInfo.symbol})`
                            : `token ${tokenTransferInfo.originAddress.substring(24)} (which is native to ${printChain(tokenTransferInfo.originChain)})`}`;
                        if (tokenTransferInfo.signedVaaTimestamp) {
                            result += `\ntransfer signed by guardians: ${new Date(tokenTransferInfo.signedVaaTimestamp).toString()}`;
                        }
                        else {
                            result += `\ntransfer not yet signed by guardians`;
                        }
                    }
                }
                else if (msgKey.keyType == KeyType.CCTP) {
                    const cctpKey = parseCCTPKey(msgKey.key);
                    result += `(Message ${i + 1}): `;
                    result += `CCTP Transfer from domain ${printCCTPDomain(cctpKey.domain)}`;
                    result += `, with nonce ${cctpKey.nonce}`;
                    if (info.additionalMessageInformation[i]) {
                        const cctpTransferInfo = info.additionalMessageInformation[i];
                        result += `\nThis is a CCTP transfer of ${`${ethers.utils.formatUnits(cctpTransferInfo.amount, 6)}`} USDC ${cctpTransferInfo.attested
                            ? "(Attestation is complete"
                            : "(Attestation currently pending"}, typically takes ${cctpTransferInfo.estimatedAttestationSeconds < 60
                            ? `${cctpTransferInfo.estimatedAttestationSeconds} seconds`
                            : `${cctpTransferInfo.estimatedAttestationSeconds / 60} minutes`})`;
                    }
                }
                else {
                    result += `(Unknown key type ${i}): ${msgKey.keyType}`;
                }
                return result;
            })
                .join(",\n");
        }
        if (payload.length == 0 && numMsgs == 0) {
            stringifiedInfo += `\nAn empty payload was requested to be sent`;
        }
        const instruction = info.deliveryInstruction;
        if (overrides) {
            instruction.requestedReceiverValue = overrides.newReceiverValue;
            instruction.encodedExecutionInfo = overrides.newExecutionInfo;
        }
        const targetChainName = CHAIN_ID_TO_NAME[instruction.targetChainId];
        stringifiedInfo += `\n\nDestination chain: ${printChain(instruction.targetChainId)}\nDestination address: 0x${instruction.targetAddress
            .toString("hex")
            .substring(24)}\n\n`;
        const totalReceiverValue = instruction.requestedReceiverValue.add(instruction.extraReceiverValue);
        stringifiedInfo += totalReceiverValue.gt(0)
            ? `Amount to pass into target address: ${ethers.utils.formatEther(totalReceiverValue)} of ${targetChainName} currency ${instruction.extraReceiverValue.gt(0)
                ? `\n${ethers.utils.formatEther(instruction.requestedReceiverValue)} requested, ${ethers.utils.formatEther(instruction.extraReceiverValue)} additionally paid for`
                : ""}\n`
            : ``;
        const [executionInfo] = parseEVMExecutionInfoV1(instruction.encodedExecutionInfo, 0);
        stringifiedInfo += `Gas limit: ${executionInfo.gasLimit} ${targetChainName} gas\n`;
        const refundAddressChosen = instruction.refundAddress.toString("hex") !==
            "0000000000000000000000000000000000000000000000000000000000000000";
        if (refundAddressChosen) {
            stringifiedInfo += `Refund rate: ${ethers.utils.formatEther(executionInfo.targetChainRefundPerGasUnused)} of ${targetChainName} currency per unit of gas unused\n`;
            stringifiedInfo += `Refund address: ${instruction.refundAddress.toString("hex")} on ${printChain(instruction.refundChainId)}\n`;
        }
        stringifiedInfo += `\n`;
        if (info.sourceTimestamp) {
            stringifiedInfo += `Sent: ${new Date(info.sourceTimestamp).toString()}\n`;
        }
        if (info.signingOfVaaTimestamp) {
            stringifiedInfo += `Delivery vaa signed by guardians: ${new Date(info.signingOfVaaTimestamp).toString()}\n`;
        }
        else {
            stringifiedInfo += `Delivery not yet signed by guardians - check https://wormhole-foundation.github.io/wormhole-dashboard/#/ for status\n`;
        }
        stringifiedInfo += `\n`;
        if (info.targetChainStatus.events.length === 0) {
            stringifiedInfo += "Delivery has not occured yet\n";
        }
        stringifiedInfo += info.targetChainStatus.events
            .map((e, i) => {
            let override = e.overrides || false;
            let overriddenExecutionInfo = e.overrides
                ? parseEVMExecutionInfoV1(e.overrides.newExecutionInfo, 0)[0]
                : executionInfo;
            let overriddenReceiverValue = e.overrides
                ? e.overrides.newReceiverValue
                : totalReceiverValue;
            const overriddenGasLimit = override
                ? overriddenExecutionInfo.gasLimit
                : executionInfo.gasLimit;
            // Add information about any override applied to the delivery
            let overrideStringifiedInfo = "";
            if (override) {
                overrideStringifiedInfo += !overriddenReceiverValue.eq(totalReceiverValue)
                    ? `Overridden amount to pass into target address: ${ethers.utils.formatEther(overriddenReceiverValue)} of ${targetChainName} currency\n`
                    : ``;
                overrideStringifiedInfo += !(overriddenGasLimit === executionInfo.gasLimit)
                    ? `Overridden gas limit: ${overriddenExecutionInfo.gasLimit} ${targetChainName} gas\n`
                    : "";
                if (refundAddressChosen &&
                    executionInfo.targetChainRefundPerGasUnused !==
                        overriddenExecutionInfo.targetChainRefundPerGasUnused) {
                    overrideStringifiedInfo += `Overridden refund rate: ${ethers.utils.formatEther(overriddenExecutionInfo.targetChainRefundPerGasUnused)} of ${targetChainName} currency per unit of gas unused\n`;
                }
            }
            return `Delivery attempt: ${e.transactionHash
                ? ` ${targetChainName} transaction hash: ${e.transactionHash}`
                : ""}\nDelivery Time: ${new Date(e.timestamp).toString()}\n${overrideStringifiedInfo}Status: ${e.status}\n${e.revertString
                ? `Failure reason: ${e.gasUsed.eq(overriddenExecutionInfo.gasLimit)
                    ? "Gas limit hit"
                    : e.revertString}\n`
                : ""}Gas used: ${e.gasUsed.toString()}\nTransaction fee used: ${ethers.utils.formatEther(overriddenExecutionInfo.targetChainRefundPerGasUnused.mul(e.gasUsed))} of ${targetChainName} currency\n${`Refund amount: ${ethers.utils.formatEther(overriddenExecutionInfo.targetChainRefundPerGasUnused.mul(overriddenExecutionInfo.gasLimit.sub(e.gasUsed)))} of ${targetChainName} currency \nRefund status: ${e.refundStatus}\n`}`;
        })
            .join("\n");
    }
    else if (info.type == RelayerPayloadId.Delivery &&
        info.deliveryInstruction.targetAddress.toString("hex") ===
            "0000000000000000000000000000000000000000000000000000000000000000") {
        stringifiedInfo += `Found delivery request in transaction ${info.sourceTransactionHash} on ${info.sourceChain}\n`;
        const instruction = info.deliveryInstruction;
        const targetChainName = CHAIN_ID_TO_NAME[instruction.targetChainId];
        stringifiedInfo += `\nA refund of ${ethers.utils.formatEther(instruction.extraReceiverValue)} ${targetChainName} currency was requested to be sent to ${targetChainName}, address 0x${info.deliveryInstruction.refundAddress.toString("hex")}\n\n`;
        stringifiedInfo += info.targetChainStatus.events
            .map((e, i) => `Delivery attempt: ${e.transactionHash
            ? ` ${targetChainName} transaction hash: ${e.transactionHash}`
            : ""}\nStatus: ${e.refundStatus == RefundStatus.RefundSent
            ? "Refund Successful"
            : "Refund Failed"}`)
            .join("\n");
    }
    return stringifiedInfo;
}
