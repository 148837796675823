/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Contract, utils } from "ethers";
const _abi = [
    {
        type: "function",
        name: "encodeAndSignMessage",
        inputs: [
            {
                name: "vm_",
                type: "tuple",
                internalType: "struct IWormhole.VM",
                components: [
                    {
                        name: "version",
                        type: "uint8",
                        internalType: "uint8",
                    },
                    {
                        name: "timestamp",
                        type: "uint32",
                        internalType: "uint32",
                    },
                    {
                        name: "nonce",
                        type: "uint32",
                        internalType: "uint32",
                    },
                    {
                        name: "emitterChainId",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "emitterAddress",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "sequence",
                        type: "uint64",
                        internalType: "uint64",
                    },
                    {
                        name: "consistencyLevel",
                        type: "uint8",
                        internalType: "uint8",
                    },
                    {
                        name: "payload",
                        type: "bytes",
                        internalType: "bytes",
                    },
                    {
                        name: "guardianSetIndex",
                        type: "uint32",
                        internalType: "uint32",
                    },
                    {
                        name: "signatures",
                        type: "tuple[]",
                        internalType: "struct IWormhole.Signature[]",
                        components: [
                            {
                                name: "r",
                                type: "bytes32",
                                internalType: "bytes32",
                            },
                            {
                                name: "s",
                                type: "bytes32",
                                internalType: "bytes32",
                            },
                            {
                                name: "v",
                                type: "uint8",
                                internalType: "uint8",
                            },
                            {
                                name: "guardianIndex",
                                type: "uint8",
                                internalType: "uint8",
                            },
                        ],
                    },
                    {
                        name: "hash",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                ],
            },
        ],
        outputs: [
            {
                name: "signedMessage",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "encodeObservation",
        inputs: [
            {
                name: "vm_",
                type: "tuple",
                internalType: "struct IWormhole.VM",
                components: [
                    {
                        name: "version",
                        type: "uint8",
                        internalType: "uint8",
                    },
                    {
                        name: "timestamp",
                        type: "uint32",
                        internalType: "uint32",
                    },
                    {
                        name: "nonce",
                        type: "uint32",
                        internalType: "uint32",
                    },
                    {
                        name: "emitterChainId",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "emitterAddress",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "sequence",
                        type: "uint64",
                        internalType: "uint64",
                    },
                    {
                        name: "consistencyLevel",
                        type: "uint8",
                        internalType: "uint8",
                    },
                    {
                        name: "payload",
                        type: "bytes",
                        internalType: "bytes",
                    },
                    {
                        name: "guardianSetIndex",
                        type: "uint32",
                        internalType: "uint32",
                    },
                    {
                        name: "signatures",
                        type: "tuple[]",
                        internalType: "struct IWormhole.Signature[]",
                        components: [
                            {
                                name: "r",
                                type: "bytes32",
                                internalType: "bytes32",
                            },
                            {
                                name: "s",
                                type: "bytes32",
                                internalType: "bytes32",
                            },
                            {
                                name: "v",
                                type: "uint8",
                                internalType: "uint8",
                            },
                            {
                                name: "guardianIndex",
                                type: "uint8",
                                internalType: "uint8",
                            },
                        ],
                    },
                    {
                        name: "hash",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                ],
            },
        ],
        outputs: [
            {
                name: "encodedObservation",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        stateMutability: "pure",
    },
    {
        type: "function",
        name: "fetchSignedMessageFromLogs",
        inputs: [
            {
                name: "log",
                type: "tuple",
                internalType: "struct VmSafe.Log",
                components: [
                    {
                        name: "topics",
                        type: "bytes32[]",
                        internalType: "bytes32[]",
                    },
                    {
                        name: "data",
                        type: "bytes",
                        internalType: "bytes",
                    },
                    {
                        name: "emitter",
                        type: "address",
                        internalType: "address",
                    },
                ],
            },
            {
                name: "emitterChainId",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "emitterAddress",
                type: "address",
                internalType: "address",
            },
        ],
        outputs: [
            {
                name: "signedMessage",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "fetchWormholeMessageFromLog",
        inputs: [
            {
                name: "logs",
                type: "tuple[]",
                internalType: "struct VmSafe.Log[]",
                components: [
                    {
                        name: "topics",
                        type: "bytes32[]",
                        internalType: "bytes32[]",
                    },
                    {
                        name: "data",
                        type: "bytes",
                        internalType: "bytes",
                    },
                    {
                        name: "emitter",
                        type: "address",
                        internalType: "address",
                    },
                ],
            },
        ],
        outputs: [
            {
                name: "",
                type: "tuple[]",
                internalType: "struct VmSafe.Log[]",
                components: [
                    {
                        name: "topics",
                        type: "bytes32[]",
                        internalType: "bytes32[]",
                    },
                    {
                        name: "data",
                        type: "bytes",
                        internalType: "bytes",
                    },
                    {
                        name: "emitter",
                        type: "address",
                        internalType: "address",
                    },
                ],
            },
        ],
        stateMutability: "pure",
    },
    {
        type: "function",
        name: "invalidateVM",
        inputs: [
            {
                name: "message",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "function",
        name: "parseVMFromLogs",
        inputs: [
            {
                name: "log",
                type: "tuple",
                internalType: "struct VmSafe.Log",
                components: [
                    {
                        name: "topics",
                        type: "bytes32[]",
                        internalType: "bytes32[]",
                    },
                    {
                        name: "data",
                        type: "bytes",
                        internalType: "bytes",
                    },
                    {
                        name: "emitter",
                        type: "address",
                        internalType: "address",
                    },
                ],
            },
        ],
        outputs: [
            {
                name: "vm_",
                type: "tuple",
                internalType: "struct IWormhole.VM",
                components: [
                    {
                        name: "version",
                        type: "uint8",
                        internalType: "uint8",
                    },
                    {
                        name: "timestamp",
                        type: "uint32",
                        internalType: "uint32",
                    },
                    {
                        name: "nonce",
                        type: "uint32",
                        internalType: "uint32",
                    },
                    {
                        name: "emitterChainId",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "emitterAddress",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "sequence",
                        type: "uint64",
                        internalType: "uint64",
                    },
                    {
                        name: "consistencyLevel",
                        type: "uint8",
                        internalType: "uint8",
                    },
                    {
                        name: "payload",
                        type: "bytes",
                        internalType: "bytes",
                    },
                    {
                        name: "guardianSetIndex",
                        type: "uint32",
                        internalType: "uint32",
                    },
                    {
                        name: "signatures",
                        type: "tuple[]",
                        internalType: "struct IWormhole.Signature[]",
                        components: [
                            {
                                name: "r",
                                type: "bytes32",
                                internalType: "bytes32",
                            },
                            {
                                name: "s",
                                type: "bytes32",
                                internalType: "bytes32",
                            },
                            {
                                name: "v",
                                type: "uint8",
                                internalType: "uint8",
                            },
                            {
                                name: "guardianIndex",
                                type: "uint8",
                                internalType: "uint8",
                            },
                        ],
                    },
                    {
                        name: "hash",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                ],
            },
        ],
        stateMutability: "pure",
    },
    {
        type: "function",
        name: "setMessageFee",
        inputs: [
            {
                name: "newFee",
                type: "uint256",
                internalType: "uint256",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
];
export class WormholeSimulator__factory {
    static createInterface() {
        return new utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    }
}
WormholeSimulator__factory.abi = _abi;
