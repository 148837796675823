var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ensureHexPrefix } from "..";
import { getSignedVAAHash } from "../bridge";
import { NFTBridge__factory } from "../ethers-contracts";
import { getClaim } from "../solana/wormhole";
import { parseVaa } from "../vaa/wormhole";
export function getIsTransferCompletedEth(nftBridgeAddress, provider, signedVAA) {
    return __awaiter(this, void 0, void 0, function* () {
        const nftBridge = NFTBridge__factory.connect(nftBridgeAddress, provider);
        const signedVAAHash = getSignedVAAHash(signedVAA);
        return yield nftBridge.isTransferCompleted(signedVAAHash);
    });
}
export function getIsTransferCompletedSolana(nftBridgeAddress, signedVAA, connection, commitment) {
    return __awaiter(this, void 0, void 0, function* () {
        const parsed = parseVaa(signedVAA);
        return getClaim(connection, nftBridgeAddress, parsed.emitterAddress, parsed.emitterChain, parsed.sequence, commitment).catch((e) => false);
    });
}
export function getIsTransferCompletedAptos(client, nftBridgeAddress, transferVaa) {
    return __awaiter(this, void 0, void 0, function* () {
        // get handle
        nftBridgeAddress = ensureHexPrefix(nftBridgeAddress);
        const state = (yield client.getAccountResource(nftBridgeAddress, `${nftBridgeAddress}::state::State`)).data;
        const handle = state.consumed_vaas.elems.handle;
        // check if vaa hash is in consumed_vaas
        const transferVaaHash = getSignedVAAHash(transferVaa);
        try {
            // when accessing Set<T>, key is type T and value is 0
            yield client.getTableItem(handle, {
                key_type: "vector<u8>",
                value_type: "u8",
                key: transferVaaHash,
            });
            return true;
        }
        catch (_a) {
            return false;
        }
    });
}
