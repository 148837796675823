/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Contract, utils } from "ethers";
const _abi = [
    {
        type: "function",
        name: "receiveWormholeMessages",
        inputs: [
            {
                name: "payload",
                type: "bytes",
                internalType: "bytes",
            },
            {
                name: "additionalMessages",
                type: "bytes[]",
                internalType: "bytes[]",
            },
            {
                name: "sourceAddress",
                type: "bytes32",
                internalType: "bytes32",
            },
            {
                name: "sourceChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "deliveryHash",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
        outputs: [],
        stateMutability: "payable",
    },
];
export class IWormholeReceiver__factory {
    static createInterface() {
        return new utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    }
}
IWormholeReceiver__factory.abi = _abi;
