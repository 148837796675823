/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Contract, utils } from "ethers";
const _abi = [
    {
        type: "function",
        name: "deliveryAttempted",
        inputs: [
            {
                name: "deliveryHash",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
        outputs: [
            {
                name: "attempted",
                type: "bool",
                internalType: "bool",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "deliveryFailureBlock",
        inputs: [
            {
                name: "deliveryHash",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
        outputs: [
            {
                name: "blockNumber",
                type: "uint256",
                internalType: "uint256",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "deliverySuccessBlock",
        inputs: [
            {
                name: "deliveryHash",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
        outputs: [
            {
                name: "blockNumber",
                type: "uint256",
                internalType: "uint256",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "forward",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "targetAddress",
                type: "bytes32",
                internalType: "bytes32",
            },
            {
                name: "payload",
                type: "bytes",
                internalType: "bytes",
            },
            {
                name: "receiverValue",
                type: "uint256",
                internalType: "TargetNative",
            },
            {
                name: "",
                type: "uint256",
                internalType: "LocalNative",
            },
            {
                name: "encodedExecutionParameters",
                type: "bytes",
                internalType: "bytes",
            },
            {
                name: "refundChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "refundAddress",
                type: "bytes32",
                internalType: "bytes32",
            },
            {
                name: "deliveryProviderAddress",
                type: "address",
                internalType: "address",
            },
            {
                name: "vaaKeys",
                type: "tuple[]",
                internalType: "struct VaaKey[]",
                components: [
                    {
                        name: "chainId",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "emitterAddress",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "sequence",
                        type: "uint64",
                        internalType: "uint64",
                    },
                ],
            },
            {
                name: "consistencyLevel",
                type: "uint8",
                internalType: "uint8",
            },
        ],
        outputs: [],
        stateMutability: "payable",
    },
    {
        type: "function",
        name: "forwardPayloadToEvm",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "targetAddress",
                type: "address",
                internalType: "address",
            },
            {
                name: "payload",
                type: "bytes",
                internalType: "bytes",
            },
            {
                name: "receiverValue",
                type: "uint256",
                internalType: "TargetNative",
            },
            {
                name: "gasLimit",
                type: "uint256",
                internalType: "Gas",
            },
        ],
        outputs: [],
        stateMutability: "payable",
    },
    {
        type: "function",
        name: "forwardToEvm",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "targetAddress",
                type: "address",
                internalType: "address",
            },
            {
                name: "payload",
                type: "bytes",
                internalType: "bytes",
            },
            {
                name: "receiverValue",
                type: "uint256",
                internalType: "TargetNative",
            },
            {
                name: "paymentForExtraReceiverValue",
                type: "uint256",
                internalType: "LocalNative",
            },
            {
                name: "gasLimit",
                type: "uint256",
                internalType: "Gas",
            },
            {
                name: "refundChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "refundAddress",
                type: "address",
                internalType: "address",
            },
            {
                name: "deliveryProviderAddress",
                type: "address",
                internalType: "address",
            },
            {
                name: "vaaKeys",
                type: "tuple[]",
                internalType: "struct VaaKey[]",
                components: [
                    {
                        name: "chainId",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "emitterAddress",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "sequence",
                        type: "uint64",
                        internalType: "uint64",
                    },
                ],
            },
            {
                name: "consistencyLevel",
                type: "uint8",
                internalType: "uint8",
            },
        ],
        outputs: [],
        stateMutability: "payable",
    },
    {
        type: "function",
        name: "forwardVaasToEvm",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "targetAddress",
                type: "address",
                internalType: "address",
            },
            {
                name: "payload",
                type: "bytes",
                internalType: "bytes",
            },
            {
                name: "receiverValue",
                type: "uint256",
                internalType: "TargetNative",
            },
            {
                name: "gasLimit",
                type: "uint256",
                internalType: "Gas",
            },
            {
                name: "vaaKeys",
                type: "tuple[]",
                internalType: "struct VaaKey[]",
                components: [
                    {
                        name: "chainId",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "emitterAddress",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "sequence",
                        type: "uint64",
                        internalType: "uint64",
                    },
                ],
            },
        ],
        outputs: [],
        stateMutability: "payable",
    },
    {
        type: "function",
        name: "getDefaultDeliveryProvider",
        inputs: [],
        outputs: [
            {
                name: "deliveryProvider",
                type: "address",
                internalType: "address",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "getRegisteredWormholeRelayerContract",
        inputs: [
            {
                name: "chainId",
                type: "uint16",
                internalType: "uint16",
            },
        ],
        outputs: [
            {
                name: "",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "quoteDeliveryPrice",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "receiverValue",
                type: "uint256",
                internalType: "TargetNative",
            },
            {
                name: "encodedExecutionParameters",
                type: "bytes",
                internalType: "bytes",
            },
            {
                name: "deliveryProviderAddress",
                type: "address",
                internalType: "address",
            },
        ],
        outputs: [
            {
                name: "nativePriceQuote",
                type: "uint256",
                internalType: "LocalNative",
            },
            {
                name: "encodedExecutionInfo",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "quoteEVMDeliveryPrice",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "receiverValue",
                type: "uint256",
                internalType: "TargetNative",
            },
            {
                name: "gasLimit",
                type: "uint256",
                internalType: "Gas",
            },
            {
                name: "deliveryProviderAddress",
                type: "address",
                internalType: "address",
            },
        ],
        outputs: [
            {
                name: "nativePriceQuote",
                type: "uint256",
                internalType: "LocalNative",
            },
            {
                name: "targetChainRefundPerGasUnused",
                type: "uint256",
                internalType: "GasPrice",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "quoteEVMDeliveryPrice",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "receiverValue",
                type: "uint256",
                internalType: "TargetNative",
            },
            {
                name: "gasLimit",
                type: "uint256",
                internalType: "Gas",
            },
        ],
        outputs: [
            {
                name: "nativePriceQuote",
                type: "uint256",
                internalType: "LocalNative",
            },
            {
                name: "targetChainRefundPerGasUnused",
                type: "uint256",
                internalType: "GasPrice",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "quoteNativeForChain",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "currentChainAmount",
                type: "uint256",
                internalType: "LocalNative",
            },
            {
                name: "deliveryProviderAddress",
                type: "address",
                internalType: "address",
            },
        ],
        outputs: [
            {
                name: "targetChainAmount",
                type: "uint256",
                internalType: "TargetNative",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "resend",
        inputs: [
            {
                name: "deliveryVaaKey",
                type: "tuple",
                internalType: "struct VaaKey",
                components: [
                    {
                        name: "chainId",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "emitterAddress",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "sequence",
                        type: "uint64",
                        internalType: "uint64",
                    },
                ],
            },
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "newReceiverValue",
                type: "uint256",
                internalType: "TargetNative",
            },
            {
                name: "newEncodedExecutionParameters",
                type: "bytes",
                internalType: "bytes",
            },
            {
                name: "newDeliveryProviderAddress",
                type: "address",
                internalType: "address",
            },
        ],
        outputs: [
            {
                name: "sequence",
                type: "uint64",
                internalType: "uint64",
            },
        ],
        stateMutability: "payable",
    },
    {
        type: "function",
        name: "resendToEvm",
        inputs: [
            {
                name: "deliveryVaaKey",
                type: "tuple",
                internalType: "struct VaaKey",
                components: [
                    {
                        name: "chainId",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "emitterAddress",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "sequence",
                        type: "uint64",
                        internalType: "uint64",
                    },
                ],
            },
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "newReceiverValue",
                type: "uint256",
                internalType: "TargetNative",
            },
            {
                name: "newGasLimit",
                type: "uint256",
                internalType: "Gas",
            },
            {
                name: "newDeliveryProviderAddress",
                type: "address",
                internalType: "address",
            },
        ],
        outputs: [
            {
                name: "sequence",
                type: "uint64",
                internalType: "uint64",
            },
        ],
        stateMutability: "payable",
    },
    {
        type: "function",
        name: "send",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "targetAddress",
                type: "bytes32",
                internalType: "bytes32",
            },
            {
                name: "payload",
                type: "bytes",
                internalType: "bytes",
            },
            {
                name: "receiverValue",
                type: "uint256",
                internalType: "TargetNative",
            },
            {
                name: "paymentForExtraReceiverValue",
                type: "uint256",
                internalType: "LocalNative",
            },
            {
                name: "encodedExecutionParameters",
                type: "bytes",
                internalType: "bytes",
            },
            {
                name: "refundChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "refundAddress",
                type: "bytes32",
                internalType: "bytes32",
            },
            {
                name: "deliveryProviderAddress",
                type: "address",
                internalType: "address",
            },
            {
                name: "vaaKeys",
                type: "tuple[]",
                internalType: "struct VaaKey[]",
                components: [
                    {
                        name: "chainId",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "emitterAddress",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "sequence",
                        type: "uint64",
                        internalType: "uint64",
                    },
                ],
            },
            {
                name: "consistencyLevel",
                type: "uint8",
                internalType: "uint8",
            },
        ],
        outputs: [
            {
                name: "sequence",
                type: "uint64",
                internalType: "uint64",
            },
        ],
        stateMutability: "payable",
    },
    {
        type: "function",
        name: "send",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "targetAddress",
                type: "bytes32",
                internalType: "bytes32",
            },
            {
                name: "payload",
                type: "bytes",
                internalType: "bytes",
            },
            {
                name: "receiverValue",
                type: "uint256",
                internalType: "TargetNative",
            },
            {
                name: "paymentForExtraReceiverValue",
                type: "uint256",
                internalType: "LocalNative",
            },
            {
                name: "encodedExecutionParameters",
                type: "bytes",
                internalType: "bytes",
            },
            {
                name: "refundChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "refundAddress",
                type: "bytes32",
                internalType: "bytes32",
            },
            {
                name: "deliveryProviderAddress",
                type: "address",
                internalType: "address",
            },
            {
                name: "messageKeys",
                type: "tuple[]",
                internalType: "struct MessageKey[]",
                components: [
                    {
                        name: "keyType",
                        type: "uint8",
                        internalType: "uint8",
                    },
                    {
                        name: "encodedKey",
                        type: "bytes",
                        internalType: "bytes",
                    },
                ],
            },
            {
                name: "consistencyLevel",
                type: "uint8",
                internalType: "uint8",
            },
        ],
        outputs: [
            {
                name: "sequence",
                type: "uint64",
                internalType: "uint64",
            },
        ],
        stateMutability: "payable",
    },
    {
        type: "function",
        name: "sendPayloadToEvm",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "targetAddress",
                type: "address",
                internalType: "address",
            },
            {
                name: "payload",
                type: "bytes",
                internalType: "bytes",
            },
            {
                name: "receiverValue",
                type: "uint256",
                internalType: "TargetNative",
            },
            {
                name: "gasLimit",
                type: "uint256",
                internalType: "Gas",
            },
            {
                name: "refundChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "refundAddress",
                type: "address",
                internalType: "address",
            },
        ],
        outputs: [
            {
                name: "sequence",
                type: "uint64",
                internalType: "uint64",
            },
        ],
        stateMutability: "payable",
    },
    {
        type: "function",
        name: "sendPayloadToEvm",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "targetAddress",
                type: "address",
                internalType: "address",
            },
            {
                name: "payload",
                type: "bytes",
                internalType: "bytes",
            },
            {
                name: "receiverValue",
                type: "uint256",
                internalType: "TargetNative",
            },
            {
                name: "gasLimit",
                type: "uint256",
                internalType: "Gas",
            },
        ],
        outputs: [
            {
                name: "sequence",
                type: "uint64",
                internalType: "uint64",
            },
        ],
        stateMutability: "payable",
    },
    {
        type: "function",
        name: "sendToEvm",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "targetAddress",
                type: "address",
                internalType: "address",
            },
            {
                name: "payload",
                type: "bytes",
                internalType: "bytes",
            },
            {
                name: "receiverValue",
                type: "uint256",
                internalType: "TargetNative",
            },
            {
                name: "paymentForExtraReceiverValue",
                type: "uint256",
                internalType: "LocalNative",
            },
            {
                name: "gasLimit",
                type: "uint256",
                internalType: "Gas",
            },
            {
                name: "refundChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "refundAddress",
                type: "address",
                internalType: "address",
            },
            {
                name: "deliveryProviderAddress",
                type: "address",
                internalType: "address",
            },
            {
                name: "vaaKeys",
                type: "tuple[]",
                internalType: "struct VaaKey[]",
                components: [
                    {
                        name: "chainId",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "emitterAddress",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "sequence",
                        type: "uint64",
                        internalType: "uint64",
                    },
                ],
            },
            {
                name: "consistencyLevel",
                type: "uint8",
                internalType: "uint8",
            },
        ],
        outputs: [
            {
                name: "sequence",
                type: "uint64",
                internalType: "uint64",
            },
        ],
        stateMutability: "payable",
    },
    {
        type: "function",
        name: "sendToEvm",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "targetAddress",
                type: "address",
                internalType: "address",
            },
            {
                name: "payload",
                type: "bytes",
                internalType: "bytes",
            },
            {
                name: "receiverValue",
                type: "uint256",
                internalType: "TargetNative",
            },
            {
                name: "paymentForExtraReceiverValue",
                type: "uint256",
                internalType: "LocalNative",
            },
            {
                name: "gasLimit",
                type: "uint256",
                internalType: "Gas",
            },
            {
                name: "refundChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "refundAddress",
                type: "address",
                internalType: "address",
            },
            {
                name: "deliveryProviderAddress",
                type: "address",
                internalType: "address",
            },
            {
                name: "messageKeys",
                type: "tuple[]",
                internalType: "struct MessageKey[]",
                components: [
                    {
                        name: "keyType",
                        type: "uint8",
                        internalType: "uint8",
                    },
                    {
                        name: "encodedKey",
                        type: "bytes",
                        internalType: "bytes",
                    },
                ],
            },
            {
                name: "consistencyLevel",
                type: "uint8",
                internalType: "uint8",
            },
        ],
        outputs: [
            {
                name: "sequence",
                type: "uint64",
                internalType: "uint64",
            },
        ],
        stateMutability: "payable",
    },
    {
        type: "function",
        name: "sendVaasToEvm",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "targetAddress",
                type: "address",
                internalType: "address",
            },
            {
                name: "payload",
                type: "bytes",
                internalType: "bytes",
            },
            {
                name: "receiverValue",
                type: "uint256",
                internalType: "TargetNative",
            },
            {
                name: "gasLimit",
                type: "uint256",
                internalType: "Gas",
            },
            {
                name: "vaaKeys",
                type: "tuple[]",
                internalType: "struct VaaKey[]",
                components: [
                    {
                        name: "chainId",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "emitterAddress",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "sequence",
                        type: "uint64",
                        internalType: "uint64",
                    },
                ],
            },
        ],
        outputs: [
            {
                name: "sequence",
                type: "uint64",
                internalType: "uint64",
            },
        ],
        stateMutability: "payable",
    },
    {
        type: "function",
        name: "sendVaasToEvm",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "targetAddress",
                type: "address",
                internalType: "address",
            },
            {
                name: "payload",
                type: "bytes",
                internalType: "bytes",
            },
            {
                name: "receiverValue",
                type: "uint256",
                internalType: "TargetNative",
            },
            {
                name: "gasLimit",
                type: "uint256",
                internalType: "Gas",
            },
            {
                name: "vaaKeys",
                type: "tuple[]",
                internalType: "struct VaaKey[]",
                components: [
                    {
                        name: "chainId",
                        type: "uint16",
                        internalType: "uint16",
                    },
                    {
                        name: "emitterAddress",
                        type: "bytes32",
                        internalType: "bytes32",
                    },
                    {
                        name: "sequence",
                        type: "uint64",
                        internalType: "uint64",
                    },
                ],
            },
            {
                name: "refundChain",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "refundAddress",
                type: "address",
                internalType: "address",
            },
        ],
        outputs: [
            {
                name: "sequence",
                type: "uint64",
                internalType: "uint64",
            },
        ],
        stateMutability: "payable",
    },
    {
        type: "event",
        name: "SendEvent",
        inputs: [
            {
                name: "sequence",
                type: "uint64",
                indexed: true,
                internalType: "uint64",
            },
            {
                name: "deliveryQuote",
                type: "uint256",
                indexed: false,
                internalType: "LocalNative",
            },
            {
                name: "paymentForExtraReceiverValue",
                type: "uint256",
                indexed: false,
                internalType: "LocalNative",
            },
        ],
        anonymous: false,
    },
    {
        type: "error",
        name: "DeliveryProviderCannotReceivePayment",
        inputs: [],
    },
    {
        type: "error",
        name: "DeliveryProviderDoesNotSupportMessageKeyType",
        inputs: [
            {
                name: "keyType",
                type: "uint8",
                internalType: "uint8",
            },
        ],
    },
    {
        type: "error",
        name: "DeliveryProviderDoesNotSupportTargetChain",
        inputs: [
            {
                name: "relayer",
                type: "address",
                internalType: "address",
            },
            {
                name: "chainId",
                type: "uint16",
                internalType: "uint16",
            },
        ],
    },
    {
        type: "error",
        name: "InvalidMsgValue",
        inputs: [
            {
                name: "msgValue",
                type: "uint256",
                internalType: "LocalNative",
            },
            {
                name: "totalFee",
                type: "uint256",
                internalType: "LocalNative",
            },
        ],
    },
    {
        type: "error",
        name: "TooManyMessageKeys",
        inputs: [
            {
                name: "numMessageKeys",
                type: "uint256",
                internalType: "uint256",
            },
        ],
    },
    {
        type: "error",
        name: "UnexpectedExecutionInfoVersion",
        inputs: [
            {
                name: "version",
                type: "uint8",
                internalType: "uint8",
            },
            {
                name: "expectedVersion",
                type: "uint8",
                internalType: "uint8",
            },
        ],
    },
];
export class WormholeRelayerSend__factory {
    static createInterface() {
        return new utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    }
}
WormholeRelayerSend__factory.abi = _abi;
