var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AptosAccount, BCS, HexString, TxnBuilderTypes, } from "aptos";
import { hexZeroPad } from "ethers/lib/utils";
import { sha3_256 } from "js-sha3";
import { CHAIN_ID_APTOS, coalesceChainId, ensureHexPrefix, hex, } from "../utils";
/**
 * Generate, sign, and submit a transaction calling the given entry function with the given
 * arguments. Prevents transaction submission and throws if the transaction fails.
 *
 * This is separated from `generateSignAndSubmitScript` because it makes use of `AptosClient`'s
 * `generateTransaction` which pulls ABIs from the node and uses them to encode arguments
 * automatically.
 * @param client Client used to transfer data to/from Aptos node
 * @param sender Account that will submit transaction
 * @param payload Payload containing unencoded fully qualified entry function, types, and arguments
 * @param opts Override default transaction options
 * @returns Data from transaction after is has been successfully submitted to mempool
 */
export const generateSignAndSubmitEntryFunction = (client, sender, payload, opts) => {
    return client
        .generateTransaction(sender.address(), payload, opts)
        .then((rawTx) => signAndSubmitTransaction(client, sender, rawTx));
};
/**
 * Generate, sign, and submit a transaction containing given bytecode. Prevents transaction
 * submission and throws if the transaction fails.
 *
 * Unlike `generateSignAndSubmitEntryFunction`, this function must construct a `RawTransaction`
 * manually because `generateTransaction` does not have support for scripts for which there are
 * no corresponding on-chain ABIs. Type/argument encoding is left to the caller.
 * @param client Client used to transfer data to/from Aptos node
 * @param sender Account that will submit transaction
 * @param payload Payload containing compiled bytecode and encoded types/arguments
 * @param opts Override default transaction options
 * @returns Data from transaction after is has been successfully submitted to mempool
 */
export const generateSignAndSubmitScript = (client, sender, payload, opts) => __awaiter(void 0, void 0, void 0, function* () {
    // overwriting `max_gas_amount` and `gas_unit_price` defaults
    // rest of defaults are defined here: https://aptos-labs.github.io/ts-sdk-doc/classes/AptosClient.html#generateTransaction
    const customOpts = Object.assign({
        gas_unit_price: "100",
        max_gas_amount: "30000",
    }, opts);
    // create raw transaction
    const [{ sequence_number: sequenceNumber }, chainId] = yield Promise.all([
        client.getAccount(sender.address()),
        client.getChainId(),
    ]);
    const rawTx = new TxnBuilderTypes.RawTransaction(TxnBuilderTypes.AccountAddress.fromHex(sender.address()), BigInt(sequenceNumber), payload, BigInt(customOpts.max_gas_amount), BigInt(customOpts.gas_unit_price), BigInt(Math.floor(Date.now() / 1000) + 10), new TxnBuilderTypes.ChainId(chainId));
    // sign & submit transaction
    return signAndSubmitTransaction(client, sender, rawTx);
});
/**
 * Derives the fully qualified type of the asset defined by the given origin chain and address.
 * @param tokenBridgeAddress Address of token bridge (32 bytes)
 * @param originChain Chain ID of chain that original asset is from
 * @param originAddress Native address of asset; if origin chain ID is 22 (Aptos), this is the
 * asset's fully qualified type
 * @returns The fully qualified type on Aptos for the given asset
 */
export const getAssetFullyQualifiedType = (tokenBridgeAddress, originChain, originAddress) => {
    // native asset
    if (originChain === CHAIN_ID_APTOS) {
        // originAddress should be of form address::module::type
        if (!isValidAptosType(originAddress)) {
            console.error("Invalid qualified type");
            return null;
        }
        return ensureHexPrefix(originAddress);
    }
    // non-native asset, derive unique address
    const wrappedAssetAddress = getForeignAssetAddress(tokenBridgeAddress, originChain, originAddress);
    return wrappedAssetAddress
        ? `${ensureHexPrefix(wrappedAssetAddress)}::coin::T`
        : null;
};
/**
 * Derive the module address for an asset defined by the given origin chain and address.
 * @param tokenBridgeAddress Address of token bridge (32 bytes)
 * @param originChain Chain ID of chain that original asset is from
 * @param originAddress Native address of asset
 * @returns The module address for the given asset
 */
export const getForeignAssetAddress = (tokenBridgeAddress, originChain, originAddress) => {
    if (originChain === CHAIN_ID_APTOS) {
        return null;
    }
    // from https://github.com/aptos-labs/aptos-core/blob/25696fd266498d81d346fe86e01c330705a71465/aptos-move/framework/aptos-framework/sources/account.move#L90-L95
    const DERIVE_RESOURCE_ACCOUNT_SCHEME = Buffer.alloc(1);
    DERIVE_RESOURCE_ACCOUNT_SCHEME.writeUInt8(255);
    let chain = Buffer.alloc(2);
    chain.writeUInt16BE(originChain);
    return sha3_256(Buffer.concat([
        hex(hexZeroPad(ensureHexPrefix(tokenBridgeAddress), 32)),
        chain,
        Buffer.from("::", "ascii"),
        hex(hexZeroPad(ensureHexPrefix(originAddress), 32)),
        DERIVE_RESOURCE_ACCOUNT_SCHEME,
    ]));
};
/**
 * Test if given string is a valid fully qualified type of moduleAddress::moduleName::structName.
 * @param str String to test
 * @returns Whether or not given string is a valid type
 */
export const isValidAptosType = (str) => /^(0x)?[0-9a-fA-F]+::\w+::\w+$/.test(str);
/**
 * Hashes the given type. Because fully qualified types are a concept unique to Aptos, this
 * output acts as the address on other chains.
 * @param fullyQualifiedType Fully qualified type on Aptos
 * @returns External address corresponding to given type
 */
export const getExternalAddressFromType = (fullyQualifiedType) => {
    // hash the type so it fits into 32 bytes
    return sha3_256(fullyQualifiedType);
};
/**
 * Given a hash, returns the fully qualified type by querying the corresponding TypeInfo.
 * @param client Client used to transfer data to/from Aptos node
 * @param tokenBridgeAddress Address of token bridge
 * @param fullyQualifiedTypeHash Hash of fully qualified type
 * @returns The fully qualified type associated with the given hash
 */
export function getTypeFromExternalAddress(client, tokenBridgeAddress, fullyQualifiedTypeHash) {
    return __awaiter(this, void 0, void 0, function* () {
        // get handle
        tokenBridgeAddress = ensureHexPrefix(tokenBridgeAddress);
        const state = (yield client.getAccountResource(tokenBridgeAddress, `${tokenBridgeAddress}::state::State`)).data;
        const handle = state.native_infos.handle;
        try {
            // get type info
            const typeInfo = yield client.getTableItem(handle, {
                key_type: `${tokenBridgeAddress}::token_hash::TokenHash`,
                value_type: "0x1::type_info::TypeInfo",
                key: { hash: fullyQualifiedTypeHash },
            });
            if (!typeInfo) {
                return null;
            }
            // construct type
            const moduleName = Buffer.from(typeInfo.module_name.substring(2), "hex").toString("ascii");
            const structName = Buffer.from(typeInfo.struct_name.substring(2), "hex").toString("ascii");
            return `${typeInfo.account_address}::${moduleName}::${structName}`;
        }
        catch (_a) {
            return null;
        }
    });
}
/**
 * Returns module address from given fully qualified type/module address.
 * @param str FQT or module address
 * @returns Module address
 */
export const coalesceModuleAddress = (str) => {
    return str.split("::")[0];
};
/**
 * The NFT bridge creates resource accounts, which in turn create a collection
 * and mint a single token for each transferred NFT. This method derives the
 * address of that resource account from the given origin chain and address.
 * @param nftBridgeAddress
 * @param originChain
 * @param originAddress External address of NFT on origin chain
 * @returns Address of resource account
 */
export const deriveResourceAccountAddress = (nftBridgeAddress, originChain, originAddress) => __awaiter(void 0, void 0, void 0, function* () {
    const originChainId = coalesceChainId(originChain);
    if (originChainId === CHAIN_ID_APTOS) {
        return null;
    }
    const chainId = Buffer.alloc(2);
    chainId.writeUInt16BE(originChainId);
    const seed = Buffer.concat([chainId, Buffer.from(originAddress)]);
    const resourceAccountAddress = yield AptosAccount.getResourceAccountAddress(nftBridgeAddress, seed);
    return resourceAccountAddress.toString();
});
/**
 * Get a hash that uniquely identifies a collection on Aptos.
 * @param tokenId
 * @returns Collection hash
 */
export const deriveCollectionHashFromTokenId = (tokenId) => __awaiter(void 0, void 0, void 0, function* () {
    const inputs = Buffer.concat([
        BCS.bcsToBytes(TxnBuilderTypes.AccountAddress.fromHex(tokenId.token_data_id.creator)),
        Buffer.from(sha3_256(tokenId.token_data_id.collection), "hex"),
    ]);
    return new Uint8Array(Buffer.from(sha3_256(inputs), "hex"));
});
/**
 * Get a hash that uniquely identifies a token on Aptos.
 *
 * Native tokens in Aptos are uniquely identified by a hash of creator address,
 * collection name, token name, and property version. This hash is converted to
 * a bigint in the `tokenId` field in NFT transfer VAAs.
 * @param tokenId
 * @returns Token hash identifying the token
 */
export const deriveTokenHashFromTokenId = (tokenId) => __awaiter(void 0, void 0, void 0, function* () {
    const propertyVersion = Buffer.alloc(8);
    propertyVersion.writeBigUInt64BE(BigInt(tokenId.property_version));
    const inputs = Buffer.concat([
        BCS.bcsToBytes(TxnBuilderTypes.AccountAddress.fromHex(tokenId.token_data_id.creator)),
        Buffer.from(sha3_256(tokenId.token_data_id.collection), "hex"),
        Buffer.from(sha3_256(tokenId.token_data_id.name), "hex"),
        propertyVersion,
    ]);
    return new Uint8Array(Buffer.from(sha3_256(inputs), "hex"));
});
/**
 * Get creator address, collection name, token name, and property version from
 * a token hash. Note that this method is meant to be used for native tokens
 * that have already been registered in the NFT bridge.
 *
 * The token hash is stored in the `tokenId` field of NFT transfer VAAs and
 * is calculated by the operations in `deriveTokenHashFromTokenId`.
 * @param client
 * @param nftBridgeAddress
 * @param tokenHash Token hash
 * @returns Token ID
 */
export const getTokenIdFromTokenHash = (client, nftBridgeAddress, tokenHash) => __awaiter(void 0, void 0, void 0, function* () {
    const state = (yield client.getAccountResource(nftBridgeAddress, `${nftBridgeAddress}::state::State`)).data;
    const handle = state.native_infos.handle;
    const { token_data_id, property_version } = (yield client.getTableItem(handle, {
        key_type: `${nftBridgeAddress}::token_hash::TokenHash`,
        value_type: `0x3::token::TokenId`,
        key: {
            hash: HexString.fromUint8Array(tokenHash).hex(),
        },
    }));
    return { token_data_id, property_version };
});
/**
 * Simulates given raw transaction and either returns the resulting transaction that was submitted
 * to the mempool, or throws if it fails.
 * @param client Client used to transfer data to/from Aptos node
 * @param sender Account that will submit transaction
 * @param rawTx Raw transaction to sign & submit
 * @returns Transaction data
 */
const signAndSubmitTransaction = (client, sender, rawTx) => __awaiter(void 0, void 0, void 0, function* () {
    // simulate transaction
    yield client.simulateTransaction(sender, rawTx).then((sims) => sims.forEach((tx) => {
        if (!tx.success) {
            throw new Error(`Transaction failed: ${tx.vm_status}\n${JSON.stringify(tx, null, 2)}`);
        }
    }));
    // sign & submit transaction
    return client
        .signTransaction(sender, rawTx)
        .then((signedTx) => client.submitTransaction(signedTx))
        .then((pendingTx) => client.waitForTransactionWithResult(pendingTx.hash));
});
