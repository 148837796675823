var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getApplicationAddress } from "algosdk";
import { Bridge__factory } from "../ethers-contracts";
import { getWrappedMeta } from "../solana/tokenBridge";
import { getTokenFromTokenRegistry } from "../sui";
import { coalesceModuleAddress, ensureHexPrefix } from "../utils";
import { safeBigIntToNumber } from "../utils/bigint";
/**
 * Returns whether or not an asset address on Ethereum is a wormhole wrapped asset
 * @param tokenBridgeAddress
 * @param provider
 * @param assetAddress
 * @returns
 */
export function getIsWrappedAssetEth(tokenBridgeAddress, provider, assetAddress) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!assetAddress)
            return false;
        const tokenBridge = Bridge__factory.connect(tokenBridgeAddress, provider);
        return yield tokenBridge.isWrappedAsset(assetAddress);
    });
}
// TODO: this doesn't seem right
export function getIsWrappedAssetTerra(tokenBridgeAddress, client, assetAddress) {
    return __awaiter(this, void 0, void 0, function* () {
        return false;
    });
}
/**
 * Returns whether or not an asset on Solana is a wormhole wrapped asset
 * @param connection
 * @param tokenBridgeAddress
 * @param mintAddress
 * @param [commitment]
 * @returns
 */
export function getIsWrappedAssetSolana(connection, tokenBridgeAddress, mintAddress, commitment) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!mintAddress) {
            return false;
        }
        return getWrappedMeta(connection, tokenBridgeAddress, mintAddress, commitment)
            .catch((_) => null)
            .then((meta) => meta != null);
    });
}
export const getIsWrappedAssetSol = getIsWrappedAssetSolana;
/**
 * Returns whethor or not an asset on Algorand is a wormhole wrapped asset
 * @param client Algodv2 client
 * @param tokenBridgeId token bridge ID
 * @param assetId Algorand asset index
 * @returns true if the asset is wrapped
 */
export function getIsWrappedAssetAlgorand(client, tokenBridgeId, assetId) {
    return __awaiter(this, void 0, void 0, function* () {
        if (assetId === BigInt(0)) {
            return false;
        }
        const tbAddr = getApplicationAddress(tokenBridgeId);
        const assetInfo = yield client.getAssetByID(safeBigIntToNumber(assetId)).do();
        const creatorAddr = assetInfo.params.creator;
        const creatorAcctInfo = yield client.accountInformation(creatorAddr).do();
        const wormhole = creatorAcctInfo["auth-addr"] === tbAddr;
        return wormhole;
    });
}
export function getIsWrappedAssetNear(tokenBridge, asset) {
    return asset.endsWith("." + tokenBridge);
}
/**
 * Determines whether or not given address is wrapped or native to Aptos.
 * @param client Client used to transfer data to/from Aptos node
 * @param tokenBridgeAddress Address of token bridge
 * @param assetFullyQualifiedType Fully qualified type of asset
 * @returns True if asset is wrapped
 */
export function getIsWrappedAssetAptos(client, tokenBridgeAddress, assetFullyQualifiedType) {
    return __awaiter(this, void 0, void 0, function* () {
        assetFullyQualifiedType = ensureHexPrefix(assetFullyQualifiedType);
        try {
            // get origin info from asset address
            yield client.getAccountResource(coalesceModuleAddress(assetFullyQualifiedType), `${tokenBridgeAddress}::state::OriginInfo`);
            return true;
        }
        catch (_a) {
            return false;
        }
    });
}
export function getIsWrappedAssetSui(provider, tokenBridgeStateObjectId, type) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function* () {
        // An easy way to determine if given asset isn't a wrapped asset is to ensure
        // module name and struct name are coin and COIN respectively.
        if (!type.endsWith("::coin::COIN")) {
            return false;
        }
        const response = yield getTokenFromTokenRegistry(provider, tokenBridgeStateObjectId, type);
        if (!response.error) {
            return ((_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.type) === null || _b === void 0 ? void 0 : _b.includes("WrappedAsset")) || false;
        }
        if (response.error.code === "dynamicFieldNotFound") {
            return false;
        }
        throw new Error(`Unexpected getDynamicFieldObject response ${response.error}`);
    });
}
