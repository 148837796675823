/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { utils, Contract, ContractFactory } from "ethers";
const _abi = [
    {
        type: "function",
        name: "setup",
        inputs: [
            {
                name: "implementation",
                type: "address",
                internalType: "address",
            },
            {
                name: "chainId",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "wormhole",
                type: "address",
                internalType: "address",
            },
            {
                name: "governanceChainId",
                type: "uint16",
                internalType: "uint16",
            },
            {
                name: "governanceContract",
                type: "bytes32",
                internalType: "bytes32",
            },
            {
                name: "tokenImplementation",
                type: "address",
                internalType: "address",
            },
            {
                name: "finality",
                type: "uint8",
                internalType: "uint8",
            },
            {
                name: "evmChainId",
                type: "uint256",
                internalType: "uint256",
            },
        ],
        outputs: [],
        stateMutability: "nonpayable",
    },
    {
        type: "event",
        name: "AdminChanged",
        inputs: [
            {
                name: "previousAdmin",
                type: "address",
                indexed: false,
                internalType: "address",
            },
            {
                name: "newAdmin",
                type: "address",
                indexed: false,
                internalType: "address",
            },
        ],
        anonymous: false,
    },
    {
        type: "event",
        name: "BeaconUpgraded",
        inputs: [
            {
                name: "beacon",
                type: "address",
                indexed: true,
                internalType: "address",
            },
        ],
        anonymous: false,
    },
    {
        type: "event",
        name: "Upgraded",
        inputs: [
            {
                name: "implementation",
                type: "address",
                indexed: true,
                internalType: "address",
            },
        ],
        anonymous: false,
    },
];
const _bytecode = "0x608060405234801561001057600080fd5b5061038d806100206000396000f3fe608060405234801561001057600080fd5b506004361061002b5760003560e01c8063363ce96b14610030575b600080fd5b61004361003e3660046102c6565b610045565b005b60028054600080546001600160a01b038a166001600160a01b031990911617905561ffff878116620100000263ffffffff19909216908a1617179055600384905561008f836100ed565b6002805464ff00000000191664010000000060ff8516021790556100b28161016a565b6100bb886101b3565b6100e3886001600160a01b03166000908152600660205260409020805460ff19166001179055565b5050505050505050565b6001600160a01b0381166101485760405162461bcd60e51b815260206004820152601e60248201527f696e76616c696420696d706c656d656e746174696f6e2061646472657373000060448201526064015b60405180910390fd5b600180546001600160a01b0319166001600160a01b0392909216919091179055565b4681146101ae5760405162461bcd60e51b81526020600482015260126024820152711a5b9d985b1a5908195d9b50da185a5b925960721b604482015260640161013f565b600b55565b6101bc816101f3565b6040516001600160a01b038216907fbc7cd75a20ee27fd9adebab32041f755214dbc6bffa90cc0225b39da2e5c2d3b90600090a250565b803b6102575760405162461bcd60e51b815260206004820152602d60248201527f455243313936373a206e657720696d706c656d656e746174696f6e206973206e60448201526c1bdd08184818dbdb9d1c9858dd609a1b606482015260840161013f565b7f360894a13ba1a3210667c828492db98dca3e2076cc3735a920a3ca505d382bbc80546001600160a01b0319166001600160a01b0392909216919091179055565b80356001600160a01b03811681146102af57600080fd5b919050565b803561ffff811681146102af57600080fd5b600080600080600080600080610100898b0312156102e2578384fd5b6102eb89610298565b97506102f960208a016102b4565b965061030760408a01610298565b955061031560608a016102b4565b94506080890135935061032a60a08a01610298565b925060c089013560ff8116811461033f578283fd5b8092505060e08901359050929598509295989093965056fea2646970667358221220179052b1aadac0c19b523be5051e65cc4f29a3ed4e0680b5847efd37659b4a6a64736f6c63430008040033";
const isSuperArgs = (xs) => xs.length > 1;
export class NFTBridgeSetup__factory extends ContractFactory {
    constructor(...args) {
        if (isSuperArgs(args)) {
            super(...args);
        }
        else {
            super(_abi, _bytecode, args[0]);
        }
    }
    deploy(overrides) {
        return super.deploy(overrides || {});
    }
    getDeployTransaction(overrides) {
        return super.getDeployTransaction(overrides || {});
    }
    attach(address) {
        return super.attach(address);
    }
    connect(signer) {
        return super.connect(signer);
    }
    static createInterface() {
        return new utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    }
}
NFTBridgeSetup__factory.bytecode = _bytecode;
NFTBridgeSetup__factory.abi = _abi;
