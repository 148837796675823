/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Contract, utils } from "ethers";
const _abi = [
    {
        type: "function",
        name: "deliver",
        inputs: [
            {
                name: "encodedVMs",
                type: "bytes[]",
                internalType: "bytes[]",
            },
            {
                name: "encodedDeliveryVAA",
                type: "bytes",
                internalType: "bytes",
            },
            {
                name: "relayerRefundAddress",
                type: "address",
                internalType: "address payable",
            },
            {
                name: "deliveryOverrides",
                type: "bytes",
                internalType: "bytes",
            },
        ],
        outputs: [],
        stateMutability: "payable",
    },
    {
        type: "function",
        name: "deliveryAttempted",
        inputs: [
            {
                name: "deliveryHash",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
        outputs: [
            {
                name: "attempted",
                type: "bool",
                internalType: "bool",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "deliveryFailureBlock",
        inputs: [
            {
                name: "deliveryHash",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
        outputs: [
            {
                name: "blockNumber",
                type: "uint256",
                internalType: "uint256",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "deliverySuccessBlock",
        inputs: [
            {
                name: "deliveryHash",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
        outputs: [
            {
                name: "blockNumber",
                type: "uint256",
                internalType: "uint256",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "function",
        name: "getRegisteredWormholeRelayerContract",
        inputs: [
            {
                name: "chainId",
                type: "uint16",
                internalType: "uint16",
            },
        ],
        outputs: [
            {
                name: "",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
        stateMutability: "view",
    },
    {
        type: "event",
        name: "Delivery",
        inputs: [
            {
                name: "recipientContract",
                type: "address",
                indexed: true,
                internalType: "address",
            },
            {
                name: "sourceChain",
                type: "uint16",
                indexed: true,
                internalType: "uint16",
            },
            {
                name: "sequence",
                type: "uint64",
                indexed: true,
                internalType: "uint64",
            },
            {
                name: "deliveryVaaHash",
                type: "bytes32",
                indexed: false,
                internalType: "bytes32",
            },
            {
                name: "status",
                type: "uint8",
                indexed: false,
                internalType: "enum IWormholeRelayerDelivery.DeliveryStatus",
            },
            {
                name: "gasUsed",
                type: "uint256",
                indexed: false,
                internalType: "Gas",
            },
            {
                name: "refundStatus",
                type: "uint8",
                indexed: false,
                internalType: "enum IWormholeRelayerDelivery.RefundStatus",
            },
            {
                name: "additionalStatusInfo",
                type: "bytes",
                indexed: false,
                internalType: "bytes",
            },
            {
                name: "overridesInfo",
                type: "bytes",
                indexed: false,
                internalType: "bytes",
            },
        ],
        anonymous: false,
    },
    {
        type: "event",
        name: "SendEvent",
        inputs: [
            {
                name: "sequence",
                type: "uint64",
                indexed: true,
                internalType: "uint64",
            },
            {
                name: "deliveryQuote",
                type: "uint256",
                indexed: false,
                internalType: "LocalNative",
            },
            {
                name: "paymentForExtraReceiverValue",
                type: "uint256",
                indexed: false,
                internalType: "LocalNative",
            },
        ],
        anonymous: false,
    },
    {
        type: "error",
        name: "DeliveryProviderCannotReceivePayment",
        inputs: [],
    },
    {
        type: "error",
        name: "InsufficientRelayerFunds",
        inputs: [
            {
                name: "msgValue",
                type: "uint256",
                internalType: "LocalNative",
            },
            {
                name: "minimum",
                type: "uint256",
                internalType: "LocalNative",
            },
        ],
    },
    {
        type: "error",
        name: "InvalidDeliveryVaa",
        inputs: [
            {
                name: "reason",
                type: "string",
                internalType: "string",
            },
        ],
    },
    {
        type: "error",
        name: "InvalidEmitter",
        inputs: [
            {
                name: "emitter",
                type: "bytes32",
                internalType: "bytes32",
            },
            {
                name: "registered",
                type: "bytes32",
                internalType: "bytes32",
            },
            {
                name: "chainId",
                type: "uint16",
                internalType: "uint16",
            },
        ],
    },
    {
        type: "error",
        name: "InvalidOverrideGasLimit",
        inputs: [],
    },
    {
        type: "error",
        name: "InvalidOverrideReceiverValue",
        inputs: [],
    },
    {
        type: "error",
        name: "InvalidPayloadId",
        inputs: [
            {
                name: "parsed",
                type: "uint8",
                internalType: "uint8",
            },
            {
                name: "expected",
                type: "uint8",
                internalType: "uint8",
            },
        ],
    },
    {
        type: "error",
        name: "InvalidPayloadLength",
        inputs: [
            {
                name: "received",
                type: "uint256",
                internalType: "uint256",
            },
            {
                name: "expected",
                type: "uint256",
                internalType: "uint256",
            },
        ],
    },
    {
        type: "error",
        name: "MessageKeysLengthDoesNotMatchMessagesLength",
        inputs: [
            {
                name: "keys",
                type: "uint256",
                internalType: "uint256",
            },
            {
                name: "vaas",
                type: "uint256",
                internalType: "uint256",
            },
        ],
    },
    {
        type: "error",
        name: "NotAnEvmAddress",
        inputs: [
            {
                name: "",
                type: "bytes32",
                internalType: "bytes32",
            },
        ],
    },
    {
        type: "error",
        name: "ReentrantDelivery",
        inputs: [
            {
                name: "msgSender",
                type: "address",
                internalType: "address",
            },
            {
                name: "lockedBy",
                type: "address",
                internalType: "address",
            },
        ],
    },
    {
        type: "error",
        name: "TargetChainIsNotThisChain",
        inputs: [
            {
                name: "targetChain",
                type: "uint16",
                internalType: "uint16",
            },
        ],
    },
    {
        type: "error",
        name: "UnexpectedExecutionInfoVersion",
        inputs: [
            {
                name: "version",
                type: "uint8",
                internalType: "uint8",
            },
            {
                name: "expectedVersion",
                type: "uint8",
                internalType: "uint8",
            },
        ],
    },
    {
        type: "error",
        name: "VaaKeysDoNotMatchVaas",
        inputs: [
            {
                name: "index",
                type: "uint8",
                internalType: "uint8",
            },
        ],
    },
    {
        type: "error",
        name: "VersionMismatchOverride",
        inputs: [
            {
                name: "instructionVersion",
                type: "uint8",
                internalType: "uint8",
            },
            {
                name: "overrideVersion",
                type: "uint8",
                internalType: "uint8",
            },
        ],
    },
];
export class WormholeRelayerDelivery__factory {
    static createInterface() {
        return new utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    }
}
WormholeRelayerDelivery__factory.abi = _abi;
