import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { PublicKey, } from "@solana/web3.js";
import { TOKEN_METADATA_PROGRAM_ID, deriveTokenMetadataKey } from "../../utils";
import { getPostMessageAccounts } from "../../wormhole";
import { deriveAuthoritySignerKey, deriveNftBridgeConfigKey, deriveWrappedMetaKey, deriveWrappedMintKey, } from "../accounts";
import { createReadOnlyNftBridgeProgramInterface } from "../program";
export function createTransferWrappedInstruction(nftBridgeProgramId, wormholeProgramId, payer, message, from, fromOwner, tokenChain, tokenAddress, tokenId, nonce, targetAddress, targetChain) {
    const methods = createReadOnlyNftBridgeProgramInterface(nftBridgeProgramId).methods.transferWrapped(nonce, Buffer.from(targetAddress), targetChain);
    // @ts-ignore
    return methods._ixFn(...methods._args, {
        accounts: getTransferWrappedAccounts(nftBridgeProgramId, wormholeProgramId, payer, message, from, fromOwner, tokenChain, tokenAddress, tokenId),
        signers: undefined,
        remainingAccounts: undefined,
        preInstructions: undefined,
        postInstructions: undefined,
    });
}
export function getTransferWrappedAccounts(nftBridgeProgramId, wormholeProgramId, payer, message, from, fromOwner, tokenChain, tokenAddress, tokenId) {
    const mint = deriveWrappedMintKey(nftBridgeProgramId, tokenChain, tokenAddress, tokenId);
    const { bridge: wormholeBridge, message: wormholeMessage, emitter: wormholeEmitter, sequence: wormholeSequence, feeCollector: wormholeFeeCollector, clock, rent, systemProgram, } = getPostMessageAccounts(wormholeProgramId, payer, nftBridgeProgramId, message);
    return {
        payer: new PublicKey(payer),
        config: deriveNftBridgeConfigKey(nftBridgeProgramId),
        from: new PublicKey(from),
        fromOwner: new PublicKey(fromOwner),
        mint,
        wrappedMeta: deriveWrappedMetaKey(nftBridgeProgramId, mint),
        splMetadata: deriveTokenMetadataKey(mint),
        authoritySigner: deriveAuthoritySignerKey(nftBridgeProgramId),
        wormholeBridge,
        wormholeMessage,
        wormholeEmitter,
        wormholeSequence,
        wormholeFeeCollector,
        clock,
        rent,
        systemProgram,
        tokenProgram: TOKEN_PROGRAM_ID,
        splMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
        wormholeProgram: new PublicKey(wormholeProgramId),
    };
}
