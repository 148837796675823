var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { PublicKey, Transaction, } from "@solana/web3.js";
import { Bridge__factory } from "../ethers-contracts";
import { createCompleteTransferNativeInstruction, createCompleteTransferWrappedInstruction, createCompleteWrappedMetaInstruction, } from "../solana/nftBridge";
import { CHAIN_ID_APTOS, CHAIN_ID_SOLANA } from "../utils";
import { parseNftTransferVaa, parseVaa } from "../vaa";
export function redeemOnEth(nftBridgeAddress, signer, signedVAA, overrides = {}) {
    return __awaiter(this, void 0, void 0, function* () {
        const bridge = Bridge__factory.connect(nftBridgeAddress, signer);
        const v = yield bridge.completeTransfer(signedVAA, overrides);
        const receipt = yield v.wait();
        return receipt;
    });
}
export function isNFTVAASolanaNative(signedVAA) {
    return __awaiter(this, void 0, void 0, function* () {
        return parseVaa(signedVAA).payload.readUInt16BE(33) === CHAIN_ID_SOLANA;
    });
}
export function redeemOnSolana(connection, bridgeAddress, nftBridgeAddress, payerAddress, signedVaa, toAuthorityAddress, commitment) {
    return __awaiter(this, void 0, void 0, function* () {
        const parsed = parseNftTransferVaa(signedVaa);
        const createCompleteTransferInstruction = parsed.tokenChain == CHAIN_ID_SOLANA
            ? createCompleteTransferNativeInstruction
            : createCompleteTransferWrappedInstruction;
        const transaction = new Transaction().add(createCompleteTransferInstruction(nftBridgeAddress, bridgeAddress, payerAddress, parsed, toAuthorityAddress));
        const { blockhash } = yield connection.getLatestBlockhash(commitment);
        transaction.recentBlockhash = blockhash;
        transaction.feePayer = new PublicKey(payerAddress);
        return transaction;
    });
}
export function createMetaOnSolana(connection, bridgeAddress, nftBridgeAddress, payerAddress, signedVaa, commitment) {
    return __awaiter(this, void 0, void 0, function* () {
        const parsed = parseNftTransferVaa(signedVaa);
        if (parsed.tokenChain == CHAIN_ID_SOLANA) {
            return Promise.reject("parsed.tokenChain == CHAIN_ID_SOLANA");
        }
        const transaction = new Transaction().add(createCompleteWrappedMetaInstruction(nftBridgeAddress, bridgeAddress, payerAddress, parsed));
        const { blockhash } = yield connection.getLatestBlockhash(commitment);
        transaction.recentBlockhash = blockhash;
        transaction.feePayer = new PublicKey(payerAddress);
        return transaction;
    });
}
export function redeemOnAptos(nftBridgeAddress, transferVAA) {
    return __awaiter(this, void 0, void 0, function* () {
        const parsedVAA = parseNftTransferVaa(transferVAA);
        if (parsedVAA.toChain !== CHAIN_ID_APTOS) {
            throw new Error("Transfer is not destined for Aptos.");
        }
        return {
            function: `${nftBridgeAddress}::complete_transfer::submit_vaa_and_register_entry`,
            type_arguments: [],
            arguments: [transferVAA],
        };
    });
}
