export {
  createSwitchScope,
  //
  Switch,
  SwitchThumb,
  //
  Root,
  Thumb,
} from './Switch';
export type { SwitchProps, SwitchThumbProps } from './Switch';
