var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { PublicKey, } from "@solana/web3.js";
import { deriveAddress, getAccountData } from "../../utils";
export function deriveTokenBridgeConfigKey(tokenBridgeProgramId) {
    return deriveAddress([Buffer.from("config")], tokenBridgeProgramId);
}
export function getTokenBridgeConfig(connection, tokenBridgeProgramId, commitment) {
    return __awaiter(this, void 0, void 0, function* () {
        return connection
            .getAccountInfo(deriveTokenBridgeConfigKey(tokenBridgeProgramId), commitment)
            .then((info) => TokenBridgeConfig.deserialize(getAccountData(info)));
    });
}
export class TokenBridgeConfig {
    constructor(wormholeProgramId) {
        this.wormhole = new PublicKey(wormholeProgramId);
    }
    static deserialize(data) {
        if (data.length != 32) {
            throw new Error("data.length != 32");
        }
        const wormholeProgramId = data.subarray(0, 32);
        return new TokenBridgeConfig(wormholeProgramId);
    }
}
