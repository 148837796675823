import { ethers } from "ethers";
export var RelayerPayloadId;
(function (RelayerPayloadId) {
    RelayerPayloadId[RelayerPayloadId["Delivery"] = 1] = "Delivery";
    RelayerPayloadId[RelayerPayloadId["Redelivery"] = 2] = "Redelivery";
})(RelayerPayloadId || (RelayerPayloadId = {}));
export var ExecutionInfoVersion;
(function (ExecutionInfoVersion) {
    ExecutionInfoVersion[ExecutionInfoVersion["EVM_V1"] = 0] = "EVM_V1";
})(ExecutionInfoVersion || (ExecutionInfoVersion = {}));
export var DeliveryStatus;
(function (DeliveryStatus) {
    DeliveryStatus["WaitingForVAA"] = "Waiting for VAA";
    DeliveryStatus["PendingDelivery"] = "Pending Delivery";
    DeliveryStatus["DeliverySuccess"] = "Delivery Success";
    DeliveryStatus["ReceiverFailure"] = "Receiver Failure";
    DeliveryStatus["ThisShouldNeverHappen"] = "This should never happen. Contact Support.";
})(DeliveryStatus || (DeliveryStatus = {}));
export var RefundStatus;
(function (RefundStatus) {
    RefundStatus["RefundSent"] = "Refund Sent";
    RefundStatus["RefundFail"] = "Refund Fail";
    RefundStatus["CrossChainRefundSent"] = "Cross Chain Refund Sent";
    RefundStatus["CrossChainRefundFailProviderNotSupported"] = "Cross Chain Refund Fail - Provider does not support the refund chain";
    RefundStatus["CrossChainRefundFailNotEnough"] = "Cross Chain Refund Fail - Refund too low for cross chain refund";
    RefundStatus["RefundAddressNotProvided"] = "No refund address provided";
    RefundStatus["InvalidRefundStatus"] = "Invalid refund status";
})(RefundStatus || (RefundStatus = {}));
export function parseRefundStatus(index) {
    return index === 0
        ? RefundStatus.RefundSent
        : index === 1
            ? RefundStatus.RefundFail
            : index === 2
                ? RefundStatus.CrossChainRefundSent
                : index === 3
                    ? RefundStatus.CrossChainRefundFailProviderNotSupported
                    : index === 4
                        ? RefundStatus.CrossChainRefundFailNotEnough
                        : index === 5
                            ? RefundStatus.RefundAddressNotProvided
                            : RefundStatus.InvalidRefundStatus;
}
export var KeyType;
(function (KeyType) {
    KeyType[KeyType["VAA"] = 1] = "VAA";
    KeyType[KeyType["CCTP"] = 2] = "CCTP";
})(KeyType || (KeyType = {}));
export var VaaKeyType;
(function (VaaKeyType) {
    VaaKeyType[VaaKeyType["EMITTER_SEQUENCE"] = 0] = "EMITTER_SEQUENCE";
    VaaKeyType[VaaKeyType["VAAHASH"] = 1] = "VAAHASH";
})(VaaKeyType || (VaaKeyType = {}));
export function parseWormholeRelayerPayloadType(stringPayload) {
    const payload = typeof stringPayload === "string"
        ? ethers.utils.arrayify(stringPayload)
        : stringPayload;
    if (payload[0] != RelayerPayloadId.Delivery &&
        payload[0] != RelayerPayloadId.Redelivery) {
        throw new Error("Unrecognized payload type " + payload[0]);
    }
    return payload[0];
}
export function createVaaKey(chainId, emitterAddress, sequence) {
    return {
        chainId,
        emitterAddress,
        sequence: ethers.BigNumber.from(sequence),
    };
}
export function parseWormholeRelayerSend(bytes) {
    let idx = 0;
    const payloadId = bytes.readUInt8(idx);
    if (payloadId !== RelayerPayloadId.Delivery) {
        throw new Error(`Expected Delivery payload type (${RelayerPayloadId.Delivery}), found: ${payloadId}`);
    }
    idx += 1;
    const targetChainId = bytes.readUInt16BE(idx);
    idx += 2;
    const targetAddress = bytes.slice(idx, idx + 32);
    idx += 32;
    let payload;
    [payload, idx] = parsePayload(bytes, idx);
    const requestedReceiverValue = ethers.BigNumber.from(Uint8Array.prototype.subarray.call(bytes, idx, idx + 32));
    idx += 32;
    const extraReceiverValue = ethers.BigNumber.from(Uint8Array.prototype.subarray.call(bytes, idx, idx + 32));
    idx += 32;
    let encodedExecutionInfo;
    [encodedExecutionInfo, idx] = parsePayload(bytes, idx);
    const refundChainId = bytes.readUInt16BE(idx);
    idx += 2;
    const refundAddress = bytes.slice(idx, idx + 32);
    idx += 32;
    const refundDeliveryProvider = bytes.slice(idx, idx + 32);
    idx += 32;
    const sourceDeliveryProvider = bytes.slice(idx, idx + 32);
    idx += 32;
    const senderAddress = bytes.slice(idx, idx + 32);
    idx += 32;
    const numMessages = bytes.readUInt8(idx);
    idx += 1;
    let messageKeys = [];
    for (let i = 0; i < numMessages; ++i) {
        const res = parseMessageKey(bytes, idx);
        idx = res[1];
        messageKeys.push(res[0]);
    }
    return {
        targetChainId,
        targetAddress,
        payload,
        requestedReceiverValue,
        extraReceiverValue,
        encodedExecutionInfo,
        refundChainId,
        refundAddress,
        refundDeliveryProvider,
        sourceDeliveryProvider,
        senderAddress,
        messageKeys: messageKeys,
    };
}
function parsePayload(bytes, idx) {
    const length = bytes.readUInt32BE(idx);
    idx += 4;
    const payload = bytes.slice(idx, idx + length);
    idx += length;
    return [payload, idx];
}
export function parseMessageKey(_bytes, idx) {
    const bytes = Buffer.from(ethers.utils.arrayify(_bytes));
    const keyType = bytes.readUInt8(idx);
    idx += 1;
    if (keyType === KeyType.VAA) {
        const vaaKeyLength = 2 + 32 + 8;
        return [
            { keyType, key: bytes.slice(idx, idx + vaaKeyLength) },
            idx + 2 + 32 + 8,
        ];
    }
    else {
        const len = bytes.readUInt32BE(idx);
        idx += 4;
        return [{ keyType, key: bytes.slice(idx, idx + len) }, idx + len];
    }
}
export function packMessageKey(key) {
    const encodedKey = ethers.utils.arrayify(key.key);
    const bytes = Buffer.alloc(1 + 4 + encodedKey.length);
    let idx = 0;
    bytes.writeUInt8(key.keyType, idx);
    idx += 1;
    if (key.keyType === KeyType.VAA) {
        bytes.fill(encodedKey, idx);
    }
    else {
        const encodedKey = ethers.utils.arrayify(key.key);
        bytes.writeUInt32BE(encodedKey.length, idx);
        idx += 4;
        bytes.fill(encodedKey, idx);
    }
    return ethers.utils.hexlify(bytes);
}
export function parseCCTPKey(_bytes) {
    const bytes = Buffer.from(ethers.utils.arrayify(_bytes));
    const domain = bytes.readUInt32BE(0);
    const nonce = ethers.BigNumber.from(bytes.readBigUInt64BE(4));
    return { domain, nonce };
}
export function packCCTPKey(key) {
    const buf = Buffer.alloc(4 + 8);
    buf.writeUInt32BE(key.domain, 0);
    buf.writeBigUInt64BE(key.nonce.toBigInt(), 4);
    return ethers.utils.hexlify(buf);
}
export function parseVaaKey(_bytes) {
    const bytes = Buffer.from(ethers.utils.arrayify(_bytes));
    let idx = 0;
    const chainId = bytes.readUInt16BE(idx);
    idx += 2;
    const emitterAddress = bytes.slice(idx, idx + 32);
    idx += 32;
    const sequence = ethers.BigNumber.from(Uint8Array.prototype.subarray.call(bytes, idx, idx + 8));
    idx += 8;
    return {
        chainId,
        emitterAddress,
        sequence,
    };
}
export function packVaaKey(vaaKey) {
    const bytes = Buffer.alloc(2 + 32 + 8);
    bytes.writeUInt16BE(vaaKey.chainId, 0);
    bytes.fill(vaaKey.emitterAddress, 2, 34);
    bytes.writeBigUInt64BE(vaaKey.sequence.toBigInt(), 34);
    return ethers.utils.hexlify(bytes);
}
export function packCCTPMessage(message) {
    return ethers.utils.defaultAbiCoder.encode(["bytes", "bytes"], [message.message, message.signature]);
}
export function parseCCTPMessage(bytes) {
    const [message, signature] = ethers.utils.defaultAbiCoder.decode(["bytes", "bytes"], bytes);
    return { message, signature };
}
export function parseEVMExecutionInfoV1(bytes, idx) {
    idx += 31;
    const version = bytes.readUInt8(idx);
    idx += 1;
    if (version !== ExecutionInfoVersion.EVM_V1) {
        throw new Error("Unexpected Execution Info version");
    }
    const gasLimit = ethers.BigNumber.from(Uint8Array.prototype.subarray.call(bytes, idx, idx + 32));
    idx += 32;
    const targetChainRefundPerGasUnused = ethers.BigNumber.from(Uint8Array.prototype.subarray.call(bytes, idx, idx + 32));
    idx += 32;
    return [{ gasLimit, targetChainRefundPerGasUnused }, idx];
}
export function packEVMExecutionInfoV1(info) {
    return ethers.utils.defaultAbiCoder.encode(["uint8", "uint256", "uint256"], [
        ExecutionInfoVersion.EVM_V1,
        info.gasLimit,
        info.targetChainRefundPerGasUnused,
    ]);
}
export function parseWormholeRelayerResend(bytes) {
    let idx = 0;
    const payloadId = bytes.readUInt8(idx);
    if (payloadId !== RelayerPayloadId.Redelivery) {
        throw new Error(`Expected Delivery payload type (${RelayerPayloadId.Redelivery}), found: ${payloadId}`);
    }
    idx += 1;
    let parsedMessageKey;
    [parsedMessageKey, idx] = parseMessageKey(bytes, idx);
    const key = parseVaaKey(parsedMessageKey.key);
    const targetChainId = bytes.readUInt16BE(idx);
    idx += 2;
    const newRequestedReceiverValue = ethers.BigNumber.from(Uint8Array.prototype.subarray.call(bytes, idx, idx + 32));
    idx += 32;
    let newEncodedExecutionInfo;
    [newEncodedExecutionInfo, idx] = parsePayload(bytes, idx);
    const newSourceDeliveryProvider = bytes.slice(idx, idx + 32);
    idx += 32;
    const newSenderAddress = bytes.slice(idx, idx + 32);
    idx += 32;
    return {
        deliveryVaaKey: key,
        targetChainId,
        newRequestedReceiverValue,
        newEncodedExecutionInfo,
        newSourceDeliveryProvider,
        newSenderAddress,
    };
}
export function executionInfoToString(encodedExecutionInfo) {
    const [parsed] = parseEVMExecutionInfoV1(encodedExecutionInfo, 0);
    return `Gas limit: ${parsed.gasLimit}, Target chain refund per unit gas unused: ${parsed.targetChainRefundPerGasUnused}`;
}
export function deliveryInstructionsPrintable(ix) {
    return {
        targetChainId: ix.targetChainId.toString(),
        targetAddress: ix.targetAddress.toString("hex"),
        payload: ix.payload.toString("base64"),
        requestedReceiverValue: ix.requestedReceiverValue.toString(),
        extraReceiverValue: ix.extraReceiverValue.toString(),
        encodedExecutionInfo: executionInfoToString(ix.encodedExecutionInfo),
        refundChainId: ix.refundChainId.toString(),
        refundAddress: ix.refundAddress.toString("hex"),
        refundDeliveryProvider: ix.refundDeliveryProvider.toString("hex"),
        sourceDeliveryProvider: ix.sourceDeliveryProvider.toString("hex"),
        senderAddress: ix.senderAddress.toString("hex"),
        messageKeys: ix.messageKeys.map(messageKeyPrintable),
    };
}
export function messageKeyPrintable(ix) {
    switch (ix.keyType) {
        case KeyType.VAA:
            return Object.assign({ keyType: "VAA" }, vaaKeyPrintable(parseVaaKey(ix.key)));
        case KeyType.CCTP:
            return Object.assign({ keyType: "CCTP" }, cctpKeyPrintable(parseCCTPKey(ix.key)));
        default:
            return {
                keyType: ix.keyType.toString(),
                key: ethers.utils.hexlify(ix.key),
            };
    }
}
export function vaaKeyPrintable(ix) {
    var _a, _b, _c;
    return {
        chainId: (_a = ix.chainId) === null || _a === void 0 ? void 0 : _a.toString(),
        emitterAddress: (_b = ix.emitterAddress) === null || _b === void 0 ? void 0 : _b.toString("hex"),
        sequence: (_c = ix.sequence) === null || _c === void 0 ? void 0 : _c.toString(),
    };
}
export function cctpKeyPrintable(ix) {
    return {
        domain: ix.domain.toString(),
        nonce: ix.nonce.toString(),
    };
}
export function redeliveryInstructionPrintable(ix) {
    return {
        deliveryVaaKey: vaaKeyPrintable(ix.deliveryVaaKey),
        targetChainId: ix.targetChainId.toString(),
        newRequestedReceiverValue: ix.newRequestedReceiverValue.toString(),
        newEncodedExecutionInfo: executionInfoToString(ix.newEncodedExecutionInfo),
        newSourceDeliveryProvider: ix.newSourceDeliveryProvider.toString("hex"),
        newSenderAddress: ix.newSenderAddress.toString("hex"),
    };
}
export function packOverrides(overrides) {
    const packed = [
        ethers.utils.solidityPack(["uint8"], [1]).substring(2),
        ethers.utils
            .solidityPack(["uint256"], [overrides.newReceiverValue])
            .substring(2),
        ethers.utils
            .solidityPack(["uint32"], [overrides.newExecutionInfo.length])
            .substring(2),
        overrides.newExecutionInfo.toString("hex"),
        overrides.redeliveryHash.toString("hex"), //toString('hex') doesn't add the 0x prefix
    ].join("");
    return "0x" + packed;
}
export function parseOverrideInfoFromDeliveryEvent(bytes) {
    let idx = 0;
    const version = bytes.readUInt8(idx);
    idx += 1;
    const newReceiverValue = ethers.BigNumber.from(Uint8Array.prototype.subarray.call(bytes, idx, idx + 32));
    idx += 32;
    let newExecutionInfo;
    [newExecutionInfo, idx] = parsePayload(bytes, idx);
    const redeliveryHash = bytes.slice(idx, idx + 32);
    idx += 32;
    return {
        newReceiverValue,
        newExecutionInfo,
        redeliveryHash,
    };
}
/*
 * Helpers
 */
export function dbg(x, msg) {
    if (msg) {
        console.log("[DEBUG] " + msg);
    }
    console.log(x);
    return x;
}
